import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaketourComponent } from './taketour.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material/material.module';
import { SliderModule } from '../../shared/components/slider/slider.module';
import { TaketourRoutingModule } from './taketour-routing.module';



@NgModule({
  declarations: [TaketourComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SliderModule,
    TaketourRoutingModule
  ],
  exports:[TaketourComponent]
})
export class TaketourModule { }
