<div class="container-fluid">
    <mat-card class="mom-body-content">
        <mat-card-content>
            <p class="pPageHeading">{{pageTitle}}</p>
            <form [formGroup]="form" class="interfaceTab">
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="Minutes of Meeting">
                      <div class="h500tabMin scroller">
                        <div class="row rowBottomPadding">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <label class="headerLabel">Status</label><br />
                            <label class="labelView">{{this.momStatusName}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <label class="headerLabel">Interface Group</label><br />
                            <label class="labelView">{{momModel.InterfaceGroup?.Name}}</label>
                          </div>
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <label class="headerLabel">Module</label><br />
                            <label class="labelView">{{momModel.Module?.Name}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">Title</label><br />
                            <label class="labelView">{{momModel.Title}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">{{this.contractor1Name}} Attendee Name</label><br />
                            <label class="labelView">{{this.momExternalAttendees.Contractor1AttendeeNames}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">{{this.contractor2Name}} Attendee Name</label><br />
                            <label class="labelView">{{this.momExternalAttendees.Contractor2AttendeeNames}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding labelView">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">Attendees from {{this.contractor1Name}}</label><br />
                            <mat-chip-list #requestingUserList aria-label="Attendees from Contractor 1"
                                           formControlName="requestingUsersFormControl" name="requestingUsers">
                              <mat-chip *ngFor="let user of selectedRequestingContractorUsers"
                                        (removed)="removeRequestingContractorUsers(user)">
                                {{user.FirstName}} {{user.LastName}}
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                        </div>
                        <div class="row rowBottomPadding labelView">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">Attendees from {{this.contractor2Name}}</label><br />
                            <mat-chip-list class="labelView" #responsibleUserList formControlName="responsibleUsersFormControl"
                                           aria-label="Attendees from Contractor 2">
                              <mat-chip *ngFor="let user of selectedResponsibleContractorUsers"
                                        (removed)="removeResponsibleContractorUsers(user)">
                                {{user.FirstName}} {{user.LastName}}
                              </mat-chip>
                            </mat-chip-list>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">External Attendees(if any)</label><br />
                            <label class="labelView">{{this.momExternalAttendees.ExternalAttendees}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <label class="headerLabel">Location</label><br />
                            <label class="labelView">{{momModel.MeetingLocation}}</label>
                          </div>
                          <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                            <label class="headerLabel">Meeting Date</label><br />
                            <label class="labelView">{{momModel.MeetingDate | date:dateTimeFormat : utcoffsetvalue}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <label class="headerLabel">Next Meeting Location</label><br />
                            <label class="labelView">{{momModel.NextMeetingLocation}}</label>
                          </div>
                          <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                            <label class="headerLabel">Next Meeting Date</label><br />
                            <label class="labelView">{{momModel.NextMeetingDate | date:dateTimeFormat : utcoffsetvalue}}</label>
                          </div>
                        </div>
                        <div class="row rowBottomPadding">
                          <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                            <label class="headerLabel">Description</label><br />
                            <label class="labelView enable-line-break">{{momModel.MeetingDescription}}</label>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab [label]="this.tabModuleTypeName">
                        <div class="h500tabMin scroller">                           
                            <div>                               
                                    <mat-accordion>
                                        <mat-expansion-panel #panel hideToggle
                                            *ngFor="let momdetailsdata of this.momModel.MinutesOfMeetingDetails;let index = index;trackBy:trackByIndex;"
                                            [expanded]="i==0" class="margin-bottom-5 listPanel">
                                            <mat-expansion-panel-header #panelHeader
                                                class="card-border {{panel.expanded ? 'listPanel-large' : 'listPanel'}}">
                                                <mat-panel-title class=" align-items-center card-div col-md-10 ">
                                                    <div class="col-md-3">
                                                        <span
                                                            [ngClass]="commonFunction.getCardColorOnStatus(momdetailsdata.Interfaces.Status, momdetailsdata.Interfaces?.IsApprovedIssuePlannedOverdue,momdetailsdata.Interfaces?.IsFirstIssuePlannedOverdue,momdetailsdata.Interfaces?.Response)"
                                                            class="status-dot"></span>
                                                        <span class="padding-15">{{ commonFunction.getStatusText(momdetailsdata.Interfaces.Status)}} </span>
                                                    </div>
                                                    <div>
                                                        <div class="display-flex font-color-darkgrey">
                                                            <div class="fw-bolder">#{{momdetailsdata.Interfaces.Number}} </div>
                                                        </div>
                                                        <div>
                                                            {{panel.expanded ? momdetailsdata.Interfaces.Title : momdetailsdata.Interfaces.Title |
                                                            shorten:125}}
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                                <div
                                                    class="hide col-md-2 align-self-center {{panel.expanded ? 'display-flex' : ''}}">
                                                    <div >
                                                        <button class="button-icon-circle align-items-center " 
                                                        [hasPermission]="'mom.view'" 
                                                        mat-mini-fab color="accent">
                                                          <mat-icon class="icon-size-18" matTooltip="View" (click)="onView(momdetailsdata.Interfaces.Id)">visibility</mat-icon>
                                                        </button>
                                                      </div>                                                    
                                                </div>
                                            </mat-expansion-panel-header>
                                            <div class="padding-top-bottom-2 card-div ">
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                                        <div class="col-md-1">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Description:
                                                            </mat-label>
                                                        </div>
                                                        <div
                                                            class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                                                            {{momdetailsdata.Interfaces.Description}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10">
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{expectedResponseDateLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdetailsdata.Interfaces.FirstIssuePlanned |
                                                                date:dateTimeFormat}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{closeOutDateLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdetailsdata.Interfaces.ApprovedIssuePlanned
                                                                | date:dateTimeFormat}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Created Date:
                                                            </mat-label>
                                                            <mat-label class="padding-left-20">{{momdetailsdata.Interfaces.CreatedDate |
                                                                date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class=" g-2 display-flex"
                                                    *ngIf="momdetailsdata.Interfaces.FirstIssueActual != null || momdetailsdata.Interfaces.ApprovedIssueActual != null">
                                                    <div class="row col-md-12 margin-top-bottom-10">
                                                        <div class="col-md-4 display-flex"
                                                            *ngIf="momdetailsdata.Interfaces.FirstIssueActual != null">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{responseDateActualLabel}}:
                                                            </mat-label>
                                                            <mat-label class="padding-left-20">{{momdetailsdata.Interfaces.Status ==
                                                                this.awaitingForResponseApprovalStatusId? '' :
                                                                momdetailsdata.Interfaces.FirstIssueActual |
                                                                date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                        <div class="col-md-4 display-flex"
                                                            *ngIf="momdetailsdata.Interfaces.ApprovedIssueActual != null && momdetailsdata.Interfaces.Status != this.awaitingForCloseOutStatusId">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                {{closeOutDateActualLabel}}:
                                                            </mat-label>
                                                            <mat-label
                                                                class="padding-left-20">{{momdetailsdata.Interfaces.ApprovedIssueActual
                                                                | date:dateTimeFormat : utcoffsetvalue}}</mat-label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class=" g-2 display-flex">
                                                    <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                                        <div class="col-md-1">
                                                            <mat-label class="font-color-darkgrey fw-bolder">
                                                                Meeting Minutes:
                                                            </mat-label>
                                                        </div>
                                                        <div
                                                            class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">                                                          
                                                            <mat-form-field appearance="fill"> <textarea matInput [disabled]="true"
                                                              [(ngModel)]="momdetailsdata.Text" [ngModelOptions]="{standalone: true}"
                                                                    class="heightTextArea"></textarea> 
                                                                </mat-form-field>                                                             
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                            </div>
                        </div>                       
                    </mat-tab>

                    <mat-tab [label]="documentCount">
                        <div class="h500tabMin overflow-hidden">
                          <app-document-view></app-document-view>
                        </div>
                    </mat-tab>  

                    <mat-tab label="Action List">
                      <div class="h500tabMin">
                         <app-action-item-list-view></app-action-item-list-view> 
                      </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="mt-2 mb-2" align="right"> 
                      <app-pdf-generate [reportName]="reportName" [projectName]="projectName" #PdfGenerateComponent></app-pdf-generate>
                      <button mat-stroked-button (click)="generateMomSheet()" type="submit" class="WIRbutton WIRbutton-primary btn-sm">
                        <mat-icon>download</mat-icon>Download Minutes of Meeting Sheet
                      </button>
                      
                                         
                    <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
                        <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
