import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceService } from 'src/app/services/interface.service';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { ActivatedRoute } from '@angular/router';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { Stage } from 'src/app/app_data/stage';
import { StageService } from 'src/app/services/stage.service';
import { Subscription } from 'src/app/app_data/subscription';
import { SubscriptionBillingPlanService } from 'src/app/services/subscriptionBillingPlan.service';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import { formatDate } from '@angular/common';

let Interfacegrplists: InterfaceGroup[] = [];
@Component({
  selector: 'app-interfacegroup-edit',
  templateUrl: './interfacegroup-edit.component.html',
  styleUrls: ['./interfacegroup-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InterfacegroupEditComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  interfacegroupForm !: FormGroup;
  selectedICcontractorGroup: number = 0;
  interfacegroupModel !: InterfaceGroup;
  errorMessage = '';
  isEdit: boolean = false;
  icContractors: Contractor[] = [];
  subscriptionBillingPlan : Subscription;
  totalInterfaceGroupsCount : number  = 0;
  interfaceGroups: InterfaceGroup[] = [];
  dataSource = Interfacegrplists;
  contractor !: Contractor;
  @Input() interfacegrp!: InterfaceGroup;
  @Input() contractorId!: number;
  @Output() interfacegrpadded: EventEmitter<InterfaceGroup[]> = new EventEmitter();
  selectedValue: any;
  editInterfaceGroupId: number = 0;
  stages : Stage[] = [];
  workflowMappings : WorkflowMapping[] =[];
  isApprovedClicked : string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = this.contractorId;
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  displayedColumns: string[] = ['Code', 'Name', 'Contractor1.Name', 'Contractor2.Name','Action'];
  interfacegroupdataSource = new MatTableDataSource<InterfaceGroup>(Interfacegrplists);
  @ViewChild('sortinterfacegrp') sortinterfacegrp !: MatSort;
  @ViewChild('paginatorinterfacegrp') paginatorinterfacegrp !: MatPaginator;
  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortinterfacegrp = ms;
    this.interfacegroupdataSource.paginator = this.paginatorinterfacegrp;
    this.interfacegroupdataSource.sort = this.sortinterfacegrp;
    this.interfacegroupdataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(private contractorService: ContractorService, private communicationService: CommunicationService,
    private subscriptionBillingPlanService: SubscriptionBillingPlanService,
    private interfaceGroupService: InterfaceGroupService,
    private dialog: DialogService,
    private interfaceService: InterfaceService,
    private route :ActivatedRoute,
    private logger: NGXLogger,
    private stageService: StageService) {
    this.interfacegrp = new InterfaceGroup(); 
    this.contractor = new Contractor();
    this.subscriptionBillingPlan = new Subscription();
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property.includes('.')) {
        return property.split('.')
          .reduce((object: { [x: string]: any; }, key: string | number) => object[key], item);
      }
      return item[property];
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ngOnInit(): void {
    this.interfacegroupForm = new FormGroup({
      Contractor1ID: new FormControl(''),
      Contractor2ID: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Name: new FormControl('', [Validators.required]),
      IsOpenApproval: new FormControl(''),
      IsCloseoutApproval:new FormControl('')
    });
    this._contractorID = this.route.snapshot.params['id']
    this.fillProviderContractorDDL(this.contractorId);
    this.getInterfaceGroupsByProjectId();
    this.getSubscriptionBillingPlan();
    this.communicationService.is_contractor$.subscribe(emivalue => {
      this.contractor.Contractor1InterfaceGroups = emivalue.Contractor1InterfaceGroups.filter(x => !x.IsDeleted);
      this.contractor.Contractor2InterfaceGroups = emivalue.Contractor2InterfaceGroups.filter(x => !x.IsDeleted);
      
      if (emivalue.Contractor1InterfaceGroups.length > 0) {
        
        let finaliterfacegrps = emivalue.Contractor1InterfaceGroups.filter(x => !x.IsDeleted);
        emivalue.Contractor2InterfaceGroups.filter(x => !x.IsDeleted).forEach((row) => {
          finaliterfacegrps.push(row);
        });
        this.interfacegroupdataSource.data = finaliterfacegrps;
        this.interfaceGroups = finaliterfacegrps;               
      }
      else {
        let finaliterfacegrps = emivalue.Contractor2InterfaceGroups.filter(x => !x.IsDeleted);
        emivalue.Contractor1InterfaceGroups.filter(x => !x.IsDeleted).forEach((row) => {
          finaliterfacegrps.push(row);
        });
        this.interfacegroupdataSource.data = finaliterfacegrps;
        this.interfaceGroups = finaliterfacegrps;
      }     
    });
    
    this.getWorkflowStageMaster();   
  }

  getInterfaceGroupsByProjectId(): void {
    try {
      this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(this._projectID).subscribe({
        next: interfaceGroups => {
          this.totalInterfaceGroupsCount = interfaceGroups.length;          
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getSubscriptionBillingPlan(): void {
    try {
      this.subscriptionBillingPlanService.getSubscriptionByProjectId(this._projectID).subscribe({
        next: subscriptionData => {
          if(subscriptionData != null && subscriptionData.length > 0){
            this.subscriptionBillingPlan = subscriptionData[0];  
          }
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  validateSubscriptionBillingPlan(): boolean{
    try {
      if(this.subscriptionBillingPlan != null && this,this.subscriptionBillingPlan.Id != 0){        
        var maxNoOfInterfaceGroupCount = this.subscriptionBillingPlan.BillingPlan?.MaxNoOfInterfaceGroups!;      
        
        //check subscription is valid or not
        if (formatDate(new Date(),'yyyy-MM-dd','en_US') > formatDate(this.subscriptionBillingPlan.ValidTill,'yyyy-MM-dd','en_US')) 
        {
          return false;
        }

        //check max no of interfaceGroup count 
        if(this.totalInterfaceGroupsCount > maxNoOfInterfaceGroupCount){
          return false;
        }

        return true;
      }
      else{
        return false;
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
      return false
    }
  }

  fillProviderContractorDDL(Id: number): void {
    try {
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: providerContractor => {
          this.icContractors = providerContractor.filter(x => !x.IsDeleted && x.Id != Id);
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangevalue(e: MatSelectChange) {
    this.selectedValue = e.source.triggerValue;
  }

  add() {
    try {
      this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) + 1;
      //Validate Subscription billing plan
      if (this.validateSubscriptionBillingPlan()) {        
        let uniqueCheck = this.contractor.Contractor1InterfaceGroups.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == this.interfacegrp.Code.toLowerCase() || x.Name.toLowerCase() == this.interfacegrp.Name.toLowerCase()
          || (x.Contractor2ID == this.interfacegrp.Contractor2ID)));
        let uniqueCheck2 = this.contractor.Contractor2InterfaceGroups.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == this.interfacegrp.Code.toLowerCase() || x.Name.toLowerCase() == this.interfacegrp.Name.toLowerCase()
          || (x.Contractor1ID == this.interfacegrp.Contractor2ID)));
        if (uniqueCheck.length <= 0 && uniqueCheck2.length <= 0) {

          this.interfaceGroups.push({ Id: 0, Contractor1ID: Number(this._contractorID), Contractor2ID: this.interfacegrp.Contractor2ID, Name: this.interfacegrp.Name, IsDeleted: false, Code: this.interfacegrp.Code, ProjectID: this._projectID, CreatedBy: this._userRoleMappingId, Contractor2: { Name: this.selectedValue }, Contractor1: { Name: this.communicationService._contractorname }, ModifiedBy: this._userRoleMappingId});
          this.contractor.Contractor1InterfaceGroups.push({ Id: 0, Contractor1ID: Number(this._contractorID), Contractor2ID: this.interfacegrp.Contractor2ID, Name: this.interfacegrp.Name, IsDeleted: false, Code: this.interfacegrp.Code, ProjectID: this._projectID, CreatedBy: this._userRoleMappingId, ModifiedBy: this._userRoleMappingId})
          this.interfacegroupdataSource.data = this.interfaceGroups.filter(x => !x.IsDeleted);
          this.interfacegrpadded.emit(this.contractor.Contractor1InterfaceGroups);
          this.interfacegroupForm.reset();                 
        }
        else {
          this.dialog.confirmDialog({
            title: 'Warning',
            message: 'Code, Name, Contractor 2 already exists!!',
            cancelCaption: 'Close',
          });
        }
      }else{
        this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) - 1;

        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Your Subscription max limit exceeds. Please contact WIR Support',
          cancelCaption: 'Close',
        });
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(code: string, name: string) {
    try {
      this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) - 1;
      
      let interfaceGroupModel = this.interfaceGroups.filter(x => (x.Code.toLowerCase() == code.toLowerCase() && x.Name.toLowerCase() == name.toLowerCase()));

      interfaceGroupModel[0].IsDeleted = true;
      interfaceGroupModel[0].ModifiedBy = this._userRoleMappingId;

      this.interfacegroupdataSource.data = this.interfaceGroups.filter(x => !x.IsDeleted);
      this.interfacegrpadded.emit(this.interfaceGroups);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  edit(IFGrp: InterfaceGroup) {
    try {
      
      this.stages.forEach(x=>{
        x.IsStageCLicked =false;
      })

      this.isEdit = true;
      this.interfacegroupForm.controls['Code'].setValue(IFGrp.Code);
      this.interfacegroupForm.controls['Name'].setValue(IFGrp.Name);
      
      if (this.icContractors.filter(x => x.Id == IFGrp.Contractor2ID).length > 0) {
        this.interfacegroupForm.controls['Contractor2ID'].setValue(IFGrp.Contractor2ID);
      }
      else {
        this.interfacegroupForm.controls['Contractor2ID'].setValue(IFGrp.Contractor1ID);
      }     
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetForm() {
    this.isEdit = false;
    this.interfacegroupForm.reset();
    this.getWorkflowStageMaster();
  }

  update(newItem: any) {
    try {
      this.isEdit = false;
      let updateItem = this.interfacegroupdataSource.data.filter(x => x.Contractor2ID == this.interfacegrp.Contractor2ID);
      let index = this.interfacegroupdataSource.data.indexOf(updateItem[0]);
      this.interfacegroupdataSource.data[index].Code = this.interfacegrp.Code;
      this.interfacegroupdataSource.data[index].Name = this.interfacegrp.Name;
      
      this.interfacegroupdataSource.data[index].ModifiedBy = this._userRoleMappingId;
      
      this.interfacegrpadded.emit(this.contractor.Contractor1InterfaceGroups.filter(x => !x.IsDeleted));
      this.interfacegroupForm.reset();
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(Id:number, code:string, name: string) {
    try {
      let filter = ' &$filter=(InterfaceGroupID eq ' + Id + ') and (ReceivingContractorID eq ' + this.contractorId + ' or ResponsibleContractorID eq  ' + this.contractorId + ')';
      this.interfaceService.getAllInterfaces(filter).subscribe({
        next: interfaces => {
          if (interfaces.length > 0) {
            this.dialog.confirmDialog({
              title: 'Information',
              message: 'You can not delete this record as it is used in application',
              cancelCaption: 'Ok',
            });
          }
          else {
            this.dialog.confirmDialog({
              title: 'Confirm Action',
              message: 'Do you want to delete this interface group?',
              confirmCaption: 'Confirm',
              cancelCaption: 'Cancel',
            })
              .subscribe((confirmed) => {
                if (confirmed) {
                  this.delete(code, name);
                }
              });
          }
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getWorkflowStageMaster(): void {
    try {
      this.stageService.getWorkflow().subscribe({
        next: stageMasterData => {
          this.stages = stageMasterData;
          
          if (this.workflowMappings.length == 0) {
            this.stages.forEach(
              x => {
                if (!x.IsApproveRequired) {
                  this.workflowMappings.push({ Id: 0, WorkflowTemplateId: 0, StageId: x.Id, SequenceNumber: x.SequenceNumber, CreatedBy: this._userRoleMappingId, IsDeleted: false, EmailTemplateId: 0 })
                }
              }
            );
          }          
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  approvalChange(ev: any, stageId: number, stageName: string) {
    try {
      let result = this.stages.filter(x => x.Id == stageId);
     
      if (result.length > 0) {      
        result[0].IsStageCLicked = ev.target.checked;
        this.isApprovedClicked = stageName +'_' + String(result[0].Id);

        let wfmDataIndex = this.workflowMappings.findIndex(z => z.StageId == result[0].Id);
        let wfmData = this.workflowMappings.filter(z => z.StageId == result[0].Id);
                   
        if (wfmDataIndex !== -1) {          
          if(wfmData.length > 0){
            wfmData[0].IsDeleted = true;
         }
        } else {
          this.workflowMappings.push({ Id: 0, WorkflowTemplateId: 0, StageId: result[0].Id, SequenceNumber: result[0].SequenceNumber, CreatedBy: this._userRoleMappingId, IsDeleted: false, EmailTemplateId:0 })
        }  
      }    

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
