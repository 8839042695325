<form [formGroup]="discussionsForm">
  <div class="row">
    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Comment</mat-label>
        <input matInput maxlength="256" autocomplete="off" placeholder="Comment" [(ngModel)]="discussion.Comment"
               formControlName="Comment" #nameInput appTrimWhitespace>
        <button *ngIf="discussion.Comment" matSuffix mat-icon-button aria-label="Clear" (click)="discussion.Comment=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="(discussionsForm.get('Comment'))?.errors?.required">
          Comment is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="discussionsForm.invalid" (click)="add()"
              class="WIRbutton WIRbutton-primary btn-sm">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorDiscussion [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="discussionDataSource" class="mat-elevation-z8" matSort #sortDiscussion="matSort">
        <ng-container matColumnDef="CommentBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size">Comment By</th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contractor != null ? (disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName + ' ('+ disc.Contractor.Name+')' : this._userName) : disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName : this._userName}}</td>
        </ng-container>
        <ng-container matColumnDef="Comment">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Comment </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Comment}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Date/Time </th>
          <td mat-cell *matCellDef="let disc">
            {{disc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{disc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
