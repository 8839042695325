import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Document } from '../app_data/document';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class DocumentService {
  private apiUrl = environment.apiURl + 'Documents';

  constructor(private http: HttpClient) {

  }

  post(model: Document): Observable<Document> {
    return this.http.post<Document>(this.apiUrl, model, httpOptions);

  }

  put(model: Document): Observable<Document> {
    return this.http.put<Document>(this.apiUrl + "/" + model.Id, model, httpOptions);

  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
