<div class="table-container">
    <table class="normalTable">
      <tr class="headerRow">
        <td rowspan="9" class="cell label1Rotate">
          <div class="label1Rotate">
            <span class="headerText">Probability</span>
          </div>
        </td>
      </tr>
      <tr class="headerRow">
        <td colspan="7" class="cell headerText">
          <span class="headerText">Risk Matrix 5 * 5</span>
        </td>
      </tr>
      <tr class="consequenceRow">
        <td colspan="7" class="cell consequenceText">
          <span class="consequenceText">Consequence</span>
        </td>
      </tr>
      <tr>
        <td class="cell consequenceProbabilityCell">
          <span class="consequenceText">Consequence</span><br>
          <span class="consequenceText">Vs.</span><br>
          <span class="consequenceText">Probability</span>
        </td>
        <td class="cell">VeryLow(1)</td>
        <td class="cell">Low(2)</td>
        <td class="cell">Medium(3)</td>
        <td class="cell">High(4)</td>
        <td class="cell">VeryHigh(5)</td>
      </tr>
      <tr>
        <td class="cell consequenceText">VeryHigh(5)</td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(1, 5)" [disabled]="getRiskCount(1, 5) == ''" [ngClass]="getButtonClass(1, 5, getRiskCount(1, 5))">{{ getRiskCount(1, 5) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(2, 5)" [disabled]="getRiskCount(2, 5) == ''" [ngClass]="getButtonClass(2, 5, getRiskCount(2, 5))">{{ getRiskCount(2, 5) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(3, 5)" [disabled]="getRiskCount(3, 5) == ''" [ngClass]="getButtonClass(3, 5, getRiskCount(3, 5))">{{ getRiskCount(3, 5) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(4, 5)" [disabled]="getRiskCount(4, 5) == ''" [ngClass]="getButtonClass(4, 5, getRiskCount(4, 5))">{{ getRiskCount(4, 5) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(5, 5)" [disabled]="getRiskCount(5, 5) == ''" [ngClass]="getButtonClass(5, 5, getRiskCount(5, 5))">{{ getRiskCount(5, 5) }}</button></td>
      </tr>
      <tr>
        <td class="cell consequenceText">High(4)</td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(1, 4)" [disabled]="getRiskCount(1, 4) == ''" [ngClass]="getButtonClass(1, 4, getRiskCount(1, 4))">{{ getRiskCount(1, 4) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(2, 4)" [disabled]="getRiskCount(2, 4) == ''" [ngClass]="getButtonClass(2, 4, getRiskCount(2, 4))">{{ getRiskCount(2, 4) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(3, 4)" [disabled]="getRiskCount(3, 4) == ''" [ngClass]="getButtonClass(3, 4, getRiskCount(3, 4))">{{ getRiskCount(3, 4) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(4, 4)" [disabled]="getRiskCount(4, 4) == ''" [ngClass]="getButtonClass(4, 4, getRiskCount(4, 4))">{{ getRiskCount(4, 4) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(5, 4)" [disabled]="getRiskCount(5, 4) == ''" [ngClass]="getButtonClass(5, 4, getRiskCount(5, 4))">{{ getRiskCount(5, 4) }}</button></td>
      </tr>
      <tr>
        <td class="cell consequenceText">Medium(3)</td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(1, 3)" [disabled]="getRiskCount(1, 3) == ''" [ngClass]="getButtonClass(1, 3, getRiskCount(1, 3))">{{ getRiskCount(1, 3) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(2, 3)" [disabled]="getRiskCount(2, 3) == ''" [ngClass]="getButtonClass(2, 3, getRiskCount(2, 3))">{{ getRiskCount(2, 3) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(3, 3)" [disabled]="getRiskCount(3, 3) == ''" [ngClass]="getButtonClass(3, 3, getRiskCount(3, 3))">{{ getRiskCount(3, 3) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(4, 3)" [disabled]="getRiskCount(4, 3) == ''" [ngClass]="getButtonClass(4, 3, getRiskCount(4, 3))">{{ getRiskCount(4, 3) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(5, 3)" [disabled]="getRiskCount(5, 3) == ''" [ngClass]="getButtonClass(5, 3, getRiskCount(5, 3))">{{ getRiskCount(5, 3) }}</button></td>
      </tr>
      <tr>
        <td class="cell consequenceText">Low(2)</td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(1, 2)" [disabled]="getRiskCount(1, 2) == ''" [ngClass]="getButtonClass(1, 2, getRiskCount(1, 2))">{{ getRiskCount(1, 2) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(2, 2)" [disabled]="getRiskCount(2, 2) == ''" [ngClass]="getButtonClass(2, 2, getRiskCount(2, 2))">{{ getRiskCount(2, 2) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(3, 2)" [disabled]="getRiskCount(3, 2) == ''" [ngClass]="getButtonClass(3, 2, getRiskCount(3, 2))">{{ getRiskCount(3, 2) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(4, 2)" [disabled]="getRiskCount(4, 2) == ''" [ngClass]="getButtonClass(4, 2, getRiskCount(4, 2))">{{ getRiskCount(4, 2) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(5, 2)" [disabled]="getRiskCount(5, 2) == ''" [ngClass]="getButtonClass(5, 2, getRiskCount(5, 2))">{{ getRiskCount(5, 2) }}</button></td>
      </tr>
      <tr>
        <td class="cell consequenceText">VeryLow(1)</td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(1, 1)" [disabled]="getRiskCount(1, 1) == ''" [ngClass]="getButtonClass(1, 1, getRiskCount(1, 1))">{{ getRiskCount(1, 1) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(2, 1)" [disabled]="getRiskCount(2, 1) == ''" [ngClass]="getButtonClass(2, 1, getRiskCount(2, 1))">{{ getRiskCount(2, 1) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(3, 1)" [disabled]="getRiskCount(3, 1) == ''" [ngClass]="getButtonClass(3, 1, getRiskCount(3, 1))">{{ getRiskCount(3, 1) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(4, 1)" [disabled]="getRiskCount(4, 1) == ''" [ngClass]="getButtonClass(4, 1, getRiskCount(4, 1))">{{ getRiskCount(4, 1) }}</button></td>
        <td class="buttonCell"><button class="button riskButton" (click)="getRiskOnRiskLevel(5, 1)" [disabled]="getRiskCount(5, 1) == ''" [ngClass]="getButtonClass(5, 1, getRiskCount(5, 1))">{{ getRiskCount(5, 1) }}</button></td>
      </tr>
    </table>

  </div>