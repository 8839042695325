import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppLayoutComponent } from './app-layout.component';
import { MaterialModule } from '../../shared/material/material.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { BodyModule } from '../body/body.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';



@NgModule({
  declarations: [AppLayoutComponent],
  imports: [
    CommonModule,BrowserAnimationsModule, RouterModule, MaterialModule, DashboardModule, BodyModule, NgxSpinnerModule,AuthorisationModule
  ],
  exports: [AppLayoutComponent]
})
export class AppLayoutModule { }
