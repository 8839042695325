import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as myGlobals from '../../app_data/globals';
import { ReferenceLink } from '../../app_data/referenceLink';
import { Interface } from '../../app_data/interface';
import { TechnicalQuery } from '../../app_data/technicalQuery';
import { IsConfidential, IsConfidential2LabelMapping } from '../../app_data/isConfidential';
import { Discipline } from '../../app_data/discipline';
import { Status } from '../../app_data/status';
import { Priority } from '../../app_data/priority';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { QueryType } from '../../app_data/queryType';
import { SystemLog } from '../../app_data/systemLog';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { User } from '../../app_data/user';
import { InterfaceService } from '../../services/interface.service';
import { DisciplineService } from '../../services/discipline.service';
import { ContractorService } from '../../services/contractor.service';
import { StatusService } from '../../services/status.service';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { PriorityService } from '../../services/priority.service';
import { CommunicationService } from '../../services/communication.service';
import { ProjectService } from '../../services/project.service';
import { SystemLogService } from '../../services/systemLog.service';
import { FilesService } from '../../services/files.service';
import { NotificationService } from '../../services/notification.service';
import { QueryTypeService } from '../../services/queryTypes.service';
import { Notification } from '../../app_data/notification';
import { Document } from '../../app_data/document';
import { File } from '../../app_data/file';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '../../app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { PrioritySettingService } from '../../services/prioritysetting.service';
import { PrioritySetting } from '../../app_data/prioritySetting';
import { ProjectFieldsConfigService } from '../../services/project-fields-config.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { DocumentService } from 'src/app/services/document.service';
import { Guid } from 'guid-typescript';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserService } from '../../services/user.service';
import { InterfaceGroupTemplateMapping } from '../../app_data/interfaceGroupTemplateMapping';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { EmailTemplateService } from '../../services/email-template.service';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { StorageDetail } from '../../app_data/storageDetail';

let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './technicalquery-create.component.html',
  styleUrls: ['./technicalquery-create.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],

})

export class TechnicalQueryCreateComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  pageTitle = 'Create Technical Query';
  permission = 'technicalquery.create';
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  selectedProviderDisciplines = '';
  selectedRequestingDisciplines = '';
  selectedStatus?: number = 0;
  /*selectedApprover?: number = 0;*/
  selectedPriority?: number = 0;
  selectedProviderContractor: number = 0;
  selectedRequestingContractor: number = 0;
  selectedInterfaceGroup: number = 0;
  interfaceModel !: Interface;
  technicalQuery !: TechnicalQuery;
  emptyInterfaceModel !: Interface;
  _isModelChanged: boolean = false;
  IsConfidential2LabelMapping = IsConfidential2LabelMapping;
  confidentialityOptions = Object.values(IsConfidential).filter(value => typeof value === 'number');
  isSubmitted = false;
  errorMessage = '';
  disciplines: Discipline[] = [];
  requestingDisciplines: Discipline[] = [];
  status: Status[] = [];
  allstatus: Status[] = [];
  priority: Priority[] = [];
  prioritySettings!: PrioritySetting;
  providerContractors: Contractor[] = [];
  requestingContractors: Contractor[] = [];
  form !: FormGroup;
  interfaceGroups: InterfaceGroup[] = [];
  interfaceGroup2!: InterfaceGroup;
  systemLog !: SystemLog;
  queryTypes: QueryType[] = [];
  dateTimeFormat: string = '';
  customiseTQNumber: string = '';
  documentCount: string = "";
  referenceLinkCount: string = "";
  _interfaceID: number = 0;
  _isCopyDocLinks: boolean = false;
  _isRevision: boolean = false;
  _isCopy: boolean = false;
  today: Date = new Date();
  pipe = new DatePipe('en-US');
  dpColorTheme: string = "theme-dark-blue";
  contractorIds: number[] = [];
  filteredInterfaces: Interface[] = [];
  revisionNumber: number = 0;
  isEnableCloseoutReminder: boolean = false;
  maxFileSize: string = "";
  closeOutReminderDays: number = 0;
  workflowMappings: WorkflowMapping[] = [];
  showJustification = false;
  interfaceType: string = "TQ";
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];

  isTQNumberByProject: boolean = false;
  isTQNumberByGroup: boolean = false;
  isEnableCustomiseTQNumberPattern: boolean = false;
  customiseTQNumberPattern: string = "";

  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _roleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  notificationModel !: Notification;
  receipentUsers: User[] = [];
  contractorReceipentUsers: Contact[] = [];
  _contractorListByIG: InterfaceGroup[] = [];
  contraArrs: Contractor[] = [];
  /*approvers: User[] = [];*/

  addDaystoExpResponseDate: number = 0;
  newCloseOutDate = new Date;
  newExpectedDate = new Date;
  newMinDate = new Date;

  uploadDocs: File[] = [];
  documentSrc!: any;

  domainlist: SafeDomainList[] = [];
  safedomainval: boolean = false;
  minDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);
  minCloseOutDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);

  isDualDisciplinebool: boolean = false; //default value is false
  commonNotificationFunction!: CommonNotificationFunction;
  sourceStorageDetail!: StorageDetail;

  showOtherTab: boolean = false;
  isDisciplineLead: boolean = false;
  commonFunction!: CommonFunction;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private interfaceService: InterfaceService, private disciplineService: DisciplineService, private contractorService: ContractorService,
    private statusService: StatusService, private projectSettingService: ProjectSettingService, private priorityService: PriorityService, private prioritySettingService: PrioritySettingService,
    private interfaceGroupService: InterfaceGroupService, private router: Router, private communicationService: CommunicationService, private systemLogService: SystemLogService,
    private notificationService: NotificationService, private filesService: FilesService, private projectService: ProjectService, private documentService:DocumentService,
    private logger: NGXLogger, private SpinnerService: NgxSpinnerService, private dialog: DialogService, private queryTypeService: QueryTypeService,
    private workflowMappingService: WorkflowmappingService, private projectFieldsConfigService: ProjectFieldsConfigService,
    private snackBar: MatSnackBar, private userService: UserService, private datePipe: DatePipe,
    private rolePermissionService: RolePermissionService,
    private cdref: ChangeDetectorRef, private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService  ) {
    this.interfaceModel = new Interface();
    this.technicalQuery = new TechnicalQuery();
    this.emptyInterfaceModel = new Interface();
    this.interfaceGroup2 = new InterfaceGroup();
    this.systemLog = new SystemLog();
    this.notificationModel = new Notification();
    this.getProjectSettings();
    this.sourceStorageDetail = new StorageDetail();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, this.datePipe, dialog, logger, router, emailTemplateService, templateBuilderService, workflowMappingService);
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
  }


  @ViewChild('fileInput') fileInput !: ElementRef;


  ngOnInit(): void {
    this.isDisciplineLead = this._roleId == Number(myGlobals.DisciplineLeadRoleId) ? true : false;
    this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
    this.fillPriorityDDL();
    this.fillStatusDDL();
    this.fillQueryTypeDDL();
    //this.fillApproverDDL();
    UploadDocuments = [];

    this.form = new FormGroup({
      titleFormControl: new FormControl(),
      ifiDateFormControl: new FormControl(),
      iaiDateFormControl: new FormControl(),
      interfaceGroupFormControl: new FormControl(),
      providerDisciplineFormControl: new FormControl(),
      requestingDisciplineFormControl: new FormControl(),
      statusFormControl: new FormControl(),
      priorityFormControl: new FormControl(),
      providerContractorFormControl: new FormControl(),
      requestingContractorFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      responseFormControl: new FormControl(),
      comments: new FormControl(),
      isConfidentialFormControl: new FormControl(),
      confidentialJustificationFormControl: new FormControl(),
      responsePriorityFormControl: new FormControl(),
      priorityJustificationFormControl: new FormControl(),
      queryTypeFormControl: new FormControl(),
      requestApproverFormControl: new FormControl()
    });


    try {
      this._isRevision = this.communicationService._isRevision;
      this._interfaceID = this.communicationService._interfaceId;
      this._isCopyDocLinks = this.communicationService._isCopyDocLinks;
      this._isCopy = this.communicationService._isCopy;
      this.communicationService._interfaceId = 0;
      this.communicationService._isCopyDocLinks = false;
      this.communicationService._isRevision = false;
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

    if (this._interfaceID > 0) {
      this.fillRequestingContractorDDL();
      this.fillProviderContractorDDL();
      this.toCopyGetInterface(this._interfaceID, this._isCopyDocLinks, this._isRevision);
    }
    else {
      var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      this.interfaceModel.FirstIssuePlanned = tempDate;
      this.interfaceModel.ApprovedIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      this.interfaceModel.ApprovedIssuePlanned = tempDate;

      try {
        this.SpinnerService.show();

        this.projectSettingService.getProjectSettings(this._projectID).subscribe({
          next: projectSettings => {
            if (projectSettings.length > 0) {
              this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
              MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
              MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
              MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
              var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
              if (index > -1) {
                var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
                if (DefExpResponseDatebool == "true") {
                  var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                  if (index > -1) {
                    this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);

                    if (this.addDaystoExpResponseDate > 0) {
                      this.changeExpectedDate(tempDate);//Set Closeout Date on the basis of ProjectSetting's Response Days setting.
                    }
                  }
                }
              }
            }
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });

      } catch (e) {
        this.SpinnerService.hide();
        this.dialog.confirmDialog({
          title: 'Error',
          message: myGlobals.exceptionmessage,
          cancelCaption: 'Close',
        });
        this.logger.error(e);
      }
    }
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  //fillApproverDDL() {
  //  try {
  //    this.SpinnerService.show();
  //    this.userService.getUsersByContractorAndRole(this._contractorID, Number(myGlobals.GatekeeperRoleId)).subscribe({
  //      next: data => {
  //        this.approvers = data.filter(x => x.UserRoleProjects.length > 0);
  //        this.SpinnerService.hide();
  //      }
  //    });
  //  }
  //  catch (e) {
  //    this.SpinnerService.hide();
  //    this.dialog.confirmDialog({
  //      title: 'Error',
  //      message: myGlobals.exceptionmessage,
  //      cancelCaption: 'Close',
  //    });
  //    this.logger.error(e);
  //  }
  //}

  fillDisciplineDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).subscribe({
        next: disciplines => {
          this.disciplines = disciplines;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
            if (index > -1) {
              var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
              if (DefExpResponseDatebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                if (index > -1) {
                  this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateReminderByDays");
                if (index > -1) {
                  this.closeOutReminderDays = Number(projectSettings.filter(x => x.ConfigKey == "CloseOutDateReminderByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
            if (index > -1) {
              var UniqueNumberByProjectbool = projectSettings.filter(x => x.ConfigKey == "IsUniqueTQNumberByProject")[0].ConfigValue;
              if (UniqueNumberByProjectbool == "true") {
                this.isTQNumberByProject = true;
              }
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
            if (index > -1) {
              var UniqueNumberByInterfaceGroupbool = projectSettings.filter(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup")[0].ConfigValue;
              if (UniqueNumberByInterfaceGroupbool == "true") {
                this.isTQNumberByGroup = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
            if (index > -1) {
              var CustomiseInterfaceNumberPatternbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern")[0].ConfigValue;
              if (CustomiseInterfaceNumberPatternbool == "true") {
                this.isEnableCustomiseTQNumberPattern = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CustomiseTQNumberPattern");
                if (index > -1) {
                  this.customiseTQNumberPattern = String(projectSettings.filter(x => x.ConfigKey == "CustomiseTQNumberPattern")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }


            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              minDate: this.today,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });

          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.projectService.getProject(this._projectID).subscribe({
        next: project => {
          if (project[0].SafeDomainLists.length > 0) {
            this.domainlist = project[0].SafeDomainLists.filter(a => !a.IsDeleted);
            this.communicationService.setDomainListdata(this.domainlist);
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.projectFieldsConfigService.getProjectFieldsConfigurationByProjectId(this._projectID, this.interfaceType).subscribe({
        next: projectFields => {
          if (projectFields.length > 0) {
            this.showOtherTab = true;
          } else {
            this.showOtherTab = false;
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  fillRequestingDisciplinesDDL(contractorId: any): void {
    this.SpinnerService.show();
    try {
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).subscribe({
        next: disciplines => {
          this.requestingDisciplines = disciplines;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillProviderContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: providerContractor => {
          this.providerContractors = providerContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }

  fillRequestingContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: requestingContractor => {
          this.requestingContractors = requestingContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.statusService.getStatusesByModule(myGlobals.TQModule).subscribe({
        next: status => {
          this.allstatus = status;
          this.status = status.filter(x => x.Id == myGlobals.IdentifiedStatusId || x.Id == myGlobals.OpenStatusId);

          let statusResult = status.find(x => x.Name.toLowerCase().includes(myGlobals.IdentifiedStatusName.toLowerCase()))
          this.selectedStatus = statusResult?.Id;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillPriorityDDL(): void {
    try {
      this.SpinnerService.show();

      this.priorityService.getPrioritiesByProject("TQ", this._projectID).subscribe({
        next: priority => {
          if (priority.length > 0) {
            this.priority = priority;
            this.selectedPriority = this.priority[0].Id;
            this.onPrioritySelected(this.selectedPriority, false);
          }
          else {
            this.priorityService.getPriorities("TQ").subscribe({
              next: defaultPriority => {

                if (defaultPriority.length > 0) {
                  this.priority = defaultPriority;
                  this.selectedPriority = this.priority[0].Id;
                  this.onPrioritySelected(this.selectedPriority, false);
                }
              }
            })
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
        next: interfaceGroup => {
          this.interfaceGroups = interfaceGroup;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  fillQueryTypeDDL(): void {
    try {
      this.SpinnerService.show();
      this.queryTypeService.getQueryTypes().subscribe({
        next: queryType => {
          this.queryTypes = queryType;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  resetInterface(forms: NgForm) {
    forms.resetForm();
  }

  updateDocumentUploadStatus(documentData: Document){
    try{
      if(documentData != null){
        documentData.Status = myGlobals.DocumentUploadStatusCompletedId; //Document status set to completed        
       this.documentService.put(documentData).subscribe({
        next: data => {   
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      }); 
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x=> x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if(docUploadData != null){
          docUploadData[0].InterfaceID = _interface.Id;
            uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          }
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              if(docUploadData != null){
                this.updateDocumentUploadStatus(docUploadData[0]);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          })
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  generateCustomiseTQNumber(interfacelist: Interface[]): string {
    //Get interface group code
    var interfaceGroup = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
    var interfaceGroupCode = interfaceGroup[0].Code;

    //Get requestor contractor code
    var contractorlist = this.requestingContractors.filter(x => x.Id == this.interfaceModel.ReceivingContractorID);
    var contractorCode = contractorlist[0].Code;

    //Get discipline code
    var disciplinelist = this.requestingDisciplines.filter(x => x.Id == this.interfaceModel.ReceivingContractorDisciplineID);
    var disciplineCode = disciplinelist[0].Code;

    //Get query type
    var queryType = "TQ";
    switch (this.technicalQuery.QueryType?.Name) {
      case "Owner Requirement Clarification":
      case "Missing Information":
      case "Value Improvement":
      case "Commercial":
      case "Other":
        queryType = "TQ";
        break;
      case "Technical Buletin":
        queryType = "TB";
        break;
    }

    this.customiseTQNumberPattern = this.customiseTQNumberPattern.replaceAll("<ContractorCode>", contractorCode);
    this.customiseTQNumberPattern = this.customiseTQNumberPattern.replaceAll("<QueryType>", queryType);
    this.customiseTQNumberPattern = this.customiseTQNumberPattern.replaceAll("<GroupCode>", interfaceGroupCode);
    this.customiseTQNumberPattern = this.customiseTQNumberPattern.replaceAll("<DisciplineCode>", disciplineCode);

    var recordlist = interfacelist.filter(x => x.Number?.includes(this.customiseTQNumberPattern));
    let lastSequenceTQNumber = "";
    if (recordlist.length > 0) {
      lastSequenceTQNumber = recordlist[0].Number!;           
    }

    //Get sequence number
    var sequenceNumber = "0001";

    if (lastSequenceTQNumber != "") {      
      let lastSequenceNumber = 0;
      let lastSequenceTQNumber4Digit = lastSequenceTQNumber;
      lastSequenceTQNumber4Digit = lastSequenceTQNumber4Digit?.slice(-4);
      let lastSequenceTQNumber3Digit = lastSequenceTQNumber;
      lastSequenceTQNumber3Digit = lastSequenceTQNumber3Digit?.slice(-3);

      if (!parseInt(lastSequenceTQNumber4Digit)) {
        if (!parseInt(lastSequenceTQNumber3Digit?.slice(-3))) {
          lastSequenceNumber = 0;
        } else {
          lastSequenceNumber = Number(lastSequenceTQNumber3Digit)
        }
      } else {
        lastSequenceNumber = Number(lastSequenceTQNumber4Digit)
      }

      lastSequenceNumber++;
      sequenceNumber = this.commonFunction.addLeadingZeros(String(lastSequenceNumber), 4);
    } else {
      //Initial number strat with 1 sequence
      let lastSequenceNumber = 1;
      sequenceNumber = this.commonFunction.addLeadingZeros(String(lastSequenceNumber), 4);
    }
    this.customiseTQNumber = this.customiseTQNumberPattern + "-" + sequenceNumber;
    return this.customiseTQNumber;
  }

  save(): void {
    try {
      this.SpinnerService.show();
      if (this.form.valid) {
        let tqInterfaceNumber: string;
        //Get sequence number
        var sequenceNumber = "0001";

        this.interfaceService.getInterfacesByProjectIdAndInterfaceType(this._projectID, this.interfaceType).subscribe({
          next: interfacelist => {

            if (this.isTQNumberByProject) {

              var projectNumber = this.commonFunction.addLeadingZeros(String(this._projectID), 6)

              if (interfacelist.length > 0) {
                let lastSequenceTQNumber = interfacelist[0].Number!;
                lastSequenceTQNumber = lastSequenceTQNumber?.slice(-4);
                let lastSequenceNumber = Number(lastSequenceTQNumber);
                lastSequenceNumber++;
                sequenceNumber = this.commonFunction.addLeadingZeros(String(lastSequenceNumber), 4)
              }
              tqInterfaceNumber = projectNumber + sequenceNumber;
            }
            else if (this.isTQNumberByGroup) {
              var interfaceGroups = this.interfaceGroups.filter(x => x.Id == this.interfaceModel.InterfaceGroupID);
              if (interfaceGroups.length > 0) {
                var groupCode = interfaceGroups[0].Code;

                var groupNumber = this.commonFunction.addLeadingZeros(groupCode!, 6)

                var tqInterfaces = interfacelist.filter(x => x.InterfaceGroupID == this.interfaceModel.InterfaceGroupID);
                if (tqInterfaces.length > 0) {

                  let lastSequenceTQNumber = tqInterfaces[0].Number!;
                  lastSequenceTQNumber = lastSequenceTQNumber?.slice(-4);
                  let lastSequenceNumber = Number(lastSequenceTQNumber);
                  lastSequenceNumber++;
                  sequenceNumber = this.commonFunction.addLeadingZeros(String(lastSequenceNumber), 4)
                }
                tqInterfaceNumber = groupNumber + sequenceNumber;
              }

            }
            else if (this.isEnableCustomiseTQNumberPattern) {
              // let lastSequenceTQNumber = "";
              // if (interfacelist.length > 0)
              //   lastSequenceTQNumber = interfacelist[0].Number!;
              tqInterfaceNumber = this.generateCustomiseTQNumber(interfacelist);
            }

            this.interfaceModel.Number = tqInterfaceNumber;
            this.interfaceModel.NumberGenerationPattern = this.isTQNumberByProject ? "ProjectLevel" : this.isTQNumberByGroup ? "InterfaceGroupLevel" : "CustomisedPattern";
            this.interfaceModel.InterfaceType = "TQ";
            this.interfaceModel.CreatedBy = this._userRoleMappingId;
            this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlannedstr;
            this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlannedstr;
            this.interfaceModel.ProjectId = this._projectID;
            this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => x.Id == 0 && !x.IsDeleted);
            this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => x.Id == 0 && !x.IsDeleted);
            this.technicalQuery.CreatedBy = this._userRoleMappingId;
            this.interfaceModel.CreatedByRoleId = this._roleId;
            this.interfaceModel.TechnicalQuery = this.technicalQuery;
            this.interfaceModel.ModuleId = myGlobals.TQModule;
            //get Workflowtemplate from interfacegrouptemplate mapping                        
            var selectedInterfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
            this.interfaceGroupTemplateMappings = selectedInterfaceGroupData[0].InterfaceGroupTemplateMappings?.filter(x => x.ModuleId == myGlobals.TQModule)!;

            if (this.interfaceGroupTemplateMappings.length > 0) {

              var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == myGlobals.TQModule);
              //mark workflow stageid if workflow mapping is not avaiable for interfacegroup then bydefault stage will be save with 1 value                   
              this.workflowMappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, myGlobals.TQModule)
                .toPromise()
                .then(workflowMappingData => {
                  this.workflowMappings = workflowMappingData!;
                  if (this.workflowMappings.length! > 0) {
                    var workflowStageData = this.workflowMappings.filter(x => x.Stage?.CurrentStatus == this.selectedStatus);
                    if (workflowStageData.length > 0) {
                      var workflowFinalStageData = workflowStageData.filter(y => y.ActionByRoleId == this._roleId);
                      if (workflowFinalStageData.length > 0) {
                        this.interfaceModel.StageId = workflowFinalStageData[0].StageId;
                        this.interfaceModel.Status = workflowFinalStageData[0].Stage?.ApprovedStatus!;

                        if (!this.isDualDisciplinebool) {
                          this.interfaceModel.ResponsibleContractorDisciplineID = this.interfaceModel.ReceivingContractorDisciplineID;
                        }

                        if (this._isCopy || this._isRevision) {
                          this.interfaceModel.RecDiscipline = null;
                          this.interfaceModel.ResDiscipline = null;
                        }

                        this.interfaceService.post(this.interfaceModel).subscribe({
                          next: data => {
                            this.SpinnerService.hide();
                            if (UploadDocuments != null && UploadDocuments.length > 0) {
                              this.uploadDocuments(UploadDocuments, data, this.interfaceModel.Documents);
                            }
                            //Write a log
                            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Technical Query created", "TQ " + this.interfaceModel.Number + " created.", this._contractorID, this._roleId, data.Id);
                            this.interfaceModel.Documents.forEach((docRow) => {
                              //Write a log
                              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._roleId, data.Id);
                            });
                            this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
                              //Write a log
                              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._roleId, data.Id);
                            });
                            this.interfaceModel = data;

                            //Update interface model data as expanded entity data is not available in the current interface model.
                            this.interfaceService.getInterfaceDataForEmailNotifcationById(this.interfaceModel.Id).subscribe({
                              next: interfaces => {

                                if (interfaces.length > 0) {
                                  //Save email notification on interface creation.
                                  this.commonNotificationFunction.processEmailNotifications(myGlobals.TQModule, this.isEnableCloseoutReminder, true, this.closeOutReminderDays, interfaces[0], "", workflowTemplate[0].WorkflowTemplateId, this.interfaceModel.StageId);
                                }
                              }
                            });

                            this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);
                            this.snackBar.openFromComponent(SnackbarComponent, {
                              data: "Technical Query created successfully!",
                              duration: myGlobals.snackBarDuration,
                              verticalPosition: myGlobals.snackBarVerticalPosition,
                              horizontalPosition: myGlobals.snackBarHorizontalPosition
                            });

                          },
                          error: err => {
                            this.SpinnerService.hide();
                            this.errorMessage = err
                            this.dialog.confirmDialog({
                              title: 'Error',
                              message: myGlobals.exceptionmessage,
                              cancelCaption: 'Close',
                            });
                            this.logger.error(err);
                          }
                        })
                      } else {
                        this.SpinnerService.hide();
                        this.dialog.confirmDialog({
                          title: 'Warning!',
                          message: "You do not have access for this item at this stage. Please check with project admin for workflow mapping.",
                          cancelCaption: 'Close',
                        });
                      }
                    } else {
                      this.SpinnerService.hide();
                      this.dialog.confirmDialog({
                        title: 'Warning!',
                        message: "Mapping not found for selected status. Please check with project admin for workflow mapping.",
                        cancelCaption: 'Close',
                      });
                    }
                  } else {
                    this.SpinnerService.hide();
                    this.dialog.confirmDialog({
                      title: 'Warning!',
                      message: "There is no Workflow Mapping. Please check with project admin for workflow mapping.",
                      cancelCaption: 'Close',
                    });
                  }
                });
            } else {
              this.SpinnerService.hide();
              this.dialog.confirmDialog({
                title: 'Warning!',
                message: "There is no workflow template mapped with selected interface group. Please check with project admin for workflow template mapping.",
                cancelCaption: 'Close',
              });
            }
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }


  onCancel() {
    //reset Interface tab
    this.form.reset();
    this.disciplines = [];
    this.requestingDisciplines = [];
    this.commonFunction.redirectToRegisterpage(myGlobals.TQModule);
  }


  onProviderContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArrs.filter(x => x.Id != contractorId);
        this.selectedRequestingContractor = result[0].Id;
        this.fillRequestingDisciplinesDDL(result[0].Id);
        this.fillDisciplineDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.disciplines = [];

      this.form.get('providerDisciplineFormControl')?.reset();
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  onConfidentialitySelected(isConfidential: boolean) {
    //If isConfidential flag is on the set required validators else remove validators and update the control's value and validity.
    if (isConfidential) {
      this.form.controls["confidentialJustificationFormControl"].setValidators(Validators.required);
      this.form.controls["confidentialJustificationFormControl"].updateValueAndValidity();
    }
    else {
      this.form.controls["confidentialJustificationFormControl"].clearValidators();
      this.form.controls["confidentialJustificationFormControl"].updateValueAndValidity();
    }
  }


  onPrioritySelected(priorityId: number, onSelectionChange: boolean) {
    try {

      this.SpinnerService.show();
      this.prioritySettingService.getPrioritySettings(priorityId).subscribe({
        next: prioritySettings => {
          var list = prioritySettings.filter(x => x.ConfigKey == "ShowJustification");
          if (list.length > 0) {
            this.showJustification = (Number(list[0].ConfigValue) == 1);
          }
          else {
            this.showJustification = false;
          }

          //If showJustification flag is on the set required validators else remove validators and update the control's value and validity.
          if (this.showJustification) {
            this.form.controls["priorityJustificationFormControl"].setValidators(Validators.required);
            this.form.controls["priorityJustificationFormControl"].updateValueAndValidity();
            this.technicalQuery.PriorityJustification = "";
          }
          else {
            this.form.controls["priorityJustificationFormControl"].clearValidators();
            this.form.controls["priorityJustificationFormControl"].updateValueAndValidity();
          }
          this.newMinDate = new Date();
          if (onSelectionChange) {
            var responseDays = 0;
            list = prioritySettings.filter(x => x.ConfigKey == "ResponseDays");
            if (list.length > 0) {
              responseDays = (Number(list[0].ConfigValue));
              this.newMinDate.setDate(this.newMinDate.getDate() + responseDays);
              this.minCloseOutDate = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(this.newMinDate), this._projectTimeZone, false);
            } else {
              responseDays = this.addDaystoExpResponseDate;
              this.minCloseOutDate = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);
            }
            if (responseDays > 0) {
              this.newCloseOutDate = new Date(this.interfaceModel.FirstIssuePlanned!);
              this.interfaceModel.FirstIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
              this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (responseDays));
              this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
              this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
            }
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }

  onRequestingContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArrs.filter(x => x.Id != contractorId);
        this.selectedProviderContractor = result[0].Id;
        this.fillDisciplineDDL(result[0].Id);
        this.fillRequestingDisciplinesDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.requestingDisciplines = [];

      this.form.get('requestingDisciplineFormControl')?.reset();
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onInterfaceGroupSelected(interfaceGrpId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupById(interfaceGrpId).subscribe({
        next: igData => {
          this.interfaceGroup2 = igData[0];
          this.contraArrs = [];
          this.contraArrs.push(this.interfaceGroup2.Contractor1);
          this.contraArrs.push(this.interfaceGroup2.Contractor2);
          this.providerContractors = this.contraArrs;
          this.requestingContractors = this.contraArrs;

          this.selectedRequestingContractor = this._contractorID;
          this.fillRequestingDisciplinesDDL(this.interfaceGroup2.Contractor1ID);

          let result = this.contraArrs.filter(x => x.Id != this._contractorID);
          this.selectedProviderContractor = result[0].Id;
          
          if (this.isDualDisciplinebool) {
            this.fillRequestingDisciplinesDDL(this._contractorID);                
            this.fillDisciplineDDL(result[0].Id);
          }
          else {
            this.fillRequestingDisciplinesDDL(this.interfaceGroup2.Contractor1ID);
            this.fillDisciplineDDL(this.interfaceGroup2.Contractor2ID);
          }

          if (this._interfaceID == 0) {
            this.resetSelectedValue();
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetSelectedValue() {
    this.form.get('requestingDisciplineFormControl')?.reset();
    this.form.get('providerDisciplineFormControl')?.reset();
  }

  onApprovedResDateChange(type: string, event: any) {
    this.interfaceModel.ApprovedIssuePlanned = new Date(event.value!);
    this.interfaceModel.ApprovedIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
  }

  onFirstResDateChange(type: string, event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event.value!);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event.value!);
        this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
      this.onPrioritySelected(this.interfaceModel.Priority, true);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  changeExpectedDate(event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event);
        this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
      this.onPrioritySelected(this.interfaceModel.Priority, true);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  toCopyGetInterface(interfaceId: number, isCopyDocLinks: boolean, isRevision: boolean): void {
    try {
      this.SpinnerService.show();
      this.interfaceService.getCopiedInterfaceById(interfaceId).subscribe({
        next: interfaceData => {
          this.interfaceModel = new Interface();
          
          this.interfaceModel.Title = interfaceData[0].Title;
          this.interfaceModel.InterfaceGroupID = interfaceData[0].InterfaceGroupID;
          this.onInterfaceGroupSelected(this.interfaceModel.InterfaceGroupID!);
          this.interfaceModel.Priority = interfaceData[0].Priority;
          this.interfaceModel.ReceivingContractorID = this.selectedRequestingContractor;
          this.interfaceModel.ResponsibleContractorID = this.selectedProviderContractor;
          this.onPrioritySelected(this.interfaceModel.Priority, false);
          this.interfaceModel.Description = interfaceData[0].Description;
          this.interfaceModel.Comments = interfaceData[0].Comments;
          this.technicalQuery = interfaceData[0].TechnicalQuery!;
          this.technicalQuery.Id = 0;
          this.interfaceModel.RequestApproverId = interfaceData[0].RequestApproverId;
          this.interfaceModel.RecDiscipline = interfaceData[0].RecDiscipline;
          this.interfaceModel.ResDiscipline = interfaceData[0].ResDiscipline;

          this.selectedInterfaceGroup = interfaceData[0].InterfaceGroupID!;
          this.selectedProviderDisciplines = interfaceData[0].ResDiscipline.Id;

          //check if login contractor is provider contractor user
          if (this._contractorID == interfaceData[0].ResponsibleContractorID) {
            this.interfaceModel.ReceivingContractorDisciplineID = interfaceData[0].ResponsibleContractorDisciplineID;
            this.interfaceModel.ResponsibleContractorDisciplineID = interfaceData[0].ReceivingContractorDisciplineID;
          } else {
            this.interfaceModel.ReceivingContractorDisciplineID = interfaceData[0].ReceivingContractorDisciplineID;
            this.interfaceModel.ResponsibleContractorDisciplineID = interfaceData[0].ResponsibleContractorDisciplineID;
          }
          var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
          this.interfaceModel.FirstIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.interfaceModel.FirstIssuePlanned = tempDate;
          this.interfaceModel.ApprovedIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.interfaceModel.ApprovedIssuePlanned = tempDate;
          MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
          MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
          MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
          this.interfaceModel.Status = myGlobals.IdentifiedStatusId;
          this.interfaceModel.FilePathName = interfaceData[0].FilePathName;
          if (this.addDaystoExpResponseDate > 0) {
            this.newCloseOutDate = new Date(tempDate);
            this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
            this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
            this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
          }
          if (isRevision) {
            this.interfaceModel.RevisionFromId = interfaceData[0].Id;
            this.interfaceModel.Response = '';
            this.interfaceModel.Documents = [];
          }

          if (isCopyDocLinks) {

            interfaceData[0].Documents.forEach((docRow) => {
              docRow.Id = 0;
              docRow.CreatedBy = this._userRoleMappingId;
              docRow.CreatedDate = null!;
              docRow.InterfaceID = 0;
              docRow.CreatedUserRoleMapping = null!;
              docRow.Status = Number(myGlobals.DocumentUploadStatusId);
            });

            this.interfaceModel.Documents = interfaceData[0].Documents.filter(x => !x.IsDeleted);
            this.communicationService.setFilePathName(this.interfaceModel.FilePathName);
            this.communicationService.setDocumentsdata(this.interfaceModel.Documents.filter(x => !x.IsDeleted));
            this.documentCount = this.interfaceModel.Documents.filter(x => !x.IsDeleted).length.toString();

            interfaceData[0].ReferenceLinks.forEach((docRow) => {
              docRow.Id = 0;
              docRow.CreatedBy = this._userRoleMappingId;
              docRow.CreatedDate = null!;
              docRow.InterfaceID = 0;
              docRow.CreatedUserRoleMapping = null!;
            });
            this.interfaceModel.ReferenceLinks = interfaceData[0].ReferenceLinks.filter(x => !x.IsDeleted);
            this.communicationService.setReferenceLinksdata(this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted));
            this.referenceLinkCount = this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted).length.toString();
          }

          if (interfaceData[0].InterfaceProjectFieldsMappings.length > 0) {
            interfaceData[0].InterfaceProjectFieldsMappings.forEach(fieldData => {
              this.interfaceModel.InterfaceProjectFieldsMappings.push({ Id: 0, InterfaceID: 0, ProjectFieldsConfigurationId: fieldData.ProjectFieldsConfigurationId, FieldValue: fieldData.FieldValue, IsDeleted: false, CreatedBy: this._userRoleMappingId })
            });
          }

          if(this.interfaceModel.Documents != null && this.interfaceModel.Documents.length > 0){
            this.downloadCopyDocuments()
          }

          this.interfaceModel.Id = 0;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  downloadCopyDocuments(){
    try {
      if(this.interfaceModel.Documents.length > 0){
        this.interfaceModel.Documents.forEach((copyDoc)=>{ 
          var previousFileNameSaved = copyDoc.FileNameSaved;
          copyDoc.FileNameSaved =  Guid.create() + "_" + copyDoc.FileName; 
          this.getDocumentByName(this.interfaceModel.FilePathName, copyDoc.FileNameSaved, previousFileNameSaved!);
        })
      }
    }  catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  async downloadDocument(blob: Blob, fileNameSaved: string): Promise<void> {
    const base64String = await CommonFunction.convertBlobToBase64(blob);
    this.documentSrc = base64String;

    var sourceBase64 = this.documentSrc.split(',');
    if (sourceBase64.length > 1) {
      this.documentSrc = sourceBase64[1];
    }

    UploadDocuments.push({ FileName: fileNameSaved, FileContent: this.documentSrc })

  }

  getDocumentByName(filePathName:string, fileNameSaved: string, previousFileName: string) {
    try {
      this.SpinnerService.show();
      this.uploadDocs = [];
      this.uploadDocs.push({ FileName: filePathName + previousFileName, SourceStorageDetail: this.sourceStorageDetail })
      this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
        next: data => {
          this.downloadDocument(data, fileNameSaved);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
  
  documentsaddedHandler(documents: Document[] = []) {
    this.interfaceModel.Documents = documents;
  }

  refLinksaddedHandler(refLinks: ReferenceLink[] = []) {
    this.interfaceModel.ReferenceLinks = refLinks;
  }


  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments.push(...files);
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  refLinkCountaddedHandler(reflinkCount: string) {
    this.referenceLinkCount = reflinkCount;
  }

  projectFieldsConfigaddedHandler(projectFieldConfig: InterfaceProjectFieldsMapping[] = []) {
    this.interfaceModel.InterfaceProjectFieldsMappings = projectFieldConfig;
  }
}
