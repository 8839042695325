import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferenceLinkCreateComponent } from './reference-link-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material/material.module';
import { ReferenceLinkEditComponent } from './reference-link-edit.component';
import { CommonpipesModule } from '../../pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';
import { ReferenceLinkViewComponent } from './reference-link-view.component';


@NgModule({
  declarations: [ReferenceLinkCreateComponent, ReferenceLinkEditComponent, ReferenceLinkViewComponent],
  imports: [
    CommonModule,
    MaterialModule, BrowserModule, ReactiveFormsModule, FormsModule, CommonpipesModule, CommonDirectivesModule
  ],
  exports: [ReferenceLinkCreateComponent, ReferenceLinkEditComponent, ReferenceLinkViewComponent]
})
export class ReferenceLinkModule { }
