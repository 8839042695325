<div class="row">
  <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12" >
    <mat-paginator #paginatorAssignment [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="assignmentDataSource" class="mat-elevation-z8" matSort #sortAssignment="matSort">
        <ng-container matColumnDef="AssignedToUser">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header>Assigned To</th>
          <td mat-cell *matCellDef="let ele"> {{ele.AssignedToUser != null ? ele.AssignedToUser.FirstName +' '+ ele.AssignedToUser.LastName +' - '+ele.AssignedToRole.Name : this._userName}}</td>
        </ng-container>
        <ng-container matColumnDef="ReasonOfAssignment">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Reason of Assignment </th>
          <td mat-cell *matCellDef="let ele"> {{ele.ReasonOfAssignment}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Assigned By </th>
          <td mat-cell *matCellDef="let ele"> {{ ele.CreatedUserRoleMapping != null ? ele.CreatedUserRoleMapping?.User.FirstName +' '+ ele.CreatedUserRoleMapping?.User.LastName +' - '+ ele.CreatedUserRoleMapping?.Contractor.Name+' - '+ ele.CreatedUserRoleMapping?.Role.Name: ''}}</td>
        </ng-container>
        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Assigned Date </th>
          <td mat-cell *matCellDef="let ele">{{ele.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{ele.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}</td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
