import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { Contact } from '../app_data/contact';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private apiUrl = environment.apiURl + 'contacts';

  constructor(private http: HttpClient) {
  }

  getContacts(): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$Expand=ContactContractorMappings&$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContactsByProjectId(projectId: number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$Expand=ContactContractorMappings($expand=contractor($filter=IsDeleted eq false);$filter=Contractor/ProjectId eq "+ projectId +" and IsDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  
  getContactsByContractor(contractorId: number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$expand=ContactContractorMappings($filter=ContractorId eq "+ contractorId +" and isDeleted eq false;$expand=Contractor)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContactById(id: number, projectId:number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + " and IsDeleted eq false &$expand=EmailSubscriptionUsers,ContactContractorMappings($filter= isDeleted eq false;$expand=contractor;$filter=Contractor/ProjectId eq " + projectId +" and Contractor/IsDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContactByIdwithallMapping(id: number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + " and IsDeleted eq false &$expand=EmailSubscriptionUsers,ContactContractorMappings($filter= isDeleted eq false;$expand=contractor;$filter=Contractor/IsDeleted eq false and Contractor/IsCompany eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContactByUser(userEmail: string, contractorId: number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?filter=Email eq '" + userEmail + "'  and IsDeleted eq false &$Expand=ContactContractorMappings($filter=ContractorId eq " + contractorId + " and IsDeleted eq false;$expand=contractor($filter=IsDeleted eq false)),EmailSubscriptionUsers($filter= ContractorId eq " + contractorId +";$expand=InterfaceGroup,Discipline,Stage,Module) ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getContactByEmail(userEmail: string): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?filter=Email eq '" + userEmail + "' and IsDeleted eq false &$Expand=ContactContractorMappings($filter=IsDeleted eq false),EmailSubscriptionUsers($expand=InterfaceGroup,Discipline) ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getEmailSubscribedContacts(projectId:number): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?filter=ProjectId eq " + projectId+" and IsRegisteredUser eq true and IsDeleted eq false &$Expand=ContactContractorMappings($filter=IsDeleted eq false),EmailSubscriptionUsers($expand=InterfaceGroup,Discipline) ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getEmailSubscribedContactsbyContractors(projectId:number, contractors: string, SubscribeFlag:string): Observable<Contact[]> {
    return this.http.get<response>(this.apiUrl + "?filter=ProjectId eq " + projectId+" and IsRegisteredUser eq true and IsDeleted eq false &$Expand=ContactContractorMappings($filter=IsDeleted eq false),EmailSubscriptionUsers($filter=ContractorId in( "+ contractors +" ) and contains(SubscriptionFlag,'"+ SubscribeFlag +"'))")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Contact): Observable<Contact> {
    return this.http.post<Contact>(this.apiUrl, model, httpOptions);
  }
  put(model: Contact): Observable<Contact> {
    return this.http.put<Contact>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
