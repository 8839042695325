
<div class="container-fluid">
  <mat-card class="">
    <mat-card-content class="h700tabMin scroller">
      <div class="page-title">
        <div class="page-title-name">Projects</div>
        <div mat-dialog-actions [align]="'end'">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary" [appSetRouteActive]="'ProjectList'" [hasPermission]="'project.create'" (click)="redirecttoadd()">
            <mat-icon class="WIRbutton-icons">add</mat-icon>New Project
          </button>
        </div>
      </div>
      <br />
      <div class="row">
        <table mat-table [dataSource]="projectdataSource" class="mat-elevation-z8" matSort #sortproject="matSort">
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" > Action </th>
            <td mat-cell *matCellDef="let element">
              <mat-icon matTooltip="Edit" class="mat-icon-small-size" [appSetRouteActive]="'ProjectList'" [hasPermission]="'project.edit'" (click)="editRecord(element.Id)">edit</mat-icon>
            </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element"> {{element.Projectstatus.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="SupportPhone">
            <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Support Phone </th>
            <td mat-cell *matCellDef="let element"> {{element.SupportPhone}} </td>
          </ng-container>
          <ng-container matColumnDef="SupportMail">
            <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Support Mail </th>
            <td mat-cell *matCellDef="let element"> {{element.SupportMail}} </td>
          </ng-container>
          <ng-container matColumnDef="MailSenderAddress">
            <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Mail Sender Address </th>
            <td mat-cell *matCellDef="let element"> {{element.MailSenderAddress}} </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
              <span><br />{{noRecordFoundMsg}}</span>
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
