import { InterfaceGroup } from "./interfaceGroup";
import { MinutesOfMeetingAttendees } from "./minutesOfMeetingAttendees";
import { MinutesOfMeetingDetails } from "./minutesOfMeetingDetails";
import { User } from "./user";
import { Document } from "./document";
import { ActionItem } from "./actionItem";
import { Module } from "./module";
import { UserRoleProject } from "./userroleproject";

export class MinutesOfMeeting {
  Id: number=0;
  InterfaceGroupID!: number;
  Title !: string;
  ProjectId!:number;
  MeetingDescription : string = "";
  MeetingLocation !: string;
  Revision : number = 0;
  MeetingDate !: Date;
  FilePathName: string = "";
  NextMeetingDate? : Date;
  ModuleId?:number;
  NextMeetingLocation : string= "";
  IsSignedByContractor1 :boolean =false;
  IsSignedByContractor2 :boolean =false;
  SignedByContractor1UserID? : number;
  SignedByContractor2UserID? : number;
  SignedByContractor1Date? : Date;
  SignedByContractor2Date? : Date;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Status: string = "";
  InterfaceGroup?: InterfaceGroup;
  SignedByContractor1User?: User;
  SignedByContractor2User?: User;
  CreatedUserRoleMapping?: UserRoleProject;
  MinutesOfMeetingDetails : MinutesOfMeetingDetails[]=[];
  MinutesOfMeetingAttendees : MinutesOfMeetingAttendees[]=[];
  Documents: Document[] = [];
  ActionItems: ActionItem[] = [];
  Module?: Module;
}
