<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <mat-tab-group animationDuration="0ms" id="tab-group">
        <mat-tab labelinterfacegroup label="Action Item">
          <div class="h500tabMin">
            <app-action-item-view></app-action-item-view>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
