import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Directive({
  selector: '[appSetRouteActive]'
})
export class SetRouteActiveDirective implements OnInit  {
  @Input() appSetRouteActive = '';
  constructor(private router: Router,private route:ActivatedRoute ) { }
  ngOnInit() {
  }
  @HostListener('click', ['$event']) onClick($event: string) {
    var ele = document.getElementsByClassName("navigation")[0].querySelector("a[href='#/" + this.appSetRouteActive + "']");
    if (this.router.url.includes(this.appSetRouteActive)) {      
      ele!.className += ' routeactive'; 
    }
  }
}
