import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { EmailTemplate } from '../app_data/emailTemplate';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  private apiUrl = environment.apiURl + 'emailtemplates';

  constructor(private http: HttpClient) {
  }

  getEmailTemplates(): Observable<EmailTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false ", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

  getEmailTemplatesByProjectId(projectId: number): Observable<EmailTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false and ProjectId eq " + projectId)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getEmailTemplateByProjectAndModule(projectId: number, moduleId: number, emailTemplateCode: string): Observable<EmailTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false and ProjectId eq " + projectId + "and ModuleId eq " + moduleId + " and Code eq '" + emailTemplateCode+"'")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getDefaultEmailTemplateByModule(moduleId: number, emailTemplateCode: string): Observable<EmailTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false and ProjectId eq null and ModuleId eq " + moduleId + " and Code eq '" + emailTemplateCode+"'")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getEmailTemplatesById(Id: number): Observable<EmailTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false and Id eq " + Id)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  post(model: EmailTemplate): Observable<EmailTemplate> {
    return this.http.post<EmailTemplate>(this.apiUrl, model, httpOptions);
  }
  put(model: EmailTemplate): Observable<EmailTemplate> {
    return this.http.put<EmailTemplate>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }
}
