import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UserInvitation } from 'src/app/app_data/userInvitation';
import * as myGlobals from '../../app_data/globals';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserInvitationService } from 'src/app/services/userInvitation.service';
import { NGXLogger } from 'ngx-logger';
import { FormControl } from '@angular/forms';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { CommunicationService } from '../../services/communication.service';
import { DatePipe } from '@angular/common';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let UserInvitationlists: UserInvitation[] = [];

@Component({
  selector: 'app-invitation-list',
  templateUrl: './invitation-list.component.html',
  styleUrls: ['./invitation-list.component.css'],  
  encapsulation: ViewEncapsulation.None
})
export class InvitationListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  errorMessage!: string;
  currentDate = new Date();
  userInvitation !: UserInvitation;
  userInvitations: UserInvitation[] = [];
  pipe = new DatePipe('en-US');
  todayDate = new Date;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  dateTimeFormat: string = "";
  contractorNameFilter = new FormControl('');
  emailFilter = new FormControl('');
  roleFilter = new FormControl('');
  statusFilter = new FormControl('');
  filterValues = {
    Name: '',
    Email: '',
    Role: '',
    Status:''
  };

  displayedColumns: string[] = ['Contractor.Name', 'Email', 'Role.Name', 'ValidTill', 'Status'];
  inviteUserdataSource = new MatTableDataSource<UserInvitation>(UserInvitationlists);
  @ViewChild('sortInviteUser') sortInviteUser !: MatSort;
  @ViewChild('paginatorInviteUser') paginatorInviteUser !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortInviteUser = ms;
    this.inviteUserdataSource.paginator = this.paginatorInviteUser;
    this.inviteUserdataSource.sort = this.sortInviteUser;
    this.inviteUserdataSource.sortingDataAccessor = this.sortingDataAccessor;
  }
  
  constructor(private router: Router,
    private userInvitationService: UserInvitationService,
    private logger: NGXLogger,
    private SpinnerService: NgxSpinnerService,
    private communicationService :CommunicationService,
    private projectSettingService: ProjectSettingService, private dialog: DialogService) {
      this.userInvitation = new UserInvitation();
      this.inviteUserdataSource.filterPredicate = this.createFilter();
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property == "Contractor.Name") {
        return item.Contractor.Name;
      }
      else if (property == "Role.Name") {
        return item.Role.Name;
      }
      else if (property == "InterfaceGroup") {
        return item.InterfaceGroup.Name;
      }
      else if (property == "Discipline") {
        return item.Discipline.Name;
      }
      else {
        return item[property];
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ngOnInit(): void {
    this.communicationService.is_addedvalue$.subscribe(emivalue => {
      if (emivalue) {
        this.getProjectSettings();
        this.fillInviteUsers();
      }
    })
    this.getProjectSettings();
    this.fillInviteUsers();

    this.contractorNameFilter.valueChanges
    .subscribe(
      contractorName => {
        this.filterValues.Name = contractorName.toLowerCase();
        this.inviteUserdataSource.filter = JSON.stringify(this.filterValues);
      }
    )
  this.emailFilter.valueChanges
    .subscribe(
      email => {
        this.filterValues.Email = email.toLowerCase();
        this.inviteUserdataSource.filter = JSON.stringify(this.filterValues);
      }
    )
  this.roleFilter.valueChanges
    .subscribe(
      role => {
        this.filterValues.Role = role.toLowerCase();
        this.inviteUserdataSource.filter = JSON.stringify(this.filterValues);
      }
    )

    this.statusFilter.valueChanges
    .subscribe(
      status => {
        this.filterValues.Status = status.toLowerCase();
        this.inviteUserdataSource.filter = JSON.stringify(this.filterValues);
      }
    )

  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.Contractor.Name.toLowerCase().indexOf(searchTerms.Name) !== -1
        && data.Email?.toLowerCase().indexOf(searchTerms.Email) !== -1
        && data.Role.Name?.toLowerCase().indexOf(searchTerms.Role) !== -1
        && data.Status?.toLowerCase().indexOf(searchTerms.Status) !== -1;
    }
    return filterFunction;
  }

  redirecttoadd() {
    this.router.navigateByUrl('/InviteUser');
  }

  updateInvitationCodes(inviteUsersData : UserInvitation[]): void {
    try {
      this.SpinnerService.show();
      inviteUsersData.forEach(x => {
        if (x.Status == "Expired") {
          this.userInvitationService.put(x).subscribe({
            next: invitationcodes => { this.SpinnerService.hide(); }
          })
          this.SpinnerService.hide();
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillInviteUsers(): void {
    try {
      this.SpinnerService.show();
      this.userInvitationService.getUserInvitations(this._projectID).subscribe({
        next: inviteUsersData => {
          inviteUsersData.forEach(x => {
            if ((this.pipe.transform(x.ValidTill, 'yyyy-MM-dd')! < this.pipe.transform(this.todayDate, 'yyyy-MM-dd')!) && x.Status == "Active") {
              x.Status = "Expired";
            }
          });
          this.userInvitations = inviteUsersData;
          this.inviteUserdataSource.data = inviteUsersData;
          this.updateInvitationCodes(inviteUsersData);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

}
