export class Notification {
  Id: number = 0;
  Activity: string = "";
  Message: string = "";
  RetryCount: number = 0;
  SentOn?: Date;
  Status: string = "0";
  ToAddress: string = "";
  Type?: string = "";
  Subject: string = "";
  IsReminder: boolean = false;
  ReminderDate?: Date;
  ReferenceId?: string = "";
  ReferenceType?: string = "";
  IsClosed: boolean = false;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
}
