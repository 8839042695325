import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/app_data/user';
import { InterfaceService } from 'src/app/services/interface.service';
import { Interface } from 'src/app/app_data/interface';
import { MatSelect } from '@angular/material/select';
import { DateAdapter, MatOption, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { DatePipe } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommunicationService } from 'src/app/services/communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MinutesOfMeeting } from 'src/app/app_data/minutesOfMeeting';
import { MinutesOfMeetingDetails } from 'src/app/app_data/minutesOfMeetingDetails';
import { MinutesofMeetingService } from 'src/app/services/minutesofmeeting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { MinutesOfMeetingAttendees } from 'src/app/app_data/minutesOfMeetingAttendees';
import { Document } from 'src/app/app_data/document';
import { Contact } from 'src/app/app_data/contact';
import { NotificationService } from 'src/app/services/notification.service';
import { Notification } from 'src/app/app_data/notification';
import { ActionItem } from '../../app_data/actionItem';
import { File } from '../../app_data/file';
import { FilesService } from 'src/app/services/files.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { DocumentService } from 'src/app/services/document.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTabGroup } from '@angular/material/tabs';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { ContractorService } from '../../services/contractor.service';
import { StatusService } from '../../services/status.service';
import { EmailTemplateService } from '../../services/email-template.service';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { WorkflowmappingService } from '../../services/workflowmapping.service';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';

let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-minutesofmeeting-edit',
  templateUrl: './minutesofmeeting-edit.component.html',
  styleUrls: ['./minutesofmeeting-edit.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MinutesofmeetingEditComponent implements OnInit {
  pageTitle = 'Edit Minutes of Meeting';
  form !: FormGroup;
  errorMessage = '';
  isMomFieldsVisible: boolean = true;
  selectedRequestingContractorUsers: User[] = [];
  selectedResponsibleContractorUsers: User[] = [];
  interfaceListData: Interface[] = [];
  filteredInterfaces: Interface[] = [];
  selectedMomStatusName: string = "";
  contractor1Id = 0;
  contractor2Id = 0;
  _actionItemID: number = 0;
  interfaceGroupID: number = 0;
  momModel: MinutesOfMeeting;
  oldmomModel !: MinutesOfMeeting;
  allMomDetailsModelList: MinutesOfMeetingDetails[] = [];
  momExternalAttendees !: MinutesOfMeetingAttendees;
  momDetails: MinutesOfMeetingDetails[] = [];
  momDetailsWithInterfaces: MinutesOfMeetingDetails[] = [];
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  documentCount: string = "";
  maxFileSize: string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  selectedInterface!: any;
  interfaceNumbers: number[] = [];
  selectedInterfaceGroup: number = 0;
  interfaceGroups: InterfaceGroup[] = [];
  dateTimeFormat: string = "";
  isDualDisciplinebool: boolean = false;
  isSaveEnable: boolean = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allRequestingUsers: User[] = [];
  allResponsibleUsers: User[] = [];
  tabModuleTypeName: string = "Interface";
  TimeZoneName: string = "";
  todayDate = new Date;
  todayDatepipe: any;
  isEdit = false;
  pipe = new DatePipe('en-US');
  dpColorTheme: string = "theme-dark-blue";
  allSelected = false;
  minutesOfMeetingModel !: MinutesOfMeeting;
  superAdminRole: string = myGlobals.SuperAdminRoleId;
  projectAdminRole: string = myGlobals.ProjectAdminRoleId;
  minutesOfMeetingAttendees: MinutesOfMeetingAttendees[] = [];
  momId!: number;
  panelOpenState = false;
  isDisableSignMom: boolean = true;
  isDisableUnsignMom: boolean = true;
  isDeleteDisable: boolean = false;
  isProvider: boolean = false;
  momStatusName: string = "";
  isControlDisable: boolean = false;
  receipentUsers: Contact[] = [];
  notificationModel !: Notification;
  interfaceGroupName: string = "";
  selectedModule!: string;
  selectedModuleId: number = 0;
  contractor1Name: string = "Contractor1";
  contractor2Name: string = "Contractor2";
  validRecord: boolean = false;
  actionItemCount: string = "0";
  moduleProjectMappings: ModuleProjectMapping[] = [];
  commonNotificationFunction!: CommonNotificationFunction;
  commonFunction!: CommonFunction;
  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;

  @ViewChild('InterfacesList') select!: MatSelect;
  @ViewChild('RequestingUsersInput') RequestingUsersInput !: ElementRef<HTMLInputElement>;
  @ViewChild('ProvidingUsersInput') ProvidingUsersInput !: ElementRef<HTMLInputElement>;

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private interfaceGroupService: InterfaceGroupService, private contractorService: ContractorService,
    private statusService: StatusService,
    private SpinnerService: NgxSpinnerService, private userService: UserService,
    private minutesofMeetingService: MinutesofMeetingService,
    private logger: NGXLogger, private datePipe: DatePipe,
    private dialog: DialogService,
    private projectSettingService: ProjectSettingService,
    private interfaceService: InterfaceService,
    private moduleProjectMappingsService: ModuleProjectMappingsService,
    private filesService: FilesService, private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private documentService: DocumentService,
    private communicationService: CommunicationService,
    private notificationService: NotificationService,
    private workflowMappingService: WorkflowmappingService,
    private snackBar: MatSnackBar,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private router: Router,
    private route: ActivatedRoute) {
    this.todayDatepipe = this.pipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.momModel = new MinutesOfMeeting();
    this.oldmomModel = new MinutesOfMeeting();
    this.momExternalAttendees = new MinutesOfMeetingAttendees()
    this.notificationModel = new Notification();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, dialog, logger, router, emailTemplateService, templateBuilderService, workflowMappingService);
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
  }

  ngOnInit(): void {
    try {
      this.momId = this.route.snapshot.params['id']
      this.checkIfMoMIdValid(this.momId);
      this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
      this.getProjectSettings();
      this.getModules(this._projectID);
      this.form = new FormGroup({
        interfaceGroupFormControl: new FormControl(),
        contractor1AttendeeFormControl: new FormControl(),
        contractor2AttendeeFormControl: new FormControl(),
        requestingUsersFormControl: new FormControl(),
        responsibleUsersFormControl: new FormControl(),
        interfacesFormControl: new FormControl(),
        titleFormControl: new FormControl(),
        locationFormControl: new FormControl(),
        meetingDateFormControl: new FormControl(),
        nextMeetinglocationFormControl: new FormControl(),
        nextMeetingDateFormControl: new FormControl(),
        meetingDescriptionFormControl: new FormControl(),
        externalAttendeesFormControl: new FormControl(),
        moduleNameFormControl: new FormControl()
      });

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  checkIfMoMIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeetingByIdandProjectId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.momId > 0 && this.validRecord) {
            this.isEdit = true;
            this.getMinutesofMeetingData(this.momId);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  actionItemCountaddedhandler(actionCount: string) {
    this.actionItemCount = actionCount;
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          this.moduleProjectMappings = moduleProjectMappings;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  actionitemaddedhandler(actionItem: ActionItem[] = []) {
    this.momModel.ActionItems = actionItem;
  }

  getMinutesofMeetingData(momId: number) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeetingById(momId)
        .subscribe({
          next: momData => {

            this.momModel = momData[0];
            this.oldmomModel = { ...this.momModel };
            this.momDetails = momData[0].MinutesOfMeetingDetails;

            this.momModel.MinutesOfMeetingDetails.forEach(x => {
              x.Interfaces!.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.Interfaces?.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Interfaces?.Status!);
              x.Interfaces!.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.Interfaces?.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Interfaces?.Status!);
            })

            this.communicationService.setFilePathName(this.momModel.FilePathName);

            this.communicationService.setDocumentsdata(this.momModel.Documents.filter(x => !x.IsDeleted));
            this.communicationService.setactionitemdata(this.momModel);
            this.documentCount = this.momModel.Documents.filter(x => !x.IsDeleted).length.toString();
            this.actionItemCount = this.momModel.ActionItems.filter(x => !x.IsDeleted).length.toString();
            this.selectedInterfaceGroup = this.momModel.InterfaceGroupID;
            if (this.momModel.MeetingDate != null) {
              this.momModel.MeetingDate = new Date(this.momModel.MeetingDate);
            }
            if (this.communicationService._actionItemId != 0) {
              this.tabGroup.selectedIndex = 3;
            }
            this.communicationService._actionItemId = 0;
            if (this.momModel.NextMeetingDate != null) {
              this.momModel.NextMeetingDate = new Date(this.momModel.NextMeetingDate);
            }
            this.selectedModuleId = this.momModel.ModuleId!;
            this.selectedModule = this.momModel.Module?.Name.toLowerCase() == "interface" ? "" : this.momModel.Module?.Name!;
            this.tabModuleTypeName = this.momModel.Module?.Name!;

            this.communicationService.sendInterfacegrpchanged(this.momModel.InterfaceGroup);
            this.generateMOM();

            var contractor1Attendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.Contractor1AttendeeNames != null)?.Contractor1AttendeeNames;
            this.momExternalAttendees.Contractor1AttendeeNames = contractor1Attendees != "" ? contractor1Attendees : "";

            var contractor2Attendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.Contractor2AttendeeNames != null)?.Contractor2AttendeeNames;
            this.momExternalAttendees.Contractor2AttendeeNames = contractor2Attendees != "" ? contractor2Attendees : "";

            var externalAttendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.ExternalAttendees != null)?.ExternalAttendees;
            this.momExternalAttendees.ExternalAttendees = externalAttendees != "" ? externalAttendees : "";

            this.allMomDetailsModelList = this.momModel.MinutesOfMeetingDetails;

            this.momModel.MinutesOfMeetingDetails?.forEach(interfaceData => {
              this.interfaceNumbers.push(interfaceData.InterfaceID)
            });

            this.selectedInterface = this.interfaceNumbers;

            this.setMomStatusAndActionButton();
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
                }

              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroups = [];
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetMOM() {
    this.form.get('interfaceGroupFormControl')?.reset();
    this.form.get('interfaceGroupFormControl')?.enable();
    this.selectedInterface = [];
    this.form.get('interfacesFormControl')?.reset();
    this.form.get('meetingDescription')?.reset();
    this.allSelected = false;
    this.filteredInterfaces = [];
  }

  setMomStatusAndActionButton() {
    if (!this.momModel.IsSignedByContractor1 && !this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM must be signed by both Contractor1 and Contractor2";
      this.selectedMomStatusName = "This MOM must be signed by both Contractor1 and Contractor2";
      this.isDisableSignMom = false;
      this.isDisableUnsignMom = true;
    }
    else if (!this.momModel.IsSignedByContractor1) {
      this.momStatusName = "This MOM must be signed by Contractor1";
      this.selectedMomStatusName = "This MOM must be signed by Contractor1";
      this.isDeleteDisable = true;
      this.disableAllFields();
      if (this.isProvider) {
        this.isDisableSignMom = true;
        this.isDisableUnsignMom = false;
      } else {
        this.isDisableSignMom = false;
        this.isDisableUnsignMom = true;
      }
    }
    else if (!this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM must be signed by Contractor2";
      this.selectedMomStatusName = "This MOM must be signed by Contractor2";
      this.isDeleteDisable = true;
      this.disableAllFields();
      if (this.isProvider) {
        this.isDisableSignMom = false;
        this.isDisableUnsignMom = true;
      } else {
        this.isDisableSignMom = true;
        this.isDisableUnsignMom = false;
      }
    }
    else if (this.momModel.IsSignedByContractor1 && this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM is signed and closed";
      this.selectedMomStatusName = "This MOM is signed and closed";
      this.isDisableSignMom = true;
      this.isDisableUnsignMom = true;
      this.isDeleteDisable = true;
      this.disableAllFields();
    }
  }

  generateMOM() {
    try {
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;
        this.interfaceGroupName = interfaceGroupData[0].Name;

        this.contractor1Name = interfaceGroupData[0].Contractor1.Name;
        this.contractor2Name = interfaceGroupData[0].Contractor2.Name;

        if (this.contractor2Id == this._contractorID && this._userRoleId == Number(myGlobals.ContractorRoleId)) {
          this.isProvider = true;
        }

        this.getRequestingContractorUserList(this.contractor1Id);
        this.getResponsibleContractorUserList(this.contractor2Id);





      }

      this.SpinnerService.show();
      this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup, this.selectedModule)
        .toPromise()
        .then(data => {
          this.SpinnerService.hide();
          if (data!.length > 0) {
            this.interfaceListData = data!;
          }

          if (this.momId > 0 && this.isEdit) {
            this.addInterfacesToMoM();
          }
        })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => {
        item.deselect()

        this.momModel.MinutesOfMeetingDetails.forEach(data => {
          data.IsDeleted = true;
        })

        this.momModel.MinutesOfMeetingDetails = this.momModel.MinutesOfMeetingDetails.filter(x => !x.IsDeleted);
        if (this.momModel.MinutesOfMeetingDetails.length == 0) {
          this.isSaveEnable = false;
        }
      });
    }
  }

  optionClick(id: number) {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
        var data = this.momModel.MinutesOfMeetingDetails.filter(x => x.InterfaceID == id);
        if (data.length > 0) {
          data[0].IsDeleted = true;
        }
        this.momModel.MinutesOfMeetingDetails = this.momModel.MinutesOfMeetingDetails.filter(x => !x.IsDeleted);
        if (this.momModel.MinutesOfMeetingDetails.length == 0) {
          this.isSaveEnable = false;
        }
      }
    });
    this.allSelected = newStatus;
  }

  removeRequestingContractorUsers(user: any): void {
    const index = this.selectedRequestingContractorUsers.indexOf(user);
    if (index >= 0) {
      this.allRequestingUsers.push(...this.selectedRequestingContractorUsers.filter(x => x.Id == user.Id && !x.IsDeleted));

      var attendee = this.momModel.MinutesOfMeetingAttendees.filter(x => x.Contractor1AttendeeUserID == user.Id)
      if (attendee.length > 0) {
        attendee[0].IsDeleted = true;
      }

      this.selectedRequestingContractorUsers.splice(index, 1);
    }
  }

  selectedRequestingContractor(event: MatAutocompleteSelectedEvent): void {
    const userId = event.option.value;
    this.selectedRequestingContractorUsers.push(...this.allRequestingUsers.filter(x => x.Id == userId));

    let userReq = this.allRequestingUsers.findIndex(item => item.Id === userId);

    if (userReq !== -1) {
      this.allRequestingUsers.splice(userReq, 1);
    }

    this.RequestingUsersInput.nativeElement.value = '';
    this.form.get('requestingUsersFormControl')?.setValue(null);
  }

  getRequestingContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid)
        .toPromise()
        .then(allusers => {
          this.allRequestingUsers = allusers!.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);

          if (this.momId > 0 && this.isEdit) {
            var contractor1Attendees = this.momModel.MinutesOfMeetingAttendees?.filter(x => x.Contractor1ID == this.contractor1Id)

            if (contractor1Attendees!.length > 0) {
              contractor1Attendees?.forEach((attendee: any) => {
                this.selectedRequestingContractorUsers.push(...this.allRequestingUsers.filter(x => x.Id == attendee.Contractor1AttendeeUserID && !x.IsDeleted));

                let userReq = this.allRequestingUsers.findIndex(item => item.Id === attendee.Contractor1AttendeeUserID);

                if (userReq !== -1) {
                  this.allRequestingUsers.splice(userReq, 1);
                }

                this.RequestingUsersInput.nativeElement.value = '';
                this.form.get('requestingUsersFormControl')?.setValue(null);
              });
            }
          }
          this.SpinnerService.hide();
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  removeResponsibleContractorUsers(user: any): void {
    const index = this.selectedResponsibleContractorUsers.indexOf(user);
    if (index >= 0) {
      this.allResponsibleUsers.push(...this.selectedResponsibleContractorUsers.filter(x => x.Id == user.Id));

      var attendee = this.momModel.MinutesOfMeetingAttendees.filter(x => x.Contractor2AttendeeUserID == user.Id)
      if (attendee.length > 0) {
        attendee[0].IsDeleted = true;
      }

      this.selectedResponsibleContractorUsers.splice(index, 1);
    }
  }

  selectedResponsibleContractor(event: MatAutocompleteSelectedEvent): void {
    const userId = event.option.value;
    this.selectedResponsibleContractorUsers.push(...this.allResponsibleUsers.filter(x => x.Id == userId));

    let userRes = this.allResponsibleUsers.findIndex(item => item.Id === userId);

    if (userRes !== -1) {
      this.allResponsibleUsers.splice(userRes, 1);
    }
    this.ProvidingUsersInput.nativeElement.value = '';
    this.form.get('responsibleUsersFormControl')?.setValue(null);
  }

  getResponsibleContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid)
        .toPromise()
        .then(allusers => {
          this.allResponsibleUsers = allusers!.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);

          if (this.momId > 0 && this.isEdit) {
            var contractor2Attendees = this.momModel.MinutesOfMeetingAttendees?.filter(x => x.Contractor2ID == this.contractor2Id)

            if (contractor2Attendees!.length > 0) {
              contractor2Attendees?.forEach((attendee: any) => {
                this.selectedResponsibleContractorUsers.push(...this.allResponsibleUsers.filter(x => x.Id == attendee.Contractor2AttendeeUserID));

                let userRes = this.allResponsibleUsers.findIndex(item => item.Id === attendee.Contractor2AttendeeUserID);

                if (userRes !== -1) {
                  this.allResponsibleUsers.splice(userRes, 1);
                }

                this.ProvidingUsersInput.nativeElement.value = '';
                this.form.get('responsibleUsersFormControl')?.setValue(null);
              });
            }
          }
          this.SpinnerService.hide();
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = this.momModel.Module?.Name == "Interface" ? "InterfaceView/" + interfaceId : "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  addInterfacesToMoM() {
    try {
      this.filteredInterfaces = [];
      this.form.get('meetingDescription')?.reset();
      if (this.selectedInterface.length > 0) {
        this.selectedInterface.forEach((element: any) => {
          var selectedInterfaceList = this.interfaceListData.filter(x => x.Id == element);

          selectedInterfaceList.forEach(element => {
            element.InterfaceGroup = null!;
          });

          this.filteredInterfaces.push(...selectedInterfaceList);
          this.isSaveEnable = true;
        });
        this.filteredInterfaces.forEach(element => {
          var momIndex = this.momModel.MinutesOfMeetingDetails.findIndex(x => x.InterfaceID == element.Id);

          var checkInterfaceExists = this.allMomDetailsModelList.filter(x => x.InterfaceID == element.Id);

          if (momIndex == -1) {
            if (checkInterfaceExists.length > 0) {
              checkInterfaceExists[0].IsDeleted = false;
              this.momModel.MinutesOfMeetingDetails.push(...checkInterfaceExists)
            } else {
              this.momModel.MinutesOfMeetingDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: element, InterfaceID: element.Id, PreviousText: "", Text: "", Comments: "", IsDeleted: false, CreatedBy: this._userRoleMappingId })
            }
          }

        });
        this.momModel.MinutesOfMeetingDetails = this.momModel.MinutesOfMeetingDetails.filter(x => !x.IsDeleted);

      } else {
        this.filteredInterfaces = [];
        this.isSaveEnable = false;
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  save(): void {
    try {
      if (this.form.valid && this.isSaveEnable) {
        this.momModel.IsDeleted = false;
        this.momModel.ModifiedBy = this._userRoleMappingId;
        
        this.selectedRequestingContractorUsers.forEach(attendeesRequesting => {
          var checkExistsReq = this.momModel.MinutesOfMeetingAttendees.find(x => x.Contractor1AttendeeUserID == attendeesRequesting.Id && !x.IsDeleted);
          if (!checkExistsReq) {
            this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor1AttendeeUserID: attendeesRequesting.Id, Contractor1ID: this.contractor1Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        this.selectedResponsibleContractorUsers.forEach(attendeesResponsible => {
          var checkExistsRes = this.momModel.MinutesOfMeetingAttendees.find(x => x.Contractor2AttendeeUserID == attendeesResponsible.Id && !x.IsDeleted);
          if (!checkExistsRes) {
            this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor2AttendeeUserID: attendeesResponsible.Id, Contractor2ID: this.contractor2Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        var indexAttendee1 = this.momModel.MinutesOfMeetingAttendees.findIndex(x => x.Contractor1AttendeeNames != null && !x.IsDeleted);
        if (indexAttendee1 > -1) {
          if (this.momExternalAttendees.Contractor1AttendeeNames != undefined) {
            this.momModel.MinutesOfMeetingAttendees[indexAttendee1].Contractor1AttendeeNames = this.momExternalAttendees.Contractor1AttendeeNames;
          }
          else {
            this.momModel.MinutesOfMeetingAttendees[indexAttendee1].Contractor1AttendeeNames = "";
          }
        } else {
          this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor1AttendeeNames: this.momExternalAttendees.Contractor1AttendeeNames, Contractor1ID: this.contractor1Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        }
        
        var indexAttendee2 = this.momModel.MinutesOfMeetingAttendees.findIndex(x => x.Contractor2AttendeeNames != null && !x.IsDeleted);
        if (indexAttendee2 > -1) {
          if (this.momExternalAttendees.Contractor2AttendeeNames != undefined) {
            this.momModel.MinutesOfMeetingAttendees[indexAttendee2].Contractor2AttendeeNames = this.momExternalAttendees.Contractor2AttendeeNames;
          }
          else {
            this.momModel.MinutesOfMeetingAttendees[indexAttendee2].Contractor2AttendeeNames = "";
          }
        } else {
          this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor2AttendeeNames: this.momExternalAttendees.Contractor2AttendeeNames, Contractor2ID: this.contractor2Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        }

        var indexExtAttendee = this.momModel.MinutesOfMeetingAttendees.findIndex(x => x.ExternalAttendees != null && !x.IsDeleted);
        if (indexExtAttendee > -1) {
          if (this.momExternalAttendees.ExternalAttendees != undefined) {
            this.momModel.MinutesOfMeetingAttendees[indexExtAttendee].ExternalAttendees = this.momExternalAttendees.ExternalAttendees;
          }
          else {
            this.momModel.MinutesOfMeetingAttendees[indexExtAttendee].ExternalAttendees = "";
          }
        }
        else {
          this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, ExternalAttendees: this.momExternalAttendees.ExternalAttendees, IsDeleted: false, CreatedBy: this._userRoleMappingId })
        }

        this.momModel.MinutesOfMeetingDetails.forEach(meetingDetail => {
          var checkDetailsExists = this.momDetails.find(x => x.InterfaceID == meetingDetail.InterfaceID && !x.IsDeleted);
          if (!checkDetailsExists) {
            this.momDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: meetingDetail.Interfaces, InterfaceID: meetingDetail.InterfaceID, PreviousText: "", Text: meetingDetail.Text, Comments: meetingDetail.Comments, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        this.momModel.ActionItems = this.momModel.ActionItems.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.momModel.ActionItems.forEach((docRow) => {
          if (docRow.Id == 0) {
            docRow.InterfaceGroup = null;
            docRow.ResContractor = null;
          }
        });

        this.momModel.MinutesOfMeetingDetails = this.momDetails;

        this.momModel.MinutesOfMeetingDetails.forEach(momdata => {
          momdata.ModifiedBy = this._userRoleMappingId;
          momdata.Interfaces = null!;
        })

        this.momModel.Documents.forEach(docs => {
          if (docs.MomId == null) {
            docs.MomId = 0;
            docs.InterfaceID = null!;
            docs.DocumentTypeID = Number(myGlobals.MinutesOfMeetingDocumentTypeId);
          }
        })

        this.minutesofMeetingService.put(this.momModel).subscribe(
          {
            next: momData3 => {
              this.SpinnerService.hide();

              if (UploadDocuments != null) {
                this.uploadDocuments(UploadDocuments, this.momModel, this.momModel.Documents);
              }

              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "MoM updated successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });

              this.redirectToBackPage();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          }
        );
        this.SpinnerService.hide();
      } else {
        this.SpinnerService.hide();
        if (!this.isSaveEnable) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please add interface to MOM",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        }
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = 2; //Document status set tp completed        
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  uploadDocuments(uploadDataList: File[], momData: MinutesOfMeeting, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var docUploadData = documentData.filter(x => x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          if (docUploadData != null) {
            docUploadData[0].MomId = momData.Id;
            uploadData.FileName = "Proj_" + momData.ProjectId + "/InterfaceGroup_" + momData.InterfaceGroupID + "/MoM_" + momData.Id + "/" + uploadData.FileName;
            uploadData.DestinationStorageDetail!.FilePath = "Proj_" + momData.ProjectId + "/InterfaceGroup_" + momData.InterfaceGroupID + "/MoM_" + momData.Id + "/";
          }

          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              this.updateDocumentUploadStatus(docUploadData[0]);
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          })
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  disableAllFields() {
    this.form.disable();
    this.isControlDisable = true;
    this.isDisableSignMom = true;
  }

  enableAllFields() {
    this.form.enable();
    this.isControlDisable = false;
    this.isDisableSignMom = false;
  }

  signMom(): void {
    try {
      if (this.form.valid || this.isSaveEnable) {

        //Check Action item is open
        var actionStatusOpenData = this.momModel.ActionItems.filter(x => x.Status == 1);

        if (actionStatusOpenData != null && actionStatusOpenData.length > 0) {
          this.SpinnerService.hide();
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please close action item before sign MoM",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });

          return;
        }

        this.SpinnerService.show();
        this.disableAllFields()
        this.isDisableSignMom = true;
        this.isDisableUnsignMom = false;
        if (this.isProvider) {
          this.momModel.IsSignedByContractor2 = true;
          this.momModel.SignedByContractor2Date = CommonFunction.getNowUTC();
          this.momModel.SignedByContractor2UserID = this._userIdLogin;
        } else {
          this.momModel.IsSignedByContractor1 = true;
          this.momModel.SignedByContractor1Date = CommonFunction.getNowUTC();
          this.momModel.SignedByContractor1UserID = this._userIdLogin;
        }

        this.momModel.ModifiedBy = this._userRoleMappingId;

        //Update MOM
        this.selectedRequestingContractorUsers.forEach(attendeesRequesting => {
          var checkExistsReq = this.momModel.MinutesOfMeetingAttendees.find(x => x.Contractor1AttendeeUserID == attendeesRequesting.Id && !x.IsDeleted);
          if (!checkExistsReq) {
            this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor1AttendeeUserID: attendeesRequesting.Id, Contractor1ID: this.contractor1Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        this.selectedResponsibleContractorUsers.forEach(attendeesResponsible => {
          var checkExistsRes = this.momModel.MinutesOfMeetingAttendees.find(x => x.Contractor2AttendeeUserID == attendeesResponsible.Id && !x.IsDeleted);
          if (!checkExistsRes) {
            this.momModel.MinutesOfMeetingAttendees.push({ Id: 0, MinutesOfMeetingID: 0, Contractor2AttendeeUserID: attendeesResponsible.Id, Contractor2ID: this.contractor2Id, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        this.momModel.MinutesOfMeetingDetails.forEach(meetingDetail => {
          var checkDetailsExists = this.momDetails.find(x => x.Id == meetingDetail.Id && !x.IsDeleted);
          if (!checkDetailsExists) {
            this.momDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: meetingDetail.Interfaces, InterfaceID: meetingDetail.InterfaceID, PreviousText: "", Text: meetingDetail.Text, Comments: meetingDetail.Comments, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
        });

        this.momModel.ActionItems = this.momModel.ActionItems.filter(x => (x.Id != 0 || !x.IsDeleted));

        this.momModel.ActionItems.forEach((docRow) => {
          if (docRow.Id == 0) {
            docRow.InterfaceGroup = null;
          }
        });

        this.momModel.MinutesOfMeetingDetails = this.momDetails;

        this.momModel.MinutesOfMeetingDetails.forEach(momdata => {
          momdata.ModifiedBy = this._userRoleMappingId;
          momdata.Interfaces = null!;
        })

        this.momModel.Documents.forEach(docs => {
          if (docs.MomId == null) {
            docs.MomId = 0;
            docs.InterfaceID = null!;
            docs.DocumentTypeID = Number(myGlobals.MinutesOfMeetingDocumentTypeId);
          }
        })

        this.minutesofMeetingService.put(this.momModel).subscribe(
          {
            next: momData3 => {
              this.SpinnerService.hide();

              //Email Notification
              this.saveEmailNotification(this.momModel)

              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "MoM sign successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });

              this.redirectToBackPage();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          }
        );
      } else {
        this.SpinnerService.hide();
        if (!this.isSaveEnable) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please add interface to MOM",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        }
      }
      this.SpinnerService.hide();

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  unsignMom(): void {
    try {
      if (this.form.valid || this.isSaveEnable) {

        this.SpinnerService.show();
        this.enableAllFields();
        this.isDisableSignMom = false;
        this.isDisableUnsignMom = true;

        if (this.isProvider) {
          this.momModel.IsSignedByContractor2 = false;
          this.momModel.SignedByContractor2Date = null!;
          this.momModel.SignedByContractor2UserID = null!;
        } else {
          this.momModel.IsSignedByContractor1 = false;
          this.momModel.SignedByContractor1Date = null!;
          this.momModel.SignedByContractor1UserID = null!;
        }

        this.momModel.ModifiedBy = this._userRoleMappingId;

        this.minutesofMeetingService.put(this.momModel).subscribe(
          {
            next: momData3 => {
              this.SpinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "MoM unsign successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });

              this.redirectToBackPage();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          }
        );
      } else {
        this.SpinnerService.hide();
        if (!this.isSaveEnable) {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Please add interface to MOM",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
        }
      }
      this.SpinnerService.hide();

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/MinutesofMeetings');
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.momModel.Documents = documents;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments = files;
  }

  confirmCancelDialog(InterfaceId: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this interface?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(InterfaceId);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(InterfaceId: number) {
    try {
      let interfaceIdex = this.filteredInterfaces.findIndex(item => item.Id === InterfaceId);
      let interfaceSelectedIndex = this.selectedInterface.indexOf(InterfaceId);
      let momdetailsIndex = this.momModel.MinutesOfMeetingDetails.findIndex(item => item.InterfaceID == InterfaceId);
      if (interfaceIdex !== -1) {
        this.filteredInterfaces.splice(interfaceIdex, 1);
        this.selectedInterface.splice(interfaceSelectedIndex, 1);
        this.form.get('interfacesFormControl')?.setValue(this.selectedInterface);

        this.momModel.MinutesOfMeetingDetails[momdetailsIndex].IsDeleted = true;

        this.momModel.MinutesOfMeetingDetails = this.momModel.MinutesOfMeetingDetails.filter(x => !x.IsDeleted);

      }

      if (this.momModel.MinutesOfMeetingDetails.length == 0) {
        this.isSaveEnable = false;
      }

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  saveEmailNotification(momModel: MinutesOfMeeting) {
    try {
      this.SpinnerService.show();

      var recipients: string[] = [];

      //Get Recipients
      momModel.MinutesOfMeetingAttendees.forEach(momAttendees => {
        let recipientEmail = "";
        if (momAttendees.Contractor1AttendeeUserID != null)
          recipientEmail = momAttendees.Contractor1AttendeeUser!.Email;
        else if (momAttendees.Contractor2AttendeeUserID != null)
          recipientEmail = momAttendees.Contractor2AttendeeUser!.Email;

        if (recipientEmail != null && recipientEmail != "" && recipients.indexOf(recipientEmail) < 0)
          recipients.push(recipientEmail)

      });

      var objects: Array<Object> = [];
      objects.push(momModel);

      //Planned Dates
      var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
      var meetingDate = (momModel.MeetingDate == null) ? '' : this.datePipe.transform(momModel.MeetingDate, projectDateTimeFormat!);
      var nextMeetingDate = (momModel.NextMeetingDate == null) ? '' : this.datePipe.transform(momModel.NextMeetingDate, projectDateTimeFormat!);
      var Dates: any = { FormattedMeetingDate: meetingDate, FormattedNextMeetingDate: nextMeetingDate }
      objects.push(Dates);

      if (momModel.IsSignedByContractor1 && momModel.IsSignedByContractor2)
        this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, momModel.ModuleId!, "MOM_CLOSED", recipients, "MOM closed", momModel.Id.toString(), myGlobals.MOMModuleName, false, 0);
      else if (momModel.IsSignedByContractor1 || momModel.IsSignedByContractor2) {

        //Signed unsigned contractors
        var signedByContractor = momModel.IsSignedByContractor1 ? momModel.InterfaceGroup!.Contractor1.Name : momModel.InterfaceGroup!.Contractor2.Name;
        var unsignedByContractor = !momModel.IsSignedByContractor1 ? momModel.InterfaceGroup!.Contractor1.Name : momModel.InterfaceGroup!.Contractor2.Name;
        var SignedDetails: any = { SignedByContractor: signedByContractor, UnsignedByContractor: unsignedByContractor }
        objects.push(SignedDetails);

        this.commonNotificationFunction.processOtherEmailNotifications(objects, this._projectID, momModel.ModuleId!, "MOM_SIGNED", recipients, "MOM signed", momModel.Id.toString(), myGlobals.MOMModuleName, false, 0);
      }
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
