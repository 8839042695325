<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorRevisions [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="revisiondataSource" class="mat-elevation-z8" matSort #sortrevisions="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon matTooltip="view" class="mat-icon-small-size" (click)="onView(element.Id)">visibility</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="Number">
          <th mat-header-cell *matHeaderCellDef class="table_header_size header-number-width" mat-sort-header> Interface Number </th>
          <td mat-cell *matCellDef="let element"> {{element.Number}} </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size header-title-width" mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let element"> {{element.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="InterfaceStatus.Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.InterfaceStatus.Name}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>


