
export enum IsConfidential {
  No=0, Yes=1
}


export const IsConfidential2LabelMapping: Record<IsConfidential, string> = {
  [IsConfidential.No]: "No",
  [IsConfidential.Yes]: "Yes"
};
