<form [formGroup]="settingForm">

  <div class="cardBox">
    <mat-card class="h-min-100">
      <mat-card-title class="titleHeader">
        <div>Technical Query number generation pattern
          <br>
          <div class="hintMessage">(Allow to select any one option)</div>
        </div>
      </mat-card-title>
    </mat-card>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsUniqueTQNumberByProject"
            formControlName="IsUniqueTQNumberByProject" value="yes"
            (change)="settingChecked('IsUniqueTQNumberByProject',$event)">
          <mat-label>Unique Technical Query number on Project level</mat-label>
          <app-tooltip [tooltiptext]="UniqueTQNumberbyProjecttext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsUniqueTQNumberByInterfaceGroup"
            formControlName="IsUniqueTQNumberByInterfaceGroup" value="yes"
            (change)="settingChecked('IsUniqueTQNumberByInterfaceGroup',$event)">
          <mat-label>Unique Technical Query number on Interface Group level</mat-label>
          <app-tooltip [tooltiptext]="UniqueTQNumberbyGrouptext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 ml-4">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableCustomiseTQNumberPattern"
              [(ngModel)]="setting.IsEnableCustomiseTQNumberPattern"
              formControlName="IsEnableCustomiseTQNumberPattern" value="yes"
              (change)="settingChecked('IsEnableCustomiseTQNumberPattern',$event)">
            <mat-label>Customise technical query number pattern</mat-label>
            <app-tooltip [tooltiptext]="CustomiseTQNumberPatterntext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 ml-4">
        <input class="freeTextbox" autocomplete="off" maxlength="100"
          [(ngModel)]="setting.CustomiseTQNumberPattern" *ngIf="showCustomiseTQNumberPattern"
          formControlName="CustomiseTQNumberPattern" placeholder="e.g. <GroupCode>-<ContractorCode>-<DisciplineCode>-<QueryType>-<FreeText>"
          (change)="onTextChange('CustomiseTQNumberPattern',$event)">
      </div>
    </div>
  </div>
  
</form>
