<div class="row">
  <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
    <mat-paginator #paginatorProposeResponse [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="proposeResponseDataSource" class="mat-elevation-z8" matSort #sortProposeResponse="matSort">
        <ng-container matColumnDef="ProposedResponse">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed Response </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ProposedResponse}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed By </th>
          <td mat-cell *matCellDef="let disc"> {{ disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName +' - '+ disc.CreatedUserRoleMapping?.Contractor.Name+' - '+ disc.CreatedUserRoleMapping?.Role.Name: ''}}</td>
        </ng-container>
        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed Date </th>
          <td mat-cell *matCellDef="let disc">{{disc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{disc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}</td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
