import { ProjectFieldsConfiguration } from "./projectFieldsConfiguration";

export class InterfaceProjectFieldsMapping {
    Id!: number;
    InterfaceID?: number = 0;
    ProjectFieldsConfigurationId: number = 0;
    FieldValue!: string;
    IsDeleted!: boolean;
    CreatedDate?: Date;
    CreatedBy?: number;
    ModifiedDate?: Date;
    ModifiedBy?: number;
    ProjectFieldsConfiguration ?: ProjectFieldsConfiguration;
}
