import { Interface } from "./interface";
import { Role } from "./role";
import { User } from "./user";
import { UserRoleProject } from "./userroleproject";


export class AssignmentHistory {
  Id !: number;
  InterfaceId!: number;
  AssignedTo!: number;
  AssignedToRoleId!: number;
  ReasonOfAssignment!: string;
  IsAssignedToTQFocal: boolean = false;
  IsDeleted : boolean=false;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Interface?: Interface;
  AssignedToUser?: User;
  AssignedToRole?: Role;
  CreatedUserRoleMapping?: UserRoleProject;
}
