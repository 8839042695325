import { UserRoleProject } from "./userroleproject";

export class Discipline {
  Id ?:number;
  Code !:string;
  Name !: string;
  Description ?: string;
  LeadContractorID!: number;
  CategoryID?: number;
  IsDeleted!: boolean;
  DisciplineLead?: string;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Contractor?: any;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: any;
}
