
<div class="container-fluid">
  <div class="">
    <div class="row">
      <div class="display-flex">
        <div class="col-md-4 align-self-center padding-0">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary" [appSetRouteActive]="'MinutesofMeetingCreate'" [hasPermission]="'mom.create'" (click)="redirecttoadd()">
            <mat-icon class="WIRbutton-icons">add</mat-icon>New Minutes of Meeting
          </button>
        </div>
        <div class="col-md-2 align-self-center text-align-end font-color-lightgrey">
          <!--<mat-label>Sort by : </mat-label>-->
        </div>
        <div class="col-md-3 align-self-center">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary float-end" (click)="exportExcel()">
            <mat-icon>download</mat-icon>
            Export to Excel
          </button>
        </div>
        <div class="col-md-3 padding-0">
          <div class="display-flex">
            <mat-form-field appearance="standard" class="width-100">
              <mat-select (selectionChange)='onSortChange($event.value)' [(value)]="sortColumn">
                <mat-option *ngFor="let sortColumn of sortColumnList" [value]="sortColumn.Value"><span class="{{sortColumn.DisplayName == '' ? 'spn_sort':'spn_sort_hide'}}">Sort by : </span>{{ sortColumn.DisplayName }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
     
      <div class="row">
        <div>
          <mat-paginator #paginatorTop
                         [length]="length"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         showFirstLastButtons
                         (page)="pageEvent=loadMOMData($event); paginatorBottom.pageIndex = pageIndex"
                         [pageIndex]="pageIndex"
                         aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
      <div class="row mb-1"></div>
      <div class="row " style="height:40%;">

        <div class="display-flex">
          <section class="grid-container border-radius-4 mat-elevation-z8" tabindex="0">
            <table mat-table [dataSource]="MOMDataSorce" class="grid-width" matSort>
              <!--Actions-->
              <ng-container matColumnDef="Attachments" sticky>
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border">Attachments</th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                  <div class="display-flex">
                    <span class="icon-container">
                      <button class="button-icon-circle align-items-center docIconMargin action-button" mat-mini-fab color="accent" [appSetRouteActive]="'MinutesofMeetingView'">
                        <mat-icon matTooltip="{{element.Module?.Name}} added" class="material-symbols-outlined font-size-20" (click)="viewInterface(element.Id)">
                          ballot
                        </mat-icon>
                      </button>
                    </span>
                    <span *ngIf="element.Documents.length > 0" class="icon-container">
                      <button class="button-icon-circle align-items-center docIconMargin action-button" mat-mini-fab color="accent" [appSetRouteActive]="'MinutesofMeetingView'">
                        <mat-icon matTooltip="Documents present" class="material-symbols-outlined font-size-20" (click)="onDocumentsPresent(element.Id)">
                          attachment
                        </mat-icon>
                      </button>
                    </span>
                    <span *ngIf="element.ActionItems.length > 0" class="icon-container">
                      <button class="button-icon-circle align-items-center docIconMargin action-button" mat-mini-fab color="accent" [appSetRouteActive]="'MinutesofMeetingView'">
                        <mat-icon matTooltip="Action item present" class="material-symbols-outlined font-size-20" (click)="viewActionItem(element.Id)">
                          move_item
                        </mat-icon>
                      </button>
                    </span>
                  </div>
                </td>
              </ng-container>

              <!-- Title Column -->
              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Title </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Title}} </td>
              </ng-container>

              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Status}} </td>
              </ng-container>
              <ng-container matColumnDef="ModuleName">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Module </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Module?.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="GroupName">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Group Name </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.InterfaceGroup?.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="Contractor1">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Contractor1 </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.InterfaceGroup?.Contractor1?.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="Contractor2">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Contractor2 </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.InterfaceGroup?.Contractor2?.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="MeetingLocation">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Meeting Location </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.MeetingLocation}} </td>
              </ng-container>
              <ng-container matColumnDef="MeetingDate">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Meeting Date </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.MeetingDate | date:dateTimeFormat : utcoffsetvalue}} </td>
              </ng-container>
              <ng-container matColumnDef="NextMeetingLocation">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Next Meeting Location </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.NextMeetingLocation}} </td>
              </ng-container>
              <ng-container matColumnDef="NextMeetingDate">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Next Meeting Date </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.NextMeetingDate | date:dateTimeFormat : utcoffsetvalue}} </td>
              </ng-container>
              <ng-container matColumnDef="SignedByContractor1">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Signed By Contractor1 </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.SignedByContractor1User?.FirstName}} {{element.SignedByContractor1User?.LastName}} </td>
              </ng-container>
              <ng-container matColumnDef="SignedByContractor2">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Signed By Contractor2 </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.SignedByContractor2User?.FirstName}} {{element.SignedByContractor2User?.LastName}} </td>
              </ng-container>
              <ng-container matColumnDef="SignedByContractor1Date">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Signed by Contractor1 Date </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.SignedByContractor1Date | date:dateTimeFormat : utcoffsetvalue}} </td>
              </ng-container>
              <ng-container matColumnDef="SignedByContractor2Date">
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Signed by Contractor2 Date </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.SignedByContractor2Date | date:dateTimeFormat : utcoffsetvalue}} </td>
              </ng-container>
              <ng-container matColumnDef="Action" class="display-flex" stickyEnd>
                <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border"> Action </th>
                <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                  <div class="display-flex">
                    <span class="padding-left-right-2">
                      <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'MinutesofMeetingView'" [disabled]="!this.hasPermission('mom.view')" (click)="viewRecord(element.Id)">
                        <mat-icon class="icon-size-20" matTooltip="View">visibility</mat-icon>
                      </button>
                    </span>
                    <span class="padding-left-right-2">
                      <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'MinutesofMeetingEdit'" [disabled]="!this.hasPermission('mom.edit') || element.Status == 'This MOM is signed and closed'|| this._userRoleId == this.gatekeeperRole" (click)="editRecord(element.Id)">
                        <mat-icon class="icon-size-20" matTooltip="Edit">edit</mat-icon>
                      </button>
                    </span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-register"></tr>
            </table>
          </section>
        </div>
      </div>
      <div class="row mb-1"></div>
      <div class="row">
        <div>
          <mat-paginator #paginatorBottom
                         [length]="length"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="pageSizeOptions"
                         showFirstLastButtons
                         (page)="pageEvent=loadMOMData($event); paginatorTop.pageIndex = pageIndex"
                         [pageIndex]="pageIndex"
                         aria-label="Select page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
