import { ContactContractorMapping } from "./contactContractorMapping";
import { EmailSubscription } from "./emailSubscription";
import { UserRoleProject } from "./userroleproject";

export class Contact {
  Id !: number;
  FirstName !: string;
  LastName !: string;
  Email !: string;
  Phone !: string;
  IsRegisteredUser!: boolean;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ProjectId!: number;
  ContactContractorMappings: ContactContractorMapping[]=[];
  EmailSubscriptionUsers !: EmailSubscription[];
  ProjId?: any;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: any;
}
