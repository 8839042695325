import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './customtooltip.component.html',
  styleUrls: ['./customtooltip.component.css']
})
export class CustomTooltipComponent implements OnInit {
  tooltipContent!: string;
  @Input() tooltiptext!: string;
  constructor() { }

  ngOnInit(): void {
    this.tooltipContent = this.tooltiptext;
  }

}
