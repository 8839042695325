<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="save()" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group" class="contactOverflow">
          <mat-tab labelcontct label="Contact">
            <div class="h500tabMin">
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Contractor</mat-label>
                    <mat-select [(value)]="selectedContractorId" formControlName="Contractor">
                      <mat-option *ngFor="let Contra of contractors" [value]="Contra.Id">{{ Contra.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="(form.get('Contractor'))?.errors?.required">
                      Contractor is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>First name</mat-label>
                    <input matInput inputmode="tel" placeholder="First name" maxlength="50"
                           [(ngModel)]="contact.FirstName" formControlName="FirstName" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('FirstName'))?.errors?.required">
                      First Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Last name</mat-label>
                    <input matInput inputmode="tel" placeholder="Last name" maxlength="50"
                           formControlName="LastName" [(ngModel)]="contact.LastName" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('LastName'))?.errors?.required">
                      Last Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput inputmode="tel" placeholder="Email" maxlength="50"
                           formControlName="Email" [(ngModel)]="contact.Email" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.required">
                      Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.pattern">Please enter valid Email</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Phone</mat-label>
                    <input matInput maxlength="15" inputmode="numeric" placeholder="Phone"
                           formControlName="Phone" [(ngModel)]="contact.Phone" appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Phone'))?.errors?.pattern">Please enter valid Phone</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button class="WIRbutton WIRbutton-primary" [disabled]="this.form.invalid" type="submit">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
            <button mat-stroked-button #btnAddDocument type="button" (click)="onCancel()" class="WIRbutton WIRbutton-secondary">
              <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
