import { Component, OnInit, ViewChild } from '@angular/core';
import { ReferenceLink } from '../../../app_data/referenceLink';
import * as myGlobals from '../../../app_data/globals';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommunicationService } from '../../../services/communication.service';
import { SafeDomainList } from '../../../app_data/safedomainlist';

let ReferenceLinklists: ReferenceLink[] = [];

@Component({
  selector: 'app-reference-link-view',
  templateUrl: './reference-link-view.component.html',
  styleUrls: ['./reference-link-view.component.css']
})
export class ReferenceLinkViewComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  referenceLinks: ReferenceLink[] = [];

  displayedColumns: string[] = ['Title', 'CreatedBy', 'SubmittedDate'];
  dataSourceLinks = new MatTableDataSource<ReferenceLink>(ReferenceLinklists);

  //get user details from sessionStorage
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  domainLists: SafeDomainList[] = [];
  @ViewChild('paginatorLinks') paginatorLinks !: MatPaginator;
  @ViewChild('sortLinks') sortLinks !: MatSort;

  @ViewChild('sortLinks') set matSort(ms: MatSort) {
    this.sortLinks = ms;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    //reference Link table
    this.dataSourceLinks.paginator = this.paginatorLinks;
    this.dataSourceLinks.sort = this.sortLinks;
  }

  constructor(private communicationService:CommunicationService) { }

  ngOnInit(): void {
    this.communicationService.is_safedomainList$.subscribe(emivalue => {
      this.domainLists = emivalue.filter(x => !x.IsDeleted);
    });

    this.communicationService.is_reflinks$.subscribe(emivalue => {
      this.referenceLinks = emivalue.filter(x => !x.IsDeleted);
      this.dataSourceLinks.data = emivalue.filter(x => !x.IsDeleted);
    });
  }

}
