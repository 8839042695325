import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { MinutesofmeetingCreateComponent } from './minutesofmeeting-create.component';
import { MinutesofmeetingEditComponent } from './minutesofmeeting-edit.component';
import { MinutesofmeetingListComponent } from './minutesofmeeting-list.component';
import { MinutesofmeetingViewComponent } from './minutesofmeeting-view.component';
import { MinutesofmeetingViewInterfaceComponent } from './minutesofmeeting-view-interface.component';

const minutesofMeetingRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [      
        { path: 'MinutesofMeetingCreate', component: MinutesofmeetingCreateComponent ,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'mom.create'}}  ,
        { path: 'MinutesofMeetingEdit/:id', component: MinutesofmeetingEditComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'mom.edit'} },
        { path: 'MinutesofMeetingView/:id', component: MinutesofmeetingViewComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'mom.view'} },
        { path: 'MinutesofMeetingViewInterface/:id', component: MinutesofmeetingViewInterfaceComponent,
          canActivate: [MsalGuard, PermissionGuard], data: { perminssion:'mom.view'} },
        { path: 'MinutesofMeetings', component: MinutesofmeetingListComponent ,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'mom.view'}}  
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(minutesofMeetingRoutes)
  ]
})
export class MinutesofMeetingRoutingModule { }
