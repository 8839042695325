<form [formGroup]="interfacegroupForm">
  <div class="row ">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Contractor 2</mat-label>
        <mat-select [(value)]="selectedICcontractorGroup" formControlName="Contractor2ID" [disabled]="this.isEdit" (selectionChange)="onChangevalue($event)" [(ngModel)]="interfacegrp.Contractor2ID" #IcContractorIdInput>
          <mat-option *ngFor="let ig of icContractors" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.interfacegroupForm.get('Contractor2ID')?.hasError('required')">
          Contractor 2 is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Group Code</mat-label>
        <input matInput maxlength="50" autocomplete="off" inputmode="tel" placeholder="Group Code" formControlName="Code" [(ngModel)]="interfacegrp.Code" #GroupCodeInput appTrimWhitespace>
        <mat-error *ngIf="this.interfacegroupForm.get('Code')?.hasError('required')">
          Group Code is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Group Name</mat-label>
        <input matInput maxlength="100" autocomplete="off" inputmode="tel" placeholder="Group Name" formControlName="Name" [(ngModel)]="interfacegrp.Name" #GroupNameInput appTrimWhitespace>
        <mat-error *ngIf="this.interfacegroupForm.get('Name')?.hasError('required')">
          Group Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 mt-4" style="display:none">
      <mat-accordion class="example-headers-align" multi>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Select Workflow
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row d-flex">
            <div class="col-xl-8 col-l-8 col-md-12 col-sm-12 col-xs-12">
              <div class="main-card mb-3 card">
                <div class="card-body">
                  <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                    <div *ngFor="let sm of stages" class="vertical-timeline-item vertical-timeline-element">
                      <div>
                        <span class="vertical-timeline-element-icon bounce-in">
                          <i [class]="sm.IsApproveRequired && !sm.IsStageCLicked? 'badge badge-dot badge-dot-xl badge-warning':'badge badge-dot badge-dot-xl badge-success'"> </i>

                        </span>
                        <div class="vertical-timeline-element-content bounce-in">
                          <p class="pl-4">
                            {{sm.Stage}}
                            <br />
                            <span *ngIf="sm.Description">{{sm.Description}}</span>
                          </p>
                          <div>
                            <span *ngIf="sm.IsApproveRequired" class="vertical-timeline-element-date form-check form-switch">
                              <input class="form-check-input" matTooltip="Turn on or off wether {{sm.Stage}} is required"
                                     type="checkbox" formControlName="IsOpenApproval"
                                     (change)="approvalChange($event, sm.Id, sm.Stage)" id="IsRequestApproval_{{sm.Id}}"
                                     [checked]="sm.IsApproveRequired && !sm.IsStageCLicked? false: true">
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="interfacegroupForm.invalid" [hidden]="interfacegroupForm.valid && this.isEdit" (click)="add()" class="WIRbutton WIRbutton-primary btn-sm">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
    <div [hidden]="interfacegroupForm.invalid || !this.isEdit">
      <button mat-stroked-button type="button" [hidden]="interfacegroupForm.invalid || !this.isEdit" (click)="update(this.interfacegrp)" class="WIRbutton WIRbutton-primary btn-sm update-button">
        <mat-icon class="WIRbutton-icons">update</mat-icon>Update
      </button>
      <button mat-stroked-button type="button" [hidden]="interfacegroupForm.invalid || !this.isEdit" (click)="resetForm()" class="btn btn-secondary btn-sm">
        <mat-icon class="WIRbutton-icons">restart_alt</mat-icon>Reset
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorinterfacegrp [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="interfacegroupdataSource" class="mat-elevation-z8" matSort #sortinterfacegrp="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <div class="d-flex">
              <mat-icon matTooltip="edit" *ngIf="disc.Contractor1ID == this._contractorID" class="mat-icon-small-size" (click)="edit(disc)">edit</mat-icon>
              <mat-icon matTooltip="delete" *ngIf="disc.Contractor1ID == this._contractorID" class="mat-icon-small-size" (click)="confirmCancelDialog(disc.Id, disc.Code, disc.Name)">delete</mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Code </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Code}} </td>
        </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Name </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="Contractor1.Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 1 </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contractor1.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="Contractor2.Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 2 </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contractor2.Name}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>

</div>
