import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInvitationComponent } from './user-invitation.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvitaionRoutingModule } from '../../../modules/invitation/invitaion-routing.module';



@NgModule({
  declarations: [UserInvitationComponent],
  imports: [
    CommonModule,
    MaterialModule, FormsModule,
    ReactiveFormsModule,
    InvitaionRoutingModule
  ],
  exports:[UserInvitationComponent]
})
export class UserInvitationModule { }
