import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AdditionalInformationHistory } from '../../../app_data/additionalInformationHistory';
import * as myGlobals from '../../../app_data/globals';
import { DialogService } from '../../../services/dialog.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NGXLogger } from 'ngx-logger';
import { AdditionalInformationHistoryService } from '../../../services/additionalInformationHistory.service';

let AdditionalInformationlists: AdditionalInformationHistory[] = [];

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.css']
})
export class AdditionalInformationComponent implements OnInit {
  displayedColumns: string[] = ['AssignedTo', 'ReasonOfAssignment', 'CreatedBy', 'CreatedDate'];
  additionalInformationDataSource = new MatTableDataSource<AdditionalInformationHistory>(AdditionalInformationlists);
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  @Input() interfaceId!: number;
  errorMessage!: string;
  additionalInformationModel: AdditionalInformationHistory[] = [];
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  @ViewChild('sortAdditional') sortAdditional !: MatSort;
  @ViewChild('paginatorAdditional') paginatorAdditional !: MatPaginator;


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortAdditional = ms;
    this.additionalInformationDataSource.paginator = this.paginatorAdditional;
    this.additionalInformationDataSource.sort = this.sortAdditional;
  }

  constructor(private SpinnerService: NgxSpinnerService, private additionalInformationService: AdditionalInformationHistoryService,
    private dialog: DialogService, private logger: NGXLogger ) { }

  ngOnInit(): void {
    if (this.interfaceId > 0) {
      this.getAdditionalInformationHistoriesByInterfaceId(this.interfaceId);
    }
  }

  getAdditionalInformationHistoriesByInterfaceId(interfaceId: number): void {
    try {
      this.SpinnerService.show();
      this.additionalInformationService.getAdditionalInformationHistoryByInterfaceId(interfaceId).subscribe({
        next: (additionalInformationData) => {
          this.additionalInformationModel = additionalInformationData;
          this.additionalInformationDataSource.data = this.additionalInformationModel.sort().reverse();
          this.SpinnerService.hide();
        },
        error: (err: string) => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
