import { UserRoleProject } from "./userroleproject";

export class ReferenceLink {
  Id?: number;
  Link !: string;
  Title !: string;
  InterfaceID?: number;
  ContractorID?: number;
  ProjectId !: number;
  IsDeleted: boolean = false;
  CreatedBy !: number;
  CreatedUserRoleMapping?: UserRoleProject;
  CreatedDate?: Date;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ModifiedUser?: any;
}
