import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import * as myGlobals from '../app_data/globals';
import { PushNotification } from '../app_data/pushNotification';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private hubConnection!: signalR.HubConnection;
  loginToken: string = myGlobals.jwtToken;
  public data!: PushNotification;
  private sendNotificationSubject = new Subject<PushNotification>();
  getUpdatedNoification$: Observable<PushNotification> = this.sendNotificationSubject.asObservable();

  constructor() { }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiDomainURL+'Notify', { accessTokenFactory: () => this.loginToken })
      .build();

    this.hubConnection
      .start()
      .then(() => console.log('Connection started'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addNotificationListner = () => {
    this.hubConnection.on('SendMessage', (notification: PushNotification) => {
      this.data = notification;
      this.sendNotificationSubject.next(notification);
    });
  }
}
