import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from '../../../app_data/user';

@Component({
  selector: 'app-user-invitation',
  templateUrl: './user-invitation.component.html',
  styleUrls: ['./user-invitation.component.css']
})
export class UserInvitationComponent implements OnInit {
  user!: User;
  UserInvitationForm!: FormGroup;
  verifyCode: string = "";
  @Output() verificationCode = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
    this.UserInvitationForm = new FormGroup({
      InvitationCodeControl: new FormControl('', [Validators.required])
    });
  }

  checkFormValid():boolean {
    if (this.UserInvitationForm.valid == false) {
      this.UserInvitationForm.get('InvitationCodeControl')?.markAsTouched();
      return false;
    }
    else {
      return true;
    }
  }

  onKeypressEventVerifyCode(event: any) {
    this.verificationCode.emit(event.target.value);
  }
}
