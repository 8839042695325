<form [formGroup]="interfacegroupForm">
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Contractor 2</mat-label>
        <mat-select [(value)]="selectedContractorGroup" formControlName="contractor2ID" (selectionChange)="onChangevalue($event)" [(ngModel)]="interfaceGroup.Contractor2ID" #IcContractorIdInput>
          <mat-option *ngFor="let ig of contractors" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.interfacegroupForm.get('Contractor2ID')?.errors?.required">
          Contractor 2 is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Group Code</mat-label>
        <input matInput maxlength="50" autocomplete="off" inputmode="tel" placeholder="Group Code" formControlName="code" [(ngModel)]="interfaceGroup.Code" #GroupCodeInput appTrimWhitespace>
        <mat-error *ngIf="this.interfacegroupForm.get('Code')?.hasError('required')">
          Group Code is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Group Name</mat-label>
        <input matInput maxlength="100" autocomplete="off" inputmode="tel" placeholder="Group Name" formControlName="name" [(ngModel)]="interfaceGroup.Name" #GroupNameInput appTrimWhitespace>
        <mat-error *ngIf="this.interfacegroupForm.get('Name')?.hasError('required')">
          Group Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">        
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="interfacegroupForm.invalid" (click)="add()"
        class="WIRbutton WIRbutton-primary">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorInterfaceGroup [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="interfaceGroupDataSource" class="mat-elevation-z8" matSort #sortInterfaceGroup="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button>
              <mat-icon matTooltip="delete" class="mat-icon-small-size" (click)="confirmCancelDialog(disc.Code, disc.Name)">delete</mat-icon>
            </button>
          </td>           
        </ng-container>
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Code </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Code}} </td>
        </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Name </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="Contractor1">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 1 </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contractor1}} </td>
        </ng-container>
        <ng-container matColumnDef="Contractor2">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 2 </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Contractor2}} </td>
        </ng-container>       
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
