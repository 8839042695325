import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { DefinitionSetting } from '../../app_data/definitionSetting';
import * as myGlobals from '../../app_data/globals';
import { Project } from '../../app_data/project';
import { ProjectSetting } from '../../app_data/projectSetting';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-definationsettings-edit',
  templateUrl: './definationsettings-edit.component.html',
  styleUrls: ['./definationsettings-edit.component.css']
})
export class DefinationsettingsEditComponent implements OnInit {
  definationsettingForm !: FormGroup;
  definationsetting !: DefinitionSetting;
  _settings: ProjectSetting[] = [];
  project!: Project;
  @Output() definationsettingemit: EventEmitter<ProjectSetting[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private communicationService: CommunicationService, private logger: NGXLogger,
    private dialog: DialogService) {
    this.definationsetting = new DefinitionSetting(); this.project = new Project();
  }

  ngOnInit(): void {
    try {
      this.definationsettingForm = new FormGroup({
        ProviderContractorName: new FormControl(''),
        RequestingContractorName: new FormControl(''),
        ProviderDisciplineName: new FormControl(''),
        RequestingDisciplineName: new FormControl(''),
        ExpectedResponseDateName: new FormControl(''),
        CloseOutDateName: new FormControl(''),
        ResponseDateActualName: new FormControl(''),
        CloseOutDateActualName: new FormControl('')
      });
      this.communicationService.is_project$.subscribe(emivalue => {
        if (emivalue.Settings != null) {
          this.project.Settings = emivalue.Settings;
          this._settings = emivalue.Settings;
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "ProviderContractorName");
          var index1 = this.project.Settings.findIndex(x => x.ConfigKey == "RequestingContractorName");
          var index2 = this.project.Settings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
          var index3 = this.project.Settings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
          var index4 = this.project.Settings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
          var index5 = this.project.Settings.findIndex(x => x.ConfigKey == "CloseOutDateName");
          var index6 = this.project.Settings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
          var index7 = this.project.Settings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
          this.definationsettingForm.setValue({
            ProviderContractorName: index > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue : myGlobals.ProviderContractorLabel,
            RequestingContractorName: index1 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue : myGlobals.RequestingContractorLabel,
            ProviderDisciplineName: index2 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue : myGlobals.ProviderDisciplineLabel,
            RequestingDisciplineName: index3 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue : myGlobals.RequestingDisciplineLabel,
            ExpectedResponseDateName: index4 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue : myGlobals.ExpectedResponseDateLabel,
            CloseOutDateName: index5 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue : myGlobals.CloseOutDateLabel,
            ResponseDateActualName: index6 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue : myGlobals.ResponseDateActualLabel,
            CloseOutDateActualName: index7 > -1 ? emivalue.Settings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue : myGlobals.CloseOutDateActualLabel
          })
        }
      });
      this.definationsettingemit.emit(this.project.Settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
    
  }

  onTextChange(controlName: string, event: any) {
    try {
      if (event.target.value == "") {
        if (controlName == "ProviderContractorName") {
          event.target.value = myGlobals.ProviderContractorLabel;
        }
        if (controlName == "RequestingContractorName") {
          event.target.value = myGlobals.RequestingContractorLabel;
        }
        if (controlName == "ProviderDisciplineName") {
          event.target.value = myGlobals.ProviderDisciplineLabel;
        }
        if (controlName == "RequestingDisciplineName") {
          event.target.value = myGlobals.RequestingDisciplineLabel;
        }
        if (controlName == "ExpectedResponseDateName") {
          event.target.value = myGlobals.ExpectedResponseDateLabel;
        }
        if (controlName == "CloseOutDateName") {
          event.target.value = myGlobals.CloseOutDateLabel;
        }
        if (controlName == "ResponseDateActualName") {
          event.target.value = myGlobals.ResponseDateActualLabel;
        }
        if (controlName == "CloseOutDateActualName") {
          event.target.value = myGlobals.CloseOutDateActualLabel;
        }
      }
      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = event.target.value;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      this.definationsettingemit.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetDefault() {
    try {
      this.definationsettingForm.setValue({
        ProviderContractorName: myGlobals.ProviderContractorLabel,
        RequestingContractorName: myGlobals.RequestingContractorLabel,
        ProviderDisciplineName: myGlobals.ProviderDisciplineLabel,
        RequestingDisciplineName: myGlobals.RequestingDisciplineLabel,
        ExpectedResponseDateName: myGlobals.ExpectedResponseDateLabel,
        CloseOutDateName: myGlobals.CloseOutDateLabel,
        ResponseDateActualName: myGlobals.ResponseDateActualLabel,
        CloseOutDateActualName: myGlobals.CloseOutDateActualLabel
      })
      var index = this._settings.findIndex(x => x.ConfigKey == "ProviderContractorName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ProviderContractorLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ProviderContractorName", ConfigValue: myGlobals.ProviderContractorLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "RequestingContractorName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.RequestingContractorLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "RequestingContractorName", ConfigValue: myGlobals.RequestingContractorLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ProviderDisciplineLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ProviderDisciplineName", ConfigValue: myGlobals.ProviderDisciplineLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.RequestingDisciplineLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "RequestingDisciplineName", ConfigValue: myGlobals.RequestingDisciplineLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ExpectedResponseDateLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ExpectedResponseDateName", ConfigValue: myGlobals.ExpectedResponseDateLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "CloseOutDateName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.CloseOutDateLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "CloseOutDateName", ConfigValue: myGlobals.CloseOutDateLabel, CreatedBy: this.project.Settings[0].CreatedBy,
          CreatedDate: this.project.Settings[0].CreatedDate, ModifiedBy: this._userRoleMappingId
        });
      }

      var index = this._settings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ResponseDateActualLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ResponseDateActualName", ConfigValue: myGlobals.ResponseDateActualLabel, CreatedBy: this._userRoleMappingId
        });
      }
      
      var index = this._settings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.CloseOutDateActualLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "CloseOutDateActualName", ConfigValue: myGlobals.CloseOutDateActualLabel, CreatedBy: this._userRoleMappingId
        });
      }
      this.definationsettingemit.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
