<form [formGroup]="workflowTemplateForm">
    <div class="row">
        <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
            <mat-form-field appearance="standard">
                <mat-label>Interface Group</mat-label>
                <mat-select [(value)]="selectedInterfaceGroupId"
                            formControlName="interfaceGroup" required>
                  <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="this.workflowTemplateForm.get('interfaceGroup')?.hasError('required')">
                  Interface Group is <strong>required</strong>
                </mat-error>
              </mat-form-field>
        </div>
        <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
            <mat-form-field appearance="standard">
                <mat-label>Module Name</mat-label>
                <mat-select [(value)]="selectedModuleId" formControlName="moduleId"
                    (selectionChange)="onModuleChangeFetchWorkflowTemplate()">
                    <mat-option *ngFor="let module of this.moduleProjectMappings" [value]="module.Module.Id">{{
                        module.Module.Name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="this.workflowTemplateForm.get('ModuleId')?.hasError('required')">
                    Module Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
            <mat-form-field appearance="standard">
                <mat-label>Workflow Template</mat-label>
                <mat-select [(value)]="selectedWorkflowTemplateId" formControlName="workflowTemplateId"
                    [disabled]="true">
                    <mat-option *ngFor="let wt of workflowTemplates" [value]="wt.Id">{{ wt.Name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="this.workflowTemplateForm.get('workflowTemplateId')?.errors?.required">
                    Workflow Template is <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
        <button mat-stroked-button type="button" [disabled]="workflowTemplateForm.invalid" (click)="add()"
            class="WIRbutton WIRbutton-primary">
            <mat-icon class="WIRbutton-icons">add</mat-icon>Add
        </button>
    </div>
</form>

<div class="row">
    <div class="col-lg-12">
      <mat-paginator #paginatorWorkflowTemplateMapping [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
      </mat-paginator>
      <div class="table-responsive scroller">
        <table mat-table [dataSource]="interfaceGroupTemplateDataSource" class="mat-elevation-z8" matSort #sortWorkflowTemplateMapping="matSort">         
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Name </th>
            <td mat-cell *matCellDef="let disc"> {{disc.InterfaceGroup.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="ModuleId">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Module Name </th>
            <td mat-cell *matCellDef="let disc"> {{disc.Module.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="WorkflowTemplateId">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Workflow Template </th>
            <td mat-cell *matCellDef="let disc"> {{disc.WorkflowTemplate.Name}} </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
              <span><br />{{noRecordFoundMsg}}</span>
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>