import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { Priority } from '../app_data/priority';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class PriorityService {

  private apiUrl = environment.apiURl + 'Priority';

  constructor(private http: HttpClient) {

  }

		getPriorities(type: string): Observable<Priority[]> {
      return this.http.get<response>(this.apiUrl + "?$filter=Type eq '" + type +"' and ProjectId eq null and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getPrioritiesByProject(type: string, projectId: number): Observable<Priority[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Type eq '" + type + "' and ProjectId eq " + projectId + " and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getPriorityById(priorityId: number): Observable<Priority[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + priorityId + " and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  post(model: Priority): Observable<Priority> {
    return this.http.post<Priority>(this.apiUrl, model, httpOptions);


  }
  put(model: Priority): Observable<Priority> {
    return this.http.put<Priority>(this.apiUrl + "/" + model.Id, model, httpOptions);


  }
  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
