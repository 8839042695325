import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectFieldsConfigurationComponent } from './project-fields-configuration.component';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectFieldsConfigurationEditComponent } from './project-fields-configuration-edit.component';
import { ProjectFieldsConfigurationCreateComponent } from './project-fields-configuration-create.component';
import { ProjectFieldsConfigurationUpdateComponent } from './project-fields-configuration-update.component';
import { ProjectFieldsConfigurationViewComponent } from './project-fields-configuration-view.component';



@NgModule({
  declarations: [ProjectFieldsConfigurationComponent, ProjectFieldsConfigurationEditComponent, ProjectFieldsConfigurationCreateComponent, ProjectFieldsConfigurationUpdateComponent, ProjectFieldsConfigurationViewComponent],
  imports: [
    CommonModule, 
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports:[
    ProjectFieldsConfigurationComponent,ProjectFieldsConfigurationEditComponent,ProjectFieldsConfigurationCreateComponent,ProjectFieldsConfigurationUpdateComponent,ProjectFieldsConfigurationViewComponent
  ]
})
export class ProjectFieldsConfigurationModule { }
