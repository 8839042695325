import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { ProjectCreateComponent } from './project-create.component';
import { ProjectsettingsModule } from '../project-setting/projectsettings.module';
import { DefinationsettingsModule } from '../defination-setting/definationsettings.module';
import { SafedomainsettingModule } from '../safedomain-setting/safedomainsetting.module';
import { ProjectEditComponent } from './project-edit.component';
import { ProjectListComponent } from './project-list.component';
import { ProjectRoutingModule } from './project-routing.module';
import { UploadModule } from '../../shared/components/upload/upload.module';
import { MaterialModule } from '../../shared/material/material.module';
import { ProjectFieldsConfigurationModule } from '../projectFieldsConfiguration/project-fields-configuration.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { ProjectSettingsTQModule } from '../project-setting-tq/projectsettingstq.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';


@NgModule({
  declarations: [ProjectCreateComponent, ProjectEditComponent, ProjectListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ProjectsettingsModule,
    ProjectSettingsTQModule,
    UploadModule,
    DefinationsettingsModule,
    ProjectFieldsConfigurationModule,
    SafedomainsettingModule,
    ProjectRoutingModule,
    AuthorisationModule,
    ActiveRouteModule,
    CommonDirectivesModule
  ],
  
})
export class ProjectModule { }
