import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { RolePermission } from '../../../app_data/rolepermission';
import { RolePermissionService } from '../../../services/rolepermission.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit {
  @Input() hasPermission = '';
  permissions: RolePermission[] = [];
  _roleId = parseInt(sessionStorage.getItem("roleId")!);

  constructor(private el: ElementRef,private rolePermissionService: RolePermissionService) {
  }

  ngOnInit() {
    //let permissions = this.rolePermissionService.getPermissionByRole(1);
    this.rolePermissionService.getPermissionByRole(this._roleId).subscribe({
      next: permissions => {
        if (permissions.filter(x => x.Permission!.Name == this.hasPermission).length > 0) {
          this.el.nativeElement.style.visibility = 'visible';
        }
        else {
          this.el.nativeElement.style.display = 'none';
        }
      }
    })    
  }
}
