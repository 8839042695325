<form [formGroup]="settingForm">

  <div class="cardBox">
    <mat-card class="h-min-100">
      <mat-card-title class="titleHeader">
        <div>
          Interface number generation pattern
          <br>
          <div class="hintMessage">(Allow to select any one option)</div>
        </div>
      </mat-card-title>
    </mat-card>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsUniqueNumberByProject"
                 formControlName="IsUniqueNumberByProject" value="yes"
                 (change)="settingChecked('IsUniqueNumberByProject',$event)">
          <mat-label>Unique Interface number on Project level</mat-label>
          <app-tooltip [tooltiptext]="UniNumberbyProjecttext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsUniqueNumberByInterfaceGroup"
                 formControlName="IsUniqueNumberByInterfaceGroup" value="yes"
                 (change)="settingChecked('IsUniqueNumberByInterfaceGroup',$event)">
          <mat-label>Unique Interface number on Interface Group level</mat-label>
          <app-tooltip [tooltiptext]="UniNumberbyGrouptext"></app-tooltip>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableCustomiseInterfaceNumberPattern"
                   [(ngModel)]="setting.IsEnableCustomiseInterfaceNumberPattern"
                   formControlName="IsEnableCustomiseInterfaceNumberPattern" value="yes"
                   (change)="settingChecked('IsEnableCustomiseInterfaceNumberPattern',$event)">
            <mat-label>Customise interface number pattern</mat-label>
            <app-tooltip [tooltiptext]="CustomiseInterfaceNumberPatterntext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 ml-4">
        <input class="freeTextbox" autocomplete="off" maxlength="100"
               [(ngModel)]="setting.CustomiseInterfaceNumberPattern" *ngIf="showCustomiseInterfaceNumberPattern"
               formControlName="CustomiseInterfaceNumberPattern" placeholder="e.g. <GroupCode>-<ContractorCode>-<DisciplineCode>-<FreeText>"
               (change)="onTextChange('CustomiseInterfaceNumberPattern',$event)">
      </div>
    </div>
  </div>
  <div class="cardBox mt-2">
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableCloseOutDateReminderByDays"
                   [(ngModel)]="setting.IsEnableCloseOutDateReminderByDays"
                   formControlName="IsEnableCloseOutDateReminderByDays" value="yes"
                   (change)="settingChecked('IsEnableCloseOutDateReminderByDays',$event)">
            <mat-label>Remind Before Closeout(Days)</mat-label>
            <app-tooltip [tooltiptext]="CloseOutDateReminderByDaysText"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <input class="smallnumbertextbox" maxlength="2" [(ngModel)]="setting.CloseOutDateReminderByDays" (keypress)="onlyDigitsAllowed($event)"
               *ngIf="showCloseOutDateReminderByDays" formControlName="CloseOutDateReminderByDays"
               (change)="onTextChange('CloseOutDateReminderByDays',$event)">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableDocumentUploadFileSize"
                   [(ngModel)]="setting.IsEnableDocumentUploadFileSize" formControlName="IsEnableDocumentUploadFileSize"
                   value="yes" (change)="settingChecked('IsEnableDocumentUploadFileSize',$event)">
            <mat-label>Maximum upload file size (in MB)</mat-label>
            <app-tooltip [tooltiptext]="DocumentUploadFileSizetext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <input class="smallnumbertextbox" autocomplete="off" maxlength="2" [(ngModel)]="setting.DocumentUploadFileSize" (keypress)="onlyDigitsAllowed($event)"
               *ngIf="showDocumentUploadFileSize" formControlName="DocumentUploadFileSize"
               (change)="onTextChange('DocumentUploadFileSize',$event)">
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="wraptxtchk">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" id="IsEnableDefaultExpectedResponseDate"
                   formControlName="IsEnableDefaultExpectedResponseDate" value="yes"
                   (change)="settingChecked('IsEnableDefaultExpectedResponseDate',$event)">
            <mat-label>Closeout planned date by number of days</mat-label>
            <app-tooltip [tooltiptext]="DefaultCloseoutPlannedDateByNoOfDaystext"></app-tooltip>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <input #defaultexpectedresponsedateInput autocomplete="off" class="smallnumbertextbox" (keypress)="onlyDigitsAllowed($event)"
               [(ngModel)]="setting.DefaultExpectedResponseDateByDays" formControlName="DefaultExpectedResponseDateByDays"
               *ngIf="show" (change)="onTextChange('DefaultExpectedResponseDateByDays',$event)">
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsDualDiscipline" formControlName="IsDualDiscipline"
                 value="yes" (change)="settingChecked('IsDualDiscipline',$event)">
          <mat-label>Enable dual disciplines on Interface</mat-label>
          <app-tooltip [tooltiptext]="IsDualDisciplinestext"></app-tooltip>
        </div>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="IsAcceptAsPreliminary"
                 formControlName="IsAcceptAsPreliminary" value="yes"
                 (change)="settingChecked('IsAcceptAsPreliminary',$event)">
          <mat-label>Enable Accept as preliminary option</mat-label>
          <app-tooltip [tooltiptext]="IsAcceptAsPreliminarytext"></app-tooltip>
        </div>
      </div>
    </div>
  </div>
  <div class="cardBox mt-2">
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <mat-label>Active Modules : {{this.selectedModuleNames}}</mat-label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 ml-4">
        <mat-form-field appearance="standard">
          <mat-label>Select modules to enable</mat-label>
          <mat-select multiple [(value)]="selectedModuleIds" formControlName="ActiveModuleIds" (selectionChange)="onModulesChange($event)" #ActiveModuleIdsInput>
            <mat-option *ngFor="let ig of modules" [value]="ig.Id" [disabled]="ig.Id === this.interfaceModuleId">{{ ig.Name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</form>
