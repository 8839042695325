<div class="container-fluid">
    <mat-card>
      <mat-card-content class="h700tabMin">
        <div class="page-title">
          <div class="page-title-name">Interface Groups</div>
        </div>
        <br />
        <div class="row">
          <mat-form-field appearance="standard" class="filter">
            <mat-label>Group Code</mat-label>
            <input matInput autocomplete="off" [formControl]="groupCodeFilter" placeholder="Search Group Code">
          </mat-form-field>
          <mat-form-field appearance="standard" class="filter">
            <mat-label>Group Name</mat-label>
            <input matInput autocomplete="off" [formControl]="groupNameFilter" placeholder="Group Name">
          </mat-form-field>
          <mat-form-field appearance="standard" class="filter">
            <mat-label>Contractor Name</mat-label>
            <input matInput autocomplete="off" [formControl]="contractorNameFilter" placeholder="Search Contractor Name">
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <mat-paginator #paginatorInterfaceGroup [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
            </mat-paginator>
            <div class="table-responsive scroller-list-Screen">
              <table mat-table [dataSource]="interfaceGroupDataSource" class="mat-elevation-z8" matSort #sortInterfaceGroup="matSort">
                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="d-flex">
                      <div class="flAction d-flex align-items-center">
                        <mat-icon matTooltip="edit" class="mat-icon-small-size" [hasPermission]="'user.edit'" (click)="edit(element.Contractor1ID, element.Id)">
                          edit
                        </mat-icon>
                      </div>
                    </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Code">
                  <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Code </th>
                  <td mat-cell *matCellDef="let element"> {{element.Code}} </td>
                </ng-container>
                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Group Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>
                <ng-container matColumnDef="Contractor1.Name">
                  <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 1 Name </th>
                  <td mat-cell *matCellDef="let element"> {{element.Contractor1.Name}} </td>
                </ng-container>
                <ng-container matColumnDef="Contractor2.Name">
                  <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor 2 Name</th>
                  <td mat-cell *matCellDef="let element"> {{element.Contractor2.Name}} </td>
                </ng-container>
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                    <span><br />{{noRecordFoundMsg}}</span>
                  </td>
                </tr>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  