import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafedomainsettingCreateComponent } from './safedomainsetting-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafedomainsettingEditComponent } from './safedomainsetting-edit.component';
import { MaterialModule } from '../../shared/material/material.module';



@NgModule({
  declarations: [SafedomainsettingCreateComponent, SafedomainsettingEditComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [SafedomainsettingCreateComponent, SafedomainsettingEditComponent]
})
export class SafedomainsettingModule { }
