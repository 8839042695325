import { InterfaceProjectFieldsMapping } from "./InterfaceProjectFieldsMapping";

export class ProjectFieldsConfiguration {
    Id!: number;
    ProjectID: number = 0;
    FieldKey!: string;
    FieldValueType!: string;
    Module!: string;
    IsDeleted!: boolean;
    CreatedDate?: Date;
    CreatedBy?: number;
    ModifiedDate?: Date;
    ModifiedBy?: number;
    InterfaceProjectFieldsMappings : InterfaceProjectFieldsMapping[] = [];
}