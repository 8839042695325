import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { Notification } from '../app_data/notification';
import { response } from '../app_data/OdataResponse';
import { User } from '../app_data/user';
import { environment } from 'src/environments/environment';
import { Contact } from '../app_data/contact';
import { CommonFunction } from '../app_data/commonFunction';
import { EmailSubscription } from '../app_data/emailSubscription';
import { Interface } from '../app_data/interface';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'Notifications';
  private apiUsersUrl = environment.apiURl + 'users';
  private apiContractorRecipientUsersUrl = environment.apiURl + 'contacts';
  private apiEmailSubscriptionUsersURL = environment.apiURl + 'emailsubscriptionusers';

  constructor(private http: HttpClient) { }
  notificationModel !: Notification;
  errorMessage = '';

  convertUTCDatesToTimezonedate(data: Notification[]){              
    // convert all dates to timezone datetime        
    data.forEach(x=> {       
      x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;
      x.ReminderDate = x.ReminderDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.ReminderDate, this._projectTimeZone, false) : null!;
      x.SentOn = x.SentOn != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.SentOn, this._projectTimeZone, false) : null!;
    })
    
    return data;
}

  post(model: Notification): Observable<Notification> {
    return this.http.post<Notification>(this.apiUrl, model, httpOptions);
  }

  put(model: Notification): Observable<Notification> {
    return this.http.put<Notification>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  getAllRecipientUsers(): Observable<User[]> {
    return this.http.get<response>(this.apiUsersUrl, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContractorRecipientUsers(contractorId: number, flag: string): Observable<Contact[]> {
    return this.http.get<response>(this.apiContractorRecipientUsersUrl + "?$expand=ContactContractorMappings($filter=ContractorId eq " + contractorId + " and IsDeleted eq false),EmailSubscriptionUsers($filter=ContractorId eq " + contractorId + " and IsDeleted eq false and contains(SubscriptionFlag,'" + flag +"'))&filter =IsDeleted eq false", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getEmailSubscriptionsByFilter(moduleId: number, interfaceModel: Interface): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiEmailSubscriptionUsersURL + "?$filter=ModuleId eq " + moduleId + " and ((ContractorId eq " + interfaceModel.ReceivingContractorID + " and (DisciplineId eq " + interfaceModel.ReceivingContractorDisciplineID + " or DisciplineId eq null)) or (ContractorId eq " + interfaceModel.ResponsibleContractorID + " and (DisciplineId eq " + interfaceModel.ResponsibleContractorDisciplineID + " or DisciplineId eq null))) and (InterfaceGroupId eq " + interfaceModel.InterfaceGroupID + " or InterfaceGroupId eq null) and (StageId eq " + interfaceModel.StageId + " or StageId eq null) and IsDeleted eq false &$expand=Contact", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getNotificationsByReferenceId(Id: string, Type: string): Observable<Notification[]> {
    return this.http.get<response>(this.apiUrl + "?filter =ReferenceId eq '" + Id + "' and ReferenceType eq '" + Type+"'", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
