import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ReportSearchFilter } from '../app_data/reportSearchFilter';
import { StandardReport } from '../app_data/standardReport';
import { CommonFunction } from '../app_data/commonFunction';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  })
}

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.reportAPIURL;

  constructor(private http: HttpClient) {
  }

  post(model: ReportSearchFilter[],reportName:string): Observable<StandardReport> {
    return this.http.post<StandardReport>(this.apiUrl + reportName, model, httpOptions)
      .pipe(
        map((data: StandardReport) => {
          return this.updateOverdueFlags(data);
        }),
        catchError(this.handleError)
      );
  }


  updateOverdueFlags(data: StandardReport) {
    if (data.InterfaceSummary != null) {
      data.InterfaceSummary.forEach(x => {
        x.IsCloseoutPlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.CloseoutPlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
        x.IsFirstResponsePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.FirstResponsePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
        x.IsRequestorActionIsPending = CommonFunction.IsRequestorActionPending(x.Status, x.Response);
        x.IsProviderActionIsPending = CommonFunction.IsProviderActionPending(x.Status, x.Response);
      })
    }
    return data;
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
