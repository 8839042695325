import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NGXLogger } from 'ngx-logger';
import { DialogService } from 'src/app/services/dialog.service';
import { Project } from '../../app_data/project';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { CommunicationService } from '../../services/communication.service';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let Safedomainlists: SafeDomainList[] = [];
@Component({
  selector: 'app-safedomainsetting-edit',
  templateUrl: './safedomainsetting-edit.component.html',
  styleUrls: ['./safedomainsetting-edit.component.css']
})
export class SafedomainsettingEditComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  domainForm !: FormGroup;
  project !: Project;
  @Input() safedomainilist: SafeDomainList;
  displayedColumns: string[] = ['DomainName', 'Action'];
  safedomaindataSource = new MatTableDataSource<SafeDomainList>(Safedomainlists);
  @Output() safedomainadded: EventEmitter<SafeDomainList[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  @ViewChild('sortsafedomain') sortsafedomain !: MatSort;
  @ViewChild('paginatorsafedomain') paginatorsafedomain !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortsafedomain = ms;
    this.safedomaindataSource.paginator = this.paginatorsafedomain;
    this.safedomaindataSource.sort = this.sortsafedomain;
  }
  constructor(private communicationService: CommunicationService, private logger: NGXLogger,
    private dialog: DialogService) {
    this.safedomainilist = new SafeDomainList();
    this.project = new Project();
  }

  ngOnInit(): void {
    try {
      this.domainForm = new FormGroup({
        DomainName: new FormControl('', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
        IsDeleted: new FormControl(false),
      });
      this.communicationService.is_project$.subscribe(emivalue => {
        this.project.SafeDomainLists = emivalue.SafeDomainLists.filter(x => !x.IsDeleted);
        this.safedomaindataSource.data = emivalue.SafeDomainLists.filter(x => !x.IsDeleted);
      });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  add() {
    try {
      let uniqueCheck = this.project.SafeDomainLists.filter(x => !x.IsDeleted && (x.DomainName.toLowerCase() == this.safedomainilist.DomainName.toLowerCase()));
      if (uniqueCheck.length <= 0) {
        this.project.SafeDomainLists.push({ Id: 0, DomainName: this.safedomainilist.DomainName, ProjectId: this._projectID, CreatedBy: this._userRoleMappingId, IsDeleted: false })
        this.safedomaindataSource.data = this.project.SafeDomainLists.filter(x => !x.IsDeleted);
        this.safedomainadded.emit(this.project.SafeDomainLists.filter(x => !x.IsDeleted));
        this.domainForm.reset();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Domain Name already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(Name: string) {
    try {
      let SafeDomainModel = this.project.SafeDomainLists.filter(a => a.DomainName == Name);
      SafeDomainModel[0].IsDeleted = true;
      SafeDomainModel[0].ModifiedBy = this._userRoleMappingId;
      this.safedomaindataSource.data = this.project.SafeDomainLists.filter(x => !x.IsDeleted);
      this.safedomainadded.emit(this.project.SafeDomainLists);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(Name: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this safe domain?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Name);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
