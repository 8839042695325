import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { ProposedResponseHistory } from '../app_data/proposedResponseHistory';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ProposeResponseHistoryService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'ProposedResponseHistories';

  constructor(private http: HttpClient) {
  }

  getProposedResponseHistories(): Observable<ProposedResponseHistory[]> {
    return this.http.get<response>(this.apiUrl + "?$filter= IsDeleted eq false&$expand=CreatedUserRoleMapping($expand=User,Contractor,Role)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getProposedResponseHistoryById(Id: number): Observable<ProposedResponseHistory[]> {
    return this.http.get<response>(this.apiUrl + "?$filter= Id eq " + Id +" and IsDeleted eq false&$expand=CreatedUserRoleMapping($expand=User,Contractor,Role)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getProposedResponseHistoriesByInterfaceId(interfaceId: number): Observable<ProposedResponseHistory[]> {
    return this.http.get<response>(this.apiUrl + "?$filter= InterfaceId eq " + interfaceId + " and IsDeleted eq false&$expand=CreatedUserRoleMapping($expand=User,Contractor,Role)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: ProposedResponseHistory): Observable<ProposedResponseHistory> {
    return this.http.post<ProposedResponseHistory>(this.apiUrl, model, httpOptions);
  }
  put(model: ProposedResponseHistory): Observable<ProposedResponseHistory> {
    return this.http.put<ProposedResponseHistory>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
