import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmailSubscription } from '../app_data/emailSubscription';
import { response } from '../app_data/OdataResponse';

@Injectable({
  providedIn: 'root'
})
export class EmailSubscriptionUserService {
  private apiUrl = environment.apiURl + 'EmailSubscriptionUsers';
  constructor(private http: HttpClient) { }

  getEmailSubscribedContacts(projectId: number): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor&$filter= Contractor/ProjectId eq " + projectId + " and Contractor/IsDeleted eq false and IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getEmailSubscriptionsByInterfaceGroupId(moduleId: number, interfaceGroupId: number): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ModuleId eq " + moduleId + " and InterfaceGroupId eq " + interfaceGroupId + " and IsDeleted eq false &$expand=Contact($filter=IsDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
