import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { File } from '../app_data/file';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class FilesService {
  private apiUrl = environment.storageAPIURL;
  statusList = [{ Id: 1, Name: "Upload inProgress" }, { Id: 2, Name: "Upload completed" }]
  constructor(private http: HttpClient) { }

  UploadFiles(model: File): Observable<File> {
    return this.http.post<File>(this.apiUrl + "UploadFile", model, httpOptions);

  }

  DownloadFile(model: File): Observable<Blob> {
    return this.http.post(this.apiUrl + "DownloadFile", model, { responseType: 'blob' });  
  }

  DeleteFile(model: File): Observable<Blob> {
    return this.http.post(this.apiUrl + "DeleteFile", model, { responseType: 'blob' });
  }

  getDocumentStatus() {
    return this.statusList;
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
