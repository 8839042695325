<div class="container-fluid">
  <mat-card class="">
    <!--<mat-card-title>
      <p class="pageheader">{{pageTitle}}</p>
    </mat-card-title>-->
    <mat-card-content>
      <form [formGroup]="Projectform" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab labelcontractor label="Project">
            <div class="h500tabMin">
              <div class="row">
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-2 col-xs-2">
                  <mat-form-field appearance="standard">
                    <mat-label>Code</mat-label>
                    <input autocomplete="off" matInput maxlength="6" inputmode="tel" placeholder="Project code" [(ngModel)]="project.Code" disabled="true" formControlName="Code" #codeInput>
                    <mat-error *ngIf="(Projectform.get('Code'))?.errors?.required">
                      Project Code is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-10 col-xs-10">
                  <mat-form-field appearance="standard">
                    <mat-label>Name</mat-label>
                    <input matInput autocomplete="off" maxlength="50" inputmode="tel" placeholder="Project name" [(ngModel)]="project.Name" formControlName="Name" #nameInput>
                    <mat-error *ngIf="(Projectform.get('Name'))?.errors?.required">
                      Project Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Support Phone</mat-label>
                    <input matInput autocomplete="off" maxlength="15" inputmode="numeric" placeholder="Support Phone" [(ngModel)]="project.SupportPhone" formControlName="SupportPhone" #supportphoneInput>
                    <mat-error *ngIf="(Projectform.get('SupportPhone'))?.errors?.required">
                      Support Phone is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Support Email</mat-label>
                    <input matInput autocomplete="off" type="email" maxlength="50" inputmode="tel" formControlName="SupportMail" [(ngModel)]="project.SupportMail" placeholder="Support Email" #supportmailInput>
                    <mat-error *ngIf="(Projectform.get('SupportMail'))?.errors?.required">
                      Support Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="(Projectform.get('SupportMail'))?.errors?.pattern">Please enter valid Support Email</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Mail Sender Address</mat-label>
                    <input matInput autocomplete="off" maxlength="50" inputmode="numeric" placeholder="Mail Sender Address" [(ngModel)]="project.MailSenderAddress" formControlName="MailSenderAddress" #mailsenderaddressInput>
                    <mat-error *ngIf="(Projectform.get('MailSenderAddress'))?.errors?.required">
                      Mail Sender Address is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="(Projectform.get('MailSenderAddress'))?.errors?.pattern">Please enter valid Mail Sender Address</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Status</mat-label>
                    <mat-select [(value)]="selectedProjStatus" formControlName="Status" #statusInput [disabled]="true">
                      <mat-option *ngFor="let ig of projectStatuslists" [value]="ig.Id">{{ ig.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.Projectform.get('Status')?.errors?.required">
                      Status is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 mt-2">
                  <app-upload [isControlDisable]="false" [maxFileSize]="maxFileSize" [isFileUploadRequired]="false" [fileTypeAllow]="'image'" [labelCaption]="labelCaption" [parentFormControlName]="parentControlName" [parentForm]="Projectform" [editFileName]="fileNameAttr" (fileSourceContentwithtype)="setfileSourceContentwithtype($event)" (fileSourceContent)="setfileSourceContent($event)" (fileNameAttr)="setfileName($event)"></app-upload>
                </div>
                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 mt-2">
                  <img [src]="imageURL || imageSrc" *ngIf="imageURL || imageSrc" class="imagePro">
                  <mat-icon *ngIf="imageURL || imageSrc" (click)="removeLogo()" matTooltip="Remove project logo." class="vertical-middle">cancel</mat-icon>
                </div>
              </div>
              <form [formGroup]="settingForm">
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field appearance="standard">
                      <mat-label>Time Zone</mat-label>
                      <mat-select [(value)]="selectedTimeZone" formControlName="TimeZoneName" (selectionChange)="ontimezoneChangevalue($event)" [(ngModel)]="setting.UTCOffset" #timezoneInput [disabled]="true">
                        <mat-option *ngFor="let ig of timezones" [value]="ig.Id">{{ ig.DisplayName }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="this.settingForm.get('TimeZoneName')?.errors?.required">
                        Time Zone is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    <label class="timelabelView">{{currentDayTime | date : 'EEEE'}} {{currentDayTime | date : this._dateTimeFormat}} {{currentDayTime | date : 'h:mm:ss a'}}  {{currentDayTime | date :'zzzz' : this.utcoffsetvalue }} </label>                   
                  </div>
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                    <mat-form-field appearance="standard">
                      <mat-label>Date/Time Format</mat-label>
                      <mat-select [(value)]="selecteddateformat" formControlName="DateTimeFormat" (selectionChange)="ontimeformatChangevalue($event)" [(ngModel)]="setting.CultureInfo" #datetimeformatInput [disabled]="true">
                        <mat-option *ngFor="let ig of dateformats" [value]="ig.CultureInfo">{{ ig.Format }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="this.settingForm.get('DateTimeFormat')?.errors?.required">
                        Date/Time Format is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                    <label class="headerLabel">Theme</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 theme-container">
                    <div>
                      <button class="themeoptions" (click)="setTheme('Default')">
                        <div class="theme-img">
                          <span class="checkmark {{ isSelected == 'Default' ? 'checkmark-default' : ''}}">
                            <div class="checkmark_circle"></div>
                            <div class="checkmark_stem"></div>
                            <div class="checkmark_kick"></div>
                          </span>
                          <div class="theme-header light"></div>
                          <div class="theme-dflex">
                            <div class="theme-sidebar light"></div>
                            <div class="theme-main">
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                            </div>
                          </div>
                        </div>
                        <div class="theme-name">
                          Default
                        </div>
                      </button>
                    </div>
                    <div>
                      <button class="themeoptions" (click)="setTheme('Blue')">
                        <div class="theme-img">
                          <span class="checkmark {{ isSelected == 'Blue' ? 'checkmark-blue' : ''}}">
                            <div class="checkmark_circle"></div>
                            <div class="checkmark_stem"></div>
                            <div class="checkmark_kick"></div>
                          </span>
                          <div class="theme-header default"></div>
                          <div class="theme-dflex">
                            <div class="theme-sidebar default"></div>
                            <div class="theme-main">
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                            </div>
                          </div>
                        </div>
                        <div class="theme-name">
                          Blue
                        </div>
                      </button>
                    </div>
                    <div class="hidecolumn">
                      <button class="themeoptions" (click)="setTheme('Dark')">
                        <div class="theme-img">
                          <span class="checkmark {{ isSelected == 'Dark' ? 'checkmark-dark' : ''}}">
                            <div class="checkmark_circle"></div>
                            <div class="checkmark_stem"></div>
                            <div class="checkmark_kick"></div>
                          </span>
                          <div class="theme-header dark"></div>
                          <div class="theme-dflex">
                            <div class="theme-sidebar dark"></div>
                            <div class="theme-main">
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                              <div class="theme-submain1"></div>
                            </div>
                          </div>
                        </div>
                        <div class="theme-name">
                          Dark mode
                        </div>
                      </button>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
          <mat-tab labelsettings label="General">
            <div class="h500tabMin">
              <app-projectsettings-edit (settingadded)="settingsaddedHandler($event)" (moduleProjectMappingEmit)="moduleProjectMappingFetch($event)"></app-projectsettings-edit>
            </div>
          </mat-tab>
          <mat-tab labelsettings label="Technical Query" *ngIf="!this.hideTQTab">
            <div class="h500tabMin">
              <app-projectsettingstq-edit (settingadded)="settingsaddedHandler($event)"></app-projectsettingstq-edit>
            </div>
          </mat-tab>
          <mat-tab labeldefsettings label="Caption">
            <div class="h500tabMin">
              <app-definationsettings-edit (definationsettingemit)="settingsaddedHandler($event)"></app-definationsettings-edit>
            </div>
          </mat-tab>
          <mat-tab labeldefsettings label="Safe Domain">
            <div class="h500tabMin">
              <app-safedomainsetting-edit (safedomainadded)="safedomainaddedHandler($event)"></app-safedomainsetting-edit>
            </div>
          </mat-tab>
          <mat-tab labeldefsettings label="Tags">
            <div class="h500tabMin">
              <app-project-fields-configuration-edit (projectConfigadded)="projectConfigaddedHandler($event)"></app-project-fields-configuration-edit>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button (click)="save()" class="WIRbutton WIRbutton-primary" type="submit">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
            <button mat-stroked-button (click)="redirectToBackPage()" type="button" class="WIRbutton WIRbutton-secondary">
              <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
