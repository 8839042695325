<form [formGroup]="projectFieldsConfigForm">
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Tag Name</mat-label>
        <input matInput maxlength="50" autocomplete="off" inputmode="tel" placeholder="Tag Name" [(ngModel)]="this.projectFieldsConfiguration.FieldKey" formControlName="tagNameFormControl" #TagNameInput required>
        <mat-error *ngIf="this.projectFieldsConfigForm.get('tagNameFormControl')?.hasError('required')">
          Tag Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Module</mat-label>
        <mat-select name="Module" formControlName="moduleFormControl" [(ngModel)]="this.projectFieldsConfiguration.Module" [(value)]="selectedModule" required>
          <mat-option value="Interface">Interface</mat-option>
          <mat-option value="TQ">TQ</mat-option>
          <mat-option value="TQInternal">TQ-Internal</mat-option>
        </mat-select>
        <mat-error *ngIf="this.projectFieldsConfigForm.get('moduleFormControl')?.hasError('required')">
          Module is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="projectFieldsConfigForm.invalid" (click)="add()" class="WIRbutton WIRbutton-primary">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorprojectfieldconfig [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="projectfieldsconfigdataSource" class="mat-elevation-z8" matSort #sortprojectfieldconfig="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button>
              <mat-icon matTooltip="delete" (click)="confirmCancelDialog(disc.FieldKey)" class="mat-icon-small-size">
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="FieldKey">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Tag Name </th>
          <td mat-cell *matCellDef="let disc"> {{disc.FieldKey}} </td>
        </ng-container>
        <ng-container matColumnDef="Module">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Module Name </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Module}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
