import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MinutesofmeetingCreateComponent } from './minutesofmeeting-create.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { AuthorisationModule } from 'src/app/shared/directive/authorisation/authorisation.module';
import { MinutesofMeetingRoutingModule } from './minutesofmeeting-routing.module';
import { CommonpipesModule } from 'src/app/shared/pipes/commonpipes/commonpipes.module';
import { DocumentModule } from 'src/app/shared/components/documents/document.module';
import { ActionlistModule } from '../actionlist/actionlist.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MinutesofmeetingEditComponent } from './minutesofmeeting-edit.component';
import { MinutesofmeetingListComponent } from './minutesofmeeting-list.component';
import { ActiveRouteModule } from 'src/app/shared/directive/active-route/active-route.module';
import { CommonDirectivesModule } from 'src/app/shared/directive/common/commondirective.module';
import { ActionItemModule } from '../../shared/components/action-item/action-item.module';
import { MinutesofmeetingViewComponent } from './minutesofmeeting-view.component';
import { MinutesofmeetingViewInterfaceComponent } from './minutesofmeeting-view-interface.component';
import { PdfGenerateModule } from 'src/app/shared/components/pdf-generate/pdf-generate.module';


@NgModule({
  declarations: [MinutesofmeetingCreateComponent, MinutesofmeetingEditComponent, MinutesofmeetingListComponent, MinutesofmeetingViewComponent, MinutesofmeetingViewInterfaceComponent],
  imports: [
    CommonModule,
    DocumentModule,
    ActionlistModule,
    ActionItemModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    CommonpipesModule,
    AuthorisationModule,
    PdfGenerateModule,
    CommonDirectivesModule,
    BsDatepickerModule.forRoot(),
    MinutesofMeetingRoutingModule,
    ActiveRouteModule
  ],
  exports: [MinutesofmeetingViewInterfaceComponent]
})
export class MinutesofmeetingModule { }
