<div class="container-fluid">
    <mat-card class="mom-body-content">
        <mat-card-content>
            <p class="pPageHeading">{{pageTitle}}</p>
            <form [formGroup]="form" class="interfaceTab">
                <mat-tab-group animationDuration="0ms">
                    <mat-tab label="Minutes of Meeting">
                      <div class="h500tabMin scroller">
                        <div class="row">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Status</mat-label>
                              <input matInput #input autocomplete="off" [disabled]="true" [value]="this.selectedMomStatusName"
                                     maxlength="256" placeholder="Status " name="Status">
                            </mat-form-field>
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Interface Group</mat-label>
                              <mat-select [(value)]="selectedInterfaceGroup" [disabled]="true" (selectionChange)="generateMOM()"
                                          formControlName="interfaceGroupFormControl" [(ngModel)]="momModel.InterfaceGroupID" required>
                                <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">
                                  {{
 ig.Name
                                  }}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="this.form.get('interfaceGroupFormControl')?.hasError('required')">
                                Interface Group is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>

                          <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Module Name</mat-label>
                              <mat-select [(value)]="selectedModuleId" formControlName="moduleNameFormControl" [disabled]="true" [(ngModel)]="momModel.ModuleId" required>
                                <mat-option *ngFor="let module of moduleProjectMappings" [value]="module.Module.Id">{{ module.Module.Name }}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="this.form.get('moduleNameFormControl')?.hasError('required')">
                                Module Name is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Title</mat-label>
                              <input matInput #input autocomplete="off" formControlName="titleFormControl"
                                     maxlength="256" placeholder="Title " name="Title" [(ngModel)]="momModel.Title" required appTrimWhitespace>
                              <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                                Title is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>{{this.contractor1Name}} Attendee Name</mat-label>
                              <input matInput #input autocomplete="off" formControlName="contractor1AttendeeFormControl" [(ngModel)]="this.momExternalAttendees.Contractor1AttendeeNames"
                                     maxlength="1000" placeholder="{{this.contractor1Name}} Attendee Name" name="contractor1Attendee" appTrimWhitespace>
                              <mat-hint align="end">Names should be comma seperated.</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field class="example-chip-list" appearance="standard">
                              <mat-label>{{this.contractor2Name}}  Attendee Name</mat-label>
                              <input matInput #input autocomplete="off" formControlName="contractor2AttendeeFormControl" [(ngModel)]="this.momExternalAttendees.Contractor2AttendeeNames"
                                     maxlength="1000" placeholder="{{this.contractor2Name}} Attendee Name" name="contractor2Attendee" appTrimWhitespace>
                              <mat-hint align="end">Names should be comma seperated.</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field class="example-chip-list" appearance="standard">
                              <mat-label>Attendees from {{this.contractor1Name}}</mat-label>
                              <mat-chip-list #requestingUserList
                                             aria-label="Attendees from Contractor 1"
                                             formControlName="requestingUsersFormControl" name="requestingUsers">
                                <mat-chip *ngFor="let user of selectedRequestingContractorUsers"
                                          (removed)="removeRequestingContractorUsers(user)">
                                  {{user.FirstName}} {{user.LastName}}
                                  <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input placeholder="New Attendees..." #RequestingUsersInput onkeypress="return false"
                                       [matAutocomplete]="auto" [matChipInputFor]="requestingUserList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes">

                              </mat-chip-list>
                              <mat-autocomplete #auto="matAutocomplete"
                                                (optionSelected)="selectedRequestingContractor($event)">
                                <mat-option [value]="user.Id" *ngFor="let user of allRequestingUsers">
                                  {{user.FirstName}} {{user.LastName}}
                                </mat-option>
                              </mat-autocomplete>
                              <mat-error *ngIf="this.form.get('requestingUsersFormControl')?.hasError('required')">
                                Attendees from {{this.contractor1Name}} Contractor is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field class="example-chip-list" appearance="standard">
                              <mat-label>Attendees from {{this.contractor2Name}}</mat-label>
                              <mat-chip-list #responsibleUserList
                                             formControlName="responsibleUsersFormControl"
                                             aria-label="Attendees from Contractor 2">
                                <mat-chip *ngFor="let user of selectedResponsibleContractorUsers"
                                          (removed)="removeResponsibleContractorUsers(user)">
                                  {{user.FirstName}} {{user.LastName}}
                                  <button matChipRemove>
                                    <mat-icon>cancel</mat-icon>
                                  </button>
                                </mat-chip>
                                <input placeholder="New Attendees..." #ProvidingUsersInput onkeypress="return false"
                                       [matAutocomplete]="auto1" [matChipInputFor]="responsibleUserList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                              </mat-chip-list>
                              <mat-autocomplete #auto1="matAutocomplete"
                                                (optionSelected)="selectedResponsibleContractor($event)">
                                <mat-option [value]="user.Id"
                                            *ngFor="let user of allResponsibleUsers">
                                  {{user.FirstName}} {{user.LastName}}
                                </mat-option>
                              </mat-autocomplete>
                              <mat-error *ngIf="this.form.get('responsibleUsersFormControl')?.hasError('required')">
                                Attendees from {{this.contractor2Name}} Contractor is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>External Attendees(if any)</mat-label>
                              <input matInput #input autocomplete="off" formControlName="externalAttendeesFormControl" [(ngModel)]="this.momExternalAttendees.ExternalAttendees"
                                     placeholder="External Attendees(if any)" name="ExternalAttendees">
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Location</mat-label>
                              <input matInput #input autocomplete="off" maxlength="256"
                                     placeholder="Location " name="Location" [(ngModel)]="momModel.MeetingLocation"
                                     formControlName="locationFormControl" required appTrimWhitespace>
                              <mat-error *ngIf="this.form.get('locationFormControl')?.hasError('required')">
                                Location is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Meeting Date</mat-label>
                              <input matInput [matDatepicker]="meetingDatePicker" autocomplete="off" placeholder="Meeting Date"
                                     [(ngModel)]="momModel.MeetingDate" formControlName="meetingDateFormControl" required disabled />
                              <mat-datepicker-toggle matSuffix [for]="meetingDatePicker"></mat-datepicker-toggle>
                              <mat-datepicker #meetingDatePicker disabled="false"></mat-datepicker>
                              <mat-error *ngIf="this.form.get('meetingDateFormControl')?.hasError('required')">
                                Meeting Date is <strong>required</strong>
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Next Meeting Location</mat-label>
                              <input matInput #input autocomplete="off" maxlength="256"
                                     placeholder="Next Meeting Location" [(ngModel)]="momModel.NextMeetingLocation"
                                     formControlName="nextMeetinglocationFormControl" appTrimWhitespace>
                            </mat-form-field>
                          </div>
                          <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Next Meeting Date</mat-label>
                              <input matInput [matDatepicker]="nextMeetingDatePicker" autocomplete="off" placeholder="Next Meeting Date"
                                     [(ngModel)]="momModel.NextMeetingDate" formControlName="nextMeetingDateFormControl"
                                     readonly disabled />
                              <mat-datepicker-toggle matSuffix [for]="nextMeetingDatePicker"></mat-datepicker-toggle>
                              <mat-datepicker #nextMeetingDatePicker disabled="false"></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                            <mat-form-field appearance="standard">
                              <mat-label>Description</mat-label>
                              <textarea matInput maxlength="3000" [(ngModel)]="momModel.MeetingDescription" formControlName="meetingDescriptionFormControl"
                                        class="heightTextArea" appTrimWhitespace></textarea>
                              <mat-hint align="end">3000</mat-hint>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-tab>

                    <mat-tab [label]="this.tabModuleTypeName">
                        <div class="h500tabMin">
                            <div class="row">
                                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                                    <mat-form-field appearance="standard">
                                        <mat-label>{{this.tabModuleTypeName}} List</mat-label>
                                        <mat-select [(value)]="this.selectedInterface"
                                            formControlName="interfacesFormControl" multiple #InterfacesList
                                            required>
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelected"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection()">Select All</mat-checkbox>
                                            </div>
                                            <mat-option *ngFor="let interface of interfaceListData"
                                                (click)="optionClick(interface.Id)" [value]="interface.Id">
                                                {{ interface.Number}} - {{interface.Title}}</mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="this.form.get('interfacesFormControl')?.hasError('required')">
                                            {{this.tabModuleTypeName}} is <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                                    <button mat-stroked-button type="button" [disabled]="this.form.invalid || this.isControlDisable"
                                        (click)="addInterfacesToMoM()" class="WIRbutton WIRbutton-primary">
                                        <mat-icon class="WIRbutton-icons">add</mat-icon>Add {{this.tabModuleTypeName}} to MOM
                                    </button>
                                </div>
                            </div>
                            <div>
                              <div class="scroller">
                                <mat-accordion>
                                  <mat-expansion-panel #panel hideToggle
                                                       *ngFor="let momdetailsdata of this.momModel.MinutesOfMeetingDetails;let index = index;trackBy:trackByIndex;"
                                                       [expanded]="i==0" class="margin-bottom-5 listPanel">
                                    <mat-expansion-panel-header #panelHeader
                                                                class="card-border {{panel.expanded ? 'listPanel-large' : 'listPanel'}}">
                                      <mat-panel-title class=" align-items-center card-div col-md-10 ">
                                        <div class="col-md-3">
                                          <!-- width-120-->
                                          <span [ngClass]="commonFunction.getCardColorOnStatus(momdetailsdata.Interfaces.Status, momdetailsdata.Interfaces?.IsApprovedIssuePlannedOverdue,momdetailsdata.Interfaces?.IsFirstIssuePlannedOverdue,momdetailsdata.Interfaces?.Response)"
                                                class="status-dot"></span>
                                          <span class="padding-15">{{commonFunction.getStatusText(momdetailsdata.Interfaces.Status)}}</span>
                                        </div>
                                        <div>
                                          <div class="display-flex font-color-darkgrey">
                                            <div class="fw-bolder">#{{momdetailsdata.Interfaces.Number}} </div>
                                          </div>
                                          <div>
                                            {{
panel.expanded ? momdetailsdata.Interfaces.Title : momdetailsdata.Interfaces.Title |
                                                            shorten:125
                                            }}
                                          </div>
                                        </div>
                                      </mat-panel-title>
                                      <div class="hide col-md-2 align-self-center {{panel.expanded ? 'display-flex' : ''}}">
                                        <div>
                                          <button class="button-icon-circle align-items-center "
                                                  [hasPermission]="'mom.view'"
                                                  mat-mini-fab color="accent">
                                            <mat-icon class="icon-size-18" matTooltip="View" (click)="onView(momdetailsdata.Interfaces.Id)">visibility</mat-icon>
                                          </button>
                                        </div>
                                        <div *ngIf="!isDeleteDisable">
                                          <button class="button-icon-circle align-items-center"
                                                  mat-mini-fab color="accent" (click)="confirmCancelDialog(momdetailsdata.InterfaceID)"
                                                  [hasPermission]="'mom.delete'">
                                            <mat-icon class="icon-size-18" matTooltip="Delete">delete</mat-icon>
                                          </button>
                                        </div>
                                      </div>
                                    </mat-expansion-panel-header>
                                    <div class="padding-top-bottom-2 card-div ">
                                      <div class=" g-2 display-flex">
                                        <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                          <div class="col-md-1">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              Description:
                                            </mat-label>
                                          </div>
                                          <div class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                                            {{momdetailsdata.Interfaces.Description}}
                                          </div>
                                        </div>
                                      </div>
                                      <div class=" g-2 display-flex">
                                        <div class="row col-md-12 margin-top-bottom-10">
                                          <div class="col-md-4 display-flex">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              {{expectedResponseDateLabel}}:
                                            </mat-label>
                                            <mat-label class="padding-left-20">
                                              {{
momdetailsdata.Interfaces.FirstIssuePlanned |
                                                                date:dateTimeFormat
                                              }}
                                            </mat-label>
                                          </div>
                                          <div class="col-md-4 display-flex">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              {{closeOutDateLabel}}:
                                            </mat-label>
                                            <mat-label class="padding-left-20">
                                              {{
momdetailsdata.Interfaces.ApprovedIssuePlanned
                                                                | date:dateTimeFormat
                                              }}
                                            </mat-label>
                                          </div>
                                          <div class="col-md-4 display-flex">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              Created Date:
                                            </mat-label>
                                            <mat-label class="padding-left-20">
                                              {{
momdetailsdata.Interfaces.CreatedDate |
                                                                date:dateTimeFormat : utcoffsetvalue
                                              }}
                                            </mat-label>
                                          </div>
                                        </div>
                                      </div>

                                      <div class=" g-2 display-flex"
                                           *ngIf="momdetailsdata.Interfaces.FirstIssueActual != null || momdetailsdata.Interfaces.ApprovedIssueActual != null">
                                        <div class="row col-md-12 margin-top-bottom-10">
                                          <div class="col-md-4 display-flex"
                                               *ngIf="momdetailsdata.Interfaces.FirstIssueActual != null">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              {{responseDateActualLabel}}:
                                            </mat-label>
                                            <mat-label class="padding-left-20">
                                              {{
momdetailsdata.Interfaces.Status ==
                                                                this.awaitingForResponseApprovalStatusId? '' :
                                                                momdetailsdata.Interfaces.FirstIssueActual |
                                                                date:dateTimeFormat : utcoffsetvalue}}
                                            </mat-label>
                                          </div>
                                          <div class="col-md-4 display-flex"
                                               *ngIf="momdetailsdata.Interfaces.ApprovedIssueActual != null && momdetailsdata.Interfaces.Status != this.awaitingForCloseOutStatusId">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              {{closeOutDateActualLabel}}:
                                            </mat-label>
                                            <mat-label class="padding-left-20">
                                              {{
momdetailsdata.Interfaces.ApprovedIssueActual
                                                                | date:dateTimeFormat : utcoffsetvalue
                                              }}
                                            </mat-label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class=" g-2 display-flex">
                                        <div class="row col-md-12 margin-top-bottom-10 display-flex">
                                          <div class="col-md-1">
                                            <mat-label class="font-color-darkgrey fw-bolder">
                                              Meeting Minutes:
                                            </mat-label>
                                          </div>
                                          <div class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                                            <mat-form-field appearance="fill">
                                              <textarea matInput [disabled]="this.isControlDisable"
                                                        [(ngModel)]="momdetailsdata.Text" [ngModelOptions]="{standalone: true}"
                                                        class="heightTextArea"></textarea>
                                            </mat-form-field>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </mat-expansion-panel>
                                </mat-accordion>
                              </div>
                              </div>
                        </div>                       
                    </mat-tab>

                    <mat-tab label="Document  {{documentCount != ''? '(' + documentCount +')' : ''}}">
                        <div class="h500tabMin mom-bodytab">
                            <app-document-edit [maxFileSize]="maxFileSize" [isControlDisable]="this.isControlDisable" (documentCountadded)="documentCountaddedHandler($event)" (documentsadded)="documentsaddedHandler($event)" (uploadDocsadded)="uploadDocsaddedHandler($event)"></app-document-edit>
                        </div>
                    </mat-tab>

                    <mat-tab label="Action List ({{actionItemCount}})">
                        <div class="h500tabMin mom-bodytab">
                          <app-action-item-edit [actionId]="_actionItemID" [isControlDisable]="this.isControlDisable" [interfaceGroupID]="interfaceGroupID" (actionItemCountadded)="actionItemCountaddedhandler($event)" (actionitemadded)="actionitemaddedhandler($event)"></app-action-item-edit>
                        </div>
                    </mat-tab>
                </mat-tab-group>

                <div class="mt-2 mb-2" align="right">
                    <button mat-stroked-button class="WIRbutton WIRbutton-primary"
                        [disabled]="this.form.invalid || this.isControlDisable" (click)='save()'>
                        <mat-icon class="WIRbutton-icons">save</mat-icon>Save
                    </button>
                    <button mat-stroked-button class="WIRbutton WIRbutton-primary" [hasPermission]="'mom.sign'" [disabled]="this.isDisableSignMom || this.form.invalid" (click)='signMom()'>
                        <mat-icon class="WIRbutton-icons mat-icon-font material-symbols-outlined">edit_square</mat-icon>Sign MoM
                    </button>
                    <button mat-stroked-button class="WIRbutton WIRbutton-primary" [hasPermission]="'mom.unsign'" [disabled]="this.isDisableUnsignMom || this.form.invalid" (click)='unsignMom()'>
                        <mat-icon class="WIRbutton-icons mat-icon-font material-symbols-outlined">edit_square</mat-icon>Unsign MoM
                    </button>
                    <button mat-stroked-button type="button" [appConfirmBackButton]="[this.oldmomModel,this.momModel]" class="WIRbutton WIRbutton-secondary">
                        <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
