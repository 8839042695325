<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <div class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab label="Role">
            <div class="h500tabMin">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field appearance="standard">
                      <mat-label>Name</mat-label>
                      <input autocomplete="off" matInput maxlength="50" inputmode="tel" placeholder="Role name" [(ngModel)]="role.Name" formControlName="roleNameFormControl" #nameInput appTrimWhitespace required>
                      <mat-error *ngIf="(form.get('roleNameFormControl'))?.errors?.required">
                        Role Name is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="IsCompany" [(ngModel)]="role.IsCompany" formControlName="isCompanyFormControl" value="yes">
                      <mat-label>Is Company</mat-label>
                      <app-tooltip [tooltiptext]="isCompanyText"></app-tooltip>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button type="button" (click)="save()" [disabled]="form.invalid" class="WIRbutton WIRbutton-primary">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
