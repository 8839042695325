import { Contractor } from "./contractor";
import { Interface } from "./interface";
import { Project } from "./project";
import { Role } from "./role";
import { User } from "./user";

export class SystemLog {
  Id?: number;
  UserId!: number;
  Module !:string;
  SystemFunction !: string;
  Description !: string;
  CreateDate?: Date;
  InterfaceId?: number;
  ProjectId!: number;
  ContractorId?: number;
  RoleId?: number;
  GeneratedUser?: User;
  LogInterfaceId?: Interface;
  LogProjectId?: Project;
  Contractor?: Contractor;
  Role?: Role;
}
