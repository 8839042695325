import { UserRoleProject } from "./userroleproject";

export class SafeDomainList {
  Id ?:number;
  DomainName!: string;
  ProjectId !: number;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  IsDeleted!: boolean;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: any;
}
