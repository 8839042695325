import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CommonFunction } from '../app_data/commonFunction';
import { response } from '../app_data/OdataResponse';
import { User } from '../app_data/user';
const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'users';

  constructor(private http: HttpClient) {    
  }

  getUsers(): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUserByUserName(userName: string) {
    return this.http.get<response>(this.apiUrl + "?$filter=UserName eq '" + userName +"' and IsDeleted eq false &$expand=UserRoleProjects($filter=isdeleted eq false;$expand=Role,Contractor)", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.alertServiceError)
      );
  }

  getContactsByContractor(id: number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$expand=UserRoleProjects($filter=ContractorId eq " + id + "  and isDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUsersByContractorAndRole(contractorid: number, roleId: number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$expand=UserRoleProjects($filter=ContractorId eq " + contractorid + "  and RoleId eq "+roleId+" and isDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUser(id: number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + "&$expand=UserRoleProjects($expand=Role,Contractor; $filter=IsDefault eq true)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUserAndRoles(id: number, projectid:number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + " &$expand=UserRoleProjects($expand=Role,Contractor;$filter=IsDeleted eq false and ProjectId eq " + projectid +" and Contractor/IsDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  convertUTCDatesToTimezonedate(data: User[]) {
    // convert all dates to timezone datetime        
    data.forEach(x => {
     x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;
    })

    return data;
  }

  getUserAndRolesFromAllProject(id: number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id + " &$expand=UserRoleProjects($expand=Role,Contractor;$filter=IsDeleted eq false and Contractor/IsDeleted eq false)")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUsersByProjectId(projectId: number): Observable<User[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=UserRoleProjects($expand=Contractor($expand=ContactContractorMappings),Role;$filter=ProjectId eq "+ projectId +" and IsDeleted eq false;$orderby=ContractorId)&$filter=IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: User): Observable<User> {
    return this.http.post<User>(this.apiUrl, model, httpOptions);
  }

  put(model: User): Observable<User> {
    return this.http.put<User>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }  
    return throwError(errorMessage);
  }

  private alertServiceError(err: HttpErrorResponse) {
    let errorMessage = 'WIR service is down. Please try again!';    
    alert(errorMessage)  
    return throwError(err.message)
  }
}
