import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';


export function CompareDates(fromDateString: string, toDateString: string) {
  const pipe = new DatePipe('en-US');
  return (formgroup: FormGroup) => {
    const fromDateControl = formgroup.controls[fromDateString];
    const toDateControl = formgroup.controls[toDateString];

    if (fromDateControl.value === null || toDateControl.value === null) {
      return null;
    }

    if (fromDateControl.value === "" || toDateControl.value === "") {
      return null;
    }

    if (!fromDateControl || !toDateControl) {
      return null;
    }
    if (toDateControl.errors && !toDateControl.errors['dateMismatch']) {
      return null;
    }

    var fromDate = new Date(fromDateControl.value);
    fromDate.setHours(0, 0, 0, 0);

    var toDate = new Date(toDateControl.value);
    toDate.setHours(0, 0, 0, 0);

    if (fromDate <= toDate) {
      toDateControl.setErrors(null)
      fromDateControl.setErrors(null)
    }
    else if (fromDateControl.value != undefined && toDateControl.value != undefined)
    {
      if (fromDateControl.value != null ? pipe.transform(new Date(fromDateControl.value), 'yyyy-MM-dd')! : "" > toDateControl.value != null ? pipe.transform(new Date(toDateControl.value), 'yyyy-MM-dd')! : "")
      {
        fromDateControl.setErrors({ dateMismatch: true })
      }
      else {
        fromDateControl.setErrors(null)
        toDateControl.setErrors(null)
      }
    }
    else {
      fromDateControl.setErrors(null)
      toDateControl.setErrors(null)
    }
    return null;
  }
}
