import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardsComponent } from './cards.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';


@NgModule({
  declarations: [CardsComponent],
  imports: [
    CommonModule,
    MaterialModule, FormsModule, ReactiveFormsModule, NgxSpinnerModule
  ],
  exports: [CardsComponent],
})
export class CardsModule { }
