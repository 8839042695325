<div class="container-fluid" style="background:white">
  <br/>
  <div class="row fw-bolder" align="center">
    <div>
      <label class="labelmsg">Access Denied!</label>
    </div>
  </div>
  <br />
  <div class="row" align="center">
    <label>You do not have permission to access this page.<br />Please contact your administrator to get access.</label>
  </div>
  <br />
  <div class="row" align="center">
    <div>
      <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
        <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Go Back
      </button>
    </div>
  </div>
  <br />
</div>
