import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[hasModule]'
})
export class HasModuleDirective implements OnInit {
  @Input() hasModule = 0;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.hasModule > 0) {
      var activeModuleIds = sessionStorage.getItem("activeModules")!;
      if (activeModuleIds != null) {
        var index = activeModuleIds.indexOf(String(this.hasModule));
        if (index > -1) {
          this.el.nativeElement.style.visibility = 'visible';
        }
        else {
          this.el.nativeElement.style.display = 'none';
        }
      }
    }
  }
}
