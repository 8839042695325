<form [formGroup]="domainForm">
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Domain Name</mat-label>
        <input matInput maxlength="50" autocomplete="off" inputmode="tel" placeholder="Domain Name" [(ngModel)]="safedomainilist.DomainName" formControlName="DomainName" #DomainNameInput>
        <mat-error *ngIf="this.domainForm.get('DomainName')?.hasError('required')">
          Domain Name is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="this.domainForm.get('DomainName')?.hasError('pattern')">
          Please provide <strong>valid Domain Name</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="domainForm.invalid" (click)="add()" class="WIRbutton WIRbutton-primary">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorsafedomain [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="safedomaindataSource" class="mat-elevation-z8" matSort #sortsafedomain="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button>
              <mat-icon matTooltip="delete" class="mat-icon-small-size" (click)="confirmCancelDialog(disc.DomainName)">
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="DomainName">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Domain Name </th>
          <td mat-cell *matCellDef="let disc"> {{disc.DomainName}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

