import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { ProjectFieldsConfiguration } from '../app_data/projectFieldsConfiguration';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}


@Injectable({
  providedIn: 'root'
})


export class ProjectFieldsConfigService {


  private apiUrl = environment.apiURl + 'ProjectFieldsConfiguration';

  constructor(private http: HttpClient) { }

  errorMessage = '';

  getProjectFieldsConfiguration(interfcaeId: number, projectId: number, interfaceType: string): Observable<ProjectFieldsConfiguration[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceProjectFieldsMappings($filter=InterfaceID eq " + interfcaeId + ")&$filter=ProjectId eq " + projectId + " and Module eq '" + interfaceType+"' and IsDeleted eq false &$orderby=id asc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  getProjectFieldsConfigurationByProjectId(projectId: number, interfaceType: string): Observable<ProjectFieldsConfiguration[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceProjectFieldsMappings&$filter=ProjectId eq " + projectId + " and Module eq '" + interfaceType +"' and IsDeleted eq false &$orderby=id asc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
