<mat-card class="shadow-cards">
  <mat-card-content>
    <div class="d-flex flex-column" >
      <div class="d-flex flex-row shadow-cards-header icon-{{statuscard.Icon}}">
        <div class="shadow-cards-icon icon-background icon-backgroundImage-{{statuscard.Icon}}" >
          &nbsp;
        </div>
        <div class="shadow-cards-name">
          {{statuscard.Status}}
        </div>
        <div *ngIf="statuscard.Status == 'Open'" class="fw-300 text-xs"> (not Overdue)</div>
      </div>
      <div class="d-flex flex-row shadow-cards-content mb-2">
        <div  class="requestor-self">
          <div class="text-xs" style="top:50%">Requestor</div>
          <div>
            <a class="anchorLinkwithblack" (click)="onFilterData(statuscard.Status+'|Requestor')">
              <div class="mb-0 fw-400 numbers">{{statuscard.Requester}}</div>
            </a>
          </div>
        </div>
        <div *ngIf="statuscard.Status != 'Identified'" style="border-left: 1px solid black; height:1.3rem; margin-top:1.2rem;"></div>
        <div class="provider" *ngIf="statuscard.Status != 'Identified'">
          <div class="text-xs" style="top:50%">Provider</div>
          <div>
            <a class="anchorLinkwithblack" (click)="onFilterData(statuscard.Status+'|Provider')">
              <div class="mb-0 fw-400 numbers">{{statuscard.Provider}}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <ngx-spinner name="{{name}}" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
  </mat-card-content>
</mat-card>
