export class RiskExcel {
  Title: string = "";
  Description !: string;
  CreatedOn: string = "";
  CreatedBy: string = "";
  InterfaceGroup: string = "";
  Status: string = "";
  ProbabilityLevel: number = 0;
  ConsequenceLevel: number = 0;
  Category: string = "";
  Goals: string = "";
  Cause: string = "";
  Mitigation: string = "";
  Deadline: string = "";
  InterfaceNumber: string = "";
  InterfaceTitle: string = "";
  Module: string = "";
}
