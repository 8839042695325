import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MaterialModule } from '../../shared/material/material.module';
import { UploadModule } from '../../shared/components/upload/upload.module';
import { CommonModule } from '@angular/common';
import { NotificationListModule } from '../../shared/components/notification/notification-list.module';
import { DialogModule } from '../../shared/components/dialog/dialog.module';
import { DiscussionModule } from 'src/app/shared/components/discussions/discussion.module';
import { ProjectFieldsConfigurationModule } from '../projectFieldsConfiguration/project-fields-configuration.module';
import { DocumentModule } from '../../shared/components/documents/document.module';
import { ReferenceLinkModule } from '../../shared/components/reference-links/reference-link.module';
import { RiskCreateComponent } from './risk-create.component';
import { RiskRoutingModule } from './risk-routing.module';
import { RiskListComponent } from './risk-list.component';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { CommonpipesModule } from '../../shared/pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { RiskViewComponent } from './risk-view.component';
import { RiskEditComponent } from './risk-edit.component';
import { RiskMatrixModule } from 'src/app/shared/components/risk-matrix/risk-matrix.module';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    AuthorisationModule,
    DocumentModule,
    ReferenceLinkModule,
    UploadModule,
    DialogModule,
    DiscussionModule,
    ProjectFieldsConfigurationModule,
    ActiveRouteModule,
    NotificationListModule,
    RiskMatrixModule,
    CommonpipesModule,
    CommonDirectivesModule,
    BsDatepickerModule.forRoot(),
    RiskRoutingModule
  ],
  declarations: [RiskListComponent,
    RiskCreateComponent, RiskViewComponent, RiskEditComponent
  ],
  exports: []
})

export class RiskModule { }
