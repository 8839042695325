import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { DefinitionSetting } from '../../app_data/definitionSetting';
import * as myGlobals from '../../app_data/globals';
import { ProjectSetting } from '../../app_data/projectSetting';
import { DialogService } from '../../services/dialog.service';


@Component({
  selector: 'app-definationsettings-create',
  templateUrl: './definationsettings-create.component.html',
  styleUrls: ['./definationsettings-create.component.css']
})
export class DefinationsettingsCreateComponent implements OnInit {
  definationsettingForm !: FormGroup;
  definationsetting !: DefinitionSetting;
  _settings: ProjectSetting[] = [];
  @Output() definationsettingemit: EventEmitter<ProjectSetting[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private logger: NGXLogger, private dialog: DialogService) {
    this.definationsetting = new DefinitionSetting();
  }

  ngOnInit(): void {
    this.definationsettingForm = new FormGroup({
      ProviderContractorName: new FormControl(''),
      RequestingContractorName: new FormControl(''),
      ProviderDisciplineName: new FormControl(''),
      RequestingDisciplineName: new FormControl(''),
      ExpectedResponseDateName: new FormControl(''),
      CloseOutDateName: new FormControl(''),
      ResponseDateActualName: new FormControl(''),
      CloseOutDateActualName: new FormControl('')
    });
    this.setDefaultValues();
  }

  setDefaultValues() {
    try {
      this.definationsettingForm.setValue({
        ProviderContractorName: myGlobals.ProviderContractorLabel,
        RequestingContractorName: myGlobals.RequestingContractorLabel,
        ProviderDisciplineName: myGlobals.ProviderDisciplineLabel,
        RequestingDisciplineName: myGlobals.RequestingDisciplineLabel,
        ExpectedResponseDateName: myGlobals.ExpectedResponseDateLabel,
        CloseOutDateName: myGlobals.CloseOutDateLabel,
        ResponseDateActualName: myGlobals.ResponseDateActualLabel,
        CloseOutDateActualName: myGlobals.CloseOutDateActualLabel
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onTextChange(controlName: string, event: any) {
    try {
      if (event.target.value == "") {
        if (controlName == "ProviderContractorName") {
          event.target.value = myGlobals.ProviderContractorLabel;
        }
        if (controlName == "RequestingContractorName") {
          event.target.value = myGlobals.RequestingContractorLabel;
        }
        if (controlName == "ProviderDisciplineName") {
          event.target.value = myGlobals.ProviderDisciplineLabel;
        }
        if (controlName == "RequestingDisciplineName") {
          event.target.value = myGlobals.RequestingDisciplineLabel;
        }
        if (controlName == "ExpectedResponseDateName") {
          event.target.value = myGlobals.ExpectedResponseDateLabel;
        }
        if (controlName == "CloseOutDateName") {
          event.target.value = myGlobals.CloseOutDateLabel;
        }
        if (controlName == "ResponseDateActualName") {
          event.target.value = myGlobals.ResponseDateActualLabel;
        }
        if (controlName == "CloseOutDateActualName") {
          event.target.value = myGlobals.CloseOutDateActualLabel;
        }
      }
      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = event.target.value;
      }
      else {
        this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this._userRoleMappingId });
      }
      this.definationsettingemit.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetDefault() {
    try {
      this.definationsettingForm.setValue({
        ProviderContractorName: myGlobals.ProviderContractorLabel,
        RequestingContractorName: myGlobals.RequestingContractorLabel,
        ProviderDisciplineName: myGlobals.ProviderDisciplineLabel,
        RequestingDisciplineName: myGlobals.RequestingDisciplineLabel,
        ExpectedResponseDateName: myGlobals.ExpectedResponseDateLabel,
        CloseOutDateName: myGlobals.CloseOutDateLabel,
        ResponseDateActualName: myGlobals.ResponseDateActualLabel,
        CloseOutDateActualName: myGlobals.CloseOutDateActualLabel
      })
      var index = this._settings.findIndex(x => x.ConfigKey == "ProviderContractorName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ProviderContractorLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ProviderContractorName", ConfigValue: myGlobals.ProviderContractorLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "RequestingContractorName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.RequestingContractorLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "RequestingContractorName", ConfigValue: myGlobals.RequestingContractorLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ProviderDisciplineLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ProviderDisciplineName", ConfigValue: myGlobals.ProviderDisciplineLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.RequestingDisciplineLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "RequestingDisciplineName", ConfigValue: myGlobals.RequestingDisciplineLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ExpectedResponseDateLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ExpectedResponseDateName", ConfigValue: myGlobals.ExpectedResponseDateLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "CloseOutDateName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.CloseOutDateLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "CloseOutDateName", ConfigValue: myGlobals.CloseOutDateLabel, CreatedBy: this._userRoleMappingId
        });
      }


      var index = this._settings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.ResponseDateActualLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "ResponseDateActualName", ConfigValue: myGlobals.ResponseDateActualLabel, CreatedBy: this._userRoleMappingId
        });
      }
      var index = this._settings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
      if (index > -1) {
        this._settings[index].ConfigValue = myGlobals.CloseOutDateActualLabel;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: "CloseOutDateActualName", ConfigValue: myGlobals.CloseOutDateActualLabel, CreatedBy: this._userRoleMappingId
        });
      }

      this.definationsettingemit.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

}
