import { UserRoleProject } from "./userroleproject";

export class Discussion {
  Id!: number;
  Comment!: string;
  InterfaceID: number = 0;
  ContractorId?: number = 0;
  IsDeleted!: boolean;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  CreatedUserRoleMapping?: UserRoleProject;
  Contractor?: any;
}
