import { Directive, HostListener } from '@angular/core';
import { NavigationService } from '../../../services/navigation.service';

@Directive({
  selector: '[appBackButton]',
})
export class BackButtonDirective {

  constructor(private navigation: NavigationService) { }


  @HostListener('click')
  onClick(): void {
    var ele = document.getElementsByClassName("navigation")[0].querySelector("a[class='routeactive']");
    if (ele?.classList !== undefined && ele?.classList.contains("routeactive")) {
      ele?.classList.remove("routeactive");
    }
    this.navigation.back()
  }
}
