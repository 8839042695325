import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import { CommunicationService } from '../../services/communication.service';
import { Router } from "@angular/router";
import { RiskService } from "../../services/risk.service";
import * as myGlobals from '../../app_data/globals';
import { Risk } from "../../app_data/risk";
import { MatTableDataSource } from "@angular/material/table";
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { MatSort } from "@angular/material/sort";
import { Interface } from "readline";
import { PageEvent } from '@angular/material/paginator';
import { RiskExcel } from "../../app_data/riskExcel";
import { DatePipe, formatDate } from "@angular/common";
import * as XLSX from 'xlsx-js-style';
import { CommonFunction } from '../../app_data/commonFunction';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { ProjectService } from "../../services/project.service";
import { RolePermissionService } from "../../services/rolepermission.service";

let RiskList: Risk[] = [];
@Component({
  templateUrl: './risk-list.component.html',
  styleUrls: ['./risk-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RiskListComponent implements OnInit {
  @Output() isAddRiskOnInterfaceChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  isInterfaceAdded: boolean = false;
  dataSource = RiskList;
  riskdataSource = new MatTableDataSource<Risk>(RiskList);
  displayedColumns: string[] = ['Attachments', 'Title', 'Created', 'RiskStatus', 'Description', 'Cause', 'Mitigation', 'Goal', 'Category', 'ConsequenceLevel', 'ProbabilityLevel', 'Responsible', 'DeadLine', 'CreatedBy', 'Action']
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  interfaceGroupsIdString: string = "";
  utcoffsetvalue: string = "";
  dateTimeFormat: string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  pageSize: number = 10;
  pageIndex: number = 0;
  length: number = 0;
  pageSizeOptions: number[] = [10, 20, 50];
  errorMessage = '';
  interfaceAdded: Interface[] = [];
  pageEvent = new PageEvent;
  allRisks: RiskExcel[] = [];
  datePipe: DatePipe = new DatePipe('en-US');
  utcoffsetvalues: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  projectName: string = "";
  sortColumnList: any = [];
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  sortColumn = 'NewestFirst';
  filteredRisks: Risk[] = [];
  isUserProjectAdmin: boolean = false;
  interfaceGroupString: string = "";
  _rolePermissions = (sessionStorage.getItem("rolePermissions"))?.toString().split(',');
  riskDataSource = new MatTableDataSource<Risk>(RiskList);
  commonFunction!: CommonFunction;
  statusText !: string;

  @ViewChild('sortRisk') sortRisk !: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortRisk = ms;
    this.riskDataSource.sort = this.sortRisk;
    this.riskDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    try {
      if (setPageSizeOptionsInput) {
        this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ngOnInit(): void {
    if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
      this.isUserProjectAdmin = true;
    }
    this.sortColumnList = this.riskService.getSortColumnList();
    this.getProjectSettings();
    this.searchFilters('', true);
  }

  constructor(private router: Router, 
    private riskService: RiskService, 
    private communicationService: CommunicationService, 
    private projectSettingService: ProjectSettingService,
    private logger: NGXLogger, 
    private SpinnerService: NgxSpinnerService,
    private dialog: DialogService,
    private projectService: ProjectService, 
    private rolePermissionService: RolePermissionService,
    private snackBar: MatSnackBar) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
  }

  redirecttoadd() {
    this.router.navigateByUrl('/RiskCreate');
  }

  editRecord(Id: number) {
    let EditUrl = "RiskEdit/" + Id;
    this.router.navigateByUrl(EditUrl);
  }

  viewRecord(Id: number) {
    let ViewUrl = "RiskView/" + Id;
    this.router.navigateByUrl(ViewUrl);
  }

  onDocumentsPresent(Id: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isDocumentPresent = true;
      let ViewUrl = "RiskView/" + Id;
      this.router.navigateByUrl(ViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property === 'RiskStatus') {
        return item.Interfaces ? this.commonFunction.getStatusText(item.Interfaces.Status) : item.StatusName;
      } else if (property === 'Category') {
        return item.RiskCategories?.CategoryName;
      } else if (property === 'ConsequenceLevel') {
        return item.ConsequenceId;
      } else if (property === 'ProbabilityLevel') {
        return item.ProbabilityId;
      } else if (property === 'DeadLine') {
        return item.Deadline;
      }
      return item[property];
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
  
  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.projectName = projectSettings[0].Project?.Name!;
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  loadRiskRegister(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.searchFilters(this.sortColumn, false);
    return event;
  }

  exportToExcel(): void {
    try {
      this.SpinnerService.show();
      this.allRisks.length = 0;

      var advFilter = "";
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        advFilter = "&$filter=ProjectId eq " + this._projectID + " and IsDeleted eq false";
      }
      else {
        advFilter = "&$filter=ProjectId eq  " + this._projectID + " and IsDeleted eq false and (ReceivingContractorID eq " + this._contractorID + " OR ResponsibleContractorID eq " + this._contractorID + ")";
      }

      this.riskService.getAllRisks(advFilter).subscribe({
        next: risks => {
          //Bind risks for Excel          
          risks.forEach((riskRow) => {
            let riskRec = new RiskExcel();
            riskRec.Title = riskRow.Title!;
            riskRec.Description = riskRow.Description!;
            riskRec.CreatedOn = formatDate(riskRow.CreatedDate!, "medium", "en-US", this.utcoffsetvalue);
            riskRec.CreatedBy = riskRow.CreatedUserRoleMapping?.Contractor.Name + "(" +riskRow.CreatedUserRoleMapping?.User.FirstName + " " + riskRow.CreatedUserRoleMapping?.User.LastName + ")";
            riskRec.InterfaceGroup = riskRow.InterfaceGroup?.Name!;
            riskRec.Status = riskRow.Interfaces ? this.commonFunction.getStatusText(riskRow.Interfaces?.Status) : riskRow.StatusName;
            riskRec.ProbabilityLevel = riskRow.ProbabilityId!;
            riskRec.ConsequenceLevel = riskRow.ConsequenceId!;
            riskRec.Category = riskRow.RiskCategories?.CategoryName!;
            riskRec.Goals = riskRow.Goal!;
            riskRec.Cause = riskRow.Cause!;
            riskRec.Mitigation = riskRow.Mitigation!;
            riskRec.Deadline = riskRow.Deadline != null ? this.datePipe.transform(riskRow.Deadline, this.dateTimeFormat)! : "";
            riskRec.InterfaceNumber = riskRow.Interfaces?.Number!;
            riskRec.InterfaceTitle = riskRow.Interfaces?.Title!;
            riskRec.Module = riskRow.Module?.Name!;
            this.allRisks.push(riskRec);
          })
          const worksheetData: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.allRisks);
          let Heading = [['Title', 'CreatedOn', 'CreatedBy', 'Interface Group',
            'Status', 'ProbabilityLevel', 'ConsequenceLevel', 'Category', 'Goals', 'Cause',
            'Mitigation', 'Deadline', 'Interface Number','Interface Title', 'Module', 'Description'
          ]];
          
          this.commonFunction.exportToExcel(this.allRisks, Heading, this.projectName, "RisksData");
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onSortChange(sortSelection: string): void {
    try {
      this.sortColumn = sortSelection;
      this.searchFilters(sortSelection, true);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  searchFilters(sortSelection: string, isShow1stPage: boolean) {
    try {
      this.SpinnerService.show();
      if (isShow1stPage) {
        this.pageIndex = 0;
      }
      var advFilter = "";
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        advFilter = "&$filter=ProjectId eq " + this._projectID + " and IsDeleted eq false";
      }
      else {
        advFilter = "&$filter=ProjectId eq  " + this._projectID + " and IsDeleted eq false and (ReceivingContractorID eq " + this._contractorID + " OR ResponsibleContractorID eq " + this._contractorID + ")";
      }

      switch (sortSelection.toLowerCase()) {
        case 'oldestfirst':
          advFilter += "&$orderby=Id";
          break;
        case 'status':
          advFilter += "&$orderby=Status";
          break;
        default:
          advFilter += "&$orderby=Id desc";
          break;
      }

      this.fillRisks(this.pageIndex, this.pageSize, advFilter);
      this.setRiskCount(advFilter);
      this.SpinnerService.hide();

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillRisks(pageIndex: number, pageSize: number, filter: string): void {
    try {
      this.SpinnerService.show();
      this.riskService.getRisks(pageIndex, pageSize, filter).subscribe({
        next: risks => {
          this.SpinnerService.hide();
          this.filteredRisks = risks;
          this.riskDataSource.data = risks;

        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err;
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }

      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(Id: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this Risk?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Id);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(riskId: number) {
    try {
      let riskData = this.filteredRisks.filter(x => x.Id == riskId);
      if (riskData.length > 0) {
        riskData[0].IsDeleted = true;
        riskData[0].ModifiedBy = this._userIdLogin;

        this.riskService.put(riskData[0]).subscribe(
          {
            next: risks => {
              this.SpinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Risk deleted successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
              this.searchFilters('', false);
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          }
        );
      }


    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  setRiskCount(filter: string): void {
    try {
      this.SpinnerService.show();
      this.length = 0;
      this.riskService.getRiskForCount(filter).subscribe({
        next: Risk => {
          this.length = Risk.length;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
  
  hasPermission(permissionName: string) {
    return this._rolePermissions!.some(s => s.includes(permissionName));
  }

  riskHandler(selectedRiskLevel: string) {
    try {
      this.SpinnerService.show();     
      var riskLevel = selectedRiskLevel.split('|');        
      this.pageIndex = 0;
      var advFilter = "";

      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        advFilter = "&$filter=ProjectId eq " + this._projectID + " and ConsequenceId eq " + Number(riskLevel[0]) + "  and ProbabilityId eq " + Number(riskLevel[1]) + " and IsDeleted eq false";
      }
      else {
        advFilter = "&$filter=ProjectId eq  " + this._projectID + " and ConsequenceId eq " + Number(riskLevel[0]) + "  and ProbabilityId eq " + Number(riskLevel[1]) + "  and IsDeleted eq false and (ReceivingContractorID eq " + this._contractorID + " OR ResponsibleContractorID eq " + this._contractorID + ")";
      }
      
      this.fillRisks(this.pageIndex, this.pageSize, advFilter);
      this.setRiskCount(advFilter);
      this.SpinnerService.hide();

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetDefault(){
    this.searchFilters('', true);
  }
}
