import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export const msalConfig: Configuration = {
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [environment.b2cPolicies.authorityDomain],
    redirectUri: environment.redirectUri,
    postLogoutRedirectUri: environment.postLogoutRedirectUri, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false    
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (logLevel, message, containsPii) => {
        ///console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false
    }
  }
}


export const loginRequest = {
  scopes: ["openid", "profile"],
  loginHint: "mictosoftonline.com"
};
