import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';



@NgModule({
  declarations: [SliderComponent],
  imports: [
    CommonModule,BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
  ],
  exports: [SliderComponent]
})
export class SliderModule { }
