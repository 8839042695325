import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { CommunicationService } from '../../services/communication.service';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MinutesofMeetingService } from 'src/app/services/minutesofmeeting.service';
import { MinutesOfMeeting } from 'src/app/app_data/minutesOfMeeting';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { InterfaceService } from '../../services/interface.service';

let MoMlists: MinutesOfMeeting[] = [];

@Component({
  selector: 'app-minutesofmeeting-view-interface',
  templateUrl: './minutesofmeeting-view-interface.component.html',
  styleUrls: ['./minutesofmeeting-view-interface.component.css']
})
export class MinutesofmeetingViewInterfaceComponent implements OnInit {

  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  dataSource = MoMlists;
  errorMessage = '';
  displayedColumns: string[] = ['Title','Doc', 'GroupName','Status','ModuleName', 'MeetingDate', 'Action'];
  minutesOfMeetingdataSource = new MatTableDataSource<MinutesOfMeeting>(MoMlists);
  @ViewChild('sortcontractor') sortminutesOfMeeting !: MatSort;
  @ViewChild('paginatorMoM') paginatorMoM !: MatPaginator;
  interfaceGroupsIdString : string = "";
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  dateTimeFormat: string = "";
  validRecord: boolean = false;

  minutesOfMeetings : MinutesOfMeeting[]=[];

  @Input() interfaceId: number = 0;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortminutesOfMeeting = ms;
    this.minutesOfMeetingdataSource.paginator = this.paginatorMoM;
    this.minutesOfMeetingdataSource.sort = this.sortminutesOfMeeting;
  }
  constructor(private minutesofMeetingService: MinutesofMeetingService, private router: Router, private communicationService: CommunicationService
    , private projectSettingService: ProjectSettingService, private interfaceService:InterfaceService,
     private logger: NGXLogger, private SpinnerService: NgxSpinnerService, private dialog: DialogService ) { }

  ngOnInit(): void {  
    this.getProjectSettings();
    this.checkIfMoMIdValid(this.interfaceId)
  }

  checkIfMoMIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceByIdandProjectId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.interfaceId > 0 && this.validRecord) {
            this.getMeeniutsofMeetings(this.interfaceId);
          }
          else {
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }    
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getMeeniutsofMeetings(interfaceId:number) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeetingbyInterfaceId(interfaceId).subscribe(
        {
          next: allminutesOfMeetings => {         
            if(allminutesOfMeetings != null){
              allminutesOfMeetings.forEach(momdata =>{
                this.minutesOfMeetings.push({Id: momdata.MinutesOfMeetings?.Id!, Title:momdata.MinutesOfMeetings?.Title!,Status: momdata.MinutesOfMeetings?.Status!, InterfaceGroupID: momdata.MinutesOfMeetings?.InterfaceGroupID!,InterfaceGroup: momdata.MinutesOfMeetings?.InterfaceGroup!,MeetingDate:momdata.MinutesOfMeetings?.MeetingDate!,ActionItems:momdata.MinutesOfMeetings?.ActionItems!,Documents:momdata.MinutesOfMeetings?.Documents!,CreatedBy:momdata.MinutesOfMeetings?.CreatedBy!,IsDeleted:momdata.MinutesOfMeetings?.IsDeleted!, MeetingDescription:momdata.MinutesOfMeetings?.MeetingDescription!,MeetingLocation:momdata.MinutesOfMeetings?.MeetingLocation!,NextMeetingLocation:momdata.MinutesOfMeetings?.NextMeetingLocation!,NextMeetingDate:momdata.MinutesOfMeetings?.NextMeetingDate!,
                ProjectId:momdata.MinutesOfMeetings?.ProjectId!,Revision:momdata.MinutesOfMeetings?.Revision!,FilePathName:momdata.MinutesOfMeetings?.FilePathName!,IsSignedByContractor1:momdata.MinutesOfMeetings?.IsSignedByContractor1!,IsSignedByContractor2:momdata.MinutesOfMeetings?.IsSignedByContractor2!,MinutesOfMeetingDetails:[],MinutesOfMeetingAttendees:[],Module:momdata.MinutesOfMeetings?.Module!});
              })
            }

            this.minutesOfMeetingdataSource.data = this.minutesOfMeetings;  
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onDocumentsPresent(Id: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isDocumentPresent = true;
      let MomViewUrl = "MinutesofMeetingView/" + Id;
      this.router.navigateByUrl(MomViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  viewRecord(Id: number) {
    let ViewUrl = "MinutesofMeetingView/" + Id;
    this.router.navigateByUrl(ViewUrl);
  }

}
