import { MinutesOfMeeting } from "./minutesOfMeeting";
import { UserRoleProject } from "./userroleproject";

export class ActionItem {
  Id !: number;
  MOMId?: number;
  InterfaceGroupId!: number;
  Title!: string;
  Description!: string;
  ResponsibleContractorId!: number;
  Responsible!: string;
  ActionDate!: Date;
  IsClosed!: boolean;
  ClosedDate?: Date;
  ProjectId!: number;
  Status!: number;
  StatusName?: string;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ProjId?: any;
  InterfaceGroup?: any;
  ResContractor?: any;
  CreatedUserRoleMapping?: UserRoleProject;
  MOM?: MinutesOfMeeting;
}
