import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RiskMatrixComponent } from './risk-matrix.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UploadModule } from '../upload/upload.module';
import { CommonpipesModule } from '../../pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';


@NgModule({
  declarations: [RiskMatrixComponent],
  imports: [
    CommonModule,
    MaterialModule, BrowserModule, ReactiveFormsModule, FormsModule, UploadModule, CommonpipesModule, CommonDirectivesModule
  ],
  exports:[RiskMatrixComponent]
})
export class RiskMatrixModule { }
