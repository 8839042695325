<div class="container-fluid" *ngIf="isAuthenticated">
  <input type="checkbox" id="toggle" (click)="clickMe($event)" />
  <div class="topbar {{storedtheme}}">
    <div class="toggle topbar-item">
      <label for="toggle">
        <mat-icon class="hamburger">menu</mat-icon>
      </label>
    </div>
    <div class="productname topbar-item">
      <div class="d-flex flex-column">
        <span class="prdname_span">Web Interface Register</span>
        <span class="version_span">{{version}}</span>
      </div>
    </div>

    <div class="client topbar-item">
      <div class="logo" *ngIf="isAuthenticated">
        <img [src]="logoSrc" *ngIf="logoSrc" alt="">
      </div>
      <div class="d-flex flex-column" *ngIf="isAuthenticated && isRolenotassigned">
        <span class="navbar-brand bold-name">{{projectName}}</span>
        <span class="version_span">{{currentDayTime | date : 'EEEE' }} {{currentDayTime | date : this.datetimeformat }} {{currentDayTime | date : 'h:mm:ss a' }} {{currentDayTime | date :'zzzz' : this.utcoffsetvalue }} </span>
      </div>
    </div>
    <div class="user topbar-item" *ngIf="isAuthenticated">
      <button *ngIf="this.isRolenotassigned" mat-button [matMenuTriggerFor]="beforeMenu" class="userprofile">
        <div class="avatar_menuheader">
          <div class="avatar_menu_tenantcontainer">
            <div id="usermenu_currentAccount_primary" class="avatar_usermenu_name usermenu_truncate">{{userName}}</div>
            <div id="usermenu_currentAccount_secondary" class="avatar_usermenu_tenant usermenu_truncate">{{role}}</div>
          </div>
          <div class="">
            <div class="usermenu_smallcirclename">{{initialUserName | uppercase}}</div>
          </div>
        </div>
      </button>
      <a *ngIf="!this.isRolenotassigned" class="usermenu-signOut" (click)="logout()" role="button">Sign Out</a>
      <mat-menu #beforeMenu="matMenu" xPosition="before" class="usermenu usermenuposition">
        <div class="usermenu-body">
          <div class="usermenu_project">
            <div class="logo" *ngIf="isAuthenticated">
              <img [src]="logoSrc" *ngIf="logoSrc" alt="">
            </div>
            <div *ngIf="isAuthenticated" class="usermenu_truncate" [matTooltip]="projectName">
              <span class="bold-name">{{projectName}}</span>
            </div>

          </div>
          <hr class="base_hz_line" />
          <div class="usermenu_mainmenu">
            <button *ngIf="this.isRolenotassigned" mat-menu-item [routerLink]="['/UserProfile']">
              <mat-icon class="usermenu_menu_icon">badge</mat-icon>
              <span class="usermenu_menu_name">My Profile</span>
            </button>
            <button mat-menu-item *ngIf="this.isSwitchRoleLinkVisible" [routerLink]="['/SwitchRole']">
              <mat-icon class="usermenu_menu_icon">switch_account</mat-icon>
              <span class="usermenu_menu_name">Switch Role</span>
            </button>
            <button mat-menu-item [routerLink]="['/Subscriptions']" *ngIf="this._userRoleId == this.projectAdminRole" [hasPermission]="'subscriptions.new'">
              <mat-icon class="usermenu_menu_icon">notifications</mat-icon>
              <span class="usermenu_menu_name">Notification Settings</span>
            </button>
            <button mat-menu-item [routerLink]="['/ProjectInvitation']">
              <mat-icon class="usermenu_menu_icon">post_add</mat-icon>
              <span class="usermenu_menu_name">Add Project/Role</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="help">
              <mat-icon class="usermenu_menu_icon">help_center</mat-icon>
              <span class="usermenu_menu_name">Help</span>
            </button>
            <hr class="base_hz_line" />
            <button mat-menu-item (click)="logout()">
              <mat-icon class="usermenu_menu_icon">exit_to_app</mat-icon>
              <span class="usermenu_menu_name">Sign Out</span>
            </button>
            <br />
          </div>

          <mat-menu #help="matMenu" xPosition="before" class="usersubmenu usermenuposition">
            <div class="usermenu_sub_menu">
              <button mat-menu-item (click)="openUserGuide()">
                <mat-icon class="usermenu_menu_icon">menu_book</mat-icon>
                <span class="usermenu_menu_name">Get started</span>
              </button>
              <!--<hr class="base_hz_line" />-->
              <button mat-menu-item>
                <mat-icon class="usermenu_menu_icon">video_library</mat-icon>
                <span class="usermenu_menu_name">Training videos</span>
              </button>
              <button mat-menu-item>
                <mat-icon class="usermenu_menu_icon">on_device_training</mat-icon>
                <span class="usermenu_menu_name">Request training</span>
              </button>
            </div>
          </mat-menu>

        </div>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="isAuthenticated && isRolenotassigned" class="navigation {{storedtheme}}">
    <div class="menu-side">
      <ul>
        <li *ngFor="let menu of menus" [matTooltip]="this.isToolTipDisable ? menu.Name : ''" matTooltipPosition="after" [hasModule]="[menu.Module?.ModuleProjectMappings.length > 0 ? menu.Module?.Id : 0]" [hasPermission]="[menu.PermissionName]" style="display: flex; align-items: center; justify-content: center;">
          <a [routerLink]="[menu.RouterLink]" appSetRouteActive [hasModule]="[menu.Module?.ModuleProjectMappings.length > 0 ? menu.Module?.Id : 0]" [hasPermission]="[menu.PermissionName]" class="{{this.currentUrl == '' ? '' : menu.ChildMenu.includes(this.currentUrl) ? 'routeactive':''}}" routerLinkActive="active">
            <span class="icon">
              <mat-icon class="mat-icon-font material-symbols-outlined">{{menu.Icon}}</mat-icon>
            </span>
            <span class="title" >{{menu.Name}}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="footer">
      <div class="footer-logo"><img class="" alt="Kongsberg logo" src="assets/images/companylogo.png" /></div>
      <div class="footer-copyrt">&#169; Kongsberg Maritime India Pvt Ltd. 2024</div>
    </div>
  </div>
  <!--<pm-body></pm-body>-->
  <!--<div class="main">
    <router-outlet *ngIf="isAuthenticated"></router-outlet>
  </div>-->
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="line-scale" [fullScreen]="true"></ngx-spinner>
