import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentCreateComponent } from './document-create.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UploadModule } from '../upload/upload.module';
import { DocumentEditComponent } from './document-edit.component';
import { CommonpipesModule } from '../../pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';
import { DocumentViewComponent } from './document-view.component';



@NgModule({
  declarations: [DocumentCreateComponent, DocumentEditComponent, DocumentViewComponent],
  imports: [
    CommonModule,
    MaterialModule, BrowserModule, ReactiveFormsModule, FormsModule, UploadModule, CommonpipesModule, CommonDirectivesModule
  ],
  exports: [DocumentCreateComponent, DocumentEditComponent, DocumentViewComponent]
})
export class DocumentModule { }
