import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';
import { UnauthorizedAccessComponent } from './unauthorized-access.component';
import { UnauthorizedAccessRoutingModule } from './unauthorized-access-routing.module';
import { InterfaceModule } from '../../../modules/interface/interface.module';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';



@NgModule({
  declarations: [UnauthorizedAccessComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule, ReactiveFormsModule, MaterialModule, CommonDirectivesModule,
    UnauthorizedAccessRoutingModule
  ],
  exports: [UnauthorizedAccessComponent]
})
export class UnauthorizedAccessModule { }
