<form [formGroup]="parentForm">
  <mat-form-field appearance="standard" class="ptZero">
    <!-- Document attachment choose file -->
    <mat-label>{{this.labelCaption}}</mat-label>
    <mat-toolbar class="toolbarFontSize">
      <input matInput [(ngModel)]="fileAttr" [formControl]="control" [formControlName]="parentFormControlName"  name="name" [required]="this.isFileUploadRequired">
      <button *ngIf="fileAttr" matSuffix mat-icon-button aria-label="Clear" class="w5" (click)="resetUpload()">
        <mat-icon class="smallIconSize">close</mat-icon>
      </button>
    </mat-toolbar>
    <input type="file" #fileInput1 id="uploadFile"  (click)="fileInputClick($event)" (change)="uploadFileEvt($event)" name="UploadFile" />
    <mat-error *ngIf="this.parentForm.get(parentFormControlName)?.hasError('required')">
      {{this.labelCaption}} is <strong>required</strong>
    </mat-error>
    <mat-error *ngIf="this.parentForm.get(parentFormControlName)?.hasError('fileSizeExceed')">
      File size must be less than {{this.maxFileSize}} MB.
    </mat-error>
    <mat-error *ngIf="this.parentForm.get(parentFormControlName)?.hasError('fileExtentionNotAllowed')">
      Only {{this.allowedExtension}} files are allowed to upload.
    </mat-error>
    <mat-error *ngIf="this.parentForm.get(parentFormControlName)?.hasError('fileAllowed')">
      only allow to upload {{this.fileTypeAllow}} file
    </mat-error>
  </mat-form-field>
</form>
