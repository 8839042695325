<div class="h500tabMin">
  <form [formGroup]="docLinkForm">
    <div class="row pb-2">
      <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
        <mat-form-field appearance="standard">
          <mat-label>Title</mat-label>
          <input matInput name="Title" autocomplete="off" formControlName="docLinkTitle" [(ngModel)]="referenceLink.Title"
                 maxlength="100" placeholder="Title" required appTrimWhitespace>
          <button *ngIf="referenceLink.Title" matSuffix mat-icon-button aria-label="Clear" (click)="referenceLink.Title=''">
            <mat-icon class="smallIconSize">close</mat-icon>
          </button>
          <mat-error *ngIf="this.docLinkForm.get('docLinkTitle')?.hasError('required')">
            Title is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
        <mat-form-field appearance="standard">
          <mat-label>Link</mat-label>
          <input matInput name="Link" autocomplete="off" formControlName="docLink" [(ngModel)]="referenceLink.Link" maxlength="256"
                 placeholder="Link" required appTrimWhitespace>
          <button *ngIf="referenceLink.Link" matSuffix mat-icon-button aria-label="Clear" (click)="referenceLink.Link=''">
            <mat-icon class="smallIconSize">close</mat-icon>
          </button>
          <mat-error *ngIf="this.docLinkForm.get('docLink')?.hasError('required')">
            Link is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="this.docLinkForm.get('docLink')?.hasError('pattern')">
            Only supports https and http link
          </mat-error>
          <mat-error *ngIf="this.docLinkForm.get('docLink')?.hasError('safedomainval')">
            Domains only from Safe Domain list are allowed!
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-xl-2 col-l-2 col-md-12 col-sm-12 col-xs-12 mt-2">
        <button mat-stroked-button #btnAddLinks [disabled]="docLinkForm.invalid" (click)="addLink()"
                type="button" class="WIRbutton WIRbutton-primary">
          <mat-icon class="WIRbutton-icons">add</mat-icon>Add
        </button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-lg-12">
      <mat-paginator #paginatorLinks [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons class="">
      </mat-paginator>
      <div class="table-responsive scroller">
        <table mat-table #linksTable [dataSource]="dataSourceLinks" class="mat-elevation-z8" matSort #sortLinks="matSort">
          <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef class="table_header_size">
              Action
            </th>
            <td mat-cell *matCellDef="let element2">
              <button mat-icon-button>
                <mat-icon class="mat-icon-small-size" (click)="confirmCancelDialog(element2.Title,'link')" matTooltip="delete">delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
              Title
            </th>
            <td mat-cell *matCellDef="let element2">
              <a href="{{element2.Link}}" class="anchorLink"
                 target="_blank">{{element2.Title}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="SubmittedByUserID">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by SubmittedByUserID">
              Added by
            </th>
            <td mat-cell *matCellDef="let element2"> {{ this._userName +' - ('+ this.userContractorName+')'}} </td>
          </ng-container>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
              <span><br />{{noRecordFoundMsg}}</span>
            </td>
          </tr>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>
