<div class="container-fluid">
    <mat-card class="interfaceTab">     
      <mat-card-content>
          <mat-tab-group animationDuration="0ms" id="tab-group">           
            <mat-tab label="Email">
              <div class="h500tabMin">
                <app-email-subscription [isContractorRequired]="true" (subscriptionadded)="subscriptionaddedHandler($event)"></app-email-subscription>
              </div>
            </mat-tab>
          </mat-tab-group>
  
          <div class="row mt-2 mb-2">
            <div align="right">
              <button mat-stroked-button (click)="save()" [disabled]="!this.saveEnable" class="WIRbutton WIRbutton-primary">
                <mat-icon class="WIRbutton-icons">save</mat-icon>Save
              </button>
              <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
                <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
              </button>
            </div>
          </div>
      </mat-card-content>
    </mat-card>
  </div>
