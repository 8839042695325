
<div class="container-fluid">
  <mat-card class="mom-body-content">
    <mat-card-content>
      <p class="pPageHeading">{{pageTitle}}</p>
      <form [formGroup]="form" class="interfaceTab">
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="Risk">
            <div class="h500tabMin scroller">
              <div class="row rowBottomPadding">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Title</label><br />
                  <label class="labelView">{{this.title}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding labelView">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Description</label><br />
                  <label class="labelView">{{ this.discription}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding labelView">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Cause/Consequence</label><br />
                  <label class="labelView">{{this.Cause}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding">
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <label class="headerLabel">Probability Level</label><br />
                  <label class="labelView">{{this.probabilityLevel}}</label>
                </div>
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <label class="headerLabel">Consequence Level</label><br />
                  <label class="labelView">{{this.consequenceLevel}}</label>
                </div>
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <label class="headerLabel">Category</label><br />
                  <label class="labelView">{{riskCategory}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding labelView">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Goals</label><br />
                  <label class="labelView">{{this.Goal}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding labelView">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Responsible</label><br />
                  <label class="labelView">{{this.Responsible}}</label>
                </div>
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Deadline</label><br />
                  <label class="labelView">{{this.Deadline  | date:dateTimeFormat}}</label>
                </div>
              </div>
              <div class="row rowBottomPadding labelView">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Mitigation</label><br />
                  <label class="labelView">{{this.Mitigation}}</label>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="this.isInterfacePresent" [label]="tabModuleTypeName">
            <div class="h500tabMin">
              <div class="row rowBottomPadding">
                <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                  <label class="headerLabel">Interface Group</label><br />
                  <label class="labelView">
                    {{this.interafaceGroup}}
                  </label>
                </div>
                <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <label class="headerLabel">Module</label><br />
                  <label class="labelView">{{this.tabModuleTypeName}}</label>
                </div>
              </div>
              <div class="row">
                <mat-accordion>
                  <mat-expansion-panel #panel hideToggle [expanded]="true" class="margin-bottom-5 listPanel">
                    <mat-expansion-panel-header #panelHeader
                                                class="card-border">
                      <mat-panel-title class="align-items-center card-div col-md-10">

                        <div class="col-md-3">
                          <span [ngClass]="commonFunction.getCardColorOnStatus(riskModel.Interfaces.Status, riskModel.Interfaces.IsApprovedIssuePlannedOverdue,riskModel.Interfaces.IsFirstIssuePlannedOverdue,riskModel.Interfaces.Response)" class="status-dot"></span>
                          <span class="padding-15">{{ commonFunction.getStatusText(riskModel.Interfaces.Status) }}</span>
                        </div>
                        <div>
                          <div class="display-flex font-color-darkgrey">
                            <div class="fw-bolder">#{{ riskModel.Interfaces.Number }} </div>
                          </div>
                          <div>
                            {{ riskModel.Interfaces.Title }}
                          </div>
                        </div>
                      </mat-panel-title>
                      <div class="hide col-md-2 align-self-center {{ panel.expanded ? 'display-flex' : '' }}">
                        <div>
                          <button class="button-icon-circle align-items-center" [hasPermission]="'risk.view'" mat-mini-fab color="accent" (click)="onView(riskModel.Interfaces.Id)">
                            <mat-icon class="mat-icon-small-size margin-left-right-5" matTooltip="View">visibility
                             
                            </mat-icon>
                          </button>
                        </div>
                      </div>
                    </mat-expansion-panel-header>
                    <div class="padding-top-bottom-2 card-div">
                      <div class="g-2 display-flex">
                        <div class="row col-md-12 margin-top-bottom-10 display-flex">
                          <div class="col-md-1">
                            <mat-label class="font-color-darkgrey fw-bolder">Description:</mat-label>
                          </div>
                          <div class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                            {{ riskModel.Interfaces.Description }}
                          </div>
                        </div>
                      </div>
                      <div class="g-2 display-flex">
                        <div class="row col-md-12 margin-top-bottom-10">
                          <div class="col-md-4 display-flex">
                            <mat-label class="font-color-darkgrey fw-bolder">{{ expectedResponseDateLabel }}:</mat-label>
                            <mat-label class="padding-left-20">
                              {{ riskModel.Interfaces.FirstIssuePlanned | date:dateTimeFormat : utcoffsetvalue }}
                            </mat-label>
                          </div>
                          <div class="col-md-4 display-flex">
                            <mat-label class="font-color-darkgrey fw-bolder">{{ closeOutDateLabel }}:</mat-label>
                            <mat-label class="padding-left-20">
                              {{ riskModel.Interfaces.ApprovedIssuePlanned | date:dateTimeFormat : utcoffsetvalue }}
                            </mat-label>
                          </div>
                          <div class="col-md-4 display-flex">
                            <mat-label class="font-color-darkgrey fw-bolder">Created Date:</mat-label>
                            <mat-label class="padding-left-20">
                              {{ riskModel.Interfaces.CreatedDate | date:dateTimeFormat : utcoffsetvalue }}
                            </mat-label>
                          </div>
                        </div>
                      </div>
                      <div class="g-2 display-flex" *ngIf="riskModel.Interfaces.FirstIssueActual || riskModel.Interfaces.ApprovedIssueActual">
                        <div class="row col-md-12 margin-top-bottom-10">
                          <div class="col-md-4 display-flex" *ngIf="riskdetailsdata?.FirstIssueActual">
                            <mat-label class="font-color-darkgrey fw-bolder">{{ responseDateActualLabel }}:</mat-label>
                            <mat-label class="padding-left-20">
                              {{ (riskModel.Interfaces.Status === awaitingForResponseApprovalStatusId ? '' : riskModel.Interfaces.FirstIssueActual | date:dateTimeFormat : utcoffsetvalue) }}
                            </mat-label>
                          </div>
                          <div class="col-md-4 display-flex" *ngIf="riskdetailsdata?.ApprovedIssueActual && riskdetailsdata?.Status !== awaitingForCloseOutStatusId">
                            <mat-label class="font-color-darkgrey fw-bolder">{{ closeOutDateActualLabel }}:</mat-label>
                            <mat-label class="padding-left-20">
                              {{ riskModel.Interfaces.ApprovedIssueActual | date:dateTimeFormat : utcoffsetvalue }}
                            </mat-label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </div>
          </mat-tab>

          <mat-tab [label]="documentCount">
            <div class="h500tabMin overflow-hidden">
              <app-document-view></app-document-view>
            </div>
          </mat-tab>
        </mat-tab-group>
        <div class="mt-2 mb-2" align="right">
          <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
