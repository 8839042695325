import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolePermissionConfigurationComponent } from './role-permission-configuration.component';
import { RolePermissionConfigurationRoutingModule } from './role-permission-configuration-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    RolePermissionConfigurationComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RolePermissionConfigurationRoutingModule
  ]
})
export class RolePermissionConfigurationModule { }
