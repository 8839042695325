import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TechnicalQueryListComponent } from './technicalquery-list.component';
import { TechnicalQueryEditComponent } from './technicalquery-edit.component';
import { TechnicalQueryViewComponent } from './technicalquery-view.component';
import { TechnicalQueryLogComponent } from './technicalquery-log.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MaterialModule } from '../../shared/material/material.module';
import { UploadModule } from '../../shared/components/upload/upload.module';
import { CommonModule } from '@angular/common';
import { NotificationListModule } from '../../shared/components/notification/notification-list.module';
import { DialogModule } from '../../shared/components/dialog/dialog.module';
import { DiscussionModule } from 'src/app/shared/components/discussions/discussion.module';
import { ProjectFieldsConfigurationModule } from '../projectFieldsConfiguration/project-fields-configuration.module';
import { DocumentModule } from '../../shared/components/documents/document.module';
import { ReferenceLinkModule } from '../../shared/components/reference-links/reference-link.module';
import { TechnicalQueryCreateComponent } from './technicalquery-create.component';
import { TechnicalQueryRoutingModule } from './technicalquery-routing.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { CommonpipesModule } from '../../shared/pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { MinutesofmeetingModule } from '../minutesofmeeting/minutesofmeeting.module';
import { PdfGenerateModule } from '../../shared/components/pdf-generate/pdf-generate.module';
import { AssignmentModule } from '../../shared/components/assignment/assignment.module';
import { ProposeresponseModule } from '../../shared/components/proposeresponse/proposeresponse.module';
import { AdditionalInformationModule } from '../../shared/components/additional-information/additional-information.module';
import { TechnicalQueryRevisionComponent } from './technicalquery-revision.component';



@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    AuthorisationModule,
    DocumentModule,
    ReferenceLinkModule,
    UploadModule,
    DialogModule,
    DiscussionModule,
    ProjectFieldsConfigurationModule,
    ActiveRouteModule,
    NotificationListModule,
    CommonpipesModule,
    PdfGenerateModule,
    MinutesofmeetingModule,
    AssignmentModule,
    AdditionalInformationModule,
    ProposeresponseModule,
    CommonDirectivesModule,
    BsDatepickerModule.forRoot(),
    TechnicalQueryRoutingModule
  ],
  declarations: [TechnicalQueryCreateComponent, 
    TechnicalQueryListComponent, 
    TechnicalQueryEditComponent, 
    TechnicalQueryViewComponent, 
    TechnicalQueryLogComponent,
    TechnicalQueryRevisionComponent
  ],
  exports: []
})

export class TechnicalQueryModule { }
