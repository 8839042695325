import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TechnicalQueryListComponent } from './technicalquery-list.component';
import { TechnicalQueryCreateComponent } from './technicalquery-create.component';
import { TechnicalQueryEditComponent } from './technicalquery-edit.component';
import { TechnicalQueryViewComponent } from './technicalquery-view.component';
import { TechnicalQueryLogComponent } from './technicalquery-log.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';

const technicalQueryRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'TechnicalQueries', component: TechnicalQueryListComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'technicalquery.view' }
      },
      {
        path: 'TechnicalQueryCreate', component: TechnicalQueryCreateComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'technicalquery.create' }
      },
      {
        path: 'TechnicalQueryEdit/:id', component: TechnicalQueryEditComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'technicalquery.edit' }
      },
      {
        path: 'TechnicalQueryView/:id', component: TechnicalQueryViewComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'technicalquery.view' }
      },
      {
        path: 'TechnicalQueryLog/:id', component: TechnicalQueryLogComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'technicalquery.log' }
      }

    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(technicalQueryRoutes)
  ]
})
export class TechnicalQueryRoutingModule { }
