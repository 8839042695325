import { Component, Input, OnInit , SimpleChanges} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import * as myGlobals from '../../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { Interface } from 'src/app/app_data/interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceService } from 'src/app/services/interface.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { SystemLogService } from 'src/app/services/systemLog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { Contact } from '../../../app_data/contact';
import { NotificationService } from '../../../services/notification.service';
import { CommonNotificationFunction } from '../../../app_data/commonNotificationFunction';
import { StatusService } from '../../../services/status.service';
import { ContractorService } from '../../../services/contractor.service';
import { ProjectSettingService } from '../../../services/projectsetting.service';
import { TemplateBuilderService } from '../../../services/template-builder.service';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { DatePipe, formatDate } from '@angular/common';
import { ProjectService } from '../../../services/project.service';
import { RolePermissionService } from '../../../services/rolepermission.service';

@Component({
  selector: 'app-approve-rejectdialog',
  templateUrl: './approve-rejectdialog.component.html',
  styleUrls: ['./approve-rejectdialog.component.css'],
  providers: [DatePipe]
})
export class ApproveRejectdialogComponent implements OnInit {
  acceptRejectForm !: FormGroup;
  commonFunction !: CommonFunction;
  interfaceModel!: Interface;
  interfacePreviousStatus!: string;
  @Input() interfaceId!: number;
  @Input() closeOutReminderDays!: number;
  nextStageFromWorkFlowMappings: WorkflowMapping[] = [];
  allStageFromWorkFlowMappings: WorkflowMapping[] = [];
  contractorReceipentUsers: Contact[] = [];
  approvalMessage: string = "";
  errorMessage = '';
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  acceptRejectComments: string = '';
  commonNotificationFunction!: CommonNotificationFunction;
  oldStatusValue = 0;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  dateTimeFormat: string = "";
  isEnableCloseoutReminder: boolean = false;
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];
  workflowTemplateId: number = 0;
  oldInterfaceModel!: Interface;
  @Input() data!: any;

  constructor(private dialog: DialogService,
    private logger: NGXLogger, private notificationService: NotificationService,
    private SpinnerService: NgxSpinnerService,
    private interfaceService: InterfaceService,
    private contractorService: ContractorService,
    private systemLogService: SystemLogService,
    private rolePermissionService: RolePermissionService, private projectService: ProjectService,
    private workflowMappingService: WorkflowmappingService,
    private snackBar: MatSnackBar, private statusService: StatusService,
    private router: Router, private datePipe: DatePipe,
    private projectSettingService: ProjectSettingService,
    private templateBuilderService: TemplateBuilderService, private emailTemplateService: EmailTemplateService) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
    this.getProjectSettings();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService,datePipe, dialog, logger, router, emailTemplateService, templateBuilderService, workflowMappingService);
  }

  ngOnInit(): void {
    this.acceptRejectForm = new FormGroup({
      comments: new FormControl()
    });
  }

  ngOnChanges(changes: SimpleChanges){
    if (this.interfaceId > 0 && this.data.Id > 0) {
      this.GetNextWorkflowStage();
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }


            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
              }
            }
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  GetNextWorkflowStage() {
    try {
      this.interfaceService.getInterfaceById(this.interfaceId).subscribe({
        next: interfaceData => {
          //getNext stage and check if approval is required
          this.interfaceModel = this.data// -- pass chnages in model from parent to child
          this.oldInterfaceModel=interfaceData[0]; //--- set db data for INterfacesattusname fields
          
          var module = this.interfaceModel.InterfaceType.toLowerCase() == "tq" ? myGlobals.TQModule : myGlobals.InterfaceModule;
          this.interfaceModel.FirstIssuePlannedstr = formatDate(this.interfaceModel.FirstIssuePlanned!, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.interfaceModel.ApprovedIssuePlanned!, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.oldStatusValue = this.interfaceModel.Status;

          this.interfaceGroupTemplateMappings = interfaceData[0].InterfaceGroup?.InterfaceGroupTemplateMappings!;
          var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == module);
          this.workflowTemplateId = workflowTemplate[0].WorkflowTemplateId;
          this.workflowMappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, module)
            .toPromise()
            .then(nextStageData => {
              if (nextStageData!.length > 0) {
                this.allStageFromWorkFlowMappings = nextStageData!;
                var currentStageData = this.allStageFromWorkFlowMappings.filter(x => x.StageId == interfaceData[0].StageId);
                let stagewithallroles: any[] = [];
                if (currentStageData.length > 1) {

                  var filterdata;
                  currentStageData.forEach(y => {
                    filterdata = this.allStageFromWorkFlowMappings.filter(x => x.StageId == y.NextStageId);
                    stagewithallroles.push(filterdata[0]);
                  });
                } else {
                  //increment workflow mapping sequence number to get next stage id
                  stagewithallroles = this.allStageFromWorkFlowMappings.filter(x => x.StageId == currentStageData[0].NextStageId);
                }
                this.nextStageFromWorkFlowMappings = stagewithallroles;
                this.approvalMessage = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovalMessage!;
              }
            });
          this.SpinnerService.hide();
        }
      })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  Accept() {
    try {
      this.SpinnerService.show();
      this.interfacePreviousStatus = this.oldInterfaceModel.InterfaceStatus.Name;
      
      if (this.nextStageFromWorkFlowMappings.length > 0) {
        this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
        this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;

        this.interfaceModel.ModifiedBy = this._userRoleMappingId;

        this.interfaceModel.IsStageRejected = false;
        this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
        this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();

      }
      this.interfaceModel.InterfaceResponses?.push({ Information: "", Comments: "Request approved comment: " + this.acceptRejectComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: false, ContractorId: this._contractorID });
      
      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {

          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
          //Save email notification.
          this.commonNotificationFunction.processEmailNotifications(this.interfaceModel.ModuleId!, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.interfacePreviousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);

          if (this.interfaceModel.InterfaceType.toLowerCase() == "tq") {
            if (this.interfaceModel.Status == myGlobals.ClosedStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Closed", "TQ " + this.interfaceModel.Number + " changed. Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Request approved", "TQ " + this.interfaceModel.Number + " changed. TQ was approved by '" + this._userName + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          } else {

            if (this.interfaceModel.Status == myGlobals.ClosedStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Closed", "Closeout approved for Interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Request approved", "Request approved for Interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }


          }

          this.commonFunction.redirectToRegisterpage(this.nextStageFromWorkFlowMappings[0].ModuleId!);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Request approved successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  Reject() {
    try {
      this.SpinnerService.show();
      
      this.interfacePreviousStatus = this.oldInterfaceModel.InterfaceStatus.Name;
      if (this.interfaceModel.Status == myGlobals.AwaitingForCloseOutStatusId || this.interfaceModel.Status == myGlobals.ProposetoCloseStatusId) {
        this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Request close out rejected comment: " + this.acceptRejectComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: true, ContractorId: this._contractorID });
      } else {
        this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Request rejected comment: " + this.acceptRejectComments, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: true, IsVisibleToProvider: false, ContractorId: this._contractorID });
      }
      if (this.interfaceModel.InterfaceType.toLowerCase() == "tq" && this.interfaceModel.Status == myGlobals.ProposetoCloseStatusId) {
        this.interfaceModel.AdditionalInformationHistory.push({
          AssignedTo: this.interfaceModel.CreatedUserRoleMapping?.UserId!, AssignedToRoleId: this.interfaceModel.CreatedByRoleId!, ReasonOfAssignment: this.acceptRejectComments, CreatedBy: this._userRoleMappingId,
          IsDeleted: false, InterfaceId: this.interfaceModel.Id, Id: 0
        })
      }

      if (this.nextStageFromWorkFlowMappings.length > 0) {
        this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
        this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

        this.interfaceModel.Response = "";
        this.interfaceModel.ReferenceForResponse = "";
        this.interfaceModel.ModifiedBy = this._userRoleMappingId;

        this.interfaceModel.IsStageRejected = true;
        this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
        this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();
      }

      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {
          
          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
          this.commonNotificationFunction.processEmailNotifications(this.interfaceModel.ModuleId!, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.interfacePreviousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);

          if (this.interfaceModel.InterfaceType.toLowerCase() == "tq") {
            if (this.oldStatusValue == myGlobals.AwaitingForRequestApprovalStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Request approval rejected", "TQ " + this.interfaceModel.Number + " changed. Status 'Open' was rejected and is now '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'. Reason : " + this.acceptRejectComments, this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (this.oldStatusValue == myGlobals.ProposetoCloseStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Propose to close rejected", "TQ rejected for proposed to close with comments " + this.acceptRejectComments + ".", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          }
          else {
            if (this.oldStatusValue == myGlobals.AwaitingForRequestApprovalStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Request approval rejected", "Request rejected for Interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
            else if (this.oldStatusValue == myGlobals.AwaitingForCloseOutStatusId) {
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Awaiting for closeout rejected", "Interface closeout rejected for " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
            }
          }

          this.commonFunction.redirectToRegisterpage(this.nextStageFromWorkFlowMappings[0].ModuleId!);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Request rejected successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
