import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RiskCategories } from '../app_data/RiskCategories';
import { response } from '../app_data/OdataResponse';
import { Observable, catchError, map, throwError } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class RiskCategoriesService {

  private apiUrl = environment.apiURl + 'RiskCategories';
  constructor(private http: HttpClient) { }

  getRiskCategoryById(Id: number): Observable<RiskCategories[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + Id)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getRiskCategories(): Observable<RiskCategories[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  getCategoriesByProjectId(Id: number): Observable<RiskCategories[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectId eq " + Id + " and IsDeleted eq false ")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: RiskCategories): Observable<RiskCategories> {
    return this.http.post<RiskCategories>(this.apiUrl, model, httpOptions);
  }

  put(model: RiskCategories): Observable<RiskCategories> {
    return this.http.put<RiskCategories>(this.apiUrl + "/" + model.CategoryId, model, httpOptions);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
