import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { EmailSubscription } from '../../../app_data/emailSubscription';
import { InterfaceGroupService } from '../../../services/interfaceGroup.service';
import * as myGlobals from '../../../app_data/globals';
import { InterfaceGroup } from '../../../app_data/interfaceGroup';
import { DisciplineService } from '../../../services/discipline.service';
import { Discipline } from '../../../app_data/discipline';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../../app_data/user';
import { MatSelectChange } from '@angular/material/select';
import { DialogService } from '../../../services/dialog.service';
import { CommunicationService } from '../../../services/communication.service';
import { ContractorService } from 'src/app/services/contractor.service';
import { Contractor } from 'src/app/app_data/contractor';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/app_data/contact';
import { Module } from '../../../app_data/module';
import { ModuleService } from '../../../services/module.service';
import { WorkflowmappingService } from '../../../services/workflowmapping.service';
import { InterfaceGroupTemplateMappingService } from '../../../services/interfaceGroupTemplateMapping.service';
import { Stage } from '../../../app_data/stage';


let Subscriptionlists: EmailSubscription[] = [];

@Component({
  selector: 'app-email-subscription',
  templateUrl: './email-subscription.component.html',
  styleUrls: ['./email-subscription.component.css']
})

export class EmailSubscriptionComponent implements OnInit {
  emailsubForm!: FormGroup;
  emailsubscription!: EmailSubscription;
  user!: User;
  contact!: Contact;
  errorMessage!: string;
  selectedInterfaceGroup: number = 0;
  selectedContractor: any;
  selectedContact: number = 0;
  selectedModule: number = 0;
  selectedStage: number = 0;
  selectedDiscipline: number = 0;
  interfaceGroups: InterfaceGroup[] = [];
  contractorIds: number[] = [];
  disciplines: Discipline[] = [];
  selectedValue: any;
  selectedStageName!: string;
  selectedModuleName!: string;
  selecteddisciplineValue: any;
  selectedContactFName: any;
  selectedContactLName: any;
  selectedContractorName: string = '';
  array: { id: number; text: string; }[] = [];
  contractors: Contractor[] = [];
  modules: Module[] = [];
  stages: Stage[] = [];
  contractorContacts: User[] = [];
  contractorAllContacts: Contact[] = [];
  isCompanyContractor: boolean = false;
  @Output() subscriptionadded: EventEmitter<EmailSubscription[]> = new EventEmitter();

  @Input() isContractorRequired: boolean = false;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  displayedColumns: string[] = ['Contractor', 'Contact', 'Module', 'InterfaceGroup', 'Stage', 'Discipline', 'Action'];
  subscriptiondataSource = new MatTableDataSource<EmailSubscription>(Subscriptionlists);
  @ViewChild('sortsubscription') sortsubscription !: MatSort;
  @ViewChild('paginatorsubscription') paginatorsubscription !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortsubscription = ms;
    this.subscriptiondataSource.paginator = this.paginatorsubscription;
    this.subscriptiondataSource.sort = this.sortsubscription;
    this.subscriptiondataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(private logger: NGXLogger, private interfaceGroupService: InterfaceGroupService,
    private disciplineService: DisciplineService, private dialog: DialogService,
    private communicationService: CommunicationService,
    private contractorService: ContractorService,
    private moduleService: ModuleService,
    private workflowMappingService: WorkflowmappingService,
    private subscriptionService: SubscriptionService,
    private interfaceGroupTemplateMappingService: InterfaceGroupTemplateMappingService,
    private contactService: ContactService) {
    this.emailsubscription = new EmailSubscription();
    this.user = new User();
    this.contact = new Contact();
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property == "Contractor") {
        return item.User.UserContractorMappings[0].Contractor_User?.Name;
      }
      else if (property == "Contact") {
        return item.User.FirstName;
      }
      else if (property == "InterfaceGroup") {
        return item.InterfaceGroup != null ? item.InterfaceGroup.Name : "ALL";
      }
      else if (property == "Discipline") {
        return item.Discipline != null ? item.Discipline.Name : "All";
      }
      else {
        item[property];
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ngOnInit(): void {
    this.emailsubForm = new FormGroup({
      interfaceGroupFormControl: new FormControl(),
      disciplineFormControl: new FormControl(),
      SubscriptionFormControl: new FormControl(),
      contractorFormControl: new FormControl(),
      contactFormControl: new FormControl(),
      moduleFormControl: new FormControl(),
      stageFormControl: new FormControl()
    });

    this.loadModules();
    if (this.isContractorRequired) {
      this.fillContractorDDL();
      this.getAllSubscribUsers();
      this.displayedColumns = ['Contractor', 'Contact', 'Module', 'InterfaceGroup', 'Stage', 'Discipline', 'Action'];
    } else {
      this.emailsubscription.ContractorId = this._contractorID;
      this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
      this.displayedColumns = ['Module', 'InterfaceGroup', 'Stage', 'Discipline', 'Action'];
    }

    this.communicationService.is_profile$.subscribe(emivalue => {
      this.contact.EmailSubscriptionUsers = emivalue.EmailSubscriptionUsers.filter(x => !x.IsDeleted);
      this.subscriptiondataSource.data = emivalue.EmailSubscriptionUsers.filter(x => !x.IsDeleted);
    });
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number, isCompanyContractor: boolean = false): void {
    try {
      if ((this._userRoleId == Number(myGlobals.ProjectAdminRoleId) && !this.isContractorRequired) || isCompanyContractor) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            if (interfaceGroup.length > 0) {

              this.interfaceGroups = interfaceGroup.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
              this.selectedInterfaceGroup = interfaceGroup.length > 0 ? interfaceGroup[0].Id! : 0;
              this.selectedValue = interfaceGroup.length > 0 ? interfaceGroup[0].Name : '';
              this.contractorIds.length = 0;
              this.contractorIds = [interfaceGroup[0].Contractor1ID, interfaceGroup[0].Contractor2ID]
              this.fillDisciplineDDL(this.contractorIds);
            }
          },
          error: err => {
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            if (interfaceGroup.length > 0) {

              this.interfaceGroups = interfaceGroup.sort((a, b) => a.Id! > b.Id! ? 1 : -1);
              this.selectedInterfaceGroup = interfaceGroup.length > 0 ? interfaceGroup[0].Id! : 0;
              this.selectedValue = interfaceGroup.length > 0 ? interfaceGroup[0].Name : '';
              this.contractorIds.length = 0;
              this.contractorIds = [interfaceGroup[0].Contractor1ID, interfaceGroup[0].Contractor2ID]
              this.fillDisciplineDDL(this.contractorIds);
            }

          },
          error: err => {
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillDisciplineDDL(contractorIds: number[]): void {
    try {
      this.disciplineService.getDisciplinesByContractorIds(contractorIds).subscribe({
        next: disciplines => {
          let finalDisciplines = disciplines.filter(x => x.Name = x.Name + ' - ' + x.Contractor.Name);
          finalDisciplines.push({ Id: 0, Code: "All", Name: "All", LeadContractorID: 0, IsDeleted: false, CreatedBy: this._userRoleMappingId, Description: "", CategoryID: 0 })
          this.disciplines = finalDisciplines.sort((a, b) => a.LeadContractorID! > b.LeadContractorID! ? 1 : -1);
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangevalue(e: MatSelectChange) {
    this.selectedValue = e.source.triggerValue;

    let interfacegrpModel = this.interfaceGroups.filter(x => x.Id == e.source.value);
    this.contractorIds.length = 0;
    this.contractorIds = [interfacegrpModel[0].Contractor1ID, interfacegrpModel[0].Contractor2ID]
    this.loadStages();
    this.fillDisciplineDDL(this.contractorIds);
  }

  onContactChangevalue(id: number) {
    let contactModel = this.contractorAllContacts.filter(x => x.Id == id);
    if (contactModel.length > 0) {
      this.selectedContactFName = contactModel[0].FirstName;
      this.selectedContactLName = contactModel[0].LastName;
    }
  }


  onDisciplineChangevalue(e: MatSelectChange) {
    this.selecteddisciplineValue = e.source.triggerValue;
  }

  onStageChange(e: MatSelectChange) {
    this.selectedStageName = e.source.triggerValue;
  }


  add() {
    try {
      let uniqueCheck;
      var alertText = "";
      let userIdList: number[] = [];
      let uIdString: string = '';
      let emailSubscriptionUsersList: string[] = [];
      userIdList.push(this.emailsubscription.ContactId);
      uIdString = userIdList.toString();
      emailSubscriptionUsersList = uIdString.split(',');
      emailSubscriptionUsersList.forEach(x => {
        let uid: number = 0;
        uid = Number(x);

        let filterData = this.contractorAllContacts.filter(x => x.Id == uid);
        if (filterData != null) {
         
          if (this.isContractorRequired) {
            if (this.emailsubscription.StageId == 0 && this.emailsubscription.DisciplineId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId && x.ContactId == uid && x.ModuleId == this.emailsubscription.ModuleId
                && x.StageId == null && x.DisciplineId == null));
            }
            else if (this.emailsubscription.StageId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.DisciplineId == this.emailsubscription.DisciplineId
                && x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId && x.ContactId == uid && x.ModuleId == this.emailsubscription.ModuleId && x.StageId == null));
            }
            else if (this.emailsubscription.DisciplineId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId
                && x.StageId == this.emailsubscription.StageId && x.ContactId == uid && x.ModuleId == this.emailsubscription.ModuleId && x.DisciplineId == null));
            }
            else {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId
                && x.DisciplineId == this.emailsubscription.DisciplineId && x.StageId == this.emailsubscription.StageId && x.ContactId == uid && x.ModuleId == this.emailsubscription.ModuleId));
            }
          }
          else {
            if (this.emailsubscription.StageId == 0 && this.emailsubscription.DisciplineId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId && x.ModuleId == this.emailsubscription.ModuleId
                && x.StageId == null && x.DisciplineId == null));
            }
            else if (this.emailsubscription.StageId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.DisciplineId == this.emailsubscription.DisciplineId
                && x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId && x.ModuleId == this.emailsubscription.ModuleId && x.StageId == null ));
            }
            else if (this.emailsubscription.DisciplineId == 0) {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId
                && x.StageId == this.emailsubscription.StageId && x.ModuleId == this.emailsubscription.ModuleId && x.DisciplineId == null));
            }
            else {
              uniqueCheck = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted && (x.InterfaceGroupId == this.emailsubscription.InterfaceGroupId
                && x.DisciplineId == this.emailsubscription.DisciplineId && x.StageId == this.emailsubscription.StageId && x.ModuleId == this.emailsubscription.ModuleId));
            }
          }

          if (uniqueCheck.length <= 0) {
            var emailSubcriptionObject = {
              Id: 0, InterfaceGroupId: this.emailsubscription.InterfaceGroupId, DisciplineId: this.emailsubscription.DisciplineId, ContractorId: this.emailsubscription.ContractorId, ContactId: this.isContractorRequired ? filterData[0].Id : this._userIdLogin,
              SubscriptionFlag: this.emailsubscription.SubscriptionFlag == null ? '' : this.emailsubscription.SubscriptionFlag, CreatedBy: this._userRoleMappingId, IsDeleted: false,
              InterfaceGroup: { Name: this.selectedValue, Id: 0, Contractor1ID: 0, Contractor2ID: 0, IsDeleted: false, Code: '', ProjectID: 0 },
              Discipline: { Name: this.selecteddisciplineValue, Id: 0, Code: '', Description: '', LeadContractorID: 0, CategoryID: 0, IsDeleted: false },
              Contact: { FirstName: this.isContractorRequired ? filterData[0].FirstName : '', LastName: this.isContractorRequired ? filterData[0].LastName : '', UserContractorMappings: [{ Contractor_User: { Name: this.selectedContractorName } }], Id: 0, CreatedBy: this._userRoleMappingId },
              Contractor: { Name: this.selectedContractorName },
              ModuleId: this.selectedModule,
              Module: { Id: 0, Name: this.selectedModuleName, IsDeleted: false, CreatedBy: this._userRoleMappingId },
              StageId: this.emailsubscription.StageId,
              Stage: {
                Id: 0, Name: this.selectedStageName, TriggerAction: "", Caption: "", ApprovedStatus: 0, RejectedStatus: 0, RejectedStage: 0,
                ActionSide: "", PermissionId: 0, Description: "", SequenceNumber: 0, CurrentStatus: 0, IsApproveRequired: false, CreatedBy: 0, ApprovalMessage: ""
              }
            }

            if (emailSubcriptionObject.StageId == 0)
              emailSubcriptionObject.StageId = null!;

            if (emailSubcriptionObject.DisciplineId == 0)
              emailSubcriptionObject.DisciplineId = null!;

            this.contact.EmailSubscriptionUsers.push(emailSubcriptionObject);
          }
          else {
            var msg = "Subscription already exists for contact ";
            var fName = this.isContractorRequired ? filterData[0].FirstName : "";
            var lName = this.isContractorRequired ? filterData[0].LastName : "";

            if (alertText == "") {
              alertText = msg.concat(fName.toString(), " ", lName.toString()), "/n";
            } else {
              alertText = alertText.concat(", ", fName.toString(), " ", lName.toString()), "/n";
            }

          }
        }
      })

      if (alertText != "") {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: alertText,
          cancelCaption: 'Close',
        });
      }
      
      this.subscriptiondataSource.data = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted);
      this.subscriptionadded.emit(this.contact.EmailSubscriptionUsers);

      this.emailsubForm.get("SubscriptionFormControl")?.reset();

      this.emailsubForm.reset();
      this.array = [];

      if (this.isContractorRequired) {
        this.emailsubForm.get("contactFormControl")?.reset();
        this.contractorAllContacts = [];
        this.interfaceGroups = [];
        this.disciplines = [];
      }

      alertText = "";
      this.selectedContractor = 0;
      this.selectedInterfaceGroup = 0;
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(moduleId: number,interfaceGroupId: number, disciplineId: number, stageId: number, userId: number) {
    try {
      this.dialog.confirmDialog({
        title: 'Confirm Action',
        message: 'Do you want to delete this subscription?',
        confirmCaption: 'Confirm',
        cancelCaption: 'Cancel',
      })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(moduleId, interfaceGroupId, disciplineId, stageId, userId);
          }
        });

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(moduleId:number, interfaceGroupId: number, disciplineId: number, stageId: number, contactId: number) {
    try {
      let emailSubscriptionModel = this.contact.EmailSubscriptionUsers.filter(x => (x.ModuleId == moduleId && x.InterfaceGroupId == interfaceGroupId && x.DisciplineId == disciplineId && x.StageId == stageId && x.ContactId == contactId));
      emailSubscriptionModel[0].IsDeleted = true;
      emailSubscriptionModel[0].ModifiedBy = this._userRoleMappingId;
      
      this.subscriptiondataSource.data = this.contact.EmailSubscriptionUsers.filter(x => !x.IsDeleted);
      this.subscriptionadded.emit(this.contact.EmailSubscriptionUsers);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillContractorDDL(): void {
    try {
      this.contractorService.getAllContractorsByProjectId(this._projectID).subscribe({
        next: requestingContractor => {
          this.contractors = requestingContractor;
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  loadModules(): void {
    try {
      this.moduleService.getModules().subscribe({
        next: moduleList => {
          this.modules = moduleList;
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  loadStages(): void {
    try {

      this.stages = [];
      var stageIds: number[] = [];
      this.interfaceGroupTemplateMappingService.getInterfaceGroupTemplateMappingsByInterfaceGroupAndModule(this.selectedInterfaceGroup, this.selectedModule).subscribe({
        next: interfaceGroupTemplateMappings => {

          if (interfaceGroupTemplateMappings.length > 0) {
            this.stages.push({
              Id: 0, Name: "All", TriggerAction: "", Caption: "", ApprovedStatus: 0, RejectedStatus: 0, RejectedStage: 0,
              ActionSide: "", PermissionId: 0, Description: "", SequenceNumber: 0, CurrentStatus: 0, IsApproveRequired: false, CreatedBy: 0, ApprovalMessage: ""
            })

            this.workflowMappingService.GetNextStageWorkFlowMapping(interfaceGroupTemplateMappings[0].WorkflowTemplateId, this.selectedModule)
              .toPromise()
              .then(workflowStages => {
                if (workflowStages!.length > 0) {
                  workflowStages!.forEach(workflowStage => {

                    if (stageIds.indexOf(workflowStage.StageId) < 0) {
                      stageIds.push(workflowStage.StageId);
                      this.stages.push(workflowStage.Stage!)
                    }
                  });
                }
              });
          }
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onContractorChangevalue(contractorId: number) {

    let contractorModel = this.contractors.filter(x => x.Id == contractorId);
    if (contractorModel.length > 0) {
      this.isCompanyContractor = contractorModel[0].IsCompany;
    }
    if (contractorModel != null && contractorModel.length > 0) {
      this.selectedContractorName = contractorModel[0].Name;
    }
    this.getContactsByContractor(contractorId);
    this.fillInterfaceGroupDDL(this._projectID, contractorId, this.isCompanyContractor);
  }


  onModuleChange(module: number) {
    let moduleModel = this.modules.filter(x => x.Id == module);
    if (moduleModel != null && moduleModel.length > 0) {
      this.selectedModuleName = moduleModel[0].Name;
      this.loadStages();
    }
  }

  getContactsByContractor(contractorid: number) {
    try {
      this.contactService.getContactsByContractor(contractorid).subscribe(
        {
          next: allContacts => {
            this.contractorAllContacts = allContacts.filter(a => !a.IsDeleted && a.ContactContractorMappings.length > 0);
          },
          error: err => {
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getAllSubscribUsers(): void {
    try {
      this.contractorService.getAllContractorsByProjectId(this._projectID).subscribe({
        next: requestingContractor => {
          let Contractors = '';
          requestingContractor.forEach((x) => {
            if (Contractors == '') {
              Contractors += x.Id.toString();
            } else {
              Contractors += ", " + x.Id.toString();
            }
          })
          this.subscriptionService.getSubscriptionsbyContractorId(Contractors).subscribe({
            next: subscribUsers => {
              this.subscriptiondataSource.data = subscribUsers;
              this.contact.EmailSubscriptionUsers = subscribUsers;
            },
            error: err => {
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          })
        },
        error: err => {
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
