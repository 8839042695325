import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { UserInvitation } from '../app_data/userInvitation';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})


export class UserInvitationService {
  private apiUrl = environment.apiURl + 'userinvitations';

  constructor(private http: HttpClient) {
  }

  getUserInvitations(projectId: number): Observable<UserInvitation[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor,Role&$filter=projectid eq " + projectId + " and IsDeleted eq false &$orderby=CreatedDate desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUserInvitationByCodeUserName(code: string, userName: string, todayDate: string) {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor,Role&$filter=Code eq '" + code + "' and Status eq 'Active' and IsDeleted eq false and Email eq '" + userName + "' and ValidTIll gt " + todayDate+"", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUserInvitationsByRoleId(projectId: number, userName: string, roleId: number, contractorId: number) {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectId eq " + projectId + " and RoleId eq " + roleId + " and ContractorId eq " + contractorId+" and IsDeleted eq false and Email eq '" + userName + "'", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getUserInvitationById(id: number): Observable<UserInvitation[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Id eq " + id)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: UserInvitation): Observable<UserInvitation> {
    return this.http.post<UserInvitation>(this.apiUrl, model, httpOptions);
  }

  put(model: UserInvitation): Observable<UserInvitation> {
    return this.http.put<UserInvitation>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
