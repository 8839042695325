import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InterfacegroupEditComponent } from './interfacegroup-edit.component';
import { InterfacegroupCreateComponent } from './interfacegroup-create.component';
import { MaterialModule } from '../../shared/material/material.module';
import { CustomTooltipModule } from 'src/app/shared/components/tooltip/customtooltip.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { InterfacegroupListComponent } from './interfacegroup-list.component';
import { InterfacegroupRoutingModule } from './interfacegroup-routing.module';


@NgModule({
  declarations: [InterfacegroupEditComponent,InterfacegroupCreateComponent, InterfacegroupListComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CustomTooltipModule,
    CommonDirectivesModule,
    InterfacegroupRoutingModule
  ],
  exports: [InterfacegroupEditComponent, InterfacegroupCreateComponent]
})
export class InterfacegroupModule { }
