import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scrolltotop',
  templateUrl: './scrolltotop.component.html',
  styleUrls: ['./scrolltotop.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrolltotopComponent implements OnInit {
  @Output() scrollToTop = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onScrollToTop(): void {
    this.scrollToTop.emit();
  }

}
