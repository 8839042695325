<div class="container-fluid">
    <mat-card class="">
      <mat-card-content>
        <div class="row">
          <table mat-table [dataSource]="minutesOfMeetingdataSource" class="mat-elevation-z8" matSort #sortminutesOfMeeting="matSort">
            <ng-container matColumnDef="Action">
              <th mat-header-cell *matHeaderCellDef class="table_header_size" > Action </th>
              <td mat-cell *matCellDef="let element">                      
                <mat-icon matTooltip="View" class="mat-icon-small-size margin-left-right-5" [appSetRouteActive]="'MinutesofMeetingView'" (click)="viewRecord(element.Id)">visibility</mat-icon>                                            
              </td>
            </ng-container>
            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Title </th>
              <td mat-cell *matCellDef="let element">
               {{element.Title}}             
              </td>
            </ng-container>
            <ng-container matColumnDef="Doc">
              <th  mat-header-cell *matHeaderCellDef class="table_header_size"></th>
              <td mat-cell *matCellDef="let element"><div *ngIf="element.Documents.length > 0" class="padding-left-10  padding-right-5">
                  
                <button class="button-icon-circle align-items-center docIconMargin" mat-mini-fab color="accent" [appSetRouteActive]="'MinutesofMeetingView'">
                  <mat-icon matTooltip="Documents present" (click)="onDocumentsPresent(element.Id)">
                    attachment
                  </mat-icon>
                </button>
              </div>         
              </td>
            </ng-container>
            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
            </ng-container>
            <ng-container matColumnDef="ModuleName">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Module </th>
              <td mat-cell *matCellDef="let element"> {{element.Module?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="GroupName">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Group Name </th>
              <td mat-cell *matCellDef="let element"> {{element.InterfaceGroup?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="Contractor1?.Name">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Contractor1 </th>
              <td mat-cell *matCellDef="let element"> {{element.InterfaceGroup?.Contractor1?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="Contractor2">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Contractor2 </th>
              <td mat-cell *matCellDef="let element"> {{element.InterfaceGroup?.Contractor2?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="MeetingDate">
              <th mat-header-cell *matHeaderCellDef class="table_header_size"  mat-sort-header> Meeting Date </th>
              <td mat-cell *matCellDef="let element"> {{element.MeetingDate | date:dateTimeFormat : utcoffsetvalue}} </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                <span><br />{{noRecordFoundMsg}}</span>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <mat-paginator #paginatorMoM [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons>
          </mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
