import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectsettingsEditComponent } from './projectsettings-edit.component';
import { ProjectsettingsCreateComponent } from './projectsettings-create.component';
import { MaterialModule } from '../../shared/material/material.module';
import { CustomTooltipModule } from '../../shared/components/tooltip/customtooltip.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';



@NgModule({
  declarations: [ProjectsettingsCreateComponent,ProjectsettingsEditComponent ],
  imports: [
    CommonModule, MaterialModule,
    FormsModule,
    ReactiveFormsModule, CustomTooltipModule, CommonDirectivesModule
  ],
  exports: [ProjectsettingsCreateComponent, ProjectsettingsEditComponent]
})
export class ProjectsettingsModule { }
