import { Guid } from "guid-typescript";
import { Project } from "./project";
import { BillingPlan } from "./billingPlan";

export class Subscription {
  Id: number = 0;
  ActivationKey?: Guid;
  Name: string = "";
  ContactPerson: string = "";
  BillingAddress: string = "";
  Billingemail: string = "";
  BillingPlanId?: number;
  BillingCycle?: string = "";
  StartDate!: Date;
  ValidTill!: Date;
  ProjectId!: number;
  AdminUser!: number;
  BillingPlan?: BillingPlan;
  Project?: Project;
}
