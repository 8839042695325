import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HasPermissionDirective } from './hasPermission.directive';
import { HasModuleDirective } from './hasModule.directive';



@NgModule({
  declarations: [HasPermissionDirective,HasModuleDirective],
  imports: [
    CommonModule
  ],
  exports: [HasPermissionDirective, HasModuleDirective]
})
export class AuthorisationModule { }
