import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DSStatusCard } from '../../../app_data/dsstatuscard';
import { DashboardStatusCard } from 'src/app/app_data/dashboardStatusCard';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {
  @Input() name = ""; @Input() Icon = ""; @Input() IconClass = "";
  @Input() dsstatuscard: DSStatusCard[] = [];
  @Input() statuscard!: DashboardStatusCard;
  @Input() visible!: boolean;
  iconUrl: string = environment.iconURl;
  @Output() buttonclicked: EventEmitter<string> = new EventEmitter();
  @Output() refreshBtnclicked: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onFilterData(name: any) {
    this.buttonclicked.emit(name);
  }

  refreshData(name: any) {
    this.refreshBtnclicked.emit(name);
  }
}
