import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'pm-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {
  clickEventSubscription: Subscription = new Subscription;
  saveUsername: boolean = false;
  isAuthenticated: boolean = false;
  isRolenotassigned: boolean = false;
  constructor(private communicationService: CommunicationService) {

  }

  ngOnInit() {
    this.communicationService.ischeckedvalue$.subscribe(emivalue => {
      this.setCheckbox(emivalue);
    })
    this.communicationService.isAuthenticatedvalue$.subscribe(emivalue => {
      this.isAuthenticated= Boolean(emivalue);
    })
    this.communicationService.isRolenotassignedvalue$.subscribe(emivalue => {
      this.isRolenotassigned = Boolean(emivalue);
    })
  }

  setCheckbox(value: any) {
    this.saveUsername = value;
  }

}
