import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceProjectFieldsMappingService } from 'src/app/services/interfaceprojectfieldsmapping.service';
import { ProjectFieldsConfigService } from 'src/app/services/project-fields-config.service';
import * as myGlobals from '../../app_data/globals';

@Component({
  selector: 'app-project-fields-configuration-view',
  templateUrl: './project-fields-configuration-view.component.html',
  styleUrls: ['./project-fields-configuration-view.component.css']
})
export class ProjectFieldsConfigurationViewComponent implements OnInit {
  errorMessage!: string;
  projectfieldConfigDataSource : ProjectFieldsConfiguration[] = []; 
  interfaceProjectFieldsMappingDataSource : InterfaceProjectFieldsMapping[] = [];
  interfaceProjectFieldsMappingData !: InterfaceProjectFieldsMapping;
  projectfieldConfigData !: ProjectFieldsConfiguration;
  projectfield !: ProjectFieldsConfiguration;
  interfaceProjectFieldsMapping : InterfaceProjectFieldsMapping[] = [];
  @Input() interfaceId!: number;
  @Input() interfaceType!: string;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  @Output() projectFieldsConfigadded: EventEmitter<InterfaceProjectFieldsMapping[]> = new EventEmitter();
  
  constructor(private SpinnerService: NgxSpinnerService, 
    private dialog: DialogService,
    private snackBar: MatSnackBar,
    private logger: NGXLogger,
    private projectFieldConfigService : ProjectFieldsConfigService,
    private interfaceProjectFieldsMappingService: InterfaceProjectFieldsMappingService ) { 
      this.interfaceProjectFieldsMappingData = new InterfaceProjectFieldsMapping();
    }

  ngOnInit(): void {
    if (this.interfaceId > 0) {
      this.getInterfaceProjectFieldsMappingByInterfaceId(this.interfaceId, this.interfaceType);
    }
  }

  getInterfaceProjectFieldsMappingByInterfaceId(interfaceId: number, interfaceType: string) {
    try {
      this.SpinnerService.show();    
      this.projectFieldConfigService.getProjectFieldsConfiguration(interfaceId, this._projectID, interfaceType).subscribe({
        next: data => {             
          this.projectfieldConfigDataSource = data;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })           
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
