import { Directive, HostListener, Input } from '@angular/core';
import { CommonFunction } from '../../../app_data/commonFunction';
import { DialogService } from '../../../services/dialog.service';
import { NavigationService } from '../../../services/navigation.service';

@Directive({
  selector: '[appConfirmBackButton]',
})
export class ConfirmBackButtonDirective {
  constructor(private navigation: NavigationService, private dialog : DialogService) { }

  @Input('appConfirmBackButton') confirmBack : any;

  @HostListener('click')
  onClick(): void {
    const isModuleChanged = CommonFunction.compareModels(this.confirmBack[0], this.confirmBack[1]);
    if (isModuleChanged) {
      this.dialog.confirmDialog({
        title: 'Confirm Action',
        message: 'Data entered will be lost.',
        confirmCaption: 'Confirm',
        cancelCaption: 'Cancel'
      }).subscribe((confirmed: boolean) => {
        if (confirmed) {
          var ele = document.getElementsByClassName("navigation")[0].querySelector("a[class='routeactive']");
          if (ele?.classList !== undefined && ele?.classList.contains("routeactive")) {
            ele?.classList.remove("routeactive");
          }
          this.navigation.back();
        }
      });
    }
    else {
      var ele = document.getElementsByClassName("navigation")[0].querySelector("a[class='routeactive']");
      if (ele?.classList !== undefined && ele?.classList.contains("routeactive")) {
        ele?.classList.remove("routeactive");
      }
      this.navigation.back();
    }
  }
}
