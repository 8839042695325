import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { DashboardAdminComponent } from './dashboard-admin.component';

const dashboardRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: '', component: DashboardComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'dashboard' }
      },
      {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'dashboard' }
      },
      {
        path: 'admindashboard', component: DashboardAdminComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'dashboardadmin' }
      },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(dashboardRoutes)
  ]
})
export class DashboardRoutingModule { }
