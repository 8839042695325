<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatoractionitem [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="actionitemdataSource" class="mat-elevation-z8" matSort #sortactionitem="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button (click)="view(disc)">
              <mat-icon matTooltip="View" class="mat-icon-small-size">visibility</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="ActionDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Action Date </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ActionDate | date:dateTimeFormat}} </td>
        </ng-container>
        <ng-container matColumnDef="InterfaceGroup?.Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Interface Group </th>
          <td mat-cell *matCellDef="let disc"> {{disc.InterfaceGroup?.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="ResContractor?.Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Responsible Contractor </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ResContractor?.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let disc" class="enable-line-break"> {{disc.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="StatusName">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let disc"> {{disc.StatusName}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

