import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InterfaceProjectFieldsMapping } from '../app_data/InterfaceProjectFieldsMapping';
import { response } from '../app_data/OdataResponse';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class InterfaceProjectFieldsMappingService {

  
  private apiUrl = environment.apiURl + 'InterfaceProjectFieldsMapping';

  constructor(private http: HttpClient) { }
  
  errorMessage = '';
  
  getInterfaceProjectFieldsMapping(interfaceId:number, projectFieldsConfigurationId:number): Observable<InterfaceProjectFieldsMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=ProjectFieldsConfigurations&$filter=InterfaceID eq "+ interfaceId +" and ProjectFieldsConfigurationId eq "+ projectFieldsConfigurationId +"&IsDeleted eq false &$orderby=ProjectFieldsConfigurationId desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  
  private handleError(err: HttpErrorResponse) {
  
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
  
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
  
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
