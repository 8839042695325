<div class="container-fluid">
  <div class="">
    <div class="row">
      <app-risk-matrix (selectedRiskLevel)="riskHandler($event)" [projectId]="this._projectID" [contractorId]="this._contractorID" [userRoleId]="this._userRoleId"></app-risk-matrix>
    </div>   
    <div class="row">
    <div class="display-flex">
      <div class="col-md-4 align-self-center padding-0">
        <button mat-stroked-button class="WIRbutton WIRbutton-primary" [appSetRouteActive]="'RiskCreate'" [hasPermission]="'risk.create'" (click)="redirecttoadd()">
          <mat-icon class="WIRbutton-icons">add</mat-icon>New Risk
        </button>
        <button mat-stroked-button (click)="resetDefault()" class="btn WIRbutton WIRbutton-secondary btn-sm">
          <mat-icon class="WIRbutton-icons">restart_alt</mat-icon>Reset to Default
        </button>
      </div>
      <div class="col-md-2 align-self-center text-align-end font-color-lightgrey">
        <!--<mat-label>Sort by : </mat-label>-->
      </div>
      <div class="col-md-3 align-self-center">
        <button mat-stroked-button class="WIRbutton WIRbutton-primary float-end" (click)="exportToExcel()">
          <mat-icon>download</mat-icon>Export to Excel
        </button>
      </div>
      <div class="col-md-3 padding-0">
        <div class="display-flex">
          <mat-form-field appearance="standard" class="width-100">
            <mat-select (selectionChange)='onSortChange($event.value)' [(value)]="sortColumn">
              <mat-option *ngFor="let sortColumn of sortColumnList" [value]="sortColumn.Value"><span class="{{sortColumn.DisplayName == '' ? 'spn_sort':'spn_sort_hide'}}">Sort by : </span>{{ sortColumn.DisplayName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
    </div>
  </div>
    <div class="row">
      <div>
        <mat-paginator #paginatorTop
                       [length]="length"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       showFirstLastButtons
                       (page)="pageEvent=loadRiskRegister($event); paginatorBottom.pageIndex = pageIndex"
                       [pageIndex]="pageIndex"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
    <div class="row mb-1"></div>
    <div class="row" style="height:40%;">
      <div class="display-flex">
        <section class="grid-container border-radius-4 mat-elevation-z8 sectionpadding" tabindex="0">
          <table mat-table [dataSource]="riskDataSource" class="grid-width" matSort>
            <ng-container matColumnDef="Action" stickyEnd>
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border"> Action </th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                <div class="display-flex">
                  <span class="padding-left-right-2">
                    <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'RiskView'" [disabled]="!this.hasPermission('risk.view')" (click)="viewRecord(element.Id)">
                      <mat-icon class="icon-size-20" matTooltip="View">visibility</mat-icon>
                    </button>
                  </span>
                  <span class="padding-left-right-2">
                    <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'RiskEdit'" [disabled]="!this.hasPermission('risk.edit') || element.StatusName == 'Closed'|| statusText == 'Closed'|| this._userRoleId == this.gatekeeperRole" (click)="editRecord(element.Id)">
                      <mat-icon class="icon-size-20" matTooltip="Edit">edit</mat-icon>
                    </button>
                  </span>
                  <span class="padding-left-right-2">
                    <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'RiskView'" [disabled]="!this.hasPermission('risk.delete') ||element.StatusName == 'Closed'|| statusText == 'Closed' " (click)="confirmCancelDialog(element.Id)">
                      <mat-icon class="icon-size-20" matTooltip="delete">delete</mat-icon>
                    </button>
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Attachments" sticky>
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border">Attachments</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">

                <div class="display-flex">

                  <span *ngIf="element.Documents.length > 0" class="">
                    <button class="button-icon-circle align-items-center action-button" mat-mini-fab color="accent">
                      <mat-icon matTooltip="Documents present" class="icon-size-20 vertical-middle material-icons-outlined" (click)="onDocumentsPresent(element.Id)">
                        attachment
                      </mat-icon>
                    </button>
                  </span>
                  <span *ngIf="element.Interfaces" class="">
                    <button class="button-icon-circle align-items-center action-button" mat-mini-fab color="accent">
                      <mat-icon matTooltip="Risk is Added on {{element.Module?.Name}}." class="icon-size-20 vertical-middle material-icons-outlined">
                        ballot
                      </mat-icon>
                    </button>
                  </span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="Created">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Created</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                {{element.CreatedDate  | date:dateTimeFormat}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Title</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border mat-column-RiskTitle">
                {{element.Title | shorten:70}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Description">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Description</th>
              <td mat-cell *matCellDef="let element" class="description-column mat-cell-register enable-line-break column-border">
                {{element.Description | shorten:100 }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Cause">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Cause</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                {{element.Cause | shorten:125}}
              </td>
            </ng-container>
             <ng-container matColumnDef="Mitigation">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Mitigation</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                {{element.Mitigation | shorten:125}}
              </td>
            </ng-container>
            <ng-container matColumnDef="Goal">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Goal</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
                {{element.Goal | shorten:125}}
              </td>
            </ng-container>
            <ng-container matColumnDef="RiskStatus">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Status </th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border mat-column-RiskStatus">
                {{ element.Interfaces ? commonFunction.getStatusText(element.Interfaces?.Status) : element.StatusName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="Category">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Category </th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.RiskCategories?.CategoryName}}</td>
            </ng-container>
            <ng-container matColumnDef="ConsequenceLevel">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Consequence Level</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">{{element.ConsequenceId}} </td>
            </ng-container>
            <ng-container matColumnDef="ProbabilityLevel">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Probability Level</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">{{element.ProbabilityId}} </td>
            </ng-container>
            <ng-container matColumnDef="DeadLine">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header>Deadline</th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">{{element.Deadline  | date:dateTimeFormat}} </td>
            </ng-container>
            <ng-container matColumnDef="Responsible">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Responsible </th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Responsible}}</td>
            </ng-container>
            <ng-container matColumnDef="CreatedBy">
              <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Created By </th>
              <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.CreatedUserRoleMapping?.Contractor.Name + "(" +element.CreatedUserRoleMapping?.User.FirstName + " " + element.CreatedUserRoleMapping?.User.LastName + ")"}}</td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                <span><br />{{noRecordFoundMsg}}</span>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-register"></tr>
          </table>
        </section>
      </div>
    </div>
    <div class="row mb-1"></div>
    <div class="row">
      <div>
        <mat-paginator #paginatorBottom
                       [length]="length"
                       [pageSize]="pageSize"
                       [pageSizeOptions]="pageSizeOptions"
                       showFirstLastButtons
                       (page)="pageEvent=loadRiskRegister($event); paginatorTop.pageIndex = pageIndex"
                       [pageIndex]="pageIndex"
                       aria-label="Select page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
