import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx'
import { DecimalPipe } from '@angular/common';
import { Contractor } from '../../app_data/contractor';
import { Discipline } from '../../app_data/discipline';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let Disciplinelists: Discipline[] = [];


@Component({
  selector: 'app-discipline',
  templateUrl: './discipline-create.component.html',
  styleUrls: ['./discipline-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DisciplineCreateComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  errorMessage!: string;
  form !: FormGroup;
  discForm !: FormGroup;
  contractor !: Contractor;
  @Input() discipline: Discipline;
  @Output() disciplineadded: EventEmitter<Discipline[]> = new EventEmitter();
  dataSource = Disciplinelists;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  displayedColumns: string[] = ['Code', 'Name', 'DisciplineLead', 'Action'];
  disciplinedataSource = new MatTableDataSource<Discipline>(Disciplinelists);
  @ViewChild('sortdiscipline') sortdiscipline !: MatSort;
  @ViewChild('paginatordiscipline') paginatordiscipline !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortdiscipline = ms;
    this.disciplinedataSource.paginator = this.paginatordiscipline;
    this.disciplinedataSource.sort = this.sortdiscipline;
  }

  isChecked = false;
  file !: File;
  arrayBuffer!: any;
  filelist!: any;
  fileAttr = '';
  isFileUploaded = false;
  isFileValid = true;

  bulkImportForm !: FormGroup;

  failedImportCount = 0;
  successImportCount = 0;
  totalImportCount = 0;
  isCountDivEnable = false;

  disciplineTemplateExcelHeaders: string[] = ["Code", "Name", "DisciplineLead"];
  templateToExcel: string[][] = [this.disciplineTemplateExcelHeaders, []];

  disciplineDirtyData !: Array<{ Code: string, Name: string, DisciplineLead: string, validationMessage: string }>

  bulkImportDisciplineData: Discipline[] = [];

  constructor(private communicationService: CommunicationService,
    private dialog: DialogService,
    private logger: NGXLogger) {
    this.discipline = new Discipline();
    this.contractor = new Contractor();
    this.disciplineDirtyData = [];
  }

  ngOnInit(): void {
    this.discForm = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Description: new FormControl(''),
      LeadContractorID: new FormControl(''),
      CategoryID: new FormControl(''),
      IsDeleted: new FormControl(false),
      DisciplineLead: new FormControl('', [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
    })
      
    this.communicationService.is_contractor$.subscribe(emivalue => {
      this.contractor.Disciplines = emivalue.Disciplines.filter(x => !x.IsDeleted);
      this.disciplinedataSource.data = emivalue.Disciplines.filter(x => !x.IsDeleted);
    });

    this.bulkImportForm = new FormGroup({
      discUploadFile: new FormControl()
    });
  }

  add() {
    try {
      let uniqueCheck = this.contractor.Disciplines.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == this.discipline.Code.toLowerCase() || x.Name.toLowerCase() == this.discipline.Name.toLowerCase()));
      if (uniqueCheck.length <= 0) {
        this.contractor.Disciplines.push({ Id: 0, Code: this.discipline.Code, Name: this.discipline.Name, Description: "", LeadContractorID: this._contractorID, CategoryID: 0, IsDeleted: false, DisciplineLead: this.discipline.DisciplineLead, CreatedBy: this._userRoleMappingId })
        this.disciplinedataSource.data = this.contractor.Disciplines.filter(x => !x.IsDeleted);
        this.disciplineadded.emit(this.contractor.Disciplines.filter(x => !x.IsDeleted));
        this.discForm.reset();
      }
      else {
        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Code or Name already exists',
          cancelCaption: 'Close',
        });
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(code: string, name: string) {
    try {
      let disciplineModel = this.contractor.Disciplines.filter(x => (x.Code.toLowerCase() == code.toLowerCase() && x.Name.toLowerCase() == name.toLowerCase()));

      disciplineModel[0].IsDeleted = true;
      disciplineModel[0].ModifiedBy = this._userRoleMappingId;

      this.disciplinedataSource.data = this.contractor.Disciplines.filter(x => !x.IsDeleted);

      let disciplineModelIndex = this.contractor.Disciplines.findIndex(item => item.Code.toLowerCase() == code.toLowerCase() && item.Name.toLowerCase() == name.toLowerCase());
      if (disciplineModelIndex !== -1) {
        this.contractor.Disciplines.splice(disciplineModelIndex, 1);
      }

      this.disciplineadded.emit(this.disciplinedataSource.data);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  importDisciplineData(event: any) {
    try {
      this.isFileUploaded = true;
      this.isFileValid = true;
      this.fileAttr = '';
      this.file = event.target.files[0];
      this.bulkImportDisciplineData = [];
      this.bulkImportForm.reset();
      this.isCountDivEnable = false;
      if (this.file == null) {
        this.isFileUploaded = false;
      }

      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var first_sheet_name = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[first_sheet_name];
        var excelHeader = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        var strExcelHeader: string = excelHeader[0] as string;

        if (strExcelHeader.toString() !== this.disciplineTemplateExcelHeaders.toString()) {
          this.isFileUploaded = false;
          this.bulkImportForm.get('discUploadFile')?.setErrors({ invalidFile: true })
          this.fileAttr = this.file.name;
          return;
        }


        var arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.filelist = [];
        this.disciplineDirtyData = [];

        this.fileAttr = this.file.name;

        arraylist.forEach((element: any) => {
          let validateResult = this.validateDisciplineData(element);
          if (validateResult == "") {
            this.bulkImportDisciplineData.push({ Id: 0, Code: element.Code.toString(), Name: element.Name.toString(), Description: "", LeadContractorID: this._contractorID, CategoryID: 0, IsDeleted: false, DisciplineLead: element.DisciplineLead != null ? element.DisciplineLead : '', CreatedBy: this._userRoleMappingId })
          }
          else {
            this.disciplineDirtyData.push({ Code: element.Code.toString(), Name: element.Name.toString(), DisciplineLead: element.DisciplineLead != null ? element.DisciplineLead : '', validationMessage: validateResult });
          }

        });

        this.isCountDivEnable = false;
        this.totalImportCount = arraylist.length;
        const decimalPipe = new DecimalPipe('en-US');
        var sucessCnt = decimalPipe.transform(arraylist.length - this.disciplineDirtyData.length);
        this.successImportCount = parseInt(sucessCnt!);
        this.failedImportCount = this.disciplineDirtyData.length;


      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  bulkImportDiscipline() {
    try {
      this.isFileUploaded = false;
      if (this.bulkImportDisciplineData != null) {
        this.contractor.Disciplines.push(...this.bulkImportDisciplineData);
        this.disciplinedataSource.data = this.contractor.Disciplines.filter(x => !x.IsDeleted);
        this.isCountDivEnable = true;

        this.disciplineadded.emit(this.contractor.Disciplines.filter(x => !x.IsDeleted));
        this.discForm.reset();
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  validateDisciplineData(discData: any): string {
    try {
      var strError = "";
      var disciplineLeadvalidatePattern = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
      if (discData != null) {
        if (discData.Code == null) {
          strError += "Discipline Code is required, ";
        }

        if (discData.Name == null) {
          strError += "Discipline Name is required, ";
        }

        if (discData.Code != null && discData.Name != null) {
          let uniqueCheck = this.contractor.Disciplines.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == discData.Code.toLowerCase() || x.Name.toLowerCase() == discData.Name.toLowerCase()));
          if (uniqueCheck.length > 0) {
            strError += "Code or Name already exists, ";
          }
        }

        if (discData.DisciplineLead != null) {
          if (!disciplineLeadvalidatePattern.test(discData.DisciplineLead)) {
            strError += "Please enter valid Discipline Lead Email, ";
          }
        }
      }
      return strError;
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
      return "";
    }
  }

  isImportBulkDisciplineChecked() {
    try {
      this.isChecked = !this.isChecked
      this.successImportCount = 0;
      this.failedImportCount = 0;
      this.totalImportCount = 0;
      this.isCountDivEnable = false;
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ExportTOExcel() {
    try {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.disciplineDirtyData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, 'disciplineData.xlsx');
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ExportTemplateAsExcel() {
    try {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, "disciplineTemplate" + ".xlsx");
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }  

  confirmCancelDialog(code:string, name: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this discipline?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(code, name);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
