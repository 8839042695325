import { NgModule } from '@angular/core';
import { InterfaceCreateComponent } from './interface-create.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InterfaceListComponent } from './interface-list.component';
import { InterfaceEditComponent } from './interface-edit.component';
import { InterfaceViewComponent } from './interface-view.component';
import { InterfaceLogComponent } from './interface-log.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { InterfaceRoutingModule } from './interface-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { CommonModule } from '@angular/common';
import { InterfaceRevisionComponent } from './interface-revision.component';
import { NotificationListModule } from '../../shared/components/notification/notification-list.module';
import { DialogModule } from '../../shared/components/dialog/dialog.module';
import { DiscussionModule } from 'src/app/shared/components/discussions/discussion.module';
import { ProjectFieldsConfigurationModule } from '../projectFieldsConfiguration/project-fields-configuration.module';
import { DocumentModule } from '../../shared/components/documents/document.module';
import { ReferenceLinkModule } from '../../shared/components/reference-links/reference-link.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { CommonpipesModule } from '../../shared/pipes/commonpipes/commonpipes.module';
import { SearchFilterModule } from '../../shared/components/search-filter/search-filter.module';
import { MinutesofmeetingModule } from '../minutesofmeeting/minutesofmeeting.module';
import { PdfGenerateModule } from '../../shared/components/pdf-generate/pdf-generate.module';
import { AssignmentModule } from '../../shared/components/assignment/assignment.module';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    AuthorisationModule,
    DocumentModule,
    ReferenceLinkModule,
    DialogModule,
    DiscussionModule,
    ActiveRouteModule,
    ProjectFieldsConfigurationModule,
    SearchFilterModule,
    PdfGenerateModule,
    AssignmentModule,
    MinutesofmeetingModule,
    NotificationListModule,
    CommonDirectivesModule,
    CommonpipesModule,
    BsDatepickerModule.forRoot(),
    InterfaceRoutingModule
  ],
  declarations: [InterfaceCreateComponent, 
    InterfaceListComponent, 
    InterfaceEditComponent, 
    InterfaceViewComponent, 
    InterfaceLogComponent, 
    InterfaceRevisionComponent
  ],
  exports: []
})

export class InterfaceModule { }
