import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileRoutingModule } from './userprofile-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserprofileComponent } from './userprofile.component';
import { EmailSubscriptionModule } from '../../shared/components/email-subscription/email-subscription.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';



@NgModule({
  
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    EmailSubscriptionModule,
    AuthorisationModule,
    ActiveRouteModule,
    UserProfileRoutingModule,
    CommonDirectivesModule
  ],
  declarations: [UserprofileComponent],
})
export class UserprofileModule { }
