import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ProjectEditComponent } from './project-edit.component';
import { ProjectListComponent } from './project-list.component';
import { ProjectCreateComponent } from './project-create.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';

const projectRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'Project', component: ProjectCreateComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'project.create' }
      },
      {
        path: 'ProjectEdit/:id', component: ProjectEditComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'project.edit' }
      },
      {
        path: 'ProjectList', component: ProjectListComponent,
        canActivate: [MsalGuard,PermissionGuard], data: { perminssion: 'project.view' }
      },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(projectRoutes)
  ]
})
export class ProjectRoutingModule { }
