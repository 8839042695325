import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserRoutingModule } from './user-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { InterfaceModule } from '../interface/interface.module';
import { UserListComponent } from './user-list.component';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';

@NgModule({
  declarations: [
    UserListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AuthorisationModule,
    InterfaceModule,
    UserRoutingModule,
  ],
  exports: [
    UserListComponent]
})
export class UserModule { }
