<p>Note : Please enter the invitation code you have received from your company.</p>
<form [formGroup]="UserInvitationForm">
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Invitation Code</mat-label>
        <input matInput #input placeholder="Enter Invitation Code" name="InvitationCode" formControlName="InvitationCodeControl" [(ngModel)]="verifyCode" (change)="onKeypressEventVerifyCode($event)" required>
        <mat-error *ngIf="this.UserInvitationForm.get('InvitationCodeControl')?.hasError('required')">
          Invitation Code is <strong>required</strong>
        </mat-error>
        <mat-hint>The purpose of the project invitation is giving your user account access to a new project.</mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>
