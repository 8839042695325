<div class="row h500tabMin">
  <div class="container-fluid body-wrapper">
    <div class="row">
      <div class="col-lg-12">
        <mat-paginator #paginatorNotification [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
        </mat-paginator>
        <div class="table-responsive scroller">
          <table mat-table [dataSource]="notificationDataSource" class="mat-elevation-z8 " matSort #sortNotification="matSort">
            <ng-container matColumnDef="SentOn">
              <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> SentOn </mat-header-cell>
              <mat-cell *matCellDef="let element">
                {{element.SentOn | date : this._dateTimeFormat: utcoffsetvalue }} {{element.SentOn | date : 'h:mm:ss a': utcoffsetvalue }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="ToAddress">
              <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> User </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.ToAddress }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Activity">
              <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Activity </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Activity}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Status">
              <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Status </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.Status}} </mat-cell>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                <span><br />{{noRecordFoundMsg}}</span>
              </td>
            </tr>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
