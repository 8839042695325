<div class="container-fluid">
  <ng-container>
    <app-scrolltotop (scrollToTop)="onScrollToTop()" *ngIf="this.hideScrollToTop"></app-scrolltotop>
  </ng-container>
  <div class="row display-flex dashboardModule" *ngIf="this.moduleProjectMappings.length > 1">
    <div class="col-md-4 align-self-center padding-0">
    </div>
    <div class="col-md-2 align-self-center">
    </div>
    <div class="col-md-3 align-self-center">
    </div>
    <div class="col-md-3 col-sm-12 padding-1 moduleSelectionBar">
      <mat-button-toggle-group #colorToggle="matButtonToggleGroup" class="h-100 moduleTab" value="{{this.selectedModuleId}}">
        <mat-button-toggle *ngFor="let mapping of this.moduleProjectMappings" [hasPermission]="[mapping.Module?.ModulePermissionMappings[0].Permission.Name]" [hasModule]="[mapping.ModuleId]" class="h-100" value="{{mapping.ModuleId}}" (click)="populateDashboard(mapping.ModuleId,mapping.Module.Name)">
        {{mapping.Module.Name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    </div>
    <div class="row" *ngIf="!isStatusCardRowVisible">
      <div *ngFor="let card of blankCardStatusData" class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6 mb-3 cardPadding">
        <app-cards [name]="cardLoader" [visible]="isVisible" [statuscard]="card" [Icon]="card.Icon" [IconClass]="IconClass" (buttonclicked)="statusClickFilter($event,'InterfaceReqCard')" (refreshBtnclicked)="refreshCardsData()"></app-cards>
        <ngx-spinner name="sp6" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
      </div>
  
      <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6 mb-3 cardAction">
        <mat-card class="shadow-cards padding0">
          <mat-card-content>
            <div class="d-flex flex-column">
              <div class="d-flex flex-row shadow-cards-header taskalert">
                <div class="shadow-cards-icon">
                  <img src="{{actionIcon}}" class="img-fluid rounded-start" alt="...">
                </div>
                <div class="shadow-cards-name">
                  Action
                </div>
                <div *ngIf="statuscard.Name == 'Open'" class="fw-300 text-xs"> (not Overdue)</div>
              </div>
              <div class="d-flex flex-row shadow-cards-content">
                <div class="requestor-self">
                  <div class="text-xs" style="top:50%">Contractor</div>
                  <div>
                    <a class="anchorLinkwithblack" (click)="taskClickFilter($event,'MyTaskCard')">
                      <div class="mb-0 fw-400 numbers">{{interfacecnt + actionCnt}}</div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row shadow-cards-footer mb-2" >
                <div class="mat-card-icon-refresh">
                </div>
              </div>
            </div>
            <ngx-spinner name="sp6" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
          </mat-card-content>
        </mat-card>
      </div>    
    </div>

  <div class="row" *ngIf="isStatusCardRowVisible">

    <div *ngFor="let card of cardStatusData" class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6 mb-3 cardPadding">
      <app-cards [name]="cardLoader" [visible]="isVisible" [statuscard]="card" [Icon]="card.Icon" [IconClass]="IconClass" (buttonclicked)="statusClickFilter($event,'InterfaceReqCard')" (refreshBtnclicked)="refreshCardsData()"></app-cards>
    </div>

    <div class="col-xl-2 col-lg-4 col-md-6 col-sm-6 col-xs-6 mb-3 cardAction">
      <mat-card class="shadow-cards padding0">
        <mat-card-content>
          <div class="d-flex flex-column">
            <div class="d-flex flex-row shadow-cards-header taskalert">
              <div class="shadow-cards-icon">
                <img src="{{actionIcon}}" class="img-fluid rounded-start" alt="...">
              </div>
              <div class="shadow-cards-name">
                Action
              </div>
              <div *ngIf="statuscard.Name == 'Open'" class="fw-300 text-xs"> (not Overdue)</div>
            </div>
            <div class="d-flex flex-row shadow-cards-content">
              <div class="requestor-self">
                <div class="text-xs" style="top:50%">Contractor</div>
                <div>
                  <a class="anchorLinkwithblack" (click)="taskClickFilter($event,'MyTaskCard')">
                    <div class="mb-0 fw-400 numbers">{{interfacecnt + actionCnt}}</div>
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row shadow-cards-footer mb-2" >
              <div class="mat-card-icon-refresh">
              </div>
            </div>
          </div>
          <ngx-spinner name="sp6" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
        </mat-card-content>
      </mat-card>
    </div>    
  </div>
  <div class="row">
    <div class="col-xl-4 col-lg-6 cardOverview">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3 card-padding-right pad-rt-15_768">
          <div class="card mat-card-firstrow {{!isChartCollapsed ? 'h-min-100':''}}">
            <div class="card-header card-padding card-title">
              <button mat-button (click)="collapsed('chart')" *ngIf="!isChartCollapsed" class="card-btntoggle p-0">{{cardHeaderLabel}} Overview<mat-icon>expand_more</mat-icon></button>
              <button mat-button (click)="collapsed('chart')" *ngIf="isChartCollapsed" class="card-btntoggle p-0">{{cardHeaderLabel}} Overview<mat-icon>expand_less</mat-icon></button>
            </div>

            <div id="chart" class="card-body card-padding chartcss" *ngIf="isChartVisible">
              <div style="height:245px" *ngIf="!isChartCollapsed">
                <apx-chart [series]="chartOptions.series"
                           [colors]="chartOptions.colors"
                           [chart]="chartOptions.chart"
                           [dataLabels]="chartOptions.dataLabels"
                           [stroke]="chartOptions.stroke"
                           [plotOptions]="chartOptions.plotOptions"
                           [responsive]="chartOptions.responsive"
                           [xaxis]="chartOptions.xaxis"
                           [legend]="chartOptions.legend"
                           [fill]="chartOptions.fill"></apx-chart>
              </div>

            </div>
            <div style="height:230px" *ngIf="!isChartVisible"></div>
            <ngx-spinner name="sp7" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3 interfaceTab pad-lt-15_768" id="MyTaskCard">
      <div class="card mat-card-firstrow {{!isTaskCollapsed ? 'h-min-100':''}}">
        <div class="card-header card-padding card-title">
          <button mat-button (click)="collapsed('task')" *ngIf="!isTaskCollapsed" class="card-btntoggle p-0">Contractor Tasks<mat-icon>expand_more</mat-icon></button>
          <button mat-button (click)="collapsed('task')" *ngIf="isTaskCollapsed" class="card-btntoggle p-0">Contractor Tasks<mat-icon>expand_less</mat-icon></button>
        </div>
        <div class="card-body card-padding" *ngIf="!isTaskCollapsed">
          <mat-tab-group animationDuration="0ms" >
            <mat-tab labelinterface label="{{cardHeaderLabel}} ({{interfacecnt}})">
              <div>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.pendingAction > 0">Pending Action ! ({{this.pendingAction}})</span>
                <span class="badge bgtaskidentifed mr-1" *ngIf="this.interfaceneedstoOpen > 0">{{cardHeaderLabel}} needs to Open ! ({{this.interfaceneedstoOpen}})</span>
                <span class="badge bgtaskidentifed mr-1" *ngIf="this.awaitingforRequestapproval > 0">Awaiting for Request approval ! ({{this.awaitingforRequestapproval}})</span>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.awaitingResponse > 0">Awaiting Response ! ({{this.awaitingResponse}})</span>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.awaitingforResponseapproval > 0">Awaiting for Response approval ! ({{this.awaitingforResponseapproval}})</span>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.awaitingforResponseacceptance > 0">Awaiting for Response acceptance ! ({{this.awaitingforResponseacceptance}})</span>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.awaitingforcloseout > 0">Awaiting for close out ! ({{this.awaitingforcloseout}})</span>
                <span class="badge bgtaskopen text-dark mr-1" *ngIf="this.responseRejected > 0">Response Rejected ! ({{this.responseRejected}})</span>              
              </div>
              <div class="table-responsive home" *ngIf="isVisible">
                
                <table class="table mat-elevation-z8">
                  <thead>                    
                    <tr>                      
                      <th>                        
                        Number</th>
                      <th>
                        Action</th>
                      <th>
                        Title</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dstask of dstasks">
                      <ng-container>
                        <td *ngIf="dstask.Type == 'ActionItem' && dstask.ResponsibleContractorID == this._contractorID">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Id}}</a>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 1">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 4">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 5">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 6">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 7">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 8">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 10">
                          <a class="anchorLink" (click)="redirectToEditScreen(dstask.Id, dstask.ModuleId)">{{dstask.Number}}</a>
                        </td>
                        <td *ngIf="dstask.Type == 'ActionItem' && dstask.ResponsibleContractorID == this._contractorID">
                          <span class="badge bgtaskopen blink text-dark">Pending Action !</span>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 1">
                          <span class="badge bgtaskidentifed blink text-white">{{cardHeaderLabel}} needs to Open !</span>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 4">
                          <span class="badge bgtaskopen blink text-dark">Awaiting for Request approval !</span>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 5">
                          <span class="badge bgtaskopen blink text-dark">Awaiting Response !</span>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 6">
                          <span class="badge bgtaskidentifed blink text-white">Awaiting for Response approval !</span>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 7">
                          <span class="badge bgtaskopen blink text-dark">Awaiting for Response acceptance !</span>
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 8">
                          <span class="badge bgtaskopen blink text-dark">Awaiting for close out !</span>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 10">
                          <span class="badge bgtaskopen blink text-dark">Response Rejected !</span>
                        </td>
                        <td *ngIf="dstask.Type == 'ActionItem' && dstask.ResponsibleContractorID == this._contractorID">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 1">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 4">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 5">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 6">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && dstask.Status == 7">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == 8">
                          {{dstask.Title}}
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorID == this._contractorID && dstask.Status == 10">
                          {{dstask.Title}}
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                  <ng-template #noData *ngIf="isVisible">
                    <tbody>
                      <tr class="noborder">
                        <td colspan="3" align="center">{{noRecordFoundMsg}}</td>
                      </tr>
                    </tbody>
                  </ng-template>
                </table>
              </div>              
            </mat-tab>
            <mat-tab labelinterface label="Action Items ({{actionCnt}})">
              <div class="table-responsive home" *ngIf="isVisible">
                <table class="table mat-elevation-z8">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Action Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let dstask of dsActiontasks">
                      <ng-container>
                        <td *ngIf="dstask.ResponsibleContractorId == this._contractorID">
                          <a class="anchorLink" (click)="redirectToActionItem(dstask.MOMId,dstask.Id)">{{dstask.Title}}</a>
                        </td>
                        <td *ngIf="dstask.ResponsibleContractorId == this._contractorID">
                          {{dstask.ActionDate | date:dateTimeFormat}}
                        </td>
                      </ng-container>
                    </tr>
                  </tbody>
                  <ng-template #noData *ngIf="isVisible">
                    <tbody>
                      <tr class="noborder">
                        <td colspan="3" align="center">{{noRecordFoundMsg}}</td>
                      </tr>
                    </tbody>
                  </ng-template>
                </table>
              </div>
            </mat-tab>
            
          </mat-tab-group>
          <ngx-spinner name="sp6" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 mb-4" id="InterfaceReqCard">
      <mat-card class="mat-card-secondrow">
        <mat-card-title class="mat-card-title card-title">
          <div>{{cardHeaderLabel}} ({{activereqcnt}}) <span class="badge taskfilter status-{{ filterCssClass == ''? filter : filterCssClass | lowercase}}">{{filter}}<mat-icon class="filtericon" (click)="removefilter(filter)">cancel</mat-icon></span></div>
        </mat-card-title>
        <mat-card-content>
          <div class="table-responsive activereq mat-elevation-z8" *ngIf="isVisible">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <thead>
                <tr>
                  <th class="w-25">Number</th>
                  <th class="w-30">Title</th>
                  <th class="w-8">Priority</th>
                  <th class="w-20">Interface Group</th>
                  <th class="w-20">Status</th>
                </tr>
              </thead>
              <tbody id="ActiveReq" *ngIf="isData; else noData">
                <tr *ngFor="let dsactivereq of dsactivereqs">
                  <td><a class="anchorLink" (click)="redirectToViewScreen(dsactivereq.Id, dsactivereq.ModuleId)">{{dsactivereq.Number}}</a></td>
                  <td class="statusname_truncate" matTooltip="{{dsactivereq.Title}}">{{dsactivereq.Title}}</td>
                  <td>{{dsactivereq.Prioritys.Name}}</td>
                  <td class="statusname_truncate" matTooltip="{{dsactivereq.InterfaceGroup.Name}}">{{dsactivereq.InterfaceGroup.Name}}</td>
                  <td>
                    <span class="status status-{{ dsactivereq.Status == this.voidedStatusId? 'voided' : dsactivereq.IsFirstIssuePlannedOverdue == true ? 'responseoverdue' : dsactivereq.IsApprovedIssuePlannedOverdue == true ? 'closeoutoverdue' : dsactivereq.StatusShortName | lowercase}} mb-0"></span>{{dsactivereq.Status == this.voidedStatusId? dsactivereq.InterfaceStatusName : dsactivereq.IsFirstIssuePlannedOverdue == true ? dsactivereq.InterfaceStatus : dsactivereq.IsApprovedIssuePlannedOverdue == true ? dsactivereq.InterfaceStatus : dsactivereq.InterfaceStatus.Name}}
                  </td>
                </tr>
              </tbody>
              <ng-template #noData *ngIf="isVisible">
                <tbody>
                  <tr class="noborder">
                    <td colspan="5" align="center">{{noRecordFoundMsg}}</td>
                  </tr>
                </tbody>
              </ng-template>
            </table>
          </div>
          <ngx-spinner name="sp8" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
