import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { DateTimeFormat } from '../../app_data/datetimeformat';
import * as myGlobals from '../../app_data/globals';
import { Project } from '../../app_data/project';
import { ProjectSetting } from '../../app_data/projectSetting';
import { Setting } from '../../app_data/setting';
import { TimeZones } from '../../app_data/timezones';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from '../../services/dialog.service';
import { InterfaceService } from '../../services/interface.service';
import { MatSelectChange } from '@angular/material/select';
import { Module } from '../../app_data/module';
import { ModuleService } from '../../services/module.service';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';

@Component({
  selector: 'app-projectsettings-edit',
  templateUrl: './projectsettings-edit.component.html',
  styleUrls: ['./projectsettings-edit.component.css']
})
export class ProjectsettingsEditComponent implements OnInit {
  settingForm !: FormGroup;
  setting !: Setting;
  project!: Project;
  errorMessage!: string;
  selectedTimeZone?: number = 0;
  selecteddateformat?: string = "";
  selectedTimeZoneName?: string = "";
  selecteddateformatName?: string = "";
  selectedvalue: any;
  timezones: TimeZones[] = [];
  dateformats: DateTimeFormat[] = [];
  todaydate: Date = new Date;
  selectedValue: any;
  utcoffsetvalue: string = "";
  _settings: ProjectSetting[] = [];
  selectedModuleIds!: number[];
  selectedModuleNames!: string[];
  modules: Module[] = [];
  show: boolean = false;
  showCloseOutDateReminderByDays: boolean = true;
  showCustomiseInterfaceNumberPattern: boolean = false;
  showDocumentUploadFileSize: boolean = true;
  showDefaultResponseByDays: boolean = true;
  activeModules: number[] = [];

  DefaultCloseoutPlannedDateByNoOfDaystext: string = myGlobals.DefaultCloseoutPlannedDateByNoOfDaystexttooltip;
  UniNumberbyProjecttext: string = myGlobals.UniNumberbyProjecttexttooltip;
  UniNumberbyGrouptext: string = myGlobals.UniNumberbyGrouptexttooltip;
  CloseOutDateReminderByDaysText: string = myGlobals.CloseOutDateReminderByDaysTextTooltip;
  CustomiseInterfaceNumberPatterntext: string = myGlobals.CustomiseInterfaceNumberPatterntexttooltip;
  DocumentUploadFileSizetext: string = myGlobals.DocumentUploadFileSizetexttooltip;
  IsDualDisciplinestext: string = myGlobals.DualDisciplinetexttooltip;
  IsAcceptAsPreliminarytext: string = myGlobals.AcceptAsPreliminarytooltip;
  interfaceModuleId: number = myGlobals.InterfaceModule;

  @Output() settingadded: EventEmitter<ProjectSetting[]> = new EventEmitter();
  @Output() moduleProjectMappingEmit: EventEmitter<ModuleProjectMapping[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private communicationService: CommunicationService, private interfaceService: InterfaceService,
    private logger: NGXLogger, private dialog: DialogService,
    private moduleService: ModuleService) {
    this.setting = new Setting(); this.project = new Project();
  }

  ngOnInit(): void {
    try {
      this.settingForm = new FormGroup({
        IsEnableDefaultExpectedResponseDate: new FormControl(false),
        DefaultExpectedResponseDateByDays: new FormControl(),
        IsUniqueNumberByProject: new FormControl(false),
        IsUniqueNumberByInterfaceGroup: new FormControl(false),
        IsEnableCloseOutDateReminderByDays: new FormControl(false),
        CloseOutDateReminderByDays: new FormControl(),
        IsEnableDocumentUploadFileSize: new FormControl(false),
        DocumentUploadFileSize: new FormControl(),
        IsDualDiscipline: new FormControl(false),
        IsAcceptAsPreliminary: new FormControl(false),
        IsEnableCustomiseInterfaceNumberPattern: new FormControl(false),
        CustomiseInterfaceNumberPattern: new FormControl(),
        ActiveModuleIds: new FormControl()
      });

      this.communicationService.is_project$.subscribe(emivalue => {
        if (emivalue.Settings != null) {
          this.project.Settings = emivalue.Settings;
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsEnableDefaultExpectedResponseDate = boolvalue;
            this.show = boolvalue;
            this.showDefaultResponseByDays = boolvalue;
          }
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
          if (index > -1) {
            this.setting.DefaultExpectedResponseDateByDays = Number(emivalue.Settings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);
          }
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByProject");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsUniqueNumberByProject")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsUniqueNumberByProject = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsDualDiscipline = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsAcceptAsPreliminary");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsAcceptAsPreliminary")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsAcceptAsPreliminary = boolvalue;
          }
          
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsUniqueNumberByInterfaceGroup = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsEnableCloseOutDateReminderByDays = boolvalue;
            this.showCloseOutDateReminderByDays = boolvalue;
            this.show = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "CloseOutDateReminderByDays");
          if (index > -1) {
            this.setting.CloseOutDateReminderByDays = Number(emivalue.Settings.filter(x => x.ConfigKey == "CloseOutDateReminderByDays")[0].ConfigValue);
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsEnableCustomiseInterfaceNumberPattern = boolvalue;
            this.showCustomiseInterfaceNumberPattern = boolvalue;
            this.show = boolvalue;
          }
          if (this.showCustomiseInterfaceNumberPattern) {
            var index = this.project.Settings.findIndex(x => x.ConfigKey == "CustomiseInterfaceNumberPattern");
            if (index > -1) {
              this.setting.CustomiseInterfaceNumberPattern = String(emivalue.Settings.filter(x => x.ConfigKey == "CustomiseInterfaceNumberPattern")[0].ConfigValue);
            }
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
          if (index > -1) {
            const boolvalue = emivalue.Settings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue.toLowerCase() === 'true';
            this.setting.IsEnableDocumentUploadFileSize = boolvalue;
            this.showDocumentUploadFileSize = boolvalue;
            this.show = boolvalue;
          }

          var index = this.project.Settings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
          if (index > -1) {
            this.setting.DocumentUploadFileSize = Number(emivalue.Settings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue);
          }
        }
        if (emivalue.ModuleProjectMappings != null) {
          this.project.ModuleProjectMappings = emivalue.ModuleProjectMappings;
          this.selectedModuleNames = [];
          emivalue.ModuleProjectMappings.filter(x => !x.IsDeleted).forEach(x => {
            this.activeModules.push(x.ModuleId);
            this.selectedModuleNames.push(x.Module?.Name!)

          });
          this.setting.ActiveModuleIds = this.activeModules;
          this.selectedModuleIds = this.activeModules;
          this.getAllModules();
        }

      });
      this.interfaceService.getInterfacesByProjectId(this._projectID).subscribe({
        next: data => {
          if (data != null) {
            this.settingForm.controls['IsUniqueNumberByProject'].disable();
            this.settingForm.controls['IsUniqueNumberByInterfaceGroup'].disable();
            this.settingForm.controls['IsEnableCustomiseInterfaceNumberPattern'].disable();
            this.settingForm.controls['CustomiseInterfaceNumberPattern'].disable();
          }
          else {
            this.settingForm.controls['IsUniqueNumberByProject'].enable();
            this.settingForm.controls['IsUniqueNumberByInterfaceGroup'].enable();
            this.settingForm.controls['IsEnableCustomiseInterfaceNumberPattern'].enable();
            this.settingForm.controls['CustomiseInterfaceNumberPattern'].enable();
          }
        }
      });
      this.settingadded.emit(this.project.Settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onlyDigitsAllowed(event: any) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }

  onModulesChange(ev: MatSelectChange) {
    var allSelectedModules = ev.source.value;
    if (allSelectedModules.length > this.project.ModuleProjectMappings.filter(x => !x.IsDeleted).length) {
      for (var i = 0; i < allSelectedModules.length; i++) {
        if (this.project.ModuleProjectMappings.filter(x => !x.IsDeleted && x.ModuleId != allSelectedModules[i]).length > 0) {
          this.project.ModuleProjectMappings.push({
            Id: 0, ProjectId: 0, ModuleId: allSelectedModules[i], IsDeleted: false, CreatedBy: this._userRoleMappingId
          })
        }
      }
    }
    else if (allSelectedModules.length < this.project.ModuleProjectMappings.filter(x => !x.IsDeleted).length) {
      this.project.ModuleProjectMappings.forEach(x => {
        for (var i = 0; i < allSelectedModules.length; i++) {
          if (x.ModuleId != allSelectedModules[i]) {
            x.IsDeleted = true;
            x.ModifiedBy = this._userRoleMappingId;
          }
        }
      })
    }
    this.moduleProjectMappingEmit.emit(this.project.ModuleProjectMappings);
    this.selectedModuleNames = [ev.source.triggerValue];
  }

  getAllModules() {
    this.moduleService.getModules().subscribe({
      next: data => {
        this.modules = data;
      }
    })
  }

  onTextChange(controlName: string, event: any) {
    try {

      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = event.target.value;
        this._settings[index].ModifiedBy = this._userRoleMappingId;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this._userRoleMappingId
        });
      }
      this.settingadded.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  settingChecked(controlName: string, ev: any) {
    try {
      if (controlName == "IsEnableDefaultExpectedResponseDate") {
        this.show = !this.show;
        this.showDefaultResponseByDays = !this.showDefaultResponseByDays;

        if (!ev.target.checked) {
          this.setting.DefaultExpectedResponseDateByDays = Number(myGlobals.DefaultExpectedResponseDateByDaysValue);
        }
      }

      if (controlName == "IsEnableCloseOutDateReminderByDays") {
        this.showCloseOutDateReminderByDays = !this.showCloseOutDateReminderByDays;

        if (!ev.target.checked) {
          this.setting.CloseOutDateReminderByDays = Number(myGlobals.DefaultCloseOutDateReminderByDaysValue);
        }
      }

      if (controlName == "IsEnableCustomiseInterfaceNumberPattern") {
        this.showCustomiseInterfaceNumberPattern = !this.showCustomiseInterfaceNumberPattern;

        if (!ev.target.checked) {
          this.setting.CustomiseInterfaceNumberPattern = "";
        }
      }

      if (controlName == "IsEnableDocumentUploadFileSize") {
        this.showDocumentUploadFileSize = !this.showDocumentUploadFileSize;

        if (!ev.target.checked) {
          this.setting.DocumentUploadFileSize = Number(myGlobals.DefaultDocumentUploadFileSizeValue);
        }
      }


      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = String(ev.target.checked);
        this._settings[index].ModifiedBy = this._userRoleMappingId;
      }
      else {
        this._settings.push({
          Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId
        });
      }
      this.settingadded.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
