import { Permission } from "./permission";
import { Role } from "./role";
import { UserRoleProject } from "./userroleproject";

export class RolePermission {
  Id !: number;
  RoleId!: number;
  PermissionId!: number;
  IsDeleted!: boolean;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  CreatedUserRoleMapping?: UserRoleProject;
  Role?: Role;
  Permission?: Permission;
}
