import { DatePipe, ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DSStatusCard } from '../../app_data/dsstatuscard';
import { Interface } from '../../app_data/interface';
import { CommunicationService } from '../../services/communication.service';
import { DashboardService } from '../../services/dashboard.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { StatusCard } from '../../app_data/statuscard';
import { CommonFunction } from '../../app_data/commonFunction';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexTitleSubtitle, ApexXAxis, ApexLegend, ChartComponent, ApexPlotOptions, ApexResponsive, ApexFill, ApexStroke } from 'ng-apexcharts';
import { DsTasks } from '../../app_data/dstasks';
import { ActionItemService } from '../../services/actionItem.service';
import { environment } from '../../../environments/environment';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { ModuleProjectMappingsService } from 'src/app/services/moduleprojectmappings.service';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { ReportSearchFilter } from 'src/app/app_data/reportSearchFilter';
import { ReportService } from 'src/app/services/report.service';
import { SignalrService } from '../../services/signalr.service';

let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  colors: string[];
  fill: ApexFill;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  dsstatuscard: DSStatusCard[] = [];
  statuscard!: StatusCard;
  dstasks: DsTasks[] = [];
  dsActiontasks: DsTasks[] = [];
  dsactivereqs: Interface[] = [];
  activereqcnt: number = 0;
  filter: string = "";
  filterCssClass: string = "";
  errorMessage = '';
  name = "";
  Icon = "";
  IconClass = "";
  filtervalue !: number; tablename !: string;
  isVisible: boolean = false;
  cardLoader: string = "cardLoader";
  isChartVisible: boolean = false;

  endDate = new Date;
  previousDate = new Date;
  isChartCollapsed: boolean = false;
  isTaskCollapsed: boolean = false;
  isData: boolean = false;
  hideScrollToTop: boolean = false;
  gatekeeperRoleId: number = Number(myGlobals.GatekeeperRoleId);
  voidedStatusId: number = Number(myGlobals.VoidedStatusId);
  lastActivityDate!: Date;
  contractorActionCount: number = 0;
  interfacecnt: number = 0; actionCnt: number = 0;
  iconUrl: string = environment.iconURl;
  actionIcon: string = this.iconUrl + "Action.svg";

  isStatusCardRowVisible: boolean = false;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _roleID = parseInt(sessionStorage.getItem("roleId")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone") != null ? sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))! : "";
  _rolePermissions = sessionStorage.getItem("rolePermissions")!;
  pipe = new DatePipe('en-US');
  todayDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
  todayDatepipe: any;
  endDatepipe: any;
  previousDatepipe: any;
  dateTimeFormat: string = "";
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  moduleProjectMappings: ModuleProjectMapping[] = [];
  selectedModuleId: number = myGlobals.InterfaceModule;
  cardHeaderLabel: string = "Interface";


  pendingAction: number = 0;
  interfaceneedstoOpen: number = 0;
  awaitingforRequestapproval: number = 0;
  awaitingResponse: number = 0;
  awaitingforResponseapproval: number = 0;
  awaitingforResponseacceptance: number = 0;
  awaitingforcloseout: number = 0;
  responseRejected: number = 0;

  reportfilters: ReportSearchFilter[] = [];
  cardStatusData: any[] = [];
  blankCardStatusData: any[] = myGlobals.blankCardStatusData;
  isLiveNotify: boolean = false;

  constructor(private dashboardservice: DashboardService, private router: Router, private communicationService: CommunicationService,
    private readonly viewport: ViewportScroller, private logger: NGXLogger, private SpinnerService: NgxSpinnerService,
    private dialog: DialogService, private signalrService: SignalrService,
    private actionitemService: ActionItemService, private projectSettingService: ProjectSettingService,
    private moduleProjectMappingsService: ModuleProjectMappingsService,
    private reportService: ReportService) {
    this.statuscard = new StatusCard();
    this.endDate.setDate(this.todayDate.getDate() + 1);
    this.previousDate.setDate(this.todayDate.getDate() - 1);
    this.todayDatepipe = this.pipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.endDatepipe = this.pipe.transform(this.endDate, 'yyyy-MM-dd');
    this.previousDatepipe = this.pipe.transform(this.previousDate, 'yyyy-MM-dd');
    this.chartOptions = {
      series: [{
        name: "Closeout Overdue",
        data: [0, 0, 0],
        color: myGlobals.overduecolor
      },
      {
        name: "Response Overdue",
        data: [0, 0, 0],
        color: myGlobals.overduecolor
      },
      {
        name: "Closeout Due Today",
        data: [0, 0, 0],
        color: "#ff00004f"
      },
      {
        name: "Response Due Today",
        data: [0, 0, 0],
        color: "#ff00004f"
      },
      {
        name: "Identified",
        data: [0, 0, 0],
        color: myGlobals.identifiedcolor
      }],
      chart: {
        height: '215',
        width: '90%',
        type: "bar",
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        toolbar: {
          export: {
            csv: {
              filename: "InterfaceOverview_Bar",
            },
            svg: {
              filename: "InterfaceOverview_Bar",
            },
            png: {
              filename: "InterfaceOverview_Bar",
            }
          },
        }
      },
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: false,
          dataLabels: {
            position: "center"
          }
        }
      },
      colors: [myGlobals.overduecolor, "#ff00004f", myGlobals.identifiedcolor],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      title: {
        text: ''
      },
      xaxis: {
        categories: ['Overdue', 'Due Today', 'Identified']
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetY: 0,
        labels: {
          colors: [myGlobals.overduecolor, myGlobals.overduecolor, "#ff00004f", "#ff00004f", myGlobals.identifiedcolor]
        },
      }
    };
  }

  ngOnInit() {
    try {
      this.SpinnerService.show();
      this.signalrService.startConnection();
      this.signalrService.addNotificationListner();
      this.signalrService.getUpdatedNoification$.subscribe(notification => {
        if (notification.isAddedOrUpdated && notification.projectId == this._projectID) {
          this.isLiveNotify = true;
          this.refreshCardsData();
          this.refreshTasks();
          this.refreshchart();
          this.refreshActiveReqs();
          this.isLiveNotify = false;
        }
      });
      this.getModules(this._projectID);
      this.getProjectSettings();
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
    }
  }

  getStatusWiseCount(moduleId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show(this.cardLoader);
      }
      
      this.reportfilters.push(
        { KeyName: "ProjectId", Value: String(this._projectID) },
        { KeyName: "ModuleId", Value: String(moduleId) },
        { KeyName: "ReceivingContractorID", Value: String(this._contractorID) },
        { KeyName: "ResponsibleContractorID", Value: String(this._contractorID) }
      )

      this.reportService.post(this.reportfilters, "GetStatusWiseCount").subscribe({
        next: data => {
          this.cardStatusData = data.DashboardModel!;
          this.isStatusCardRowVisible = true;
          this.SpinnerService.hide(this.cardLoader);
        }
      });

      this.reportfilters = [];
    } catch (e) {
      this.reportfilters = [];
      this.SpinnerService.hide(this.cardLoader);
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    if (window.pageYOffset != 0) {
      this.hideScrollToTop = true;
    }
    else {
      this.hideScrollToTop = false;
    }
  }

  getchartdata(contractorId: number, projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp7');
      }
      this.dashboardservice.getInterfacesById(contractorId, projectId, this.selectedModuleId).subscribe({
        next: interfaceData => {
          var closeoutoverduedata = interfaceData.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length;
          var responseoverduedata = interfaceData.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
            (x.IsFirstIssuePlannedOverdue == true && x.Response != "" && x.Status == myGlobals.AwaitingForResponseApprovalStatusId))).length;
          var responseduetodaydata = interfaceData.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.IsApprovedIssuePlannedOverdue == false && x.Response == "")
            && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId)
            && (this.pipe.transform(x.FirstIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe)).length;
          var closeoutduetodaydata = interfaceData.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.IsApprovedIssuePlannedOverdue == false && x.Response != "")
            && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId)
            && (this.pipe.transform(x.ApprovedIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe)).length;
          var identifieddata = interfaceData.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId) && x.ReceivingContractorID == this._contractorID).length;

          this.chartOptions = {
            colors: [myGlobals.overduecolor, myGlobals.responseoverduecolor, '#d2d05f', '#ffcc00', myGlobals.identifiedcolor],
            series: [{
              name: "Closeout Overdue",
              data: [closeoutoverduedata, 0, 0],
            },
            {
              name: "Response Overdue",
              data: [responseoverduedata, 0, 0],
            },
            {
              name: "Closeout Due Today",
              data: [0, closeoutduetodaydata, 0],
            },
            {
              name: "Response Due Today",
              data: [0, responseduetodaydata, 0],
            },
            {
              name: "Identified",
              data: [0, 0, identifieddata],
            }
            ],
            chart: {
              height: '225',
              width: '80%',
              type: "bar",
              stacked: true,
              animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                  enabled: true,
                  delay: 150
                },
                dynamicAnimation: {
                  enabled: true,
                  speed: 350
                }
              },
              toolbar: {
                export: {
                  csv: {
                    filename: this.cardHeaderLabel + "StatusSummary_Bar",
                  },
                  svg: {
                    filename: this.cardHeaderLabel + "StatusSummary_Bar",
                  },
                  png: {
                    filename: this.cardHeaderLabel + "StatusSummary_Bar",
                  }
                },
              },
              events: {
                click: (event, chartContext, config) => {
                  if (config.seriesIndex != -1) {
                    this.loadChartData(config.seriesIndex == -1 ? '' : config.config.series[config.seriesIndex].name, config.config.xaxis.categories[config.dataPointIndex], config.seriesIndex == -1 ? '' : config.config.series[config.seriesIndex].data[config.dataPointIndex], 'InterfaceReqCard');
                  }

                },
                mounted: (chart) => {
                  chart.windowResizeHandler();
                }
              }
            },
            plotOptions: {
              bar: {
                barHeight: "100%",
                distributed: false,
                horizontal: false,
                dataLabels: {
                  position: "center"
                },
              }
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"]
            },
            title: {
              text: ''
            },
            xaxis: {
              categories: ['Overdue', 'Due Today', 'Identified']
            },
            fill: {
              opacity: 1
            },

            legend: {
              position: "bottom",
              horizontalAlign: "center",
              offsetY: 0,
              onItemClick: {
                toggleDataSeries: false
              },
            }
          };
          this.isChartVisible = true;
          this.SpinnerService.hide('sp7');
        },
        error: err => {
          this.SpinnerService.hide('sp7');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp7');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }

  getTasks(contractorid: number, projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp6');
      }

      this.pendingAction = 0;
      this.interfaceneedstoOpen = 0;
      this.awaitingforRequestapproval = 0;
      this.awaitingResponse = 0;
      this.awaitingforResponseapproval = 0;
      this.awaitingforResponseacceptance = 0;
      this.awaitingforcloseout = 0;
      this.responseRejected = 0;

      this.dashboardservice.getTasks(contractorid, projectId, this.selectedModuleId).subscribe({
        next: dstasksdata => {
          //get task count
          dstasksdata.forEach(dstask => {
            //Pending Action !
            if (dstask.Type == 'ActionItem' && dstask.ResponsibleContractorID == this._contractorID) {
              this.pendingAction++;
            }//Interface needs to Open !
            else if (dstask.ReceivingContractorID == this._contractorID && dstask.Status == myGlobals.IdentifiedStatusId) {
              this.interfaceneedstoOpen++;
            }//Awaiting for Request approval !
            else if (dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == myGlobals.AwaitingForRequestApprovalStatusId) {
              this.awaitingforRequestapproval++;
            }//Awaiting Response ! 
            else if (dstask.ResponsibleContractorID == this._contractorID && dstask.Status == myGlobals.AwaitingResponseStatusId) {
              this.awaitingResponse++;
            } //Awaiting for Response approval !
            else if (dstask.ResponsibleContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == myGlobals.AwaitingForResponseApprovalStatusId) {
              this.awaitingforResponseapproval++;
            } //Awaiting for Response acceptance !
            else if (dstask.ReceivingContractorID == this._contractorID && dstask.Status == myGlobals.AwaitingForResponseAcceptanceStatusId) {
              this.awaitingforResponseacceptance++;
            } //Awaiting for close out !
            else if (dstask.ReceivingContractorID == this._contractorID && this._roleID == this.gatekeeperRoleId && dstask.Status == myGlobals.AwaitingForCloseOutStatusId) {
              this.awaitingforcloseout++;
            } //Response Rejected !
            else if (dstask.ResponsibleContractorID == this._contractorID && dstask.Status == myGlobals.ResponseRejectedStatusId) {
              this.responseRejected++;
            }
          });

          this.dstasks = [];
          dstasksdata.filter(x => x.Type = "Interface")
          var interfacetasks = dstasksdata.filter(x => x.ModuleId == this.selectedModuleId);

          this.dstasks = [...interfacetasks];
          this.interfacecnt = dstasksdata.filter(x => x.ModuleId == this.selectedModuleId).length;
          this.dstasks.sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
          this.SpinnerService.hide('sp6');
          this.isVisible = true;
        },
        error: err => {
          this.SpinnerService.hide('sp6');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
      this.actionitemService.getActionItemByResponsibleContractorId(contractorid, this.selectedModuleId).subscribe({
        next: items => {
          this.dsActiontasks = [];
          items.filter(x => x.Type = "ActionItem");
          this.dsActiontasks.push(...items);
          this.actionCnt = items.length;
          this.dsActiontasks.sort((a, b) => a.CreatedDate! < b.CreatedDate! ? 1 : -1);
          this.isVisible = true;
          this.SpinnerService.hide('sp6');
        },
        error: err => {
          this.SpinnerService.hide('sp6');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide('sp6');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getGateKeeperTasks(contractorid: number, projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp6');
      }
      this.dashboardservice.getGateKeeperTasks(contractorid, projectId, this.selectedModuleId).subscribe({
        next: dstasks => {
          this.dstasks = dstasks;
          this.interfacecnt = this.dstasks.length;
          this.SpinnerService.hide('sp6');
        },
        error: err => {
          this.SpinnerService.hide('sp6');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide('sp6');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  loadChartData(statusname: string, legendName: string, count: number, ele: string) {
    try {
      this.SpinnerService.show('sp8');
      const activereqEle = document.getElementById(ele) as HTMLInputElement;
      activereqEle.scrollIntoView();
      this.dashboardservice.getActiveReqsByChartClick(this._contractorID, this._projectID, this.selectedModuleId).subscribe({
        next: data => {
          if (legendName == "Overdue") {
            if (statusname == "Response Overdue") {
              this.dsactivereqs = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
                (x.IsFirstIssuePlannedOverdue == true && x.Response != "" && x.Status == myGlobals.AwaitingForResponseApprovalStatusId)));
              this.activereqcnt = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
                (x.IsFirstIssuePlannedOverdue == true && x.Response != "" && x.Status == myGlobals.AwaitingForResponseApprovalStatusId))).length;
            }
            else {
              this.dsactivereqs = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId));
              this.activereqcnt = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length;
            }

            this.filterCssClass = statusname;

          } else if (legendName == "Due Today") {
            if (statusname == "Response Due Today") {
              this.dsactivereqs = data.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.Response == "" && x.IsApprovedIssuePlannedOverdue == false && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId) && this.pipe.transform(x.FirstIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe));
              this.activereqcnt = data.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.Response == "" && x.IsApprovedIssuePlannedOverdue == false && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId) && this.pipe.transform(x.FirstIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe)).length;
              this.filterCssClass = "responseduetoday";
            } else if (statusname == "Closeout Due Today") {
              this.dsactivereqs = data.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.Response != "" && x.IsApprovedIssuePlannedOverdue == false && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId) && this.pipe.transform(x.ApprovedIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe));
              this.activereqcnt = data.filter(x => (x.IsFirstIssuePlannedOverdue == false && x.Response != "" && x.IsApprovedIssuePlannedOverdue == false && (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId) && this.pipe.transform(x.ApprovedIssuePlanned, 'yyyy-MM-dd') == this.todayDatepipe)).length;
              this.filterCssClass = "closeoutduetoday";
            } else if (statusname == "Identified") {
              this.dsactivereqs = [];
              this.activereqcnt = 0;
              this.filterCssClass = "identified";
            }

          } else {
            this.dsactivereqs = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId));
            this.activereqcnt = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length;
            this.filterCssClass = statusname;
          }
          this.filter = statusname;
          this.isVisible = true;
          this.SpinnerService.hide('sp8');
        },
        error: err => {
          this.SpinnerService.hide('sp8');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide('sp8');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getActiveReq(contractorid: number, projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp8');
      }
      this.dashboardservice.getActiveReqs(contractorid, projectId, this.selectedModuleId).subscribe({
        next: dsactivereqs => {
          this.dsactivereqs = dsactivereqs;
          this.activereqcnt = dsactivereqs.length;
          if (dsactivereqs.length > 0) {
            this.isData = true;
            this.filter = "";
            this.filterCssClass = "";
          }

          this.SpinnerService.hide('sp8');
          this.isVisible = true;
        },
        error: err => {
          this.SpinnerService.hide('sp8');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide('sp8');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToViewScreen(id: number, moduleId: number) {
    try {
      this.communicationService._interfaceId = id;
      if (moduleId == Number(myGlobals.InterfaceModule)) {
        this.router.navigateByUrl('/InterfaceView/' + id);
      } else {
        this.router.navigateByUrl('/TechnicalQueryView/' + id);
      }

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToEditScreen(id: number, moduleId: number) {
    try {
      this.communicationService._interfaceId = id;
      if (moduleId == Number(myGlobals.InterfaceModule)) {
        this.router.navigateByUrl('/InterfaceEdit/' + id);
      } else {
        this.router.navigateByUrl('/TechnicalQueryEdit/' + id);
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToActionItem(MoMId: number, Id: number) {
    try {
      this.communicationService._interfaceId = MoMId;
      let MinutesofMeetingEditUrl = "MinutesofMeetingEdit/" + MoMId;
      this.communicationService._actionItemId = Id;
      this.router.navigateByUrl(MinutesofMeetingEditUrl);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  refreshCardsData() {
    this.getStatusWiseCount(this.selectedModuleId);
  }

  refreshchart() {
    this.getchartdata(this._contractorID, this._projectID);
  }

  refreshTasks() {
    if (this._roleID == Number(myGlobals.GatekeeperRoleId)) {
      this.getGateKeeperTasks(this._contractorID, this._projectID);
    }
    else {
      this.getTasks(this._contractorID, this._projectID);
    }

  }

  refreshActiveReqs() {
    this.getActiveReq(this._contractorID, this._projectID);
  }

  taskClickFilter(valueEmitted: any, ele: string) {
    try {
      this.SpinnerService.show('sp6');
      const activereqEle = document.getElementById(ele) as HTMLInputElement;
      activereqEle.scrollIntoView();
      if (this._roleID == Number(myGlobals.GatekeeperRoleId)) {
        this.getGateKeeperTasks(this._contractorID, this._projectID);
      }
      else {
        this.getTasks(this._contractorID, this._projectID);
      }
      this.SpinnerService.hide('sp6');
    } catch (e) {
      this.SpinnerService.hide('sp6');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  statusClickFilter(valueEmitted: any, ele: string) {
    try {
      this.SpinnerService.show('sp8');
      var value = valueEmitted.split("|")[0];
      var type = valueEmitted.split("|")[1];
      const activereqEle = document.getElementById(ele) as HTMLInputElement;
      activereqEle.scrollIntoView();
      this.dashboardservice.getActiveReqsbyStatus(value, this._contractorID, this._projectID, type, this.selectedModuleId).subscribe({
        next: data => {
          if (value == myGlobals.ResponseOverdueStatusName) {
            this.dsactivereqs = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
              ((x.IsFirstIssuePlannedOverdue == true && x.Response != "" && x.Status == myGlobals.AwaitingForResponseApprovalStatusId))));
            this.activereqcnt = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
              ((x.IsFirstIssuePlannedOverdue == true && x.Response != "" && x.Status == myGlobals.AwaitingForResponseApprovalStatusId)))).length;
          }
          else if (value == myGlobals.CloseoutOverdueStatusName) {
            this.dsactivereqs = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId));
            this.activereqcnt = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length;
          }
          else if (value == myGlobals.OpenStatusName) {
            this.dsactivereqs = data.filter(x => x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false);
            this.activereqcnt = data.filter(x => x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length;
          }
          else {
            this.dsactivereqs = data.filter(x => x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false);
            this.activereqcnt = data.filter(x => x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length;
          }
          this.filter = value;
          this.filterCssClass = value;
          this.SpinnerService.hide('sp8');
        },
        error: err => {
          this.SpinnerService.hide('sp8');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide('sp8');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  removefilter(filter: any) {
    this.getActiveReq(this._contractorID, this._projectID);
    this.filter = "";
    this.filterCssClass = "";
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  collapsed(name: any) {
    if (name == 'chart')
      this.isChartCollapsed = !this.isChartCollapsed;
    else {
      this.isTaskCollapsed = !this.isTaskCollapsed;
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  populateDashboard(moduleId: number, moduleName: string) {
    try {
      this.getStatusWiseCount(moduleId);
      this.selectedModuleId = moduleId;
      this.cardHeaderLabel = moduleName;

      this.refreshTasks();
      this.getActiveReq(this._contractorID, this._projectID);
      this.getchartdata(this._contractorID, this._projectID);
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          moduleProjectMappings.forEach(x => {
           var a= x.Module?.ModulePermissionMappings?.filter(v=>v.IsProjectWise)
            var permissionindex = this._rolePermissions.indexOf(a![0].Permission?.Name!);
            if (permissionindex == -1) {
              x.IsDeleted = true;
            }
          });
          this.moduleProjectMappings = moduleProjectMappings.filter(x => !x.IsDeleted);
          let defaultModule = moduleProjectMappings.filter(x => !x.IsDeleted);
          this.selectedModuleId = defaultModule[0].ModuleId;
          this.cardHeaderLabel = defaultModule[0].Module?.Name!;
          this.getStatusWiseCount(this.selectedModuleId);
          if (this._roleID == Number(myGlobals.GatekeeperRoleId)) {
            this.getGateKeeperTasks(this._contractorID, this._projectID);
          } else {
            this.getTasks(this._contractorID, this._projectID);
          }

          this.getActiveReq(this._contractorID, this._projectID);
          this.getchartdata(this._contractorID, this._projectID);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
