<div class="container-fluid" *ngIf="!isAuthenticated">
  <nav class="navbar navbar-expand-lg navbar-light nav_padding">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">WEB INTERFACE REGISTER</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!--<li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li>-->
        </ul>
        <form class="d-flex">
          <button mat-button *ngIf="false" class="btn me-2">Register</button>
          <button mat-button class="btn me-2" (click)="login()">Sign In</button>
        </form>
      </div>
    </div>
  </nav>
  <div class="Section1">
    <div class="welcome_text">
      <h1>Welcome to</h1>
      <h1>Web Interface Register</h1>
    </div>
    <div class="tour">
      <button mat-button class="btn btn-lg me-2">
        Take Tour
      </button>
      <mat-icon class="login_welcome_icon" matSuffix>arrow_forward_ios</mat-icon>
    </div>
    <div class="contactus">
      <button mat-button class="btn btn-lg me-2">
        Contact Us
      </button>
      <mat-icon class="login_welcome_icon" matSuffix>arrow_forward_ios</mat-icon>
    </div>
    <div class="productinfo">
      <button mat-button class="btn btn-lg me-2">Product Information</button>
      <mat-icon class="login_welcome_icon" matSuffix>arrow_forward_ios</mat-icon>
    </div>
  </div>
  <div class="Section2">
    <div class="containers">
      <div class="card mt-5 mb-5">
        <div class="row g-0">
          <div class="col-md-4">
            <img src="{{imageUrl}}People.jpg" class="img-fluid rounded-start" alt="...">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">What?</h5>
              <p class="card-text">Web Interface Register is a specialized product management tool to keep track of and document all interface information exchange between all participants on a project.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Section3">
    <div class="containers">
      <div class="card mt-5 mb-5">
        <div class="row g-0">
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Who?</h5>
              <p class="card-text">Web Interface Register can be used on any type of project that has the needs to exchange interface information, both internally and externally.</p>
            </div>
          </div>
          <div class="col-md-4">
            <img src="{{imageUrl}}Platform.jpg" class="img-fluid rounded-start" alt="...">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="Section4">
    <div class="containers">
      <div class="card mt-5 mb-5">
        <div class="row g-0">
          <div class="col-md-4">
            <img src="{{imageUrl}}FPSO.jpg" class="img-fluid rounded-start" alt="...">
          </div>
          <div class="col-md-8">
            <div class="card-body">
              <h5 class="card-title">Why?</h5>
              <p class="card-text">Helps identify issues with potential cost & schedule impact and gives the project a cost effective status assessment.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="Footer" *ngIf="!isAuthenticated">
  <div class="wrapper">
    <ul class="Footer__list">
      <li class="Footer__logo"><a href="/"><span class="visuallyHidden">Kongsberg</span></a></li>
      <!--<li class="Footer__listItem">
        <h2 class="Footer__heading">Maritime</h2>

        <ul>
          <li>
            <a href="/maritime/products/">Products</a>
          </li>
          <li>
            <a href="/maritime/solutions/">Solutions</a>
          </li>
          <li>
            <a href="/maritime/services/">Services</a>
          </li>
          <li>
            <a href="/maritime/support/">Support</a>
          </li>
          <li>
            <a href="/maritime/contact/">Contact</a>
          </li>
          <li>
            <a href="/maritime/the-full-picture-magazine/">The Full Picture Magazine</a>
          </li>
          <li>
            <a href="/maritime/about-us/">About us</a>
          </li>
        </ul>

      </li>
      <li class="Footer__listItem">
        <h2 class="Footer__heading">Follow us</h2>

        <ul>
          <li>
            <a href="https://www.facebook.com/KongsbergGruppen">Facebook</a>
          </li>
          <li>
            <a href="https://twitter.com/KOGMaritime">Twitter</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/kongsberg-maritime/">LinkedIn</a>
          </li>
          <li>
            <a href="https://www.youtube.com/user/kongsbergmaritime/featured">YouTube</a>
          </li>
        </ul>

      </li>
      <li class="Footer__listItem">
        <h2 class="Footer__heading">Legal</h2>

        <ul>
          <li>
            <a href="/no/privacy/">Privacy</a>
          </li>
          <li>
            <a href="/no/cookies/">Cookies</a>
          </li>
        </ul>

        <div class="Footer__text">
          © 2022 Kongsberg Maritime
        </div>
      </li>
      <li class="Footer__listItem">
        <h2 class="Footer__heading">
          <a class="Footer__headingLink" href="/">KONGSBERG</a>
        </h2>

        <ul>
          <li>
            <a href="/who-we-are/">Who we are</a>
          </li>
          <li>
            <a href="/what-we-do/">What we do</a>
          </li>
          <li>
            <a href="/our-commitment/">Our commitment</a>
          </li>
          <li>
            <a href="/careers/">Careers</a>
          </li>
          <li>
            <a href="/investor-relations/">Investors</a>
          </li>
          <li>
            <a href="/contact/">Contact</a>
          </li>
          <li>
            <a href="/newsandmedia/">News and Media</a>
          </li>
          <li>
            <a href="/kmagazine/">K-Magazine</a>
          </li>
        </ul>

      </li>-->
    </ul>
  </div>
</footer>
