import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Contractor } from '../app_data/contractor';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ContractorService {

  private apiUrl = environment.apiURl + 'contractors';

  constructor(private http: HttpClient) {
  }


  getContractorsByProjectId(projectId:number): Observable<Contractor[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor1InterfaceGroups($expand=InterfaceGroupTemplateMappings($filter=IsDeleted eq false;)),Contractor2InterfaceGroups($expand=InterfaceGroupTemplateMappings($filter=IsDeleted eq false;))&$filter=ProjectID eq " + projectId +" and IsCompany eq false and IsDeleted eq false &$orderby=Id", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getAllContractorsByProjectId(projectId: number): Observable<Contractor[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectID eq " + projectId + " and IsDeleted eq false &$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContractors(): Observable<Contractor[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=IsDeleted eq false &$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getContractor(id: number): Observable<Contractor[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Disciplines($filter=IsDeleted eq false),ContactContractorMappings($filter=IsDeleted eq false),Contractor2InterfaceGroups($filter=IsDeleted eq false;$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;)),Contractor1InterfaceGroups($filter=IsDeleted eq false;$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;))&$filter=Id eq " + id + " and IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getContractorByIdAndProjecId(id: number,projectId:number): Observable<Contractor[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Disciplines($filter=IsDeleted eq false),ContactContractorMappings($filter=IsDeleted eq false),Contractor2InterfaceGroups($filter=IsDeleted eq false;$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;)),Contractor1InterfaceGroups($filter=IsDeleted eq false;$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;))&$filter=Id eq " + id + " and ProjectId eq "+projectId+" and IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Contractor): Observable<Contractor> {
    return this.http.post<Contractor>(this.apiUrl, model, httpOptions);
  }
  put(model: Contractor): Observable<Contractor> {
    return this.http.put<Contractor>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
