<div class="container-fluid">
  <mat-card class="">
    <!--<mat-card-title>
      <p class="pageheader">{{pageTitle}}</p>
    </mat-card-title>-->
    <mat-card-content>
      <form [formGroup]="form" class="interfaceTab">

        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab labelcontractor label="Contractor">
            <div class="h500tabMin">
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Name</mat-label>
                    <input matInput maxlength="50" inputmode="tel" placeholder="Contractor name" (keypress)="onKeypressEvent($event)" [(ngModel)]="contractor.Name" formControlName="Name" #nameInput appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Name'))?.errors?.required">
                      Contractor Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Code</mat-label>
                    <input matInput maxlength="30" inputmode="tel" placeholder="Contractor code" [(ngModel)]="contractor.Code" formControlName="Code" #codeInput appTrimWhitespace>
                    <mat-error *ngIf="(form.get('Code'))?.errors?.required">
                      Contractor Code is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Contact Person</mat-label>
                    <input matInput maxlength="50" inputmode="tel" placeholder="Contact Person Name" [(ngModel)]="contractor.ContactPerson" formControlName="ContactPerson" #contactpersonInput appTrimWhitespace>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput type="email" maxlength="50" inputmode="tel" formControlName="EmailId" [(ngModel)]="contractor.EmailId" placeholder="Your email" #emailInput appTrimWhitespace>
                    <mat-error *ngIf="(form.get('EmailId'))?.errors?.pattern">Please enter valid Email</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Phone Number</mat-label>
                    <input matInput maxlength="15" inputmode="numeric" placeholder="Phone Number" [(ngModel)]="contractor.PhoneNumber" formControlName="PhoneNumber" #phonenumberInput>
                    <mat-error *ngIf="(form.get('PhoneNumber'))?.errors?.pattern">Please enter valid Phone Number</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Address:</mat-label>
                    <textarea matInput maxlength="150" placeholder="address" [(ngModel)]="contractor.Address" formControlName="Address" appTrimWhitespace></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>City</mat-label>
                    <input matInput maxlength="50" inputmode="tel" placeholder="Your City" [(ngModel)]="contractor.City" formControlName="City" #cityInput appTrimWhitespace>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Country</mat-label>
                    <input matInput maxlength="50" inputmode="tel" placeholder="Your Country" [(ngModel)]="contractor.Country" formControlName="Country" #countryInput appTrimWhitespace>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab labeldiscipline label="Discipline">
            <div class="h500tabMin">
              <app-discipline (disciplineadded)="disciplineaddedHandler($event)"></app-discipline>
            </div>
          </mat-tab>
          <mat-tab labelinterfacegroup label="Interface Group">
            <div >
              <app-interfacegroup (interfaceGroupAdded)="interfaceGroupAddedHandler($event)"></app-interfacegroup>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button (click)="save()" class="WIRbutton WIRbutton-primary" [disabled]="this.form.invalid" type="submit">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
            <button mat-stroked-button #btnAddDocument type="button" (click)="onCancel()" class="WIRbutton WIRbutton-secondary">
              <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
