import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { Status } from '../app_data/status';
import { environment } from 'src/environments/environment';
import { WorkflowTemplate } from '../app_data/workflowTemplate';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class WorkflowTemplateService {

  private apiUrl = environment.apiURl + 'WorkflowTemplates';

  constructor(private http: HttpClient) {

  }

  getWorkflowTemplateByModuleId(moduleId: number): Observable<WorkflowTemplate[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=WorkflowMappings($filter=ModuleId eq " + moduleId +" and IsDeleted eq false)")
      .pipe(
        map((data) => {
          return data.value.filter(x => x.WorkflowMappings.length > 0);
        }),
        catchError(this.handleError)
      );
  }

  post(model: Status): Observable<Status> {
    return this.http.post<Status>(this.apiUrl, model, httpOptions);
  }

  put(model: Status): Observable<Status> {
    return this.http.put<Status>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
