import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Discussion } from 'src/app/app_data/discussion';
import { Interface } from 'src/app/app_data/interface';
import { DialogService } from 'src/app/services/dialog.service';
import { DiscussionService } from 'src/app/services/discussion.service';
import * as myGlobals from '../../../app_data/globals';

let Discussionlists: Discussion[] = [];

@Component({
  selector: 'app-discussion-view',
  templateUrl: './discussion-view.component.html',
  styleUrls: ['./discussion-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DiscussionViewComponent implements OnInit {
  discussionsForm !: FormGroup;
  noRecordFound: string = myGlobals.NoRecordFound;
  dateTimeFormat: string = "";
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  dpColorTheme: string = "theme-dark-blue";
  errorMessage = '';
  @Input() interfaceId!: number;
  displayedColumns: string[] = ['Comment', 'CommentBy', 'CreatedDate'];
  discussiondataSource = new MatTableDataSource<Discussion>(Discussionlists);
  @ViewChild('sortdiscussion') sortdiscussion !: MatSort;
  @ViewChild('paginatordiscussion') paginatordiscussion !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortdiscussion = ms;
    this.discussiondataSource.paginator = this.paginatordiscussion;
    this.discussiondataSource.sort = this.sortdiscussion;
  }
  @Output() discussionadded: EventEmitter<Discussion[]> = new EventEmitter();

  interface !: Interface;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;

  constructor(private dialog: DialogService,
    private logger: NGXLogger,
    private SpinnerService: NgxSpinnerService,
    private discussionService: DiscussionService
    ) { 
      this.interface = new Interface();
    }

  ngOnInit(): void {   
    try {
      this.discussionsForm = new FormGroup({
        Comment: new FormControl('', [Validators.required])      
      });

      if(this.interfaceId > 0){
        this.getDiscussionByInterfaceId(this.interfaceId);
      }

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getDiscussionByInterfaceId(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.discussionService.getDiscussionsByInterfaceId(interfaceId).subscribe(
        {
          next: allDiscussions => {
            this.discussiondataSource.data = allDiscussions;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
