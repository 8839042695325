import { Module } from "./module";
import { Stage } from "./stage";
import { UserRoleProject } from "./userroleproject";

export class EmailSubscription {
  Id !: number;
  ContactId !: number;
  InterfaceGroupId?: number;
  StageId?: number;
  ModuleId?: number;
  DisciplineId ?: number;
  ContractorId !: number;
  SubscriptionFlag!: string;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  IsDeleted: boolean = false;
  Stage?: Stage;
  Module?: Module;
  InterfaceGroup?: any;
  Contact?: any;
  Discipline?: any;
  Contractor?: any;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: any;
}
