import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInvitationModule } from '../../shared/components/user-invitation/user-invitation.module';
import { InvitationComponent } from './invitation.component';
import { MaterialModule } from '../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvitationCreateComponent } from './invitation-create.component';
import { InvitationListComponent } from './invitation-list.component';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';



@NgModule({
  declarations: [InvitationComponent, InvitationCreateComponent, InvitationListComponent],
  imports: [
    CommonModule,
    MaterialModule, FormsModule,ReactiveFormsModule,
    UserInvitationModule,CommonDirectivesModule
  ]
})
export class InvitationModule { }
