import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { ActionItem } from '../app_data/actionItem';
import { CommonFunction } from '../app_data/commonFunction';
import { DsTasks } from '../app_data/dstasks';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ActionItemService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'actionitems';

  sortColumns = [
    { "DisplayName": "Newest First", "Value": "NewestFirst" },
    { "DisplayName": "Oldest First", "Value": "OldestFirst" },
  ];

  statusList = [{ Id: 1, Name: "Open" }, { Id: 2, Name: "Closed" }]

  constructor(private http: HttpClient) {
  }

  convertUTCDatesToTimezonedate(data: ActionItem[]){              
    // convert all dates to timezone datetime        
    data.forEach(x=> {       
      x.ClosedDate = x.ClosedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.ClosedDate, this._projectTimeZone, false) : null!;
      x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;
    })
    
    return data;
}


  getActionItemswithAdditionalFilters(pageIndex: number, pageSize: number, filter: string): Observable<ActionItem[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=MOM,InterfaceGroup,ResContractor" + filter + "&$count=true&$top=" + pageSize + "&$skip=" + pageIndex * pageSize)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getActionItems(filter: string): Observable<ActionItem[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=MOM,InterfaceGroup,ResContractor,CreatedUserRoleMapping($expand=User)" + filter)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getActionItemById(Id: number): Observable<ActionItem[]> {
    return this.http.get<response>(this.apiUrl + "?$filter= Id eq " + Id +" and IsDeleted eq false &$expand=InterfaceGroup,ResContractor")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getActionItemByResponsibleContractorId(Id: number, moduleId: number): Observable<DsTasks[]> {
    return this.http.get<response>(this.apiUrl + "?$select=Id,Title,CreatedDate,ResponsibleContractorID,Status,MOMId,ActionDate&$filter= ResponsibleContractorId eq " + Id + " and MOM/ModuleId eq " + moduleId + " and Status eq 1 and IsDeleted eq false &$expand=InterfaceGroup,ResContractor")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getActionItemStatus() {
    return this.statusList;
  }

  getSortColumnList() {
    return this.sortColumns;
  }

  post(model: ActionItem): Observable<ActionItem> {
    return this.http.post<ActionItem>(this.apiUrl, model, httpOptions);
  }
  put(model: ActionItem): Observable<ActionItem> {
    return this.http.put<ActionItem>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
