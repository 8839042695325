import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommunicationService } from '../../services/communication.service';
import { DialogService } from '../../services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactService } from 'src/app/services/contact.service';
import { Contact } from 'src/app/app_data/contact';
import { ContactContractorMappingsService } from 'src/app/services/contactContractorMappings.service';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { CommonFunction } from 'src/app/app_data/commonFunction';

let ContactLists: Contact[] = [];

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactListComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  errorMessage = '';
  displayedColumns: string[] = ['FirstName', 'LastName', 'Email','RegisterUser', 'Phone', 'Action'];
  contactdataSource = new MatTableDataSource<Contact>(ContactLists);
  firstNameFilter = new FormControl('');
  lastNameFilter = new FormControl('');
  contractorNameFilter = new FormControl('');
  emailFilter = new FormControl('');
  filterValues = {
    FirstName: '',
    LastName: '',
    Email: '',
    Phone: '',
    Name: ''
  };


  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  @Input() contractorId!: number;
  hidecolumn: boolean = false;

  @ViewChild('sortContact') sortContact !: MatSort;
  @ViewChild('paginatorContact') paginatorContact !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortContact = ms;
    this.contactdataSource.paginator = this.paginatorContact;
    this.contactdataSource.sort = this.sortContact;
    this.contactdataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(private router: Router,
    private communicationService: CommunicationService, private dialog: DialogService,
    private logger: NGXLogger, private SpinnerService: NgxSpinnerService,
    private contactContractorService: ContactContractorMappingsService,
    private snackBar: MatSnackBar,
    private contactService: ContactService) {
    this.contactdataSource.filterPredicate = this.createFilter();
  }

  sortingDataAccessor(item: any, property: any) {
    try {
      if (property.includes('.')) {
        return item.ContactContractorMappings[0].Contractor_User?.Name;
        //return property.split('.')
        //  .reduce((object: { [x: string]: any; }, key: string | number) => {
        //    object[key], item
        //  });
      }
      return item[property];
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ngOnInit(): void {
    if (this.contractorId > 0) {
      this.hidecolumn = true;
      this.displayedColumns = ['FirstName', 'LastName', 'Email', 'RegisterUser', 'Phone'];
      this.getContactsByContractor(this.contractorId);
    } else {
      this.hidecolumn = false;
      this.getContacts();
    }
    this.firstNameFilter.valueChanges
      .subscribe(
        firstName => {
          this.filterValues.FirstName = firstName.toLowerCase();
          this.contactdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.lastNameFilter.valueChanges
      .subscribe(
        lastName => {
          this.filterValues.LastName = lastName.toLowerCase();
          this.contactdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.contractorNameFilter.valueChanges
      .subscribe(
        ContractorName => {
          this.filterValues.Name = ContractorName.toLowerCase();
          this.contactdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
    this.emailFilter.valueChanges
      .subscribe(
        email => {
          this.filterValues.Email = email.toLowerCase();
          this.contactdataSource.filter = JSON.stringify(this.filterValues);
        }
      )
  }

  redirecttoadd() {
    this.router.navigateByUrl('/Contact');
  }

  getContacts() {
    try {
      this.SpinnerService.show();
      this.contactService.getContactsByProjectId(this._projectID).subscribe(
        {
          next: allContacts => {
            this.contactdataSource.data = allContacts.filter(x => x.ContactContractorMappings.length > 0);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getContactsByContractor(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.contactService.getContactsByContractor(contractorid).subscribe(
        {
          next: allContacts => {
            this.contactdataSource.data = allContacts.filter(a => a.ContactContractorMappings.length > 0);
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        }
      )
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  createFilter(): (data: any, filter: string) => boolean {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.FirstName.toLowerCase().indexOf(searchTerms.FirstName) !== -1
        && data.LastName?.toLowerCase().indexOf(searchTerms.LastName) !== -1
        && data.Email?.toLowerCase().indexOf(searchTerms.Email) !== -1
        && data.ContactContractorMappings[0].Contractor.Name.toLowerCase().indexOf(searchTerms.Name) !== -1;
    }
    return filterFunction;
  }

  delete(contactId: number, contactContractorMappingId: number) {
    try {
      this.SpinnerService.show();
      let contactModel = this.contactdataSource.data.filter(a => a.Id == contactId);
      contactModel[0].IsDeleted = true;
      contactModel[0].ModifiedBy = this._userIdLogin;

      let contactContractorMappingsData = contactModel[0].ContactContractorMappings.filter(c => c.Id == contactContractorMappingId);
      contactContractorMappingsData[0].IsDeleted = true;
      contactContractorMappingsData[0].ModifiedBy = this._userIdLogin;

      this.contactContractorService.getContactsByProjectId(contactId, this._projectID).subscribe({
        next: contacts => {
          if (contacts.length > 0) {
            if (contacts.length > 1) {
              this.contactContractorService.put(contactContractorMappingsData[0]).subscribe({
                next: userDataUpdated => {
                  this.SpinnerService.hide();
                  this.snackBar.openFromComponent(SnackbarComponent, {
                    data: "Contact deleted successfully!",
                    duration: myGlobals.snackBarDuration,
                    verticalPosition: myGlobals.snackBarVerticalPosition,
                    horizontalPosition: myGlobals.snackBarHorizontalPosition
                  });
                  this.getContacts();
                },
                error: err => {
                  this.SpinnerService.hide();
                  this.errorMessage = err
                  this.dialog.confirmDialog({
                    title: 'Error',
                    message: myGlobals.exceptionmessage,
                    cancelCaption: 'Close',
                  });
                  this.logger.error(err);
                }
              });
            }
            else {
              this.contactService.put(contactModel[0]).subscribe(
                {
                  next: userDataUpdated => {
                    this.SpinnerService.hide();
                    this.snackBar.openFromComponent(SnackbarComponent, {
                      data: "Contact deleted successfully!",
                      duration: myGlobals.snackBarDuration,
                      verticalPosition: myGlobals.snackBarVerticalPosition,
                      horizontalPosition: myGlobals.snackBarHorizontalPosition
                    });
                    this.getContacts();
                  },
                  error: err => {
                    this.SpinnerService.hide();
                    this.errorMessage = err
                    this.dialog.confirmDialog({
                      title: 'Error',
                      message: myGlobals.exceptionmessage,
                      cancelCaption: 'Close',
                    });
                    this.logger.error(err);
                  }
                }
              );
            }
          }
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  edit(Id: number) {
    try {
      let ContactEditUrl = "ContactEdit/" + Id;
      this.router.navigateByUrl(ContactEditUrl);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(Id: number, roleId: number) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete contact from this contractor?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(Id, roleId);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

}
