<div class="row h500tabMin">
  <div class="container-fluid body-wrapper">
    <div class="row">
      <div class="col-lg-12">
        <mat-paginator #paginatordiscussion [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
        </mat-paginator>
        <div class="table-responsive scroller">
          <table mat-table [dataSource]="discussiondataSource" class="mat-elevation-z8" matSort #sortdiscussion="matSort">
            <ng-container matColumnDef="CommentBy">
              <th mat-header-cell *matHeaderCellDef class="table_header_size">Comment By</th>
              <td mat-cell *matCellDef="let disc"> {{disc.Contractor != null ? (disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName + ' ('+ disc.Contractor.Name+')' : this._userName) : disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName : this._userName}} </td>
            </ng-container>
            <ng-container matColumnDef="Comment">
              <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Comment </th>
              <td mat-cell *matCellDef="let disc"> {{disc.Comment}} </td>
            </ng-container>
            <ng-container matColumnDef="CreatedDate">
              <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Date/Time </th>
              <td mat-cell *matCellDef="let disc">
                {{disc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{disc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                <span><br />{{noRecordFound}}</span>
              </td>
            </tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
