import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CommunicationService } from './services/communication.service';
import { DialogService } from './services/dialog.service';

@Component({
  selector: 'pm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'WIRUI';
  idleState = 'Not started.';
  timedOut = false;
  lastPing!: Date;
  isAuthenticated: boolean = false;
  constructor(private authService: MsalService, private communicationService:CommunicationService,
    private dialog: DialogService) {
    //this.communicationService.isAuthenticatedvalue$.subscribe(emivalue => {
    //  this.isAuthenticated = Boolean(emivalue);
    //  if (this.isAuthenticated) {
    //    let idleTimeout = sessionStorage.getItem('sessionTimeout');
    //    let defaultTimeout = Number(idleTimeout) * 60; //minutes
    //    // sets an idle timeout of 5 seconds, for testing purposes.
    //    this.idle.setIdle(defaultTimeout);
    //    // sets a timeout period of 60 seconds. after defalut time + 60 seconds of inactivity, the user will be considered timed out.
    //    this.idle.setTimeout(60);
    //    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    //    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    //    this.idle.onIdleEnd.subscribe(() => this.idleState = 'No longer idle.');
    //    this.idle.onIdleStart.subscribe(() => this.idleState = 'You\'ve gone idle!');
    //    this.idle.onTimeoutWarning.subscribe((countdown) => {
    //      this.idleState = 'You will time out in ' + countdown + ' seconds!'
          
    //    });
    //    this.idle.onTimeout.subscribe(() => {
    //      this.idleState = 'Timed out!';
    //      this.dialog.confirmDialog({
    //        title: 'Warning!!',
    //        message: this.idleState,
    //        cancelCaption: 'Close',
    //      });
    //      this.timedOut = true;
    //      this.authService.logout();
    //    });
    //    // sets the ping interval to 15 seconds
    //    this.keepalive.interval(15);

    //    this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    //    this.reset();
    //  }
    //})
    
    
  }

  //reset() {
  //  this.idle.watch();
  //  this.idleState = 'Started.';
  //  this.timedOut = false;
  //}
}
