import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StandardReportComponent } from './standard-report.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SummaryReportComponent } from './summary-report.component';
import { PdfGenerateModule } from '../pdf-generate/pdf-generate.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';



@NgModule({
  declarations: [StandardReportComponent, SummaryReportComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PdfGenerateModule,
    CommonDirectivesModule,
    NgApexchartsModule
  ],
  exports: [StandardReportComponent, SummaryReportComponent]
})
export class ReportsModule { }
