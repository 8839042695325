export class MinutesOfMeetingExcel {
  Title: string = "";
  InterfaceGroup: string = "";
  Module: string = "";
  MeetingDescription: string = "";
  MeetingLocation: string = "";
  MeetingDate: string = "";
  NextMeetingDate: string = "";
  NextMeetingLocation: string = "";
  Contractor1: string = "";
  Contractor2: string = "";
  SignedByContractor1User: string = "";
  SignedByContractor2User: string = "";
  SignedByContractor1Date: string = "";
  SignedByContractor2Date: string = "";
  Status: string = "";
  CreatedOn: string = "";
  CreatedBy: string = "";
}
