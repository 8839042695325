import { Contractor } from "./contractor";
import { QueryType } from "./queryType";
import { User } from "./user";

export class TechnicalQuery {
  Id?: number;
  InterfaceId?: number;
  IsConfidential?: boolean;
  ConfidentialJustification !: string;
  PriorityJustification !: string;
  QueryTypeId?: number;
  RouteToUserId?: number;
  RouteToDateTime?: Date;
  SharedToContractorId?: number;
  IsResponseBySharedContractor?: boolean;
  IsDeleted?: boolean;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  SharedToContractor?: Contractor;
  QueryType?: QueryType;
  RouteToUser?: User;
}
