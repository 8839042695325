import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { RolePermission } from '../../app_data/rolepermission';
import { DialogService } from '../../services/dialog.service';
import { RoleService } from '../../services/role.service';
import * as myGlobals from '../../app_data/globals';
import { Role } from '../../app_data/role';
import { Permission } from '../../app_data/permission';
import { PermissionService } from '../../services/permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';

@Component({
  selector: 'app-role-permission-configuration',
  templateUrl: './role-permission-configuration.component.html',
  styleUrls: ['./role-permission-configuration.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RolePermissionConfigurationComponent implements OnInit {
  form !: FormGroup;
  rolePermission !: RolePermission;
  userRoles: Role[] = [];
  role!: Role;
  permissions: Permission[] = [];
  selectedRoleId: number = 0;
  selectedPermissionIds: number[] = [];

  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private roleService: RoleService, private SpinnerService: NgxSpinnerService, private snackBar: MatSnackBar,
    private logger: NGXLogger, private dialog: DialogService, private permissionService: PermissionService) {
    this.rolePermission = new RolePermission();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      roleFormControl: new FormControl([Validators.required]),
      permissionFormControl: new FormControl([Validators.required]),
    });
    this.getRoles();
    this.getPermissions();
  }

  getRoles() {
    try {
      this.SpinnerService.show();
      this.roleService.getRoles().subscribe({
        next: rolesData => {
          if (rolesData.length > 0) {
            this.userRoles = rolesData;
            this.SpinnerService.hide();
          }
          this.SpinnerService.hide();
        }
      })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getPermissions() {
    try {
      this.SpinnerService.show();
      this.permissionService.getPermissions().subscribe({
        next: permissionsData => {
          if (permissionsData.length > 0) {
            this.permissions = permissionsData;
            this.SpinnerService.hide();
          }
          this.SpinnerService.hide();
        }
      })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onRoleChange(selectedRoleId: number) {
    try {
      this.SpinnerService.show();
      this.roleService.getRoleById(selectedRoleId).subscribe({
        next: roleData => {
          if (roleData.length > 0) {
            this.role = roleData[0];
            this.selectedPermissionIds = [];
            roleData[0].RolePermissions.forEach(x => {
              this.selectedPermissionIds.push(x.PermissionId);
            });
            this.SpinnerService.hide();
          }
          this.SpinnerService.hide();
        }
      })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  save() {
    try {
      if (this.form.valid) {
        this.SpinnerService.show();
        if (this.role.RolePermissions.length > 0) {
          // add new role
          for (var i = 0; i < this.selectedPermissionIds.length; i++) {
            var index = this.role.RolePermissions.findIndex(x => x.PermissionId == this.selectedPermissionIds[i]);
            if (index == -1) {
              this.role.RolePermissions.push({ Id: 0, PermissionId: this.selectedPermissionIds[i], RoleId: 0, IsDeleted: false, CreatedBy: this._userRoleMappingId })
            }
          }
          //remove if any role removed
          this.role.RolePermissions.forEach(s => {
            for (var i = 0; i < this.selectedPermissionIds.length; i++) {
              var index = this.selectedPermissionIds.indexOf(s.PermissionId)
              if (index == -1) {
                s.IsDeleted = true;
                s.ModifiedBy = this._userRoleMappingId;
              }
            }
          });
          this.roleService.put(this.role).subscribe({
            next: data => {
              this.SpinnerService.hide();
              this.form.reset();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Permissions mapped to role successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
            }
          })
        }
        else {
          // add new role
          for (var i = 0; i < this.selectedPermissionIds.length; i++) {
            this.role.RolePermissions.push({ Id: 0, PermissionId: this.selectedPermissionIds[i], RoleId: 0, IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }
          this.roleService.put(this.role).subscribe({
            next: data => {
              this.SpinnerService.hide();
              this.form.reset();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Permissions mapped to role successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
            }
          })
        }
      }
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
