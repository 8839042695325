export class UserRoleProject {
  Id !: number;
  ProjectId!: number;
  RoleId!: number;
  UserId!: number;
  ContractorId!: number;
  IsDefault!: boolean;
  IsDeleted !: boolean;
  Role?: any;
  Project?: any;
  User?: any;
  Contractor?: any;
}
