import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonDirective } from './back-button.directive';
import { MatchDateDirective } from './datemismatch.directive';
import { ActionIconsVisibilityDirective } from './actionIconsVisibility.directive';
import { DisableButtonOnSubmitDirective } from './debounce-click.directive ';
import { ConfirmBackButtonDirective } from './confirm-back-button.directive';
import { TrimWhitespaceDirective } from './trim-whitespace.directive';



@NgModule({
  declarations: [BackButtonDirective, MatchDateDirective, ActionIconsVisibilityDirective, DisableButtonOnSubmitDirective, ConfirmBackButtonDirective, TrimWhitespaceDirective],
  imports: [
    CommonModule
  ],
  exports: [BackButtonDirective, MatchDateDirective, ActionIconsVisibilityDirective, DisableButtonOnSubmitDirective, ConfirmBackButtonDirective, TrimWhitespaceDirective]
})
export class CommonDirectivesModule { }
