import { Interface } from "./interface";
import { UserRoleProject } from "./userroleproject";


export class ProposedResponseHistory {
  Id !: number;
  InterfaceId!: number;
  ProposedResponse!: string;
  IsDeleted : boolean=false;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Interface?: Interface;
  CreatedUserRoleMapping?: UserRoleProject;
}
