import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SystemLog } from '../app_data/systemLog';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { CommonFunction } from '../app_data/commonFunction';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class SystemLogService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'systemlogs';

  systemLog !: SystemLog;

  constructor(private http: HttpClient) {

  }

  post(model: SystemLog): Observable<SystemLog> {
    return this.http.post<SystemLog>(this.apiUrl, model, httpOptions);
  }

  convertUTCDatesToTimezonedate(data: SystemLog[]){              
    // convert all dates to timezone datetime        
    data.forEach(x=> {
      x.CreateDate = x.CreateDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreateDate, this._projectTimeZone, false) : null!;
      
    })   
    
    return data;
}

  getSystemLog(projectId: number, interfaceId: number, filter: string): Observable<SystemLog[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=GeneratedUser,Contractor,Role,LogInterfaceId($expand=InterfaceGroup)&$filter=ProjectId eq " + projectId + " and InterfaceId eq " + interfaceId + filter + "&$orderby=CreateDate desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getSystemLogByProjectId(projectId: number, filter: string): Observable<SystemLog[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=GeneratedUser,Contractor,Role,LogInterfaceId($expand=InterfaceGroup)&$filter=ProjectId eq " + projectId + "" + filter + "&$orderby=CreateDate desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }
  

  writeLog(projectId: number, userId: number, module: string, systemFunction: string, description: string, contractorId?: number, roleId?: number, interfaceId?: number) {
    this.systemLog = new SystemLog();
    this.systemLog.UserId = userId;

    this.systemLog.Module = module;
    this.systemLog.SystemFunction = systemFunction;
    this.systemLog.Description = description;
    this.systemLog.InterfaceId = interfaceId;
    this.systemLog.ContractorId = contractorId;
    this.systemLog.RoleId = roleId;
    this.systemLog.ProjectId = projectId;
    this.post(this.systemLog).subscribe();
  }


  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
