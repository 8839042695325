import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
// Import MSAL and MSAL browser libraries. 
import {
  MsalModule,
  MsalService,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalGuard,
  MsalGuardConfiguration,
  MsalBroadcastService, 
  MsalRedirectComponent,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG
} from "@azure/msal-angular";
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

// Import the Azure AD B2C configuration 
import { msalConfig } from './auth-config';

// Import the Angular HTTP interceptor. 
import { HttpClientModule, HttpHeaders, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { AppRoutingModule } from './app-routing.module';
import { BodyComponent } from './modules/body/body.component';
import { LayoutComponent } from './modules/layout/layout.component';
import { MaterialModule } from './shared/material/material.module';
import { ProjectModule } from './modules/project/project.module';
import { ContractorModule } from './modules/contractor/contractor.module';
import { InterfaceModule } from './modules/interface/interface.module';
import { LoginModule } from './modules/login/login.module';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ContactModule } from './modules/contact/contact.module';
import { AppLayoutModule } from './modules/app-layout/app-layout.module';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { CustomizeErrorLogService } from './services/customizeerrorlog.service';
import * as myGlobals from '../app/app_data/globals';
import { UserprofileModule } from './modules/userprofile/userprofile.module';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { SubscriptionModule } from './modules/subscription/subscription.module';
import { InvitationModule } from './modules/invitation/invitation.module';
import { UnauthorizedAccessModule } from './shared/components/unauthorize/unauthorized-access.module';
import { BodyModule } from './modules/body/body.module';
import { SwitchroleModule } from './modules/switch-role/switchrole.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SnackbarComponent } from './shared/components/snackbar/snackbar.component';
import { UserModule } from './modules/user/user.module';
import { ReportModule } from './modules/report/report.module';
import { TechnicalQueryModule } from './modules/technicalquery/technicalquery.module';
import { TaketourModule } from './modules/taketour/taketour.module';
import { MinutesofmeetingModule } from './modules/minutesofmeeting/minutesofmeeting.module';
import { ActionlistModule } from './modules/actionlist/actionlist.module';
import { RiskModule } from './modules/risk/risk.module';
import { AppHeaderComponent } from './modules/layout/app-header.component';
import { RolePermissionConfigurationModule } from './modules/rolePermissionConfiguration/role-permission-configuration.module';
import { RoleModule } from './modules/role/role.module';
import { TokenInterceptor } from './interceptor/token.interceptor';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    /*BodyComponent,*/
    LayoutComponent,
    SnackbarComponent,
    AppHeaderComponent,  
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    LoggerModule.forRoot({
      serverLoggingUrl: environment.apiURl+'ErrorLogs', // Replace with YOUR API
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      disableConsoleLogging: false,
      enableSourceMaps: true,
      customHttpHeaders: new HttpHeaders({ 'Authorization': 'Bearer '+myGlobals.jwtToken })
    },
      {
        serverProvider: {
          provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: CustomizeErrorLogService
        }
      }
    ),
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    ProjectModule,
    ContractorModule,
    InterfaceModule,
    TechnicalQueryModule,
    LoginModule,
    DashboardModule,
    ContactModule,
    UserModule,
    MinutesofmeetingModule,
    ActionlistModule,
    UserprofileModule,
    SubscriptionModule,
    InvitationModule,
    SwitchroleModule,
    RoleModule,
    RolePermissionConfigurationModule,
    ReportModule,
    UnauthorizedAccessModule,
    TaketourModule,
    AppLayoutModule,
    BodyModule,
    LoginModule,
    NgxSpinnerModule,
    RiskModule,
    AppRoutingModule,
    MsalModule
   
  ],
  exports: [NgxSpinnerModule],
  providers: [
     {
       provide: HTTP_INTERCEPTORS,
       useClass: TokenInterceptor,
       multi: true
     },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent,
    MsalRedirectComponent]
})
export class AppModule { }
