import { ModuleProjectMapping } from "./moduleProjectMapping";
import { ProjectFieldsConfiguration } from "./projectFieldsConfiguration";
import { ProjectSetting } from "./projectSetting";
import { SafeDomainList } from "./safedomainlist";
import { UserRoleProject } from "./userroleproject";

export class Project {
  Id !: number;
  Name: string = "";
  Code: string = "";
  Status !: string;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  IsDeleted !: boolean;
  SupportMail: string = "";
  SupportPhone : string = "";
  MailSenderAddress : string = "";
  LogoPath : string = "";
  Settings !: ProjectSetting[];
  SafeDomainLists : SafeDomainList[]=[];
  UserRoleProjects: UserRoleProject[] = [];
  ModifiedUser?: any;
  ProjectFieldsConfigurations: ProjectFieldsConfiguration[] = [];
  ModuleProjectMappings: ModuleProjectMapping[] = [];
}
