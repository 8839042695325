<form [formGroup]="proposeForm">
  <div class="row">
    <div class="col-xl-8 col-l-8 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Propose Response</mat-label>
        <textarea matInput formControlName="ProposedResponse" name="ProposedResponse" [(ngModel)]="proposeresponseHistory.ProposedResponse" maxlength="3000" class="heightTextArea" required appTrimWhitespace></textarea>
        <mat-error *ngIf="this.proposeForm.get('ProposedResponse')?.hasError('required')">
          Propose Response is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <button mat-stroked-button class="WIRbutton WIRbutton-accept mt-2" (click)='onPropose()' [disabled]="this.proposeForm.invalid">
        <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Propose Reponse
      </button>
    </div>
  </div>
</form>

<div class="row">
  <div class="col-lg-12" *ngIf="proposeResponseDataSource.data.length > 0">
    <mat-paginator #paginatorproposeresponse [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="proposeResponseDataSource" class="mat-elevation-z8" matSort #sortproposeresponse="matSort">
        <ng-container matColumnDef="ProposedResponse">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed Response </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ProposedResponse}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed By </th>
          <td mat-cell *matCellDef="let disc"> {{ disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName +' - '+ disc.CreatedUserRoleMapping?.Contractor.Name+' - '+ disc.CreatedUserRoleMapping?.Role.Name: ''}}</td>
        </ng-container>
        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Proposed Date </th>
          <td mat-cell *matCellDef="let disc">{{disc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{disc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}</td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
