import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material/material.module';
import { SubscriptionsComponent } from './subscriptions.component';
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { EmailSubscriptionModule } from 'src/app/shared/components/email-subscription/email-subscription.module';
import { InterfaceModule } from '../interface/interface.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';

@NgModule({
  declarations: [
    SubscriptionsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonDirectivesModule,
    SubscriptionRoutingModule,
    EmailSubscriptionModule,
    InterfaceModule   
  ],
  exports: [SubscriptionsComponent]
})
export class SubscriptionModule { }
