import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmailSubscription } from '../app_data/emailSubscription';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  })
}

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {
  private apiUrl = environment.apiURl + 'EmailSubscriptionUsers';

  constructor(private http: HttpClient) {
  }

  getSubscriptions(): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup,User($expand=UserContractorMappings($expand=Contractor_User)),Discipline &$filter=IsDeleted eq false &$orderby=id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getSubscriptionsbyContractorId(contractorIds: string): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup,Contact($expand=ContactContractorMappings($filter=IsDeleted eq false;$expand=Contractor)),Contractor,Discipline, Module, Stage &$filter=IsDeleted eq false and ContractorId in (" + contractorIds +") &$orderby=CreatedDate desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getSubscriptionById(id: number): Observable<EmailSubscription[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=id eq " + id +"&$orderby=id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: EmailSubscription): Observable<EmailSubscription> {
    return this.http.post<EmailSubscription>(this.apiUrl, model, httpOptions);
  }

  put(model: EmailSubscription): Observable<EmailSubscription> {
    return this.http.put<EmailSubscription>(this.apiUrl + "/" + model.Id, model, httpOptions);

  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
