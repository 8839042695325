export class TechnicalQueryExcel {
  Number: string = "";
  Title: string = "";
  Description: string = "";
  CreatedOn: string = "";
  CreatedBy: string = "";
  /*RequestApprover: string = "";*/
  FirstResponseDate: string = "";
  FirstIssueActual: string = "";
  CloseOutDate: string = "";
  ApprovedIssueActual: string = "";
  ProviderFirstIssuePlanned: string = "";
  ProviderApprovedIssuePlanned: string = "";
  Comments: string = "";
  InterfaceGroup: string = "";
  RequestingContractor: string = "";
  ProviderContractor: string = "";
  Status: string = "";
  NextActionText: string = ""; 
  Response: string = "";
  RejectionComment: string = "";
  ReferenceForResponse: string = "";
  NotificationComments: string = "";
  CompletedDate: string = "";
  CompletedBy: string = "";
  Priority: string = "";
  IsConfidential: string = "";
  ConfidentialJustification: string = "";
  PriorityJustification: string = "";
  QueryType: string = "";
  RouteToUser: string="";
  RouteToDateTime: string="";
  SharedToContractor?: string;
  IsResponseBySharedContractor?: boolean;
  ProviderDiscipline: string = "";
  RequestingDiscipline: string = "";
}
