import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssignmentHistory } from '../../../app_data/assignmentHistory';
import * as myGlobals from '../../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../../services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import { AssignmentHistoryService } from '../../../services/assignmentHistory.service';

let Assignmentlists: AssignmentHistory[] = [];

@Component({
  selector: 'app-assignment-view',
  templateUrl: './assignment-view.component.html',
  styleUrls: ['./assignment-view.component.css']
})
export class AssignmentViewComponent implements OnInit {
  displayedColumns: string[] = ['AssignedToUser', 'ReasonOfAssignment', 'CreatedBy', 'CreatedDate'];
  assignmentDataSource = new MatTableDataSource<AssignmentHistory>(Assignmentlists);
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  @Input() interfaceId!: number;
  assignmentModel: AssignmentHistory[]=[];
  errorMessage!: string;

  @ViewChild('sortAssignment') sortAssignment !: MatSort;
  @ViewChild('paginatorAssignment') paginatorAssignment !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortAssignment = ms;
    this.assignmentDataSource.paginator = this.paginatorAssignment;
    this.assignmentDataSource.sort = this.sortAssignment;
  }
  constructor(private SpinnerService: NgxSpinnerService, private assignmentService: AssignmentHistoryService,
    private dialog: DialogService, private logger: NGXLogger ) { }

  ngOnInit(): void {
    if (this.interfaceId > 0) {
      this.getAssignmentsByInterfaceId(this.interfaceId);
    }
  }

  getAssignmentsByInterfaceId(interfaceId: number): void {
    try {
      this.SpinnerService.show();
      this.assignmentService.getAssignmentHistoryByInterfaceId(interfaceId).subscribe({
        next: (assignmentData) => {
          this.assignmentModel = assignmentData;
          this.assignmentDataSource.data = this.assignmentModel.sort().reverse();
          this.SpinnerService.hide();
        },
        error: (err: string) => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
