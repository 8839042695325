import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { Contractor } from '../app_data/contractor';
import { Project } from '../app_data/project';
import { User } from '../app_data/user';
import { Contact } from '../app_data/contact';
import { Document } from '../app_data/document';
import { SafeDomainList } from '../app_data/safedomainlist';
import { ReferenceLink } from '../app_data/referenceLink';
import { MinutesOfMeeting } from '../app_data/minutesOfMeeting';
import { InterfaceGroup } from '../app_data/interfaceGroup';
import { AssignmentHistory } from '../app_data/assignmentHistory';
import { ProposedResponseHistory } from '../app_data/proposedResponseHistory';
import { AdditionalInformationHistory } from '../app_data/additionalInformationHistory';
import { File } from '../app_data/file';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  @Output() change: EventEmitter<any> = new EventEmitter();
  ischecked = false;
  _interfaceId: number = 0;
  _actionItemId: number = 0;
  _interfaceGroupId: number = 0;
  _statusId: number[] = [];
  _filterDate?: Date;
  _Id: number = 0;
  _isCopyDocLinks: boolean = false;
  _isRevision: boolean = false;
  _isCopy: boolean = false;
  _isView: boolean = false;
  _isProposeDate: boolean = false;
  _isDocumentPresent: boolean = false;
  _isInterfacePresent: boolean = false;
  _isRevisionsPresent: boolean = false;
  _isActionItemPresent: boolean = false;
  _isMOMPresent: boolean = false;
  _contractorname!: string;
  private _checkedvalue = new Subject<string>();
  ischeckedvalue$ = this._checkedvalue.asObservable();

  private _InterfaceGrpSelectedvalue = new Subject<InterfaceGroup>();
  isInterfaceGrpSelected$ = this._InterfaceGrpSelectedvalue.asObservable();

  private _resetvalue = new Subject<string>();
  isresetvalue$ = this._resetvalue.asObservable();

  private _downloadPDF = new Subject<string>();
  isdownloadPDFvalue$ = this._downloadPDF.asObservable();

  private _downloadStandPDF = new Subject<string>();
  isdownloadStandPDFvalue$ = this._downloadStandPDF.asObservable();

  private _searchvalue = new Subject<string>();
  issearchvalue$ = this._searchvalue.asObservable();

  private _filepathvalue = new Subject<string>();
  isfilepathvalue$ = this._filepathvalue.asObservable();

  private _isAuthenticatedvalue = new Subject<string>();
  isAuthenticatedvalue$ = this._isAuthenticatedvalue.asObservable();

  private isRolenotassignedvalue = new Subject<string>();
  isRolenotassignedvalue$ = this.isRolenotassignedvalue.asObservable();

  public _contractor = new Subject<Contractor>();
  is_contractor$ = this._contractor.asObservable();

  public _mom = new Subject<MinutesOfMeeting>();
  is_mom$ = this._mom.asObservable();

  public _sendPDFContent = new Subject<any>();
  is_sendPDFContent$ = this._sendPDFContent.asObservable();

  public _project = new Subject<Project>();
  is_project$ = this._project.asObservable();

  public _profile = new Subject<Contact>();
  is_profile$ = this._profile.asObservable();

  public _documents = new Subject<Document[]>();
  is_documents$ = this._documents.asObservable();

  public _files = new Subject<File[]>();
  is_files$ = this._files.asObservable();

  public _assignments = new Subject<AssignmentHistory[]>();
  is_assignments$ = this._assignments.asObservable();

  public _additionalInfo = new Subject<AdditionalInformationHistory[]>();
  is_additionalInfo$ = this._additionalInfo.asObservable();

  public _proposeResponses = new Subject<ProposedResponseHistory[]>();
  is_proposeResponses$ = this._proposeResponses.asObservable();

  public _reflinks = new Subject<ReferenceLink[]>();
  is_reflinks$ = this._reflinks.asObservable();

  public _safedomainList = new Subject<SafeDomainList[]>();
  is_safedomainList$ = this._safedomainList.asObservable();

  public _user = new Subject<User>();
  is_user$ = this._user.asObservable();

  public _userdata = new Subject<User>();
  is_userdata$ = this._userdata.asObservable();


  public _addedvalue = new Subject<boolean>();
  is_addedvalue$ = this._addedvalue.asObservable();

  setEmailSubscriptiondata(value: any) {
    this._profile.next(value);
  }

  setProjectSettingsdata(value: any) {
    this._project.next(value);
  }

  setSafeDomainSettingsdata(value: any) {
    this._project.next(value);
  }

  setDomainListdata(value: any) {
    this._safedomainList.next(value);
  }

  setDiscipliendata(value: any) {
    this._contractor.next(value);
  }

  setDocumentsdata(value: any) {
    this._documents.next(value);
  }
  setFilesdata(value: any) {
    this._files.next(value);
  }

  setAssignmentsData(value: any) {
    this._assignments.next(value);
  }

  setAdditionalInformationData(value: any) {
    this._additionalInfo.next(value);
  }

  setProposedResponsesData(value: any) {
    this._proposeResponses.next(value);
  }

  setFilePathName(value: any) {
    this._filepathvalue.next(value);
  }

  setReferenceLinksdata(value: any) {
    this._reflinks.next(value);
  }

  setInterfacegrpdata(value: any) {
    this._contractor.next(value);
  }

  setactionitemdata(value: any) {
    this._mom.next(value);
  }

  setPDfContentdata(value: any) {
    this._sendPDFContent.next(value);
  }

  sendClickEvent(value: any) {
    this.ischecked = value;
    this._checkedvalue.next(value);
  }

  sendInterfacegrpchanged(value: any) {
    this._InterfaceGrpSelectedvalue.next(value);
  }

  sendRecordAdded(value: any) {
    this._addedvalue.next(value);
  }

  sendUserdata(user: any) {
    this._user.next(user);
  }

  sendUserdatatoLayout(user: any) {
    this._userdata.next(user);
  }

  checkIfAuthenticated(value: any) {
    this._isAuthenticatedvalue.next(value);
  }

  checkIfNoRole(value: any) {
    this.isRolenotassignedvalue.next(value);
  }

  resetFilter(value: any) {
    this._resetvalue.next(value);
  }

  searchFilter(value: any) {
    this._searchvalue.next(value);
  }

  clickDownloadPDF(value: any) {
    this._downloadPDF.next(value);
  }

  clickDownloadStanPDF(value: any) {
    this._downloadStandPDF.next(value);
  }

  clickDownloadSummPDF(value: any) {
    this._downloadStandPDF.next(value);
  }
}
