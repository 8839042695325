import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InterfaceListComponent } from './interface-list.component';
import { InterfaceCreateComponent } from './interface-create.component';
import { InterfaceEditComponent } from './interface-edit.component';
import { InterfaceViewComponent } from './interface-view.component';
import { InterfaceLogComponent } from './interface-log.component';
import { AppLayoutComponent } from '../app-layout/app-layout.component';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';

const interfaceRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      { path: 'Interfaces', component: InterfaceListComponent ,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interface.view'}},
      { path: 'InterfaceCreate', component: InterfaceCreateComponent ,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interface.create'}},
      { path: 'InterfaceEdit/:id', component: InterfaceEditComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interface.edit'} },
      { path: 'InterfaceView/:id', component: InterfaceViewComponent ,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interface.view'}},
      { path: 'InterfaceLog/:id', component: InterfaceLogComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'interface.log'} },
        { path: 'InterfaceLog', component: InterfaceLogComponent,
        canActivate: [MsalGuard,PermissionGuard], data:{perminssion:'system.log'} }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(interfaceRoutes)
  ]
})
export class InterfaceRoutingModule { }
