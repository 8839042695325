<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <form [formGroup]="form" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab labelcontractor label="Personal Information">
            <div class="h500tabMin">
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>First name</mat-label>
                    <input matInput inputmode="tel" placeholder="First name" maxlength="50" [(ngModel)]="user.FirstName" formControlName="FirstName" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('FirstName'))?.errors?.required">
                      First Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Last name</mat-label>
                    <input matInput inputmode="tel" placeholder="Last name" maxlength="50" formControlName="LastName" [(ngModel)]="user.LastName" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('LastName'))?.errors?.required">
                      Last Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Email</mat-label>
                    <input matInput inputmode="tel" placeholder="Email" maxlength="50" [disabled]="true" formControlName="Email" [(ngModel)]="user.Email" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.required">
                      Email is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="(form.get('Email'))?.errors?.pattern">Please enter valid Email</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Phone</mat-label>
                    <input matInput maxlength="15" inputmode="numeric" placeholder="Phone" formControlName="Phone" [(ngModel)]="user.Phone" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('Phone'))?.errors?.pattern">Please enter valid Phone</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>City</mat-label>
                    <input matInput inputmode="tel" placeholder="City" maxlength="50" [disabled]="true" formControlName="City" [(ngModel)]="user.City" appTrimWhiteSpace>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>State</mat-label>
                    <input matInput inputmode="tel" placeholder="State" maxlength="50" [disabled]="true" formControlName="State" [(ngModel)]="user.State" appTrimWhiteSpace>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Country</mat-label>
                    <input matInput inputmode="tel" placeholder="Country" maxlength="50" [disabled]="true" formControlName="Country" [(ngModel)]="user.Country" appTrimWhiteSpace>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <div class="d-flex flex-row">
                    <label mat-label class="headerLabel">Creation Date</label>
                    <label mat-label class="headerLabel">{{user.CreatedDate | date :this._dateTimeFormat : utcoffsetvalue}} {{user.CreatedDate | date :'h:mm:ss a' : utcoffsetvalue}}</label>
                  </div>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <div class="d-flex flex-row">
                    <label mat-label class="headerLabel">Last Login Date</label>
                    <label mat-label class="headerLabel">{{this.lastLoginDate | date :this._dateTimeFormat: utcoffsetvalue}} {{this.lastLoginDate | date :'h:mm:ss a' : utcoffsetvalue}}</label>
                  </div>
                </div>
              </div>              
              <div class="row">
                <div class="col-lg-12">
                  <mat-paginator #paginatorrole [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
                  </mat-paginator>
                  <div class="table-responsive scroller user-profile">
                    <table mat-table [dataSource]="userRoledataSource" class="mat-elevation-z8" matSort #sortrole="matSort">
                      <ng-container matColumnDef="Role.Name">
                        <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Role </th>
                        <td mat-cell *matCellDef="let element"> {{element.Role.Name}}</td>
                      </ng-container>
                      <ng-container matColumnDef="Contractor.Name">
                        <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor </th>
                        <td mat-cell *matCellDef="let element"> {{element.Contractor.Name}} </td>
                      </ng-container>
                      <ng-container matColumnDef="Action">
                        <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
                        <td mat-cell *matCellDef="let element">
                          <mat-icon matTooltip="remove" class="mat-icon-small-size" (click)="confirmCancelDialog(element.Id,element.UserId)" [hidden]="element.UserId == this._userIdLogin">delete</mat-icon>
                        </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab labeldiscipline label="Email Subscription">
            <div class="h500tabMin">
              <app-email-subscription (subscriptionadded)="subscriptionaddedHandler($event)"></app-email-subscription>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button (click)="save()" class="WIRbutton WIRbutton-primary">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
            <button mat-stroked-button #btnAddDocument appBackButton type="button" class="WIRbutton WIRbutton-secondary">
              <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
