import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrimWhitespace]'
})
export class TrimWhitespaceDirective {

  constructor(private control: NgControl) { }

  
  @HostListener('blur')
  onBlur() {
    const trimmedValue = this.control.value != null ? this.control.value.trim() : "";
    this.control.control?.setValue(trimmedValue);
  }
}
