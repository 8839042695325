import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProposeresponseComponent } from './proposeresponse.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProposeresponseViewComponent } from './proposeresponse-view.component';



@NgModule({
  declarations: [ProposeresponseComponent, ProposeresponseViewComponent],
  imports: [
    CommonModule, MaterialModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [ProposeresponseComponent, ProposeresponseViewComponent]
})
export class ProposeresponseModule { }
