import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { Role } from '../app_data/role';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  })
}

@Injectable({
  providedIn: 'root'
})

export class RoleService {
  private apiUrl = environment.apiURl + 'Roles';

  constructor(private http: HttpClient) {
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<response>(this.apiUrl)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getRoleById(id: number): Observable<Role[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=RolePermissions($filter=IsDeleted eq false)&$filter=id eq " + id +"&$orderby=id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Role): Observable<Role> {
    return this.http.post<Role>(this.apiUrl, model, httpOptions);

  }

  put(model: Role): Observable<Role> {
    return this.http.put<Role>(this.apiUrl + "/" + model.Id, model, httpOptions);

  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
