import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApproveRejectdialogComponent } from './approve-rejectdialog.component';
import { ResponseApproveRejectdialogComponent } from './responseapprove-rejectdialog.component';


@NgModule({
  declarations: [DialogComponent, ApproveRejectdialogComponent, ResponseApproveRejectdialogComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,ReactiveFormsModule
  ],
  exports: [DialogComponent, ApproveRejectdialogComponent, ResponseApproveRejectdialogComponent]
})
export class DialogModule { }
