import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { InterfaceGroup } from '../app_data/interfaceGroup';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class InterfaceGroupService {

  private apiUrl = environment.apiURl + 'InterfaceGroups';

  constructor(private http: HttpClient) {

  }


  getInterfaceGroupsByProjectId(mprojectId: number, ContractorID: number): Observable<InterfaceGroup[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;) &$filter=ProjectID eq " + mprojectId + " and (Contractor1ID  eq " + ContractorID + " or Contractor2ID eq " + ContractorID + ") and IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getInterfaceGroupsbyProjectAndContractors(mprojectId: number, ContractorIDs: number[]): Observable<InterfaceGroup[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor1,Contractor2,InterfaceGroupTemplateMappings($filter=IsDeleted eq false;) &$filter=ProjectID eq " + mprojectId + " and (Contractor1ID  in (" + ContractorIDs + ") or Contractor2ID in (" + ContractorIDs + ")) and IsDeleted eq false")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getInterfaceGroupsforProjectAdmin(mprojectId: number): Observable<InterfaceGroup[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectID eq " + mprojectId + "and IsDeleted eq false &$expand=Contractor1,Contractor2")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getInterfaceGroupById(igId: number): Observable<InterfaceGroup[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ID eq " + igId + "&$expand=Contractor1,Contractor2")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  post(model: InterfaceGroup): Observable<InterfaceGroup> {
    return this.http.post<InterfaceGroup>(this.apiUrl, model, httpOptions);


  }
  put(model: InterfaceGroup): Observable<InterfaceGroup> {
    return this.http.put<InterfaceGroup>(this.apiUrl + "/" + model.Id, model, httpOptions);


  }
  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }


  getContractorsByInterfaceGroupIds(interfaceGroupIds: number[]): Observable<InterfaceGroup[]> {
    let advFilter = "&$filter=";

    var i = 0;
    interfaceGroupIds.forEach(function (val) {
      if (i != 0)
        advFilter += " or ";
      
      advFilter += "Id eq " + val + "";
      i++;
    });

    return this.http.get<response>(this.apiUrl + "?$Select=Contractor1,Contractor2&$expand=Contractor1,Contractor2" + advFilter, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

}
