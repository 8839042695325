<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorLinks [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons class="">
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table #linksTable [dataSource]="dataSourceLinks" class="mat-elevation-z8" matSort #sortLinks="matSort">
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
            Title
          </th>
          <td mat-cell *matCellDef="let element2">
            <a href="{{element2.Link}}" class="anchorLink"
               target="_blank">{{element2.Title}}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by CreatedBy">
            Added by
          </th>
          <td mat-cell *matCellDef="let element2"> {{element2.CreatedUserRoleMapping?.User.FirstName}} {{element2.CreatedUserRoleMapping?.User.LastName}} </td>
        </ng-container>
        <ng-container matColumnDef="SubmittedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header
              sortActionDescription="Sort by SubmittedDate">
            Submit Date
          </th>
          <td mat-cell *matCellDef="let element2">
            {{element2.CreatedDate | date :this.dateTimeFormat: utcoffsetvalue}} {{element2.CreatedDate | date :'h:mm:ss a': utcoffsetvalue}}
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
