
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12"
         *ngFor="let fieldConfigData of projectfieldConfigDataSource">
      <mat-form-field appearance="standard">
        <mat-label>{{fieldConfigData.FieldKey}}</mat-label>
        <input matInput autocomplete="off" maxlength="256" [disabled]="this.isControlDisable || this.isOtherTabControlDisable"
               placeholder={{fieldConfigData.FieldKey}} value="{{fieldConfigData.InterfaceProjectFieldsMappings.length > 0 ? fieldConfigData.InterfaceProjectFieldsMappings[0].FieldValue : ''}}"
               (focusout)="fieldFocusOutEvent($event,  fieldConfigData)" name={{fieldConfigData.FieldKey}}>
      </mat-form-field>
    </div>
  </div>
