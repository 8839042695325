export class RiskCategories {
  CategoryId!: number;
  CategoryName !: string;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ProjectId!: number;
}
