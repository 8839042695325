import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonpipesModule } from '../../pipes/commonpipes/commonpipes.module';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';
import { WorkflowTemplateMappingComponent } from './workflow-template-mapping.component';



@NgModule({
  declarations: [WorkflowTemplateMappingComponent],
  imports: [
    CommonModule, MaterialModule, BrowserModule, ReactiveFormsModule, FormsModule, CommonpipesModule, CommonDirectivesModule
  ],
  exports:[
    WorkflowTemplateMappingComponent
  ]  
})
export class WorkflowTemplateMappingModule { }
