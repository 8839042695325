<form [formGroup]="definationsettingForm">
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12"></div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12" [align]="'right'">
      <button mat-stroked-button (click)="resetDefault()" class="btn WIRbutton WIRbutton-secondary btn-sm">
        <mat-icon class="WIRbutton-icons">restart_alt</mat-icon>Reset to Default
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Requesting Contractor</mat-label>
        <input matInput maxlength="35" autocomplete="off" inputmode="tel"  formControlName="RequestingContractorName" placeholder="Requesting Contractor" (change)="onTextChange('RequestingContractorName',$event)" #requestingcontractornameInput appTrimWhitespace>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Provider Contractor</mat-label>
        <input matInput maxlength="35" autocomplete="off" inputmode="tel" placeholder="Provider Contractor" (change)="onTextChange('ProviderContractorName',$event)" formControlName="ProviderContractorName" #providercontractornameInput appTrimWhitespace>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Requesting Discipline</mat-label>
        <input matInput inputmode="tel" autocomplete="off" maxlength="35" formControlName="RequestingDisciplineName" (change)="onTextChange('RequestingDisciplineName',$event)" placeholder="Requesting Discipline" #requestingdisciplinenameInput appTrimWhitespace>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Provider Discipline</mat-label>
        <input matInput maxlength="35" autocomplete="off" inputmode="tel" placeholder="Provider Discipline" (change)="onTextChange('ProviderDisciplineName',$event)" formControlName="ProviderDisciplineName" #providerdisciplinenameInput appTrimWhitespace>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Expected Response Date</mat-label>
        <input matInput maxlength="35" autocomplete="off" inputmode="tel" placeholder="Expected Response Date" (change)="onTextChange('ExpectedResponseDateName',$event)" formControlName="ExpectedResponseDateName" #expectedresponsedatenameInput appTrimWhitespace>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Expected Close Out Date</mat-label>
        <input matInput inputmode="tel" autocomplete="off" maxlength="35" formControlName="CloseOutDateName" (change)="onTextChange('CloseOutDateName',$event)" placeholder="Expected Close Out Date" #closeoutdatenameInput appTrimWhitespace>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Response Date Actual</mat-label>
        <input matInput maxlength="35" autocomplete="off" inputmode="tel" placeholder="Response Date Actual" (change)="onTextChange('ResponseDateActualName',$event)" formControlName="ResponseDateActualName" #responsedateactualnameInput appTrimWhitespace>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Close Out Date Actual</mat-label>
        <input matInput inputmode="tel" autocomplete="off" maxlength="35" formControlName="CloseOutDateActualName" (change)="onTextChange('CloseOutDateActualName',$event)" placeholder="Close Out Date Actual" #closeoutdateactualnameInput appTrimWhitespace>
      </mat-form-field>
    </div>
  </div>
</form>
