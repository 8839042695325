import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import { NavigationEnd, Router } from '@angular/router';
import * as myGlobals from '../app_data/globals';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = []

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  back(): void {
    if (this.history.indexOf("/AccessDenied") >= 0) {
      var roleID = parseInt(sessionStorage.getItem("roleId")!);
      this.history.pop();
        if (roleID == Number(myGlobals.ProjectAdminRoleId)) {
          this.router.navigateByUrl('/admindashboard');
        } else {
          this.router.navigateByUrl('/dashboard');
        }
    } else {
      if (this.history[this.history.length - 1].includes("View")) {
        this.location.back();
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        this.location.back();
      }
    }
    
  }
}
