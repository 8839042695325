import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DiscussionComponent } from './discussion.component';
import { DiscussionViewComponent } from './discussion-view.component';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';



@NgModule({
  declarations: [DiscussionComponent, DiscussionViewComponent],
  imports: [
    CommonModule, 
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonDirectivesModule
  ],
  exports: [DiscussionComponent,DiscussionViewComponent]
})
export class DiscussionModule { }
