import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTooltipComponent } from './customtooltip.component';
import { MaterialModule } from '../../material/material.module';


@NgModule({
  declarations: [CustomTooltipComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CustomTooltipComponent]
})
export class CustomTooltipModule { }
