import { ContactContractorMapping } from "./contactContractorMapping";
import { Discipline } from "./discipline";
import { InterfaceGroup } from "./interfaceGroup";
import { UserContractorMapping } from "./userContractorMapping";

export class Contractor {
  Id !: number;
  Code !: string;
  Name !: string;
  Address !: string;
  City !: string;
  Country !: string;
  ContactPerson !: string;
  EmailId !: string;
  PhoneNumber !: string;
  ProjectId!: number;
  IsCompany!: boolean;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Disciplines: Discipline[] = [];
  Contractor1InterfaceGroups: InterfaceGroup[] = [];
  Contractor2InterfaceGroups: InterfaceGroup[] = [];
  ProjId!: any;
  UserContractorMappings: UserContractorMapping[] = [];
  ContactContractorMappings: ContactContractorMapping[] = [];
  ModifiedUser?: any;
}
