import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionItemModule } from '../../shared/components/action-item/action-item.module';
import { ActionListRoutingModule } from './actionlist-routing.module';
import { ActionlistCreateComponent } from './actionlist-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../shared/material/material.module';
import { SearchFilterModule } from '../../shared/components/search-filter/search-filter.module';
import { ActionlistComponent } from './actionlist-list.component';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { CommonpipesModule } from '../../shared/pipes/commonpipes/commonpipes.module';
import { ActionlistEditComponent } from './actionlist-edit.component';
import { ActionlistViewComponent } from './actionlist-view.component';



@NgModule({
  declarations: [
    ActionlistCreateComponent,ActionlistComponent, ActionlistEditComponent, ActionlistViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule, MaterialModule, ReactiveFormsModule,
    ActiveRouteModule, CommonDirectivesModule, AuthorisationModule,
    ActionItemModule,
    SearchFilterModule,
    CommonDirectivesModule,
    CommonpipesModule,
    ActionListRoutingModule
  ],
  exports: [ActionlistCreateComponent, ActionlistEditComponent, ActionlistViewComponent, ActionlistComponent]
})
export class ActionlistModule { }
