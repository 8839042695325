import { UserRoleProject } from "./userroleproject";

export class UserInvitation {
  Id!: number;
  Code!: string;
  ProjectId!: number;
  RoleId!: number;
  Email!: string;
  ContractorId!: number;
  ValidTill!: Date;
  Status: string = "Active";
  Project?: any;
  Role?: any;
  Contractor?: any;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  IsDeleted: boolean = false;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: any;
}
