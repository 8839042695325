<div class="container-fluid">
  <mat-card class="switchrole">
    <mat-card-content>
      <div class="row">
        <table mat-table [dataSource]="userSwitchroledataSource" matSort #sortSwitchRole="matSort">
          <ng-container matColumnDef="Project.Name">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" > Project </th>
            <td mat-cell class="sw_projectname" *matCellDef="let disc">
              {{ disc.Name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="Contractor.Name">
            <th mat-header-cell *matHeaderCellDef class="table_header_size" > Contractor(Role) </th>
            <td mat-cell *matCellDef="let disc">
              <mat-chip-list aria-label="Role selection" class="matChipListWrapper">
                <mat-chip *ngFor="let userData of disc.UserRoleProjects" (click)="userData.IsDefault? '': loginWithRole(userData)" [ngClass]="userData.IsDefault? 'selectedRole matChipWidth':'matChipWidth'" [selected]="userData.IsDefault">
                  <span class="matChipSpanWidth matChipFont">{{ userData.Contractor.Name}} ({{userData.Role.Name}})   </span>
                  <mat-icon class="WIRbutton-icons subscriptionFlag" *ngIf="userData.IsDefault" matTooltip="Current role">check_circle</mat-icon>
                  <mat-icon *ngIf="!userData.IsDefault"></mat-icon>
                </mat-chip>
              </mat-chip-list>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
