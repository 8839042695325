import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { InterfaceGroupTemplateMapping } from '../app_data/interfaceGroupTemplateMapping';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class InterfaceGroupTemplateMappingService {

  private apiUrl = environment.apiURl + 'InterfaceGroupTemplateMappings';

  constructor(private http: HttpClient) {
  }


  getInterfaceGroupTemplateMappingsByProjectIdandContractorId(projectId: number, contractorId: number): Observable<InterfaceGroupTemplateMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Isdeleted eq false and ProjectId eq "+ projectId +" and InterfaceGroup/Contractor1ID eq " + contractorId + " &$expand=InterfaceGroup,module,WOrkflowTemplate &$orderby=InterfaceGroupId ", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getInterfaceGroupTemplateMappingsByInterfaceGroupAndModule(interfaceGroupId: number, moduleId: number): Observable<InterfaceGroupTemplateMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=Isdeleted eq false and ModuleId eq " + moduleId + " and InterfaceGroupId eq " + interfaceGroupId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
