import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PermissionGuard } from '../authorization/permission.guard';
import { RoleCreateComponent } from './role-create.component';

const roleRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'CreateRole', component: RoleCreateComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'role.create' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(roleRoutes)
  ]
})
export class RoleRoutingModule { }
