
import { InterfaceGroup } from "./interfaceGroup";
import { RiskCategories } from "./RiskCategories";
import { Document } from "./document";
import { Contractor } from "./contractor";
import { Module } from "./module";
import { UserRoleProject } from "./userroleproject";
import { Interface } from "./interface";

export class Risk {
  Id: number = 0;
  InterfaceGroupID?: number;
  ConsequenceId?: number;
  ProbabilityId?: number;
  CategoryId?: number;
  Title?: string;
  Description?: string;
  Cause !: string;
  Goal!: string;
  ProjectId !: number;
  Mitigation !: string;
  Responsible?: string;
  Deadline!: Date;
  InterfaceGroup?: InterfaceGroup;
  IsDeleted !: boolean;
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  ModuleId?: number;
  CreatedUserRoleMapping?: UserRoleProject;
  RiskCategories?: RiskCategories;
  Interfaces?: Interface;
  InterfaceId?: number;  
  Documents: Document[] = [];
  RiskLevel?: number;
  FilePathName: string = "";
  Status !: number;
  StatusName !: string;
  RecContractor?: Contractor;
  ResContractor?: Contractor;
  ReceivingContractorID?: number;
  ResponsibleContractorID?: number;
  Module?: Module;
}
