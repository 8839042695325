import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { DialogService } from 'src/app/services/dialog.service';
import { ProjectFieldsConfigService } from 'src/app/services/project-fields-config.service';
import * as myGlobals from '../../app_data/globals';
import { CommonFunction } from 'src/app/app_data/commonFunction';

@Component({
  selector: 'app-project-fields-configuration-create',
  templateUrl: './project-fields-configuration-create.component.html',
  styleUrls: ['./project-fields-configuration-create.component.css']
})
export class ProjectFieldsConfigurationCreateComponent implements OnInit {
  errorMessage!: string;
  projectfieldConfigDataSource : ProjectFieldsConfiguration[] = []; 
  interfaceProjectFieldsMappingData !: InterfaceProjectFieldsMapping;
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  interfaceProjectFieldsMapping : InterfaceProjectFieldsMapping[] = [];
  @Input() interfaceType!: string;
  @Input() interfaceId!: number;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  @Output() projectFieldsConfigadded: EventEmitter<InterfaceProjectFieldsMapping[]> = new EventEmitter();
  
  constructor(private SpinnerService: NgxSpinnerService, 
    private dialog: DialogService,
    private snackBar: MatSnackBar,
    private logger: NGXLogger,
    private projectFieldConfigService : ProjectFieldsConfigService ) { 
      this.interfaceProjectFieldsMappingData = new InterfaceProjectFieldsMapping();
    }

  ngOnInit(): void {
    this.fillProjectFieldsConfig(this.interfaceType);
  }

  fillProjectFieldsConfig(interfaceType: string): void {
    try {
      this.SpinnerService.show();
      
      if(this.interfaceId == null)
      {
        this.interfaceId = 0;
      }

      this.projectFieldConfigService.getProjectFieldsConfiguration(this.interfaceId, this._projectID, interfaceType).subscribe({
        next: data => {             
          this.projectfieldConfigDataSource = data;   
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fieldFocusOutEvent(event: any, fieldConfigData: any) {
    //delete if duplicate is exists in model list
    let fieldModel = this.interfaceProjectFieldsMapping.findIndex(item => item.ProjectFieldsConfigurationId === fieldConfigData.Id);

    if (fieldModel !== -1) {
      this.interfaceProjectFieldsMapping.splice(fieldModel, 1);
    }

    this.interfaceProjectFieldsMapping.push({ Id: 0, InterfaceID: 0, ProjectFieldsConfigurationId: fieldConfigData.Id, FieldValue: event.target.value, IsDeleted: false, CreatedBy: this._userIdLogin })
    this.projectFieldsConfigadded.emit(this.interfaceProjectFieldsMapping.filter(x=> x.FieldValue != ''));
  }
}
