import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../../shared/material/material.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { BodyComponent } from './body.component';



@NgModule({
  declarations: [BodyComponent],
  imports: [
    CommonModule,RouterModule,MaterialModule,DashboardModule
  ],
  exports:[BodyComponent]
})
export class BodyModule { }
