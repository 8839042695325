import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateBuilderService {

  constructor() { }

  buildTemplate(template: string, objects: object[]): string {

    let placeholders = this.extractPlaceHolders(template);

    template = this.replaceValues(template, placeholders, objects)

    return template;
  }

  extractPlaceHolders(template: string): string[] {
    let result: string[] = [];
    let segments = template.split('@{');

    segments.forEach(function (s) {
      let endIndex = s.indexOf('}');
      if (result.indexOf(s) < 0) {
        result.push(s.substring(0, endIndex));
      }
    });

    return result;
  }

  replaceValues(template: string, placeholders: string[], objects: object[]): string {
    placeholders.forEach(function (placeholder) {
      if (placeholder !== '') {
        objects.forEach(function (obj) {
          if (placeholder in obj) {
            template = template.replaceAll('@{' + placeholder + '}', getValue(obj, placeholder));
          }
          else {

            if (placeholder.indexOf('.') > -1) {

              let splitedPlaceholders = placeholder.split('.');

              if (splitedPlaceholders.length > 1) {

                var val: any;
                val = obj;

                for (var i = 0; i < splitedPlaceholders.length; i++) {
                  val = getValue(val, splitedPlaceholders[i]);
                }

                template = template.replaceAll('@{' + placeholder + '}', val);

              }

            }
          }
        });
      }
    });
    return template;
  }
}


function getValue(obj: object, placeholder: string): any {
  return (Object.entries(obj).find(([key, val]) => key === placeholder) != null) ? Object.entries(obj).find(([key, val]) => key === placeholder)?.[1] : '';
}
