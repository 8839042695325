import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/services/dialog.service';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { WorkflowTemplate } from 'src/app/app_data/workflowTemplate';
import { ModuleProjectMapping } from 'src/app/app_data/moduleProjectMapping';
import { SubscriptionBillingPlanService } from 'src/app/services/subscriptionBillingPlan.service';
import { Subscription } from 'src/app/app_data/subscription';
import { formatDate } from '@angular/common';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';

let interfaceGroupLists: InterfaceGroup[] = [];
@Component({
  selector: 'app-interfacegroup',
  templateUrl: './interfacegroup-create.component.html',
  styleUrls: ['./interfacegroup-create.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class InterfacegroupCreateComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  interfacegroupForm !: FormGroup;
  selectedContractorGroup: number = 0;
  interfacegroupModel !: InterfaceGroup;
  errorMessage = '';
  contractors: Contractor[] = [];
  subscriptionBillingPlan !: Subscription;
  totalInterfaceGroupsCount : number  = 0;
  dataSource = interfaceGroupLists;
  contractor !: Contractor;
  interfaceGroups: InterfaceGroup[] = [];
  IsOpenApproval : boolean = false;
  IsCloseoutApproval : boolean = false;
  workflowMappings : WorkflowMapping[] =[];
  @Input() interfaceGroup: InterfaceGroup;
  @Output() interfaceGroupAdded: EventEmitter<InterfaceGroup[]> = new EventEmitter();
  selectedValue: any;
  selectedWorkflowTemplateId: number = 0;
  selectedModuleId: number = 0;
  workflowTemplates: WorkflowTemplate[]=[];
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  moduleProjectMappings: ModuleProjectMapping[] = [];
  displayedColumns: string[] = ['Code', 'Name', 'Contractor1', 'Contractor2', 'Action'];
  interfaceGroupDataSource = new MatTableDataSource<InterfaceGroup>(interfaceGroupLists);
  @ViewChild('sortInterfaceGroup') sortInterfaceGroup !: MatSort;
  @ViewChild('paginatorInterfaceGroup') paginatorInterfaceGroup !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortInterfaceGroup = ms;
    this.interfaceGroupDataSource.paginator = this.paginatorInterfaceGroup;
    this.interfaceGroupDataSource.sort = this.sortInterfaceGroup;
  }

  constructor(private contractorService: ContractorService, 
    private subscriptionBillingPlanService: SubscriptionBillingPlanService,
    private interfaceGroupService: InterfaceGroupService,
    private communicationService: CommunicationService,
    private dialog: DialogService,
    private logger: NGXLogger) {
    this.interfaceGroup = new InterfaceGroup(); this.contractor = new Contractor();
  }

  ngOnInit(): void {
    this.interfacegroupForm = new FormGroup({
      contractor1ID: new FormControl(''),
      contractor2ID: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });

    this.fillProviderContractor();  
    this.getInterfaceGroupsByProjectId();
    this.getSubscriptionBillingPlan();
  }

  getInterfaceGroupsByProjectId(): void {
    try {
      this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(this._projectID).subscribe({
        next: interfaceGroups => {
          this.totalInterfaceGroupsCount = interfaceGroups.length;      
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getSubscriptionBillingPlan(): void {
    try {
      this.subscriptionBillingPlanService.getSubscriptionByProjectId(this._projectID).subscribe({
        next: subscriptionData => {
          this.subscriptionBillingPlan = subscriptionData[0];
          
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  validateSubscriptionBillingPlan(): boolean{
    try {
      if(this.subscriptionBillingPlan != null && this,this.subscriptionBillingPlan.Id != 0){        
        var maxNoOfInterfaceGroupCount = this.subscriptionBillingPlan.BillingPlan?.MaxNoOfInterfaceGroups!;      
        
        //check subscription is valid or not
        if (formatDate(new Date(),'yyyy-MM-dd','en_US') > formatDate(this.subscriptionBillingPlan.ValidTill,'yyyy-MM-dd','en_US')) 
        {
          return false;
        }

        //check max no of interfaceGroup count 
        if(this.totalInterfaceGroupsCount > maxNoOfInterfaceGroupCount){
          return false;
        }

        return true;
      }
      else{
        return false;
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
      return false
    }
  }

  fillProviderContractor(): void {
    try {
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: providerContractor => {
          this.contractors = providerContractor.filter(x => !x.IsDeleted);
        },
        error: err => {this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangevalue(e: MatSelectChange) {
    this.selectedValue = e.source.triggerValue;
  }

  add() {
    try {
      this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) + 1;
      //Validate Subscription billing plan
      if (this.validateSubscriptionBillingPlan()) {
        let uniqueCheck = this.contractor.Contractor1InterfaceGroups.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == this.interfaceGroup.Code.toLowerCase() || x.Name.toLowerCase() == this.interfaceGroup.Name.toLowerCase()
          || (x.Contractor2ID == this.interfaceGroup.Contractor2ID)));
        if (uniqueCheck.length <= 0) {
          this.interfaceGroups.push({ Id: 0, Contractor1ID: this._contractorID, Contractor2ID: this.interfaceGroup.Contractor2ID, Name: this.interfaceGroup.Name, IsDeleted: false, Code: this.interfaceGroup.Code, ProjectID: this._projectID, CreatedBy: this._userRoleMappingId, Contractor2: this.selectedValue, Contractor1: this.communicationService._contractorname})
          this.contractor.Contractor1InterfaceGroups.push({ Id: 0, Contractor1ID: this._contractorID, Contractor2ID: this.interfaceGroup.Contractor2ID, Name: this.interfaceGroup.Name, IsDeleted: false, Code: this.interfaceGroup.Code, ProjectID: this._projectID, CreatedBy: this._userRoleMappingId, Contractor1: null, Contractor2: null })
          this.interfaceGroupDataSource.data = this.interfaceGroups.filter(x => !x.IsDeleted);

          this.interfaceGroupAdded.emit(this.contractor.Contractor1InterfaceGroups.filter(x => !x.IsDeleted));
          this.interfacegroupForm.reset();
        }
        else {
          this.dialog.confirmDialog({
            title: 'Warning',
            message: 'Code, Name, Contractor 2 already exists!!',
            cancelCaption: 'Close',
          });
        }
      } else {
        this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) - 1;

        this.dialog.confirmDialog({
          title: 'Warning',
          message: 'Your Subscription max limit exceeds. Please contact WIR Support',
          cancelCaption: 'Close',
        });
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  delete(code: string, name: string) {
    try {
      this.totalInterfaceGroupsCount = Number(this.totalInterfaceGroupsCount) - 1;
      
      let InterfacegrpModel = this.interfaceGroups.filter(x => !x.IsDeleted && (x.Code.toLowerCase() == code.toLowerCase() && x.Name.toLowerCase() == name.toLowerCase()));
      if(InterfacegrpModel != null && InterfacegrpModel.length > 0){
        InterfacegrpModel[0].IsDeleted = true;
        InterfacegrpModel[0].ModifiedBy = this._userRoleMappingId;
      }

      let contractor1InterfaceGroups = this.contractor.Contractor1InterfaceGroups.filter(x=> !x.IsDeleted && (x.Code.toLowerCase() == code.toLowerCase() && x.Name.toLowerCase() == name.toLowerCase()));
      if(contractor1InterfaceGroups != null && contractor1InterfaceGroups.length > 0){
        contractor1InterfaceGroups[0].IsDeleted = true;
        contractor1InterfaceGroups[0].ModifiedBy = this._userRoleMappingId;
      }

      this.interfaceGroupDataSource.data = this.interfaceGroups.filter(x => !x.IsDeleted);
      this.interfaceGroupAdded.emit(this.interfaceGroups);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  confirmCancelDialog(code:string, name: string) {
    try {
      this.dialog
        .confirmDialog({
          title: 'Confirm Action',
          message: 'Do you want to delete this interface group?',
          confirmCaption: 'Confirm',
          cancelCaption: 'Cancel',
        })
        .subscribe((confirmed) => {
          if (confirmed) {
            this.delete(code, name);
          }
        });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }  
}
