import { UserContractorMapping } from "./userContractorMapping";
import { UserRoleProject } from "./userroleproject";

export class User {
  Id !: number;
  FirstName: string = "";
  LastName: string = "";
  UserName: string = "";
  Email: string = "";
  Phone: string = "";
  City: string = "";
  State: string = "";
  Country: string = "";
  CreatedDate!: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  IsDeleted: boolean = false;
  IsBlocked?: boolean = false;
  BlockedDate?: Date;
  BlockedBy?: number;
  LastActivityDate?: Date;
  IsLoggingOut: boolean = false;
  UserContractorMappings: UserContractorMapping[] = [];
  UserRoleProjects: UserRoleProject[] = [];
  BlockedfromUser: any;
  BlockedByUser: User[]=[];
}
