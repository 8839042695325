import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../authorization/permission.guard';
import { MsalGuard } from '@azure/msal-angular';
import { ActionlistCreateComponent } from './actionlist-create.component';
import { ActionlistComponent } from './actionlist-list.component';
import { ActionlistEditComponent } from './actionlist-edit.component';
import { ActionlistViewComponent } from './actionlist-view.component';

const actionListRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'ActionList', component: ActionlistComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'actionlist.view' }
      },
      {
        path: 'ActionListCreate', component: ActionlistCreateComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'actionlist.create' }
      },
      {
        path: 'ActionListEdit/:id', component: ActionlistEditComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'actionlist.edit' }
      },
      {
        path: 'ActionListView/:id', component: ActionlistViewComponent,
        canActivate: [MsalGuard, PermissionGuard], data: { perminssion: 'actionlist.view' }
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(actionListRoutes)
  ]
})
export class ActionListRoutingModule { }
