import { Directive, Input } from "@angular/core";
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { CompareDates } from "../../../services/compareDate.service";



@Directive({
    selector:"[appMatchDate]",
    providers:[{provide: NG_VALIDATORS,useExisting:MatchDateDirective,multi:true}]
})


export class MatchDateDirective implements Validator{
    
    @Input('appMatchDate') matchDate: string[]=[];

    validate(formGroup: FormGroup): ValidationErrors | null {       

        return CompareDates(this.matchDate[0], this.matchDate[1])(formGroup);
    }

}
