export class ActionItemExcel {
  Number : string = "";
  MOMTitle: string = "";
  InterfaceGroup: string = "";
  Title : string = "";
  Description : string = "";
  Responsible: string = "";
  ActionDate: string = "";
  IsClosed: string = "";
  ClosedDate: string = "";
  Status: string = "";
  IsDeleted : string = "";
  CreatedDate?: string = "";
  CreatedBy: string = "";
}
