import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { ContactContractorMapping } from '../app_data/contactContractorMapping';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ContactContractorMappingsService {

  private apiUrl = environment.apiURl + 'ContactContractorMappings';

  constructor(private http: HttpClient) {
  }

  getContactsByProjectId(Id:number,projectId: number): Observable<ContactContractorMapping[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor($filter= ProjectID eq " + projectId +" and IsCompany eq false and IsDeleted eq false)&$filter=ContactId eq " + Id + " and IsDeleted eq false &$orderby=Id", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: ContactContractorMapping): Observable<ContactContractorMapping> {
    return this.http.post<ContactContractorMapping>(this.apiUrl, model, httpOptions);
  }
  put(model: ContactContractorMapping): Observable<ContactContractorMapping> {
    return this.http.put<ContactContractorMapping>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
