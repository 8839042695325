import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { TaketourComponent } from './taketour.component';


const taketourRoutes: Routes = [
  {
    path: 'taketour', component: TaketourComponent
    //children: [
    //  {
    //    path: 'taketour', component: TaketourComponent
    //  }
    //]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(taketourRoutes)
  ]
})
export class TaketourRoutingModule { }
