import { Contractor } from "./contractor";
import { User } from "./user";

export class MinutesOfMeetingAttendees {
  Id !: number;
  MinutesOfMeetingID!: number;
  Contractor1ID?: number;
  Contractor1AttendeeUserID?: number;
  Contractor2ID?: number;
  Contractor2AttendeeUserID?: number;
  IsDeleted !: boolean;
  Contractor1AttendeeNames?: string="";
  Contractor2AttendeeNames?: string="";
  CreatedDate?: Date;
  CreatedBy!: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Contractor1?: Contractor;
  Contractor2?: Contractor;
  Contractor1AttendeeUser?: User;
  Contractor2AttendeeUser?: User;
  ExternalAttendees?: string="";
}
