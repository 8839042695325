<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <div class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab label="Role Permission Configuration">
            <div class="h500tabMin">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field appearance="standard">
                      <mat-label>Role</mat-label>
                      <mat-select [(value)]="selectedRoleId" [(ngModel)]="rolePermission.RoleId" (selectionChange)="onRoleChange(selectedRoleId)" formControlName="roleFormControl" required>
                        <mat-option *ngFor="let role of userRoles" [value]="role.Id">{{ role.Name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="this.form.get('roleFormControl')?.hasError('required')">
                        Role is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                    <mat-form-field appearance="standard">
                      <mat-label>Permission</mat-label>
                      <mat-select multiple [(value)]="selectedPermissionIds" [(ngModel)]="rolePermission.PermissionId" formControlName="permissionFormControl" required>
                        <mat-option *ngFor="let permission of permissions" [value]="permission.Id">{{ permission.Name }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="this.form.get('permissionFormControl')?.hasError('required')">
                        Permission is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button type="button" (click)="save()" [disabled]="form.invalid" class="WIRbutton WIRbutton-primary">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Save
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
