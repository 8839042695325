import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrolltotopComponent } from './scrolltotop.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material/material.module';


@NgModule({
  declarations: [ScrolltotopComponent],
  imports: [
    CommonModule, MaterialModule, FormsModule, ReactiveFormsModule
  ],
  exports: [ScrolltotopComponent]
})
export class ScrolltotopModule { }
