<div class="container-fluid">
    <mat-card class="">
        <mat-card-content>
            <div class="interfaceTab">
                <mat-tab-group animationDuration="0ms" id="tab-group">
                    <mat-tab label="Invite User">
                        <div class="h500tabMin">
                            <form [formGroup]="form">
                                <div class="row">
                                    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Contractor</mat-label>
                                            <mat-select [(value)]="selectedContractor" [(ngModel)]="userInvitation.ContractorId" (selectionChange)="onContractorChangevalue(selectedContractor)"                                            
                                                formControlName="contractorFormControl" required>
                                                <mat-option *ngFor="let con of contractors" [value]="con.Id">{{ con.Name
                                                    }}</mat-option>
                                            </mat-select>
                                            <mat-error
                                                *ngIf="this.form.get('contractorFormControl')?.hasError('required')">
                                                Contractor is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Role</mat-label>
                                            <mat-select [(value)]="selectedRole" [(ngModel)]="userInvitation.RoleId" formControlName="roleFormControl"
                                                required>
                                                <mat-option *ngFor="let role of userRoles" [value]="role.Id">{{
                                                    role.Name }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="this.form.get('roleFormControl')?.hasError('required')">
                                                Contact is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Email</mat-label>
                                            <input matInput inputmode="tel" autocomplete="off" [(ngModel)]="userInvitation.Email" name="ContactEmail" formControlName="contactFormControl"
                                            placeholder="Email" required>
                                            <mat-error
                                                *ngIf="this.form.get('contactFormControl')?.hasError('required')">
                                                Email is <strong>required</strong>
                                            </mat-error>
                                            <mat-error *ngIf="(this.form.get('contactFormControl'))?.errors?.pattern">Please enter valid Email</mat-error>
                                        </mat-form-field>
                                    </div>  
                                </div>
                            </form>                            
                        </div>
                    </mat-tab>
                  <mat-tab label="Invitation History">
                    <div class="h500tabMin">
                      <app-invitation-list></app-invitation-list>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div class="row mt-2 mb-2">
                    <div align="right">
                        <button mat-stroked-button type="button" [disabled]="form.invalid" (click)="add()" class="WIRbutton WIRbutton-primary">
                            <mat-icon class="WIRbutton-icons">contact_mail</mat-icon>Send Invite
                        </button>
                        <!--<button mat-stroked-button type="button" (click)="onCancel()" class="WIRbutton WIRbutton-secondary">
                            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
                        </button>-->
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
