import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppLayoutComponent } from "../../../modules/app-layout/app-layout.component";
import { UnauthorizedAccessComponent } from "./unauthorized-access.component";

const unauthorizedRoutes: Routes = [
  {
    path: '',
    //component: AppLayoutComponent,
    children: [
      {
        path: 'AccessDenied', component: UnauthorizedAccessComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(unauthorizedRoutes)
  ]
})
export class UnauthorizedAccessRoutingModule { }
