export class DefinitionSetting {
  ProviderContractorName !: string;
  RequestingContractorName !: string;
  ProviderDisciplineName !: string;
  RequestingDisciplineName !: string;
  ExpectedResponseDateName !: string;
  CloseOutDateName !: string;
  ResponseDateActualName!: string;
  CloseOutDateActualName!: string;
}
