<form [formGroup]="assignmentForm">
  <div class="row">
    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Reason of Assignment</mat-label>
        <textarea matInput formControlName="ReasonofAssignment" name="ReasonofAssignment" [(ngModel)]="assignmentHistory.ReasonOfAssignment" maxlength="256" class="heightTextArea" required appTrimWhitespace></textarea>
        <mat-error *ngIf="this.assignmentForm.get('ReasonofAssignment')?.hasError('required')">
          Reason of Assignment is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Assigned To</mat-label>
        <mat-select [(value)]="selectedAssigneeId" name="AssignedTo" formControlName="AssignedTo" [(ngModel)]="assignmentHistory.AssignedTo" required>
          <mat-option *ngFor="let ap of assignees" [value]="ap.Id">{{ ap.User.FirstName}} {{ ap.User.LastName }} - {{ ap.Role.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.assignmentForm.get('AssignedTo')?.hasError('required')">
          Assigned To is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
      <button mat-stroked-button class="WIRbutton WIRbutton-accept mt-2" (click)='onAssign()' [disabled]="this.assignmentForm.invalid">
        <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Assign
      </button>
    </div>
  </div>
</form>


<div class="row">
  <div class="col-lg-12" *ngIf="assignmentdataSource.data.length > 0">
    <mat-paginator #paginatorassignment [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="assignmentdataSource" class="mat-elevation-z8" matSort #sortassignment="matSort">
        <ng-container matColumnDef="AssignedTo">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header>Assigned To</th>
          <td mat-cell *matCellDef="let disc"> {{disc.AssignedToUser != null ? disc.AssignedToUser.FirstName +' '+ disc.AssignedToUser.LastName + ' - '+disc.AssignedToRole.Name : '' }}</td>
        </ng-container>
        <ng-container matColumnDef="ReasonOfAssignment">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Reason of Assignment </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ReasonOfAssignment}} </td>
        </ng-container>
        <ng-container matColumnDef="CreatedBy">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Assigned By </th>
          <td mat-cell *matCellDef="let disc"> {{ disc.CreatedUserRoleMapping != null ? disc.CreatedUserRoleMapping?.User.FirstName +' '+ disc.CreatedUserRoleMapping?.User.LastName +' - '+ disc.CreatedUserRoleMapping?.Contractor.Name+'-'+ disc.CreatedUserRoleMapping?.Role.Name: ''}}</td>
        </ng-container>
        <ng-container matColumnDef="CreatedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Assigned Date </th>
          <td mat-cell *matCellDef="let disc">{{disc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{disc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}</td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
