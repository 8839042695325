<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorDocument [pageSizeOptions]="[10, 15, 20]" showFirstLastButtons class="">
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="documentDataSource" class="mat-elevation-z8" matSort #sortDocument="matSort">
        <ng-container matColumnDef="Number">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by number">
            No.
          </th>
          <td mat-cell *matCellDef="let doc"> {{doc.Number}} </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
            Document Title
          </th>
          <td mat-cell *matCellDef="let doc"> {{doc.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="DocumentType">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
            Document Type
          </th>
          <td mat-cell *matCellDef="let doc"> {{this.showFileType(doc.FileName)}} </td>
        </ng-container>
        <ng-container matColumnDef="SubmittedByUserID">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by CreatedBy">
            Added by
          </th>
          <td mat-cell *matCellDef="let doc"> {{doc.CreatedUserRoleMapping != null ? doc.CreatedUserRoleMapping?.User.FirstName +' '+ doc.CreatedUserRoleMapping?.User.LastName +' - ('+doc.Contractor.Name +')' : this._userName}}</td>
        </ng-container>
        <ng-container matColumnDef="SubmittedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header
              sortActionDescription="Sort by SubmittedDate">
            Submit Date
          </th>
          <td mat-cell *matCellDef="let doc">
            {{doc.CreatedDate | date :this.dateTimeFormat : utcoffsetvalue}} {{doc.CreatedDate | date :'h:mm:ss a' : utcoffsetvalue}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size">
            Action
          </th>
          <td mat-cell *matCellDef="let doc">
            <button mat-icon-button *ngIf="doc.Id != 0" [disabled]="doc.Status == 1" (click)="downloadDocument(doc.FileNameSaved, doc.FileName,doc.FilePath)"
                    [matTooltip]='doc.Status == 1? "Upload inprogress" : "download"'>
              <mat-icon class="mat-icon-small-size">cloud_download</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedDocumentColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
      </table>
    </div>
  </div>
</div>
