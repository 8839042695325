
<div class="container-fluid body-wrapper">
  <mat-card class="">
    <mat-card-content class="h500tabMin">
      <div *ngIf="calledOnActionButtonClick">
        <p class="pPageHeading">{{pageTitle}}</p>
      </div>
      <div *ngIf="this._interfaceId > 0">
        <form [formGroup]="rangeFormGroup">
          <div class="row g-2 display-flex">
            <div class="col-md-2 align-self-center">
              <mat-form-field appearance="standard">
                <mat-label>Interface Group</mat-label>
                <input matInput placeholder="" [value]="interfaceGroup" readonly>
              </mat-form-field>

            </div>
            <div class="col-md-2 align-self-center">
              <mat-form-field appearance="standard">
                <mat-label>Interface Number</mat-label>
                <input matInput placeholder="" [value]="interfaceNumber" readonly>
              </mat-form-field>
            </div>

            <div class="col-md-4 align-self-center">
              <mat-form-field appearance="standard" class="dateRange">
                <mat-label>Date Range</mat-label>
                <div class="input-group hide-blue-border">
                  <input autocomplete="off" matInput name="dateRange" placeholder="Date Range" formControlName="dateRange" [(ngModel)]="dateRange"
                         type="text" bsDaterangepicker class="form-control" [bsConfig]="datePickerConfig" #d="bsDaterangepicker" />
                  <button style="padding:0px !important" class="btn-sm calendar calendar-icon-btn " (click)="d.toggle()" type="button">
                    <mat-icon>calendar_month</mat-icon>
                  </button>

                </div>
              </mat-form-field>
            </div>

            <div class="col-md-3  align-self-center">
              <button mat-button class="button-form WIRbutton WIRbutton-primary mat-stroked-button"
                      (click)="applyDateFilter()">
                <mat-icon>search</mat-icon>
                <span class="">Search</span>
              </button>

            </div>
            <div class="col-md-2">
            </div>
          </div>

        </form>

      </div>
      <div class="row">
        <div class="col-lg-12">
          <mat-paginator #paginatorLog [pageSizeOptions]="[5, 15, 20]" showFirstLastButtons>
          </mat-paginator>
          <div class="table-responsive scroller">
            <table mat-table [dataSource]="interfaceLogDataSource" class="mat-elevation-z8" matSort #sortlog="matSort">
              <ng-container matColumnDef="CreateDate">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Date/Time </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.CreateDate | date : this.dateTimeFormat: utcoffsetvalue }} {{element.CreateDate | date : 'h:mm:ss a': utcoffsetvalue }}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="GeneratedUser_FirstName">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> User </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.GeneratedUser.FirstName +' '+ element.GeneratedUser.LastName }} {{element.ContractorId != null ? '('+ element.Contractor.Name +')' : ''}} {{element.RoleId != null ? '('+ element.Role.Name +')' : ''}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Module">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Module </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Module}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="SystemFunction">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Activity </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.SystemFunction}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="Description">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Description </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Description}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LogInterfaceId.Number">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Interface Number </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.LogInterfaceId ? element.LogInterfaceId.Number : ''}} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="LogInterfaceId.InterfaceGroup.Name">
                <mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Interface Group </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.LogInterfaceId ? element.LogInterfaceId.InterfaceGroup.Name : ''}} </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-2 mb-2" align="right" *ngIf="calledOnActionButtonClick">
        <button mat-stroked-button type="button" (click)="onBackButtonClick()" class="btn btn-secondary btn-sm">
          <mat-icon>arrow_back</mat-icon>Back
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
