import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { response } from '../app_data/OdataResponse';
import { Stage } from '../app_data/stage';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class StageService {
  private apiUrl = environment.apiURl + 'Stages';

  constructor(private http: HttpClient) {
  }

  getWorkflow(): Observable<Stage[]> {
    return this.http.get<response>(this.apiUrl + "?$orderby=SequenceNumber", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  
  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
