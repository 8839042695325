import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { response } from '../app_data/OdataResponse';
import { environment } from 'src/environments/environment';
import { Discussion } from '../app_data/discussion';
import { CommonFunction } from '../app_data/commonFunction';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  })
}

@Injectable({
  providedIn: 'root'
})
export class DiscussionService {
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  private apiUrl = environment.apiURl + 'discussions';

  constructor(private http: HttpClient) {
  }

  convertUTCDatesToTimezonedate(data: Discussion[]){              
    // convert all dates to timezone datetime        
    data.forEach(x=> {       
      x.CreatedDate = x.CreatedDate != null ? CommonFunction.ConvertUTCToTimeZoneTime(x.CreatedDate, this._projectTimeZone, false) : null!;
    })
    
    return data;
}

  getDiscussionsByInterfaceId(interfaceId: number): Observable<Discussion[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Contractor,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false)&$filter=interfaceId eq " + interfaceId + "&$orderby=Id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Discussion): Observable<Discussion> {
    return this.http.post<Discussion>(this.apiUrl, model, httpOptions)
  }

  put(model: Discussion): Observable<Discussion> {
    return this.http.put<Discussion>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }  
}
