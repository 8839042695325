<div class="container-fluid">
  <mat-card class="risk-body-content">
    <mat-card-content>
      <p class="pPageHeading">{{pageTitle}}</p>
      <form [formGroup]="form" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" class="content-font">
          <mat-tab label="Risk">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Title</mat-label>
                    <input matInput #input autocomplete="off" maxlength="256" placeholder="Title" name="Title"
                      formControlName="titleFormControl" [(ngModel)]="riskModel.Title" required>
                    <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                      Title is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end"></mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="descriptionFormControl" maxlength="3000"
                      [(ngModel)]="riskModel.Description" name="Description" class="heightTextArea" required></textarea>
                    <mat-error *ngIf="this.form.get('descriptionFormControl')?.hasError('required')">
                      Description is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end"></mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Cause/Consequence</mat-label>
                    <textarea matInput formControlName="causeFormControl" maxlength="3000" [(ngModel)]="riskModel.Cause"
                      name="Cause/Consequence" class="heightTextArea" required></textarea>
                    <mat-error *ngIf="this.form.get('causeFormControl')?.hasError('required')">
                      Cause/Consequence is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end"></mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-2 col-l-2 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Probability Level</mat-label>
                    <mat-select [(value)]="selectedProbabilityLevel" formControlName="probabilityLevelFormControl"
                      (selectionChange)="generateRisk()" [(ngModel)]="riskModel.ProbabilityId" required>
                      <mat-option *ngFor="let ic of probabilityLevel" [value]="ic.Id">{{ ic.Probability }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('probabilityLevelFormControl')?.hasError('required')">
                      Probability Level is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-2 col-l-2 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Consequence Level</mat-label>
                    <mat-select formControlName="consequenceLevelFormControl" [(value)]="selectedConsequenceLevel"
                      (selectionChange)="generateRisk()" [(ngModel)]="riskModel.ConsequenceId" required>
                      <mat-option *ngFor="let ic of consequenceLevel" [value]="ic.Id">{{ ic.Consequence }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('consequenceLevelFormControl')?.hasError('required')">
                      Consequence Level is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Category</mat-label>
                    <mat-select [(value)]="selectedRiskCategoryId" formControlName="categoriesFormControl"
                      (selectionChange)="generateRisk()" [(ngModel)]="riskModel.CategoryId" required>
                      <mat-option *ngFor="let ic of riskCategories" [value]="ic.CategoryId">
                        {{ ic.CategoryName}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.form.get('categoriesFormControl')?.hasError('required')">
                      Category is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Goals</mat-label>
                    <textarea matInput formControlName="goalFormControl" maxlength="3000" [(ngModel)]="riskModel.Goal"
                      name="Goals" class="heightTextArea" required></textarea>
                    <mat-error *ngIf="this.form.get('goalFormControl')?.hasError('required')">
                      Goals is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end"></mat-hint>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Responsible</mat-label>
                    <input matInput #input autocomplete="off" maxlength="256" placeholder="Responsible "
                      name="Responsible" formControlName="responsibleFormControl" [(ngModel)]="riskModel.Responsible"
                      required>
                    <mat-error *ngIf="this.form.get('responsibleFormControl')?.hasError('required')">
                      Responsible is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Deadline</mat-label>
                    <input matInput [matDatepicker]="deadlinePicker" autocomplete="off" placeholder="Deadline"
                      formControlName="deadlineDateFormControl" [(ngModel)]="riskModel.Deadline" required disabled />
                    <mat-datepicker-toggle matSuffix [for]="deadlinePicker"></mat-datepicker-toggle>
                    <mat-datepicker #deadlinePicker disabled="false"></mat-datepicker>
                    <mat-error *ngIf="this.form.get('deadlineDateFormControl')?.hasError('required')">
                      Deadline Date is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-8 col-l-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Mitigation</mat-label>
                    <textarea matInput formControlName="mitigationFormControl" maxlength="3000"
                      [(ngModel)]="riskModel.Mitigation" name="Mitigation" class="heightTextArea" required></textarea>
                    <mat-error *ngIf="this.form.get('mitigationFormControl')?.hasError('required')">
                      Mitigation is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end"></mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="!isProjectAdminRole" [label]="tabModuleTypeName">
            <div class="h500tabMin">
              <form [formGroup]="interfaceForm">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Interface Group</mat-label>
                    <mat-select [(value)]="selectedInterfaceGroup" formControlName="interfaceGroupFormControl"
                      (selectionChange)="enableModule()" [(ngModel)]="riskModel.InterfaceGroupID" required>
                      <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">
                        {{ ig.Name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.interfaceForm.get('interfaceGroupFormControl')?.hasError('required')">
                      Interface Group is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Module Name</mat-label>
                    <mat-select [(value)]="selectedModuleId" formControlName="moduleFormControl" [disabled]="true"
                      [(ngModel)]="riskModel.ModuleId"  (selectionChange)="generateRisk()" required>
                      <mat-option *ngFor="let module of moduleProjectMappings" [value]="module.Module.Id">{{
                        module.Module.Name }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.interfaceForm.get('moduleFormControl')?.hasError('required')">
                      Module Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>{{this.tabModuleTypeName}} List</mat-label>
                    <mat-select [(value)]="this.selectedInterface" formControlName="interfacesFormControl" (selectionChange)=" this.isInterfaceAddedToRisk = false;" required>
                      <mat-option *ngFor="let interface of interfaceListData" [value]="interface.Id">
                        {{ interface.Number }} - {{ interface.Title }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="this.interfaceForm.get('interfacesFormControl')?.hasError('required')">
                      {{this.tabModuleTypeName}} is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <button mat-stroked-button type="button" (click)="addInterfacesToRisk()"
                    [disabled]="this.interfaceForm.invalid" class="WIRbutton WIRbutton-primary">
                    <mat-icon class="WIRbutton-icons">add</mat-icon>Add {{this.tabModuleTypeName}} to Risk
                  </button>
                </div>
              </div>
            </form>
              <div class="row">
                <div *ngIf="this.isInterfaceAddedToRisk">
                  <mat-accordion>
                    <mat-expansion-panel #panel hideToggle [expanded]="true" class="margin-bottom-5 listPanel">
                      <mat-expansion-panel-header #panelHeader class="card-border listPanel-large">
                        <mat-panel-title class="align-items-center card-div col-md-10">
                          <div class="col-md-3">
                            <span
                              [ngClass]="commonFunction.getCardColorOnStatus(interfaceStatus, IsApprovedIssuePlannedOverdue,IsFirstIssuePlannedOverdue,response)"
                              class="status-dot"></span>
                            <span class="padding-15">{{commonFunction.getStatusText(interfaceStatus)}}</span>
                          </div>
                          <div>
                            <div class="display-flex font-color-darkgrey">
                              <div class="fw-bolder">#{{ interfaceNumber }}</div>
                            </div>
                            <div>{{ this.riskTitle }}</div>
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="padding-top-bottom-2 card-div">
                        <div class=" g-2 display-flex">
                          <div class="row col-md-12 margin-top-bottom-10 display-flex">
                            <div class="col-md-1">
                              <mat-label class="font-color-darkgrey fw-bolder">Description:</mat-label>
                            </div>
                            <div class="col-md-11 padding-left-75 min-20-max-100-height overflow-auto">
                              {{ this.riskDiscription }}
                            </div>
                          </div>
                        </div>

                        <div class="g-2 display-flex">
                          <div class="row col-md-12 margin-top-bottom-10">
                            <div class="col-md-4 display-flex">
                              <mat-label class="font-color-darkgrey fw-bolder">{{ expectedResponseDateLabel
                                }}:</mat-label>
                              <mat-label class="padding-left-20">{{ firstIssuePlanned | date:dateTimeFormat :
                                utcoffsetvalue }}</mat-label>
                            </div>
                            <div class="col-md-4 display-flex">
                              <mat-label class="font-color-darkgrey fw-bolder">{{ closeOutDateLabel }}:</mat-label>
                              <mat-label class="padding-left-20">{{ approvedIssuePlanned | date:dateTimeFormat :
                                utcoffsetvalue }}</mat-label>
                            </div>
                            <div class="col-md-4 display-flex">
                              <mat-label class="font-color-darkgrey fw-bolder">Created Date:</mat-label>
                              <mat-label class="padding-left-20">{{ interfaceCreatedDate | date:dateTimeFormat :
                                utcoffsetvalue }}</mat-label>
                            </div>
                          </div>
                        </div>

                        <div class="g-2 display-flex" *ngIf="firstIssueActual != null || approvedIssueActual != null">
                          <div class="row col-md-12 margin-top-bottom-10">
                            <div class="col-md-4 display-flex" *ngIf="firstIssueActual != null">
                              <mat-label class="font-color-darkgrey fw-bolder">{{ responseDateActualLabel
                                }}:</mat-label>
                              <mat-label class="padding-left-20">{{ interfaceStatus ==
                                awaitingForResponseApprovalStatusId ? '' : firstIssueActual | date:dateTimeFormat :
                                utcoffsetvalue }}</mat-label>
                            </div>
                            <div class="col-md-4 display-flex"
                              *ngIf="approvedIssueActual != null && iterfaceStatus != awaitingForCloseOutStatusId">
                              <mat-label class="font-color-darkgrey fw-bolder">{{ closeOutDateActualLabel
                                }}:</mat-label>
                              <mat-label class="padding-left-20">{{ approvedIssueActual | date:dateTimeFormat :
                                utcoffsetvalue }}</mat-label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>

                </div>
              </div>


            </div>

          </mat-tab>
          <mat-tab label="Document  {{documentCount != ''? '(' + documentCount +')' : ''}}">
            <div class="h500tabMin">
              <app-document-create (documentCountadded)="documentCountaddedHandler($event)"
                (documentsadded)="documentsaddedHandler($event)"
                (uploadDocsadded)="uploadDocsaddedHandler($event)"></app-document-create>
            </div>
          </mat-tab>
        </mat-tab-group>

        <div class="mt-2 mb-2" align="right">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary" (click)='save()'
            [disabled]="this.form.invalid">
            <mat-icon class="WIRbutton-icons">save</mat-icon>Save
          </button>
          <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>