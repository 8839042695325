import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceGroup } from 'src/app/app_data/interfaceGroup';
import { DialogService } from 'src/app/services/dialog.service';
import { InterfaceGroupService } from 'src/app/services/interfaceGroup.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/app_data/user';
import { InterfaceService } from 'src/app/services/interface.service';
import { Interface } from 'src/app/app_data/interface';
import { MatSelect } from '@angular/material/select';
import { ProjectSettingService } from 'src/app/services/projectsetting.service';
import { DatePipe, formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { CommunicationService } from 'src/app/services/communication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MinutesOfMeeting } from 'src/app/app_data/minutesOfMeeting';
import { MinutesOfMeetingDetails } from 'src/app/app_data/minutesOfMeetingDetails';
import { MinutesofMeetingService } from 'src/app/services/minutesofmeeting.service';
import { MinutesOfMeetingAttendees } from 'src/app/app_data/minutesOfMeetingAttendees';
import { ActionItem } from '../../app_data/actionItem';
import { MatTabGroup } from '@angular/material/tabs';
import { ActionItemService } from 'src/app/services/actionItem.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { PdfGenerateComponent } from 'src/app/shared/components/pdf-generate/pdf-generate.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';

let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  selector: 'app-minutesofmeeting-view',
  templateUrl: './minutesofmeeting-view.component.html',
  styleUrls: ['./minutesofmeeting-view.component.css'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class MinutesofmeetingViewComponent implements OnInit {
  pageTitle = 'View Minutes of Meeting';
  form !: FormGroup;
  errorMessage = '';
  selectedRequestingContractorUsers: User[] = [];
  selectedResponsibleContractorUsers: User[] = [];
  interfaceListData: Interface[] = [];
  filteredInterfaces: Interface[] = [];
  contractor1Id = 0;
  contractor2Id = 0;
  interfaceGroupID: number = 0;
  momModel: MinutesOfMeeting;
  momExternalAttendees !: MinutesOfMeetingAttendees;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  documentCount: string = "";
  projectName: string = sessionStorage.getItem("projectName")!;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  utcoffsetvalue: string = sessionStorage.getItem("projectTimeZone")!.substring(0, sessionStorage.getItem("projectTimeZone")!.lastIndexOf(':'))!;
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);
  commonFunction!: CommonFunction;

  selectedInterface!: any;
  interfaceNumbers: number[] = [];
  selectedInterfaceGroup: number = 0;
  interfaceGroups: InterfaceGroup[] = [];
  dateTimeFormat: string = "";
  isDualDisciplinebool: boolean = false;
  allRequestingUsers: User[] = [];
  allResponsibleUsers: User[] = [];
  todayDate = new Date;
  todayDatepipe: any;
  pipe = new DatePipe('en-US');
  dpColorTheme: string = "theme-dark-blue";
  statusText: string = "";
  actionItemStatusText: string = "";
  minutesOfMeetingAttendees: MinutesOfMeetingAttendees[] = [];
  momId!: number;
  isDocumentPresent: boolean = false;
  isInterfacePresent: boolean = false;
  isActionItemPresent: boolean = false;
  contractor1Name : string = "Contractor1";
  contractor2Name : string = "Contractor2";
  momStatusName: string = "";
  tabModuleTypeName: string = "Interface";
  docDefination: any;
  momDetails: MinutesOfMeetingDetails[] = [];
  momDetailsWithInterfaces: MinutesOfMeetingDetails[] = [];

  @ViewChild('InterfacesList') select!: MatSelect;
  validRecord: boolean = false;
  @ViewChild('PdfGenerateComponent') pdfGenerateComponentRef!: PdfGenerateComponent;

  actionStatusList: any;

  reportName: string = "Minutes of Meeting Sheet";

  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;
  
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private interfaceGroupService: InterfaceGroupService,
    private SpinnerService: NgxSpinnerService, private userService: UserService,
    private minutesofMeetingService: MinutesofMeetingService,
    private logger: NGXLogger,
    private dialog: DialogService,
    private actionitemService: ActionItemService,
    private projectSettingService: ProjectSettingService,
    private interfaceService: InterfaceService, 
    private communicationService: CommunicationService,
    private projectService: ProjectService, private rolePermissionService: RolePermissionService,
    private router: Router,
    private route: ActivatedRoute,) {
    this.todayDatepipe = this.pipe.transform(this.todayDate, 'yyyy-MM-dd');
    this.momModel = new MinutesOfMeeting();
    this.momExternalAttendees = new MinutesOfMeetingAttendees();
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
  }

  ngOnInit(): void {
    try {
      this.momId = this.route.snapshot.params['id']
      this.checkIfMoMIdValid(this.momId);
      this.isDocumentPresent = this.communicationService._isDocumentPresent;
      this.communicationService._isDocumentPresent = false;
      this.isInterfacePresent = this.communicationService._isInterfacePresent;
      this.communicationService._isInterfacePresent = false;
      this.isActionItemPresent = this.communicationService._isActionItemPresent;
      this.communicationService._isActionItemPresent = false;
      this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
      this.getProjectSettings();      
      this.form = new FormGroup({
        requestingUsersFormControl: new FormControl(),
        responsibleUsersFormControl: new FormControl()
      });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  checkIfMoMIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeetingByIdandProjectId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.momId > 0 && this.validRecord) {
            this.getMinutesofMeetingData(this.momId);
          }
          else {
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  actionitemaddedhandler(actionItem: ActionItem[] = []) {
    this.momModel.ActionItems = actionItem;
  }

  getMinutesofMeetingData(momId: number) {
    try {
      this.SpinnerService.show();
      this.minutesofMeetingService.getMinutesofMeetingById(momId)
        .subscribe({
          next: momData => {            
            this.momModel = momData[0];
            this.momDetails = momData[0].MinutesOfMeetingDetails;
            this.momModel.MinutesOfMeetingDetails.forEach(x => {
              x.Interfaces!.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.Interfaces?.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Interfaces?.Status!);
              x.Interfaces!.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.Interfaces?.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Interfaces?.Status!);
            })
            this.tabModuleTypeName = momData[0].Module?.Name!;        
            this.communicationService.setFilePathName(this.momModel.FilePathName);
            
            this.communicationService.setDocumentsdata(this.momModel.Documents.filter(x => !x.IsDeleted));
            this.communicationService.setactionitemdata(this.momModel);
            this.documentCount = "Documents (" + this.momModel.Documents.filter(x => !x.IsDeleted).length.toString() + ")";

            this.selectedInterfaceGroup = this.momModel.InterfaceGroupID;

            this.contractor1Name = this.momModel.InterfaceGroup?.Contractor1.Name;
            this.contractor2Name = this.momModel.InterfaceGroup?.Contractor2.Name;
            if (this.communicationService._actionItemId != 0) {
              this.tabGroup.selectedIndex = 3;
            }
            this.communicationService._actionItemId = 0;

            if (this.momModel.MeetingDate != null) {
              this.momModel.MeetingDate = new Date(this.momModel.MeetingDate);
            }

            if (this.momModel.NextMeetingDate != null) {
              this.momModel.NextMeetingDate = new Date(this.momModel.NextMeetingDate);
            }
            this.communicationService.sendInterfacegrpchanged(this.momModel.InterfaceGroupID);
            this.generateMOM();

            var contractor1Attendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.Contractor1AttendeeNames != null)?.Contractor1AttendeeNames;
            this.momExternalAttendees.Contractor1AttendeeNames = contractor1Attendees != "" ? contractor1Attendees : "";

            var contractor2Attendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.Contractor2AttendeeNames != null)?.Contractor2AttendeeNames;
            this.momExternalAttendees.Contractor2AttendeeNames = contractor2Attendees != "" ? contractor2Attendees : "";

            var externalAttendees = this.momModel.MinutesOfMeetingAttendees?.find(x => x.ExternalAttendees != null)?.ExternalAttendees;
            this.momExternalAttendees.ExternalAttendees = externalAttendees != "" ? externalAttendees : "";
            this.momDetailsWithInterfaces = this.momModel.MinutesOfMeetingDetails!;

            this.momModel.MinutesOfMeetingDetails?.forEach(interfaceData => {
              this.interfaceNumbers.push(interfaceData.InterfaceID)
            });

            this.selectedInterface = this.interfaceNumbers;


            if (this.isDocumentPresent) {                 
                this.tabGroup.selectedIndex = 2;
            }
            if (this.isInterfacePresent) {
              this.tabGroup.selectedIndex = 1;
            }
            if (this.isActionItemPresent) {
              this.tabGroup.selectedIndex = 3;
            }
            this.setMomStatusAndActionButton();
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
            MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
            MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
            var index = projectSettings.findIndex(x => x.ConfigKey == "UTCOffset");
            if (index > -1) {
              var offsetValue = projectSettings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue.split("|")[0]!;
              this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsCustomWIRDefination");
            if (index > -1) {
              var settingvalue = projectSettings.filter(x => x.ConfigKey == "IsCustomWIRDefination")[0].ConfigValue;
              if (settingvalue == "true") {

                var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
                if (index > -1) {
                  this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
                if (index > -1) {
                  this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
                }

                var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
                if (index > -1) {
                  this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
                }
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
                if (index > -1) {
                  this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;                                  
                }  
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }

            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });
            this.SpinnerService.hide();
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  setMomStatusAndActionButton() {
    if (!this.momModel.IsSignedByContractor1 && !this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM must be signed by both Contractor1 and Contractor2";
    }
    else if (!this.momModel.IsSignedByContractor1) {
      this.momStatusName = "This MOM must be signed by Contractor1";
    }
    else if (!this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM must be signed by Contractor2";
    }
    else if (this.momModel.IsSignedByContractor1 && this.momModel.IsSignedByContractor2) {
      this.momStatusName = "This MOM is signed and closed";
    }
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroups = [];
      if (this._userRoleId == Number(myGlobals.ProjectAdminRoleId)) {
        this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(mprojectId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      } else {
        this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
          next: interfaceGroup => {
            this.interfaceGroups = interfaceGroup;
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

 

  generateMOM() {
    try {
      var interfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
      if (interfaceGroupData.length > 0) {        
        this.contractor1Id = interfaceGroupData[0].Contractor1ID;
        this.contractor2Id = interfaceGroupData[0].Contractor2ID;

        this.getRequestingContractorUserList(this.contractor1Id);
        this.getResponsibleContractorUserList(this.contractor2Id);
      }

      this.SpinnerService.show();
      this.interfaceService.getAllInterfacesByInterfaceGroupIdMOM(this._projectID, this.selectedInterfaceGroup,"")
        .toPromise()
        .then(data => {
          this.SpinnerService.hide();
          if (data!.length > 0) {
            this.interfaceListData = data!;
          }

          if (this.momId > 0) {
            this.addInterfacesToMoM();
          }
        })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getRequestingContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid)
        .toPromise()
        .then(allusers => {
          this.allRequestingUsers = allusers!.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);

          if (this.momId > 0) {
            var contractor1Attendees = this.momModel.MinutesOfMeetingAttendees?.filter(x => x.Contractor1ID == this.contractor1Id)

            if (contractor1Attendees!.length > 0) {
              contractor1Attendees?.forEach((attendee: any) => {
                this.selectedRequestingContractorUsers.push(...this.allRequestingUsers.filter(x => x.Id == attendee.Contractor1AttendeeUserID && !x.IsDeleted));

                let userReq = this.allRequestingUsers.findIndex(item => item.Id === attendee.Contractor1AttendeeUserID);

                if (userReq !== -1) {
                  this.allRequestingUsers.splice(userReq, 1);
                }
              });
            }
          }
          this.SpinnerService.hide();
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }



  getResponsibleContractorUserList(contractorid: number) {
    try {
      this.SpinnerService.show();
      this.userService.getContactsByContractor(contractorid)
        .toPromise()
        .then(allusers => {
          this.allResponsibleUsers = allusers!.filter(a => !a.IsDeleted && a.UserRoleProjects.length > 0);

          if (this.momId > 0) {
            var contractor2Attendees = this.momModel.MinutesOfMeetingAttendees?.filter(x => x.Contractor2ID == this.contractor2Id)

            if (contractor2Attendees!.length > 0) {
              contractor2Attendees?.forEach((attendee: any) => {
                this.selectedResponsibleContractorUsers.push(...this.allResponsibleUsers.filter(x => x.Id == attendee.Contractor2AttendeeUserID));

                let userRes = this.allResponsibleUsers.findIndex(item => item.Id === attendee.Contractor2AttendeeUserID);

                if (userRes !== -1) {
                  this.allResponsibleUsers.splice(userRes, 1);
                }
              });
            }
          }
          this.SpinnerService.hide();
        })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onView(interfaceId: number) {
    try {
      this.SpinnerService.show();
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = this.momModel.Module?.Name == "Interface" ? "InterfaceView/" + interfaceId : "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl);
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  addInterfacesToMoM() {
    try {
      this.filteredInterfaces = [];
      if (this.selectedInterface.length > 0) {
        this.selectedInterface.forEach((element: any) => {
          var selectedInterfaceList = this.interfaceListData.filter(x => x.Id == element);
          this.filteredInterfaces.push(...selectedInterfaceList);
        });

        this.filteredInterfaces.forEach(element => {
          var momIndex = this.momModel.MinutesOfMeetingDetails.findIndex(x => x.InterfaceID == element.Id);
          if (momIndex == -1) {
            this.momModel.MinutesOfMeetingDetails.push({ Id: 0, MinutesOfMeetingID: 0, Interfaces: element, InterfaceID: element.Id, PreviousText: "", Text: "", Comments: "", IsDeleted: false, CreatedBy: this._userRoleMappingId })
          }

        });      
        this.momModel.MinutesOfMeetingDetails = this.momModel.MinutesOfMeetingDetails.filter(x=> !x.IsDeleted);
       
      } else {
        this.filteredInterfaces = [];
      }

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }



  setStatusName(status: number) {
    this.actionStatusList = this.actionitemService.getActionItemStatus().filter(x => x.Id == status);
    this.actionItemStatusText = this.actionStatusList[0].Name;
  }

  generateMomSheet() {
    try {               
      var AttendeesFromContractor1body =[];
      var AttendeesFromContractor1Columns = "";
      for (var key in this.selectedRequestingContractorUsers) {
        if (this.selectedRequestingContractorUsers.hasOwnProperty(key)) {
          var header = this.selectedRequestingContractorUsers[key];

          if(AttendeesFromContractor1Columns.length == 0){
            AttendeesFromContractor1Columns = header.FirstName + " " + header.LastName;
          }else{
            AttendeesFromContractor1Columns += ", " + header.FirstName + " " + header.LastName;
          }                
        }        
      }

      if (this.momExternalAttendees.Contractor1AttendeeNames != null) {
        if (AttendeesFromContractor1Columns.length == 0) {
          AttendeesFromContractor1Columns = this.momExternalAttendees.Contractor1AttendeeNames;
        } else {
          AttendeesFromContractor1Columns += ", " + this.momExternalAttendees.Contractor1AttendeeNames
        }
      }
      var AttendeesFromContractor1ColumnsText =  [{text: AttendeesFromContractor1Columns}];
      AttendeesFromContractor1body.push(AttendeesFromContractor1ColumnsText);

      //attendees from contractor 2
      var AttendeesFromContractor2body =[];
      var AttendeesFromContractor2Columns = "";
      for (var key in this.selectedResponsibleContractorUsers) {
        if (this.selectedResponsibleContractorUsers.hasOwnProperty(key)) {
          var header = this.selectedResponsibleContractorUsers[key];

          if(AttendeesFromContractor2Columns.length == 0){
            AttendeesFromContractor2Columns = header.FirstName + " " + header.LastName;
          }else{
            AttendeesFromContractor2Columns += ", " + header.FirstName + " " + header.LastName;
          }                
        }        
      }
      if (this.momExternalAttendees.Contractor2AttendeeNames != null) {
        if (AttendeesFromContractor2Columns.length == 0) {
          AttendeesFromContractor2Columns = this.momExternalAttendees.Contractor2AttendeeNames;
        } else {
          AttendeesFromContractor2Columns += ", " + this.momExternalAttendees.Contractor2AttendeeNames
        }
      }
      var AttendeesFromContractor2ColumnsText =  [{text: AttendeesFromContractor2Columns}];
      AttendeesFromContractor2body.push(AttendeesFromContractor2ColumnsText);
      
    var docbody = [];
    var docColumns = [{ text: "No.", fillColor: '#dcdcdc' }, { text: "Title", fillColor: '#dcdcdc' }, { text: "Added By", fillColor: '#dcdcdc' }, { text: "Submit Date", fillColor: '#dcdcdc' }];
    docbody.push(docColumns);
      if (this.momModel.Documents.filter(x => !x.IsDeleted).length > 0) {
        for (var key in this.momModel.Documents.filter(x => !x.IsDeleted)) {
          if (this.momModel.Documents.filter(x => !x.IsDeleted).hasOwnProperty(key)) {
            var docHeader = this.momModel.Documents.filter(x => !x.IsDeleted)[key];
          var row = new Array();
          row.push(docHeader.Number);
          row.push(docHeader.Title);
          row.push(docHeader.CreatedUserRoleMapping != null ? docHeader.CreatedUserRoleMapping?.User.FirstName + ' ' + docHeader.CreatedUserRoleMapping?.User.LastName + ' - (' + docHeader.Contractor.Name + ')' : this._userName);
          row.push(formatDate(docHeader.CreatedDate!,"mediumDate","en-US", this.utcoffsetvalue));
          docbody.push(row);
        }
      }      
    }
    else {
      var row = new Array();
      row.push({ text: "No Data", colSpan: 4,alignment:'center' });
      docbody.push(row);
    }

    var interfaceList=[]
    var interfaceBody : any[] = [];
    if (this.momModel.MinutesOfMeetingDetails.length > 0) {          
      for (var momdetailsdata in this.momModel.MinutesOfMeetingDetails) {
        if (this.momModel.MinutesOfMeetingDetails.hasOwnProperty(momdetailsdata)) {
          var momdata = this.momModel.MinutesOfMeetingDetails[momdetailsdata];
          var row = new Array();
          interfaceBody = [
            [{ text: this.tabModuleTypeName + ' Number: ', style: 'tableHeader', fillColor: '#dcdcdc' }, { text: momdata.Interfaces?.Number }, { text: 'Status: ', style: 'tableHeader', fillColor: '#dcdcdc' }, { text: this.commonFunction.getStatusText(momdata.Interfaces!.Status),border: [true, true, false, true]},{text:'',border: [false, true, false, true]},{text:'',border: [false, true, true, true]}]
          ,[{text:'Title: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momdata.Interfaces?.Title,colSpan: 5}]
          ,[{text:'Description: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momdata.Interfaces?.Description,colSpan: 5}]
          ,[{text:this.expectedResponseDateLabel +': ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:formatDate(momdata.Interfaces?.FirstIssuePlanned!,"mediumDate","en-US")}, {text:this.closeOutDateLabel +': ',style: 'tableHeader',fillColor: '#dcdcdc'},{text:formatDate(momdata.Interfaces?.ApprovedIssuePlanned!,"mediumDate","en-US")}, {text:'Created Date:  ',style: 'tableHeader',fillColor: '#dcdcdc'},{text:formatDate(momdata.Interfaces?.CreatedDate!,"mediumDate","en-US",this.utcoffsetvalue)}]
          ,[{text:this.responseDateActualLabel +': ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momdata.Interfaces?.FirstIssueActual != null? formatDate(momdata.Interfaces?.FirstIssueActual!,"mediumDate","en-US",this.utcoffsetvalue) : ""}, {text:this.closeOutDateActualLabel +': ',style: 'tableHeader',fillColor: '#dcdcdc'},{text:momdata.Interfaces?.ApprovedIssueActual != null? formatDate(momdata.Interfaces?.ApprovedIssueActual!,"mediumDate","en-US",this.utcoffsetvalue) : "",colSpan: 3 }]
          ,[{text:'Meeting Minutes: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momdata.Text,colSpan: 5}]
        ,[{text:" ",	border: [false, true, false, false], colSpan: 6}]
        ];
          
        interfaceList.push({
            table: {
              widths: ['*','auto','*','auto','*','auto'], 
              dontBreakRows: true,           
              body: interfaceBody
            },
          });
        }
      }                 
    }

    //Action List
    var actionItemList=[]
    var actionItemBody : any[] = [];
    if (this.momModel.ActionItems.length > 0) {
      for (var momActiondata in this.momModel.ActionItems) {
        if (this.momModel.ActionItems.hasOwnProperty(momActiondata)) {          
          var momActionItemdata = this.momModel.ActionItems[momActiondata];   
          var actionItemStatusName = this.setStatusName(momActionItemdata.Status)            
          actionItemBody = [
           [{text:' Action Number: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momActionItemdata.Id}, {text:'Status: ',style: 'tableHeader',fillColor: '#dcdcdc'},{text:this.actionItemStatusText}]
          ,[{text:'Title: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momActionItemdata.Title,colSpan: 3}]
          ,[{text:'Description: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:momActionItemdata.Description,colSpan: 3}]
          ,[{text:'Action Date: ',style: 'tableHeader',fillColor: '#dcdcdc'}, {text:formatDate(momActionItemdata.ActionDate,"mediumDate","en-US")}, {text:'Responsible: ',style: 'tableHeader',fillColor: '#dcdcdc'},{text:momActionItemdata.Responsible}]
          ,[{text:" ",	border: [false, true, false, false], colSpan: 4}]
        ];
          
        actionItemList.push({
            table: {
              widths: ['auto',180,'auto',200], 
              dontBreakRows: true,           
              body: actionItemBody
            },
          });
        }
      }                 
    }else {
      var row = new Array();
      row.push({text:"No Data",border: [true, true, true, true], colSpan: 4});
      actionItemList.push(row);
    }

      this.docDefination = [
        { text: 'Meeting Details: ', fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: "momtableExample",
          table: {
            widths: [80, '*', 50, '*'],
            headerRows: 0,
            body: [
              [{ text: 'Title: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.Title,colSpan: 3 },{text:''},{text:''}],
              [{ text: 'Interface Group: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { colSpan: 3, text: this.momModel.InterfaceGroup?.Name }],
              [{ text: 'Contractor1: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.InterfaceGroup?.Contractor1?.Name! }, { text: 'Contractor2: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.InterfaceGroup?.Contractor2?.Name! }],
              [{ text: 'Status: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momStatusName }, { text: 'Module: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.Module?.Name }],
              [{ text: 'Meeting Location: ',style: 'tableHeader',fillColor: '#dcdcdc'}, { text: this.momModel.MeetingLocation }, { text: 'Meeting Date: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: formatDate(this.momModel.MeetingDate,"mediumDate","en-US")}],
              [{ text: 'Next Meeting Location: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.NextMeetingLocation }, { text: 'Next Meeting Date: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.NextMeetingDate != null ? formatDate(this.momModel.NextMeetingDate!,"mediumDate","en-US") : ""}],
              [{ text: "Signed by "+ this.contractor1Name +": ",style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.IsSignedByContractor1? "Yes":"No" }, { text: "Signed by "+ this.contractor2Name +": ",style: 'tableHeader',fillColor: '#dcdcdc' }, { text: this.momModel.IsSignedByContractor2? "Yes":"No"}],
              [{ text: 'Description: ',style: 'tableHeader',fillColor: '#dcdcdc' }, { colSpan: 3, text: this.momModel.MeetingDescription }]
            ]
          },
        },

        { text: "Attendees from "+ this.contractor1Name +" :", fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 0,
            body: AttendeesFromContractor1body
          },
          layout: 'headerLineOnly'
        },

        { text: "Attendees from "+ this.contractor2Name +": ", fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 0,
            body: AttendeesFromContractor2body
          },
          layout: 'headerLineOnly'
        },

        { text: 'External Attendees(if any): ', fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 0,
            body: [
              [{text: this.momExternalAttendees.ExternalAttendees }]
            ]
          },
          layout: 'headerLineOnly'
        },
        
        { text: 'Enclosed Documents: ', fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*'],
            body: docbody
          }
        },

        { text: this.tabModuleTypeName +' List: ', fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: "tableExample",
          table: {
            headerRows: 0,
            body: [
              [
                interfaceList
              ]
            ]
            
          },
          layout: 'noBorders'
        },
        { text: 'Action List: ', fontSize: 12, bold: true, margin: [0, 8] },
        {
          style: "tableExample",
          table: {
            headerRows: 0,
            body: [
              [
                actionItemList
              ]
            ]
            
          },
          layout: 'noBorders'
        }
      ];
      this.pdfGenerateComponentRef.docDefination = this.docDefination;
      this.pdfGenerateComponentRef.generatePdf(this.reportName, this.projectName);
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

}
