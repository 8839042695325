<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <div class="row">
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Contractor Name</mat-label>
          <input matInput autocomplete="off" [formControl]="contractorNameFilter" placeholder="Search Contractor Name">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Email</mat-label>
          <input matInput autocomplete="off" [formControl]="emailFilter" placeholder="Search Email">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Role</mat-label>
          <input matInput autocomplete="off" [formControl]="roleFilter" placeholder="Search Role">
        </mat-form-field>
        <mat-form-field appearance="standard" class="filter">
          <mat-label>Filter Status</mat-label>
          <input matInput autocomplete="off" [formControl]="statusFilter" placeholder="Search Status">
        </mat-form-field>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <mat-paginator #paginatorInviteUser [pageSizeOptions]="[10, 15, 20]"
                         showFirstLastButtons>
          </mat-paginator>
          <div class="table-responsive scroller-list-Screen">
            <table mat-table [dataSource]="inviteUserdataSource" class="mat-elevation-z8" matSort #sortInviteUser="matSort">
              <ng-container matColumnDef="Contractor.Name">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Contractor </th>
                <td mat-cell *matCellDef="let disc"> {{ disc.Contractor.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="Email">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Email </th>
                <td mat-cell *matCellDef="let disc"> {{disc.Email}} </td>
              </ng-container>
              <ng-container matColumnDef="Role.Name">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Role </th>
                <td mat-cell *matCellDef="let disc"> {{disc.Role.Name}} </td>
              </ng-container>
              <ng-container matColumnDef="ValidTill">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Valid Till </th>
                <td mat-cell *matCellDef="let disc"> {{disc.ValidTill | date:dateTimeFormat : utcoffsetvalue}}</td>
              </ng-container>
              <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let disc"> {{disc.Status}} </td>
              </ng-container>
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
                  <span><br />{{noRecordFoundMsg}}</span>
                </td>
              </tr>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
