<form [formGroup]="discForm">
  <div class="row">
    <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 toggleAlign">
      <mat-slide-toggle (click)="isImportBulkDisciplineChecked()">Bulk Import</mat-slide-toggle>
      <span>
        <a class="templateAnchor" *ngIf="this.isChecked" matTooltip="click to download template format" (click)="ExportTemplateAsExcel()">Template format</a>
      </span>
    </div>
  </div>
  <div class="row" *ngIf="!this.isChecked">
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Code</mat-label>
        <input matInput maxlength="30" inputmode="tel" autocomplete="off" placeholder="Discipline Code" [(ngModel)]="discipline.Code" formControlName="Code" #nameInput appTrimWhitespace>
        <button *ngIf="isDisplay? isDisplay : isEdit? isDisplay: discipline.Code" matSuffix mat-icon-button aria-label="Clear" (click)="discipline.Code=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="(discForm.get('Code'))?.errors?.required">
          Discipline Code is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Name</mat-label>
        <input matInput maxlength="50" autocomplete="off" inputmode="tel" placeholder="Discipline Name" [(ngModel)]="discipline.Name" formControlName="Name" #nameInput appTrimWhitespace>
        <button *ngIf="discipline.Name" matSuffix mat-icon-button aria-label="Clear" (click)="discipline.Name=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="(discForm.get('Name'))?.errors?.required">
          Discipline Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Discipline Lead</mat-label>
        <input matInput type="email" autocomplete="off" maxlength="50" inputmode="tel" placeholder="Discipline Lead" [(ngModel)]="discipline.DisciplineLead" formControlName="DisciplineLead" #leadInput appTrimWhitespace>
        <button *ngIf="discipline.DisciplineLead" matSuffix mat-icon-button aria-label="Clear" (click)="discipline.DisciplineLead=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="(discForm.get('DisciplineLead'))?.errors?.required">
          Discipline Lead is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="(discForm.get('DisciplineLead'))?.errors?.pattern">Please enter valid Discipline Lead Email</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button type="button" [disabled]="discForm.invalid" [hidden]="discForm.valid && this.isEdit" (click)="add()" class="WIRbutton WIRbutton-primary btn-sm">
        <mat-icon>add</mat-icon>Add
      </button>
    </div>
    <div [hidden]="!this.isEdit">
      <button mat-stroked-button type="button" [hidden]="!this.isEdit" (click)="update(this.discipline)" class="WIRbutton WIRbutton-primary btn-sm update-button">
        <mat-icon class="WIRbutton-icons">update</mat-icon>Update
      </button>
      <button mat-stroked-button type="button" [hidden]="!this.isEdit" (click)="resetForm()" class="btn btn-secondary btn-sm">
        <mat-icon class="WIRbutton-icons">restart_alt</mat-icon>Reset
      </button>
    </div>
  </div>
  <form [formGroup]="bulkImportForm">
    <div class="row" *ngIf="this.isChecked">
      <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
        <mat-form-field appearance="standard" class="ptZero">
          <mat-label>Choose file</mat-label>
          <mat-toolbar class="toolbarFontSize">
            <input matInput [value]="fileAttr" formControlName="discUploadFile" readonly name="name">
          </mat-toolbar>
          <input type="file" #fileInput (change)="importDisciplineData($event)" id="uploadFile" name="uploadfile"
                 accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
          <mat-error *ngIf="this.bulkImportForm.get('discUploadFile')?.hasError('invalidFile')">
            Please upload valid template file
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-xl-2 col-l-2 col-md-2 col-sm-6 col-xs-6 mt-4">
        <button mat-stroked-button type="button" [disabled]="!isFileUploaded" (click)="bulkImportDiscipline()"
                class="WIRbutton WIRbutton-primary">
          <mat-icon class="WIRbutton-icons">add</mat-icon>Import
        </button>
      </div>

      <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 mt-4" *ngIf="this.isCountDivEnable">
        <div class="row">
          <div class="col-xl-4 col-l-4 col-md-4 col-sm-4 col-xs-4 countRed">
            Failed count:<br /><a class="failedRecordAnchor" matTooltip="click to download" (click)="ExportTOExcel()">{{failedImportCount}}</a>
          </div>
          <div class="col-xl-4 col-l-4 col-md-4 col-sm-4 col-xs-4 countSuccess">
            Success count:<br />{{successImportCount}}
          </div>
          <div class="col-xl-4 col-l-4 col-md-4 col-sm-4 col-xs-4 countTotal">
            Total count:<br />{{totalImportCount}}
          </div>
        </div>
      </div>
    </div>
  </form>
</form>

<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatordiscipline [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="disciplinedataSource" class="mat-elevation-z8" matSort #sortdiscipline="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <div class="d-flex">
              <mat-icon matTooltip="edit" class="mat-icon-small-size" (click)="edit(disc)">edit</mat-icon>
              <mat-icon matTooltip="delete" class="mat-icon-small-size" (click)="confirmCancelDialog(disc.Id, disc.Code, disc.Name)">delete</mat-icon>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Code">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Code </th>
          <td mat-cell *matCellDef="let disc" > {{disc.Code}} </td>
        </ng-container>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let disc" > {{disc.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="DisciplineLead">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Discipline Lead </th>
          <td mat-cell *matCellDef="let disc" > {{disc.DisciplineLead}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
  

</div>

