import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Contractor } from '../../app_data/contractor';
import { Discipline } from '../../app_data/discipline';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { CommunicationService } from '../../services/communication.service';
import { ContractorService } from '../../services/contractor.service';
import { SystemLogService } from '../../services/systemLog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-contractor-edit',
  templateUrl: './contractor-edit.component.html',
  styleUrls: ['./contractor-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractorEditComponent implements OnInit {
  form !: FormGroup;
  contractor !: Contractor;
  discipline !: Discipline;
  pageTitle: string = "Edit Contractor";
  errorMessage: string = "";
  contractorID!: number;
  isEdit = false;  
  interfaceGroupTooltip: string = "";
  validRecord: boolean = false;

  @ViewChild(MatTabGroup) tabGroup !: MatTabGroup;
  
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");

  constructor(private contractorService: ContractorService, private router: Router, private communicationService: CommunicationService,
    private systemLogService: SystemLogService,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private logger: NGXLogger, private dialog: DialogService,
    private snackBar:MatSnackBar) {
    this.contractor = new Contractor(); this.discipline = new Discipline();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Address: new FormControl(''),
      City: new FormControl(''),
      Country: new FormControl(''),
      ContactPerson: new FormControl(''),
      EmailId: new FormControl('', [Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      PhoneNumber: new FormControl('', [Validators.pattern(/^-?(\d+-?)+$/)]),
      IsDeleted: new FormControl(false),
    });
    this.contractorID = this.route.snapshot.params['id'];
    this.checkIfContractorIdValid(this.contractorID);
    this.communicationService._interfaceId = 0;

    this.form.controls['Code'].disable();
  }

  checkIfContractorIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorByIdAndProjecId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = data.length > 0 ? true : false;
          if (this.contractorID > 0 && this.validRecord) {
            this.isEdit = true;
            this.getbyContractorId(this.contractorID);
          }
          else {
            this.isEdit = false;
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  disciplineaddedHandler(discipline: Discipline[] = []) {
    this.contractor.Disciplines = discipline;
  }

  interfacegrpaddedHandler(interfacegroup: InterfaceGroup[] = []) {
    this.contractor.Contractor1InterfaceGroups = interfacegroup;
  }

  interfaceGroupTemplateMappingAddedHandler(interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = []) {
    this.contractor.Contractor1InterfaceGroups.forEach(element => {
      var templateData = interfaceGroupTemplateMappings.filter(x => x.InterfaceGroupId == element.Id);
      if (templateData.length > 0 && element.Id == templateData[0].InterfaceGroupId) {
        element.InterfaceGroupTemplateMappings = [];
        element.InterfaceGroupTemplateMappings?.push(...templateData);
      }
    });
  }

  save() {
    try {
      this.SpinnerService.show();
      if (this.form.valid) {
        if (this.contractorID > 0) {
          this.contractor.Disciplines = this.contractor.Disciplines.filter(x => (x.Id != 0 || !x.IsDeleted));
          this.contractor.Contractor1InterfaceGroups = this.contractor.Contractor1InterfaceGroups.filter(x => (x.Id != 0 || !x.IsDeleted) && x.Contractor1ID == this.contractor.Id);
          this.contractor.ModifiedBy = this._userIdLogin;
          this.contractor.Contractor1InterfaceGroups.forEach((docRow) => {
            if (docRow.Id == 0) {
              docRow.Contractor1 = null;
              docRow.Contractor2 = null;              
            }

            docRow.InterfaceGroupTemplateMappings?.forEach(templateMapping => {
              templateMapping.InterfaceGroup = null!;
              templateMapping.Module = null!;
              templateMapping.WorkflowTemplate = null!;
              templateMapping.Project = null!;
            });

          });       

          this.contractorService.put(this.contractor).subscribe({
            next: allcontractors => {              
              this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Edit Contractor", "Contractor Detail", this.contractor.Name + "|" + this.contractor.Code + "|" + this.contractor.ContactPerson
                + "|" + this.contractor.EmailId + "|" + this.contractor.PhoneNumber + "|" + this.contractor.Address + "|" + this.contractor.City + "|" + this.contractor.Country, this._contractorID, this._userRoleId);
              this.contractor.Disciplines.forEach((docRow) => {
                //Write a log
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Edit Contractor", "Disciplines", docRow.Name + "|" + docRow.Code + "|" + docRow.DisciplineLead + "|" + docRow.IsDeleted, this._contractorID, this._userRoleId);
              });
              this.contractor.Contractor1InterfaceGroups.forEach((docRow) => {
                //Write a log
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Edit Contractor", "Interfacegroups", docRow.Name + "|" + docRow.Code + "|" + docRow.Contractor1 + "|" + docRow.Contractor2 + "|" + docRow.IsDeleted, this._contractorID, this._userRoleId);
              });
              
              this.SpinnerService.hide();
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: "Contractor saved successfully!",
                duration: myGlobals.snackBarDuration,
                verticalPosition: myGlobals.snackBarVerticalPosition,
                horizontalPosition: myGlobals.snackBarHorizontalPosition
              });
              this.redirecttoList();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          });
        }
      }

      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirecttoList() {
    this.router.navigateByUrl('/Contractors');
  }

  getbyContractorId(Id: number) {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractor(Id).subscribe({
        next: Data => {
          this.contractor = Data[0];
          this.communicationService.setDiscipliendata(this.contractor);
          this.communicationService.setInterfacegrpdata(this.contractor);
          this.communicationService._contractorname = this.contractor.Name;
          
           if (this.communicationService._interfaceGroupId != 0) {
            this.tabGroup.selectedIndex = 2;
          }
          this.communicationService._interfaceGroupId = 0;

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
