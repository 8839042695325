import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DisciplineCreateComponent } from './discipline-create.component';
import { DisciplineEditComponent } from './discipline-edit.component';
import { MaterialModule } from '../../shared/material/material.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';

@NgModule({
  declarations: [DisciplineCreateComponent, DisciplineEditComponent ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AuthorisationModule,
    CommonDirectivesModule
  ],
  exports: [DisciplineCreateComponent, DisciplineEditComponent]
})
export class DisciplineModule { }
