import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Risk } from '../app_data/risk';
import { response } from '../app_data/OdataResponse';
import { Observable, catchError, map, throwError } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})

export class RiskService {

  sortColumns = [
    { "DisplayName": "Newest First", "Value": "NewestFirst" },
    { "DisplayName": "Oldest First", "Value": "OldestFirst" },
    { "DisplayName": "Status", "Value": "Status" }];

  private apiUrl = environment.apiURl + 'Risks';
  isAddRiskToInterface = false;
  statusList = [{ Id: 1, Name: "Open" }, { Id: 2, Name: "Closed" }]
  probabilityLevels = [{ Id: 1, Probability: 1 }, { Id: 2, Probability: 2 }, { Id: 3, Probability: 3 }, { Id: 4, Probability: 4 }, { Id: 5, Probability: 5 }]
  consequenceLevels = [{ Id: 1, Consequence: 1 }, { Id: 2, Consequence: 2 }, { Id: 3, Consequence: 3 }, { Id: 4, Consequence: 4 }, { Id: 5, Consequence: 5 }]
  constructor(private http: HttpClient) { }

  getRiskStatus() {
    return this.statusList;
  }

  getRiskById(riskId: number): Observable<Risk[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=InterfaceGroup($expand=Contractor1,Contractor2),Module,RiskCategories,Documents,Interfaces & $filter=Id eq " + riskId + " and IsDeleted eq false&$orderby=Id desc")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: Risk): Observable<Risk> {
    return this.http.post<Risk>(this.apiUrl, model, httpOptions);
  }

  getAllRisks(filter: string): Observable<Risk[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Interfacegroup($expand=Contractor1,Contractor2),Module,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false),RiskCategories,Interfaces,Documents($filter=IsDeleted eq false;$expand=Contractor)&" + filter, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  put(model: Risk): Observable<Risk> {
    return this.http.put<Risk>(this.apiUrl + "/" + model.Id, model, httpOptions);
  }

  getRiskByIdandPrjectId(riskId: number, projectId: number): Observable<Risk[]> {
    return this.http.get<response>(
      this.apiUrl + "?$expand=InterfaceGroup($expand=Contractor1,Contractor2),Module,RiskCategories,Interfaces&$filter=Id eq " + riskId + " and ProjectId eq " + projectId + " and IsDeleted eq false&$orderby=Id desc"
    ).pipe(
      map((data: response) => data.value),
      catchError(this.handleError)
    );
  }

  getRisks(pageIndex: number, pageSize: number, filter: string): Observable<Risk[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Interfacegroup($expand=Contractor1,Contractor2),Module,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false),RiskCategories,Interfaces,Documents($filter=IsDeleted eq false;$expand=Contractor)&" + filter + "&$count=true&$top=" + pageSize + "&$skip=" + pageIndex * pageSize)
      .pipe(map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  
  getSortColumnList() {
    return this.sortColumns;
  }
  getRiskForCount(filter: string): Observable<Risk[]> {
    return this.http.get<response>(this.apiUrl + "?$select=Id" + filter)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
