<div class="container-fluid">
  <ng-container>
    <app-scrolltotop (scrollToTop)="onScrollToTop()" *ngIf="this.hideScrollToTop"></app-scrolltotop>
  </ng-container>
  <div class="row display-flex dashboardModule" *ngIf="this.moduleProjectMappings.length > 1">
    <div class="col-md-4 align-self-center padding-0">
    </div>
    <div class="col-md-2 align-self-center">
    </div>
    <div class="col-md-3 align-self-center">
    </div>
    <div class="col-md-3 col-sm-12 padding-1 moduleSelectionBar">
      <mat-button-toggle-group #colorToggle="matButtonToggleGroup" class="h-100 moduleTab" value="{{this.selectedModuleId}}">
        <mat-button-toggle *ngFor="let mapping of this.moduleProjectMappings" [hasPermission]="[mapping.Module?.ModulePermissionMappings[0].Permission.Name]" [hasModule]="[mapping.ModuleId]" class="h-100" value="{{mapping.ModuleId}}" (click)="populateDashboard(mapping.ModuleId,mapping.Module.Name)">
          {{mapping.Module.Name}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="mb-3">
    <mat-card class="">
      <mat-card-title class="mat-card-title">
        <div class="d-flex align-items-center justify-content-between header-card">
          <div class="title-font">{{cardHeaderLabel}} Group Summary</div>
          <div class="d-flex flex-row align-items-center w-status-icon">
            <div class="square-icon tx-identified mr-1"></div><div class="mr-1 tx-sm">Identified</div>
            <div class="square-icon tx-open mr-1"></div><div class="mr-1 tx-sm">Open</div>
            <div class="square-icon tx-responseoverdue mr-1"></div><div class="mr-1 tx-sm">Response Overdue</div>
            <div class="square-icon tx-closeOverdue mr-1"></div><div class="mr-1 tx-sm">Closeout Overdue</div>
            <div class="square-icon tx-closed mr-1"></div><div class="mr-1 tx-sm">Closed</div>
          </div>
          <div class="m-status-icon">
            <div class="d-flex flex-row">
              <div class="square-icon tx-identified mr-1"></div><div class="mr-1 tx-sm">Identified</div>
              <div class="square-icon tx-open mr-1"></div><div class="mr-1 tx-sm">Open</div>
            </div>
            <div class="d-flex flex-row">
              <div class="square-icon tx-overdue mr-1"></div><div class="mr-1 tx-sm">All Overdue</div>
              <div class="square-icon tx-closed mr-1"></div><div class="mr-1 tx-sm">Closed</div>
            </div>
          </div>
        </div>

      </mat-card-title>
      <mat-card-content class="summary" #summary (scroll)="onScrollDiv($event)">
        <ng-container>
          <app-scrolltotop (scrollToTop)="onScrollToTop()" (click)="resetScroll(summary)" *ngIf="this.hideResetScroll"></app-scrolltotop>
        </ng-container>
        <div class="" *ngIf="isVisible">
          <div class="">
            <table class="subtitle-font">
              <tr>
                <td scope="col" class="thtr-col">Contractors</td>
                <td scope="col" class="th-col" *ngFor="let drow of contractors"><div class="td-contrctor">{{drow.Name}}</div></td>
              </tr>
              <tr *ngFor="let drow1 of contractors">
                <td class="tr-col"><div class="tr-contrctor">{{drow1.Name}}</div></td>
                <td *ngFor="let drow2 of contractors">
                  <mat-card class="matrixcards">
                    <mat-card-content>
                      <div class="fx-row" *ngFor="let drow3 of findInterfaceGroup(interfacegrps,drow1,drow2)">
                        <div *ngFor="let drow4 of findInterfaces(interfaces,drow3)">
                          <div class="square tx-identified" *ngIf="drow4.Identified >=0"><a class="anchorLink" (click)="redirectToInterface(drow3.Id,1)">{{drow4.Identified}}</a></div>
                          <div class="square tx-open" *ngIf="drow4.Open >=0"><a class="anchorLink" (click)="redirectToInterface(drow3.Id,2)">{{drow4.Open}}</a></div>
                          <div class="square tx-responseoverdue" *ngIf="drow4.ResponseOverdue >=0"><a class="anchorLink" (click)="redirectToInterface(drow3.Id,98)">{{drow4.ResponseOverdue}}</a></div>
                          <div class="square tx-closeOverdue" *ngIf="drow4.CloseOverdue >=0"><a class="anchorLink" (click)="redirectToInterface(drow3.Id,99)">{{drow4.CloseOverdue}}</a></div>
                          <div class="square tx-closed" *ngIf="drow4.Closed >=0"><a class="anchorLink" (click)="redirectToInterface(drow3.Id,3)">{{drow4.Closed}}</a></div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="height:215px" *ngIf="!isVisible"></div>
        <ngx-spinner name="sp9" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
      </mat-card-content>
    </mat-card>
  </div>


  <div class="row">
    <div class="col-xl-4 col-md-6 card-padding-right pad-rt-15_480 mb-3">
      <div class="card h-min-100 ">
        <div class="card-header">
          <div class="title-option">
            <div class="title-font">{{cardHeaderLabel}} Status Summary</div>
            <div class="title-icon">
              <div class="mr-1">
                <mat-icon *ngIf="!isBarShown" (click)="showBarSummary()">leaderboard</mat-icon>
                <mat-icon *ngIf="isBarShown" (click)="showpieSummary()">donut_small</mat-icon>
              </div>
              <div></div>
            </div>
          </div>

        </div>
        <div>
          <div class="card-body align-self-center p-3" *ngIf="isChartVisible">
            <apx-chart id="chart" class="chartcss chartwidth" [series]="chartOptions.series"
                       [chart]="chartOptions.chart"
                       [colors]="chartOptions.colors"
                       [labels]="chartOptions.labels"
                       [legend]="chartOptions.legend"
                       [xaxis]="chartOptions.xaxis"
                       [plotOptions]="chartOptions.plotOptions"
                       [dataLabels]="chartOptions.dataLabels"
                       [responsive]="chartOptions.responsive"></apx-chart>
            <ngx-spinner name="sp10" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
          </div>
          <div style="height:315px" *ngIf="!isChartVisible"></div>
        </div>
      </div>
    </div>
    <div class="col-xl-8 col-md-6 card-padding-left pad-lt-15_480  mb-3">
      <mat-card class="h-min-100  ">
        <mat-card-title class="mat-card-title title-ProjSummary">
          <div>Project Summary</div>
        </mat-card-title>
        <mat-card-content class="">
          <div class="interface-tile wit-metric-tile mb-3" *ngIf="isVisible">
            <div class="tile-dropdown">
              <div class="body-l">{{cardHeaderLabel}}</div>
              <div class="stats-drdw">
                <mat-select [(value)]="selectedfilter" (selectionChange)="onChangefilter($event)" #filterInput>
                  <mat-option [value]="option.value" *ngFor="let option of daysfilters">{{ option.viewValue }}</mat-option>
                </mat-select>
              </div>
            </div>
            <div class="work-stats flex">
              <div class="work-items-created work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">assignment</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"><a class="linkCursor" (click)="redirectToInterface(0,-1)">{{this.allOpenInterface}}</a> </div>
                  <div class="text subtitle-font">Items Created</div>
                </div>
              </div>
              <div class="work-items-completed work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">assignment_turned_in</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"><a class="linkCursor" (click)="redirectToInterface(0,this.closedStatusId)">{{this.completedInterface}} </a> </div>
                  <div class="text subtitle-font">Items Closed</div>
                </div>
              </div>
            </div>
          </div>
          <div class="interface-tile wit-metric-tile mb-3" *ngIf="isVisible">
            <div class="tile-dropdown">
              <div class="body-l">Contractors & Interface Groups</div>
            </div>
            <div class="work-stats flex">
              <div class="work-items-created work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">group</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"> <a class="linkCursor" (click)="redirectToContractor()">{{this.contractorcnt}}</a> </div>
                  <div class="text subtitle-font">No. of Contractors</div>
                </div>
              </div>
              <div class="work-items-completed work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">group</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"><a class="linkCursor" (click)="redirectToInterfaceGroups()">{{this.interfacegrpcnt}}</a> </div>
                  <div class="text subtitle-font">No. of Interface groups</div>
                </div>
              </div>
            </div>
          </div>
          <div class="interface-tile wit-metric-tile mb-3" *ngIf="isVisible">
            <div class="tile-dropdown">
              <div class="body-l">Users</div>
            </div>
            <div class="work-stats flex">
              <div class="work-items-created work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">person</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"> <a class="linkCursor" (click)="redirectToUsers()">{{this.activeUserscnt}}</a></div>
                  <div class="text subtitle-font">Active Users</div>
                </div>
              </div>
              <div class="work-items-completed work-stats-component flex flex-grow">
                <mat-icon class="stat-icon fontSizeML flex-noshrink fabric-icon ms-Icon--Assign">mark_unread_chat_alt</mat-icon>
                <div class="count-and-text">
                  <div class="title-xs"><a class="linkCursor" (click)="redirectToNotificationSubscription()">{{this.subscribedUserscnt}}</a></div>
                  <div class="text subtitle-font">Notification Subscriptions</div>
                </div>
              </div>
            </div>
          </div>
          <ngx-spinner name="sp11" bdColor="rgba(0, 0, 0, 0.6)" [fullScreen]="false" color="#fff" type="line-scale" size="small"></ngx-spinner>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
