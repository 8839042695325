import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DSStatusCard } from '../app_data/dsstatuscard';
import { response } from '../app_data/OdataResponse';
import { Injectable } from '@angular/core';
import { Interface } from '../app_data/interface';
import { environment } from 'src/environments/environment';
import * as myGlobals from '../app_data/globals';
import { CommonFunction } from '../app_data/commonFunction';
import { DsTasks } from '../app_data/dstasks';

const httpOptions = {
  headers: new HttpHeaders({

    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}


@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private apiUrl = environment.apiURl;
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  constructor(private http: HttpClient) {
  }

  updateIsOverdueFlag(data: Interface[]) {
    data.forEach(x => {
      x.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
      x.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
      x.InterfaceStatus = x.IsFirstIssuePlannedOverdue ? myGlobals.ResponseOverdueStatusName : x.IsApprovedIssuePlannedOverdue ? myGlobals.CloseoutOverdueStatusName : x.InterfaceStatus;
    })
    return data;
  }

  getInterfacesById(contractorId: number, projectId: number, moduleId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$expand=InterfaceGroup,ResContractor,RecContractor,InterfaceStatus,RecDiscipline,ResDiscipline,Prioritys,CreatedUserRoleMapping($expand=User,Contractor,Role;$filter=IsDeleted eq false),InterfaceResponses&$filter=((ReceivingContractorID eq " + contractorId + ") or (ResponsibleContractorID eq " + contractorId + " and (Status ne 1 and Status ne 4))) and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq " + projectId)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getReqCount(tablename: string, filtervalue: number, projectId: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=ReceivingContractorID eq " + filtervalue + " and IsDeleted eq false and ProjectId eq " + projectId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getReqApprovalCount(tablename: string, contractorId: number, projectId: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status eq 4 and IsDeleted eq false and ProjectId eq " + projectId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getCloseoutApprovalCount(tablename: string, contractorId: number, projectId: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status eq 8 and IsDeleted eq false and ProjectId eq " + projectId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getResCountReceiving(tablename: string, filtervalue: number, projectId: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=(( ReceivingContractorID eq " + filtervalue + " and ((Status eq 2 and (Response ne '') ) or Status eq 1) ) or (ResponsibleContractorID eq " + filtervalue + " and (Status eq 2 and (Response eq '' ))) ) and IsDeleted eq false and ProjectId eq " + projectId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getResCountResponsible(tablename: string, filtervalue: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=IsDeleted eq false and ResponsibleContractorID eq " + filtervalue + " and Status eq 2 and (Response eq ' ' )", httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getStatusCount(tablename: string, filtervalue: number, contractorId: number, projectId: number): Observable<DSStatusCard[]> {
    if (filtervalue == 1) {
      return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status eq " + filtervalue + " and IsDeleted eq false and ProjectId eq " + projectId + " and (ReceivingContractorID eq " + contractorId + ")", httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    } else {
      return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status eq " + filtervalue + " and IsDeleted eq false and ProjectId eq " + projectId + " and (ReceivingContractorID eq " + contractorId + " or ResponsibleContractorID eq " + contractorId + ")", httpOptions)
        .pipe(
          map((data: response) => data.value),
          catchError(this.handleError)
        );
    }
  }

  getStatusCountAsRequestor(tablename: string, filtervalue: string, contractorId: number, projectId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status in (" + filtervalue + ") and IsDeleted eq false and ProjectId eq " + projectId + " and (ReceivingContractorID eq " + contractorId + ")", httpOptions)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getStatusCountAsProvider(tablename: string, filtervalue: string, contractorId: number, projectId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=Status in (" + filtervalue + ") and IsDeleted eq false and ProjectId eq " + projectId + " and (ResponsibleContractorID eq " + contractorId + ")", httpOptions)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }


  getOverdueCount(tablename: string, filtervalue: string, contractorId: number, projectId: number): Observable<DSStatusCard[]> {
    return this.http.get<response>(this.apiUrl + tablename + "?$count=true&$expand=InterfaceStatus&$filter=( ReceivingContractorID eq " + contractorId + " or ResponsibleContractorID eq " + contractorId + ") and IsDeleted eq false and ProjectId eq " + projectId, httpOptions)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  getTasks(contractorid: number, projectId: number, moduleId: number): Observable<DsTasks[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$select=Id,Number,Title,ModuleId,Status,ReceivingContractorID,ResponsibleContractorID,ApprovedIssuePlanned,FirstIssuePlanned,CreatedDate,Response,FirstIssuePlanned,ApprovedIssuePlanned&$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=(( ReceivingContractorID eq " + contractorid + " and ((Status in (2,7)) or Status eq 1) ) or (ResponsibleContractorID eq " + contractorid + " and (Status in (2,5,10) ))) and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq " + projectId + " &$orderby=Id desc", httpOptions)
      .pipe(
        map((data) => {
          data.value.forEach(x => {
            x.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
            x.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
          })
          return data.value;
        }),
        catchError(this.handleError)
      );
  }

  getGateKeeperTasks(contractorid: number, projectId: number, moduleId: number): Observable<DsTasks[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$select=Id,Number,Title,ModuleId,Status,ReceivingContractorID,ResponsibleContractorID,ApprovedIssuePlanned,FirstIssuePlanned,CreatedDate&$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=((ReceivingContractorID eq " + contractorid + " and Status in (4,8)) or (ResponsibleContractorID eq " + contractorid + " and  Status in (6))) and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq " + projectId + " &$orderby=Id desc", httpOptions)
      .pipe(
        map((data) => {
          data.value.forEach(x => {
            x.IsApprovedIssuePlannedOverdue = CommonFunction.IsApprovedIssuedPlannedOverDue(new Date(x.ApprovedIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
            x.IsFirstIssuePlannedOverdue = CommonFunction.IsFirstIssuePlannedOverdue(new Date(x.FirstIssuePlanned!), CommonFunction.getTimeZoneNowUTC(this._projectTimeZone), x.Status);
          })
          return data.value;
        }),
        catchError(this.handleError)
      );
  }

  getMyReq(contractorid: number, projectId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$select=Id,Number,Title,Priority,Status,InterfaceGroupID,IsOverdue,Response,ReceivingContractorID,ResponsibleContractorID&$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=( ReceivingContractorID eq " + contractorid + ") and IsDeleted eq false and ProjectId eq " + projectId + " &$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getActiveReqs(contractorid: number, projectId: number, moduleId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=((ReceivingContractorID eq " + contractorid + ") or (ResponsibleContractorID eq " + contractorid + " and (Status ne 1 and Status ne 4)) ) and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq "+projectId+"&$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getActiveReqsByChartClick(contractorid: number, projectId: number, moduleId: number): Observable<Interface[]> {
    return this.http.get<response>(this.apiUrl + "interfaces?$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=((ReceivingContractorID eq " + contractorid + ") or (ResponsibleContractorID eq " + contractorid + " and (Status ne 1 and Status ne 4)) ) and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq " + projectId + "&$orderby=Id desc", httpOptions)
      .pipe(
        map((data: response) => {
          return this.updateIsOverdueFlag(data.value);
        }),
        catchError(this.handleError)
      );
  }

  getActiveReqsbyStatus(name: string, contractorid: number, projectId: number,type:string, moduleId: number): Observable<Interface[]> {
    var Id;
    switch (name) {
      case myGlobals.IdentifiedStatusName: {
        Id = myGlobals.AllIdentifiedSatusIds;
        break;
      }
      case myGlobals.OpenStatusName: {
        Id = myGlobals.AllOpenStatusIds;
        break;
      }
      case myGlobals.ClosedStatusName: {
        Id = 3;
        break;
      }
      case (myGlobals.ResponseOverdueStatusName): {
        Id = myGlobals.AllOpenStatusIds;
        break;
      }
      case (myGlobals.CloseoutOverdueStatusName): {
        Id = myGlobals.AllOpenStatusIds;
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
    if (type == "Requestor") {
      return this.http.get<response>(this.apiUrl + "interfaces?$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=Status in (" + Id + ") and (ReceivingContractorID eq " + contractorid + ") and ModuleId eq "+ moduleId +" and IsDeleted eq false and ProjectId eq " + projectId + " &$orderby=Id desc", httpOptions)
        .pipe(
          map((data: response) => {
            return this.updateIsOverdueFlag(data.value);
          }),
          catchError(this.handleError)
        );
    } else {
      return this.http.get<response>(this.apiUrl + "interfaces?$expand=InterfaceStatus,Prioritys,InterfaceGroup&$filter=Status in (" + Id + ") and (ResponsibleContractorID eq " + contractorid + ") and ModuleId eq " + moduleId +" and IsDeleted eq false and ProjectId eq " + projectId + " &$orderby=Id desc", httpOptions)
        .pipe(
          map((data: response) => {
            return this.updateIsOverdueFlag(data.value);
          }),
          catchError(this.handleError)
        );
    }
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
