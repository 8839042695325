<div class="container-fluid body-wrapper">
  <mat-card class="">
    <mat-card-content>
      <p class="pPageHeading heading-font">{{pageTitle}}</p>

      <mat-tab-group animationDuration="0ms" class="interfaceTab content-font">
        <mat-tab label="Interface">
          <div class="h500tabMin scroller">
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                <label class="headerLabel">Number</label><br />
                <label class="labelView">{{interfaceModel.Number}}</label>
              </div>
              <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                <label class="headerLabel">Status</label><br />
                <span class="status status-{{statusShortName | lowercase}} mb-0"></span>{{statusText}}
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                <label class="headerLabel">Title</label><br />
                <label class="labelView">{{interfaceModel.Title}}</label>
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                <label class="headerLabel">Interface Group</label><br />
                <label class="labelView">{{interfaceModel.InterfaceGroup?.Name}}</label>
              </div>

              <div class="col-xl-2 col-l-2 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">Priority</label><br />
                <label class="labelView">{{interfaceModel.Prioritys?.Name}}</label>
              </div>

            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                <label class="headerLabel">{{requestingContractorLabel}}</label><br />
                <label class="labelView">{{interfaceModel.RecContractor?.Name}}</label>
              </div>
              <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                <label class="headerLabel">{{providerContractorLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ResContractor?.Name}}</label>
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                <label class="headerLabel">{{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}}</label><br />
                <label class="labelView">{{this.contractor1DisciplineName}}</label>
              </div>
              <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12"  *ngIf="this.isDualDisciplinebool">
                <label class="headerLabel">{{providerDisciplineLabel}}</label><br />
                <label class="labelView">{{this.contractor2DisciplineName}}</label>
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">{{expectedResponseDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.FirstIssuePlanned | date:dateTimeFormat}}</label>
              </div>

              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">{{closeOutDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ApprovedIssuePlanned | date:dateTimeFormat}}</label>
              </div>

              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">Created Date</label><br />
                <label class="labelView">{{interfaceModel.CreatedDate | date:dateTimeFormat : utcoffsetvalue}}</label>
              </div>

            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12" *ngIf="interfaceModel.FirstIssueActual != null">
                <label class="headerLabel">{{responseDateActualLabel}}</label><br />
                <label class="labelView">{{interfaceModel.FirstIssueActual | date:dateTimeFormat : utcoffsetvalue}}</label>
              </div>
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12" *ngIf="interfaceModel.ApprovedIssueActual != null && interfaceModel.Status != this.awaitingForCloseOutStatusId">
                <label class="headerLabel">{{closeOutDateActualLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ApprovedIssueActual | date:dateTimeFormat : utcoffsetvalue}}</label>
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                <label class="headerLabel">Request Description</label><br />
                <label class="labelView enable-line-break">{{interfaceModel.Description}}</label>
              </div>

              <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                <label class="headerLabel">Additional Comments</label><br />
                <label class="labelView enable-line-break">{{interfaceModel.Comments}}</label>
              </div>
            </div>

          </div>
        </mat-tab>

        <mat-tab label="Propose date" *ngIf="this.isProposeDateTabVisible">
          <div class="h500tabMin scroller">
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">{{expectedResponseDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.FirstIssuePlanned | date:dateTimeFormat}}</label>
              </div>
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">{{closeOutDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ApprovedIssuePlanned | date:dateTimeFormat}}</label>
              </div>
            </div>
            <div class="row rowBottomPadding">
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">Proposed {{expectedResponseDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ProviderFirstIssuePlanned | date:dateTimeFormat}}</label>
              </div>
              <div class="col-xl-4 col-l-4 col-md-2 col-sm-12 col-xs-12">
                <label class="headerLabel">Proposed {{closeOutDateLabel}}</label><br />
                <label class="labelView">{{interfaceModel.ProviderApprovedIssuePlanned | date:dateTimeFormat}}</label>
              </div>
            </div>
          </div>
        </mat-tab>

        <mat-tab [label]="documentCount">
          <div class="h500tabMin">
            <app-document-view></app-document-view>
          </div>
        </mat-tab>

        <mat-tab [label]="referenceLinkCount">
          <div class="h500tabMin">
            <app-reference-link-view></app-reference-link-view>
          </div>
        </mat-tab>

        <mat-tab label="Tags" *ngIf="this.showOtherTab">
          <div class="h500tabMin">
            <app-project-fields-configuration-view (projectFieldsConfigadded)="projectFieldsConfigaddedHandler($event)" [interfaceId]="this.interfaceId" [interfaceType]="this.interfaceType"></app-project-fields-configuration-view>
          </div>
        </mat-tab>

        <mat-tab label="Response" *ngIf="(interfaceModel.InterfaceStatus?.Name.toLowerCase() == 'closed' || isResponseGiven) && isResponseTabVisible">
          <div class="h500tabMin">
            <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 pbZero txtMargingTop rowBottomPadding">
              <label class="headerLabel">Response</label><br />
              <label class="labelView enable-line-break">{{interfaceModel.Response}}</label>
            </div>

            <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 pbZero txtMargingTop rowBottomPadding">
              <label class="headerLabel">References</label><br />
              <label class="labelView enable-line-break">{{interfaceModel.ReferenceForResponse}}</label>
            </div>

            <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12 rowBottomPadding">
              <label class="headerLabel">{{providerContractorLabel}} User</label><br />
              <label class="labelView">{{this._userName}}</label>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Response History" *ngIf="this.isResponseGiven || this.isResponseHistoryVisible">
          <div class="h500tabMin scroller">
            <div class="container py-2 mt-4 mb-4">
              <!-- timeline item 1 -->
              <div class="row" *ngFor="let response of responseHistoryPagings let i = index;">
                <!-- timeline item 1 left dot -->
                <div class="col-auto text-center flex-column d-none d-sm-flex">
                  <div class="row h-40">
                    &nbsp;
                  </div>
                  <h5 class="m-2">
                    <div class="float-right text-muted fw-bolder">
                      {{response.CreatedDate | date : this.dateTimeFormat: utcoffsetvalue }} {{response.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}
                    </div>
                  </h5>
                  <div class="row h-50">
                    &nbsp;
                  </div>
                </div>
                <div class="col-auto text-center flex-column d-none d-sm-flex">
                  <div class="row h-40">
                    <div class="col border-right">&nbsp;</div>
                    <div class="col">&nbsp;</div>
                  </div>
                  <h5 class="m-2">
                    <span class="badge badge-pill bg-light border pillBorder">&nbsp;</span>
                  </h5>
                  <div class="row h-50">
                    <div class="col border-right">&nbsp;</div>
                    <div class="col">&nbsp;</div>
                  </div>
                </div>
                <!-- timeline item 1 event content -->
                <div class="col py-2">
                  <div class="card right">
                    <div class="card-body">
                      <!-- <div class="float-right text-muted fw-bolder">{{response.CreatedDate | date:'dd/MM/yyyy hh:mm:ss' : utcoffsetvalue}}</div> -->
                      <h4 class="card-title">
                        <span *ngIf="response.Comments == ''">Response by :</span> <span *ngIf="response.Comments != ''">Comments by :</span> {{
response.CreatedUserRoleMapping != null ?
                        response.CreatedUserRoleMapping?.User.FirstName +' '+ response.CreatedUserRoleMapping?.User.LastName +' - ('+ response.Contractor?.Name +')'  : ""
                        }}
                      </h4>
                      <div *ngIf="response.Comments == ''">
                        <p class="card-text card-text-content">{{response.Information}}</p>
                      </div>
                      <div *ngIf="response.Comments != ''">
                        <p class="card-text card-text-content">{{response.Comments}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--/row-->
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Assignments" *ngIf="this.isAssignmentTabVisible">
          <div class="h500tabMin scroller">
            <app-assignment-view [interfaceId]="this.interfaceId"></app-assignment-view>
          </div>
        </mat-tab>

        <mat-tab labelinterfacegroup label="Revisions" *ngIf="this.isRevisionTabVisible">
          <div class="h500tabMin">
            <app-interface-revision [interfaceId]="this.interfaceId"></app-interface-revision>
          </div>
        </mat-tab>
        <mat-tab labelinterfacegroup label="Minutes of Meeting" *ngIf="this.isMomTabVisible">
          <div class="h500tabMin">
            <app-minutesofmeeting-view-interface [interfaceId]="this.interfaceId"></app-minutesofmeeting-view-interface>
          </div>
        </mat-tab>
        <mat-tab labelinterfacegroup label="Logs">
          <div>
            <app-interface-log [calledOnActionButtonClick]="false"></app-interface-log>
          </div>
        </mat-tab>
        <mat-tab labelinterfacegroup label="Notifications">
          <div class="h500tabMin">
            <app-notification-list [type]="type"></app-notification-list>
          </div>
        </mat-tab>
        <mat-tab label="Discussions">
          <div class="h500tabMin">
            <app-discussion-view [interfaceId]="this.interfaceId"></app-discussion-view>
          </div>
        </mat-tab>
      </mat-tab-group>

      <div class="mt-2 mb-2" align="right">
        <button mat-stroked-button class="WIRbutton WIRbutton-primary" *ngIf="this.statusText != 'Voided'" [hasPermission]="'interface.void'"
                (click)='voidInterface()'>
          <mat-icon class="WIRbutton-icons">block</mat-icon>Void
        </button>
        <button mat-stroked-button class="WIRbutton WIRbutton-primary" *ngIf="this.statusText == 'Voided'" [hasPermission]="'interface.unvoid'"
                (click)='unVoidInterface()'>
          <mat-icon class="WIRbutton-icons">lock_open_right</mat-icon>UnVoid
        </button>
        <button [hidden]="this.notClosed" mat-stroked-button (click)="downloadInterfaceSheet('interfacesheet')" type="submit" class="WIRbutton WIRbutton-primary btn-sm">
          <mat-icon>download</mat-icon>Download Interface Sheet
        </button>
        <app-pdf-generate [reportName]="reportName" [projectName]="projectName" #PdfGenerateComponent></app-pdf-generate>

        <button *ngIf="this.isCopyItemVisible" mat-stroked-button type="button" [hasPermission]="'interface.copy'" class="WIRbutton WIRbutton-primary" [matMenuTriggerFor]="beforeMenu">
          Copy Item <mat-icon class="WIRbutton-icons dropdownButton">expand_more</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" class="cdkoverlaypanedropdownButton" xPosition="before">
          <button mat-menu-item (click)="onCopy(true)">with documents & links</button>
          <button mat-menu-item (click)="onCopy(false)">without documents & links</button>
        </mat-menu>

        <button *ngIf="interfaceModel.InterfaceStatus?.Name.toLowerCase() == 'closed'" mat-stroked-button type="button" [hasPermission]="'interface.revision'" (click)="onRevision()" class="WIRbutton WIRbutton-primary">
          Create Revision
        </button>
        <button mat-stroked-button class="WIRbutton WIRbutton-primary" *ngIf="this.isEditVisible" [hasPermission]="'interface.edit'" (click)="redirectEditInterface()">
          <mat-icon class="WIRbutton-icons">edit</mat-icon>Edit Interface
        </button>
        <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
          <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>

