import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Interface } from '../../app_data/interface';
import { InterfaceService } from '../../services/interface.service';
import * as myGlobals from '../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { CommunicationService } from '../../services/communication.service';

let Revision_DATA: Interface[] = [];

@Component({
  selector: 'app-technicalquery-revision',
  templateUrl: './technicalquery-revision.component.html',
  styleUrls: ['./technicalquery-revision.component.css']
})
export class TechnicalQueryRevisionComponent implements OnInit {
  displayedColumns: string[] = ['Number', 'Title', 'InterfaceStatus.Name', 'Action'];
  revisiondataSource = new MatTableDataSource<Interface>(Revision_DATA);
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  validRecord: boolean = false;
  @Input() interfaceId!: number;

  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;

  @ViewChild('sortrevisions') sortrevisions !: MatSort;
  @ViewChild('paginatorRevisions') paginatorRevisions !: MatPaginator;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sortrevisions = ms;
    this.revisiondataSource.paginator = this.paginatorRevisions;
    this.revisiondataSource.sort = this.sortrevisions;
  }
  constructor(private interfaceService: InterfaceService,
    private logger: NGXLogger, private dialog: DialogService,
    private SpinnerService: NgxSpinnerService, private router: Router,
    private communicationService: CommunicationService,  ) { }

  ngOnInit(): void {
    this.checkIfInterfaceIdValid(this.interfaceId);
  }

  checkIfInterfaceIdValid(Id: number) {
    try {
      this.SpinnerService.show();
      this.interfaceService.getInterfaceByIdandProjectId(Id, this._projectID).subscribe({
        next: data => {
          this.validRecord = (data.length > 0 && data[0].InterfaceType.toLowerCase() == "tq") ? true : false;
          if (Id > 0 && this.validRecord) {
            this.revisiondataSource.data = data[0].Revisions;
          }
          else {
            this.router.navigateByUrl("/AccessDenied");
          }
        }
      });
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onView(interfaceId: number) {
    try {
      this.communicationService._isView = true;
      this.communicationService._interfaceId = interfaceId;
      let InterfaceViewUrl = "TechnicalQueryView/" + interfaceId;
      this.router.navigateByUrl(InterfaceViewUrl).then(() => {
        window.location.reload();
      });
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
