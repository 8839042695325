export class InterfaceExcel {
  Number: string = "";
  Title: string = "";
  Description: string = "";
  CreatedOn: string = "";
  CreatedBy: string = "";
  FirstResponseDate: string = "";
  FirstIssueActual: string = "";
  CloseOutDate: string = "";
  ApprovedIssueActual: string = "";
  ProviderFirstIssuePlanned : string = "";
  ProviderApprovedIssuePlanned: string = "";
  Comments: string = "";
  InterfaceGroup: string = "";
  RequestingContractor: string = "";
  ProviderContractor: string = "";  
  Status: string = "";
  NextActionText: string = ""; 
  Response: string = "";
  RejectionComment: string = "";
  AssignedToUser: string = "";
  ReferenceForResponse: string = "";
  NotificationComments: string = "";
  CompletedDate: string = "";
  CompletedBy: string = "";
  Priority: string = "";
  ProviderDiscipline: string = "";  
  RequestingDiscipline: string = "";  
}
