<form [formGroup]="actionitemForm">
  <div class="row">
    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Title</mat-label>
        <input matInput #input autocomplete="off" maxlength="256" placeholder="Title" name="Title" [(ngModel)]="actionItemModel.Title"
               formControlName="Title" required appTrimWhitespace>
        <mat-error *ngIf="this.actionitemForm.get('Title')?.hasError('required')">
          Title is <strong>required</strong>
        </mat-error>
        <mat-hint align="end">{{actionItemModel.Title?.length || 0}}/256</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Status</mat-label>
        <mat-select [(value)]="selectedStatus" name="Status" [(ngModel)]="actionItemModel.Status" formControlName="Status" required>
          <mat-option *ngFor="let stat of actionStatusList" [value]="stat.Id">{{ stat.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.actionitemForm.get('Status')?.hasError('required')">
          Status is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Action Description</mat-label>
        <textarea matInput autocomplete="off" formControlName="Description" maxlength="3000" name="Description"
                  [(ngModel)]="actionItemModel.Description" class="heightTextArea" required appTrimWhitespace></textarea>
        <mat-error *ngIf="this.actionitemForm.get('Description')?.hasError('required')">
          Description is <strong>required</strong>
        </mat-error>
        <mat-hint align="end">{{actionItemModel.Description?.length || 0}}/3000</mat-hint>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Action Date</mat-label>
        <input matInput [matDatepicker]="dp3" [min]="minDate" [(ngModel)]="actionItemModel.ActionDate" formControlName="ActionDate" disabled>
        <!--<mat-hint>MM/DD/YYYY</mat-hint>-->
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
        <mat-error *ngIf="this.actionitemForm.get('ActionDate')?.hasError('required')">
          ActionDate is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Interface Group</mat-label>
        <mat-select [(value)]="selectedInterfaceGroup" [(ngModel)]="actionItemModel.InterfaceGroupId" formControlName="InterfaceGroupId" disabled>
          <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.actionitemForm.get('InterfaceGroupId')?.hasError('required')">
          Interface Group is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Responsible Contractor</mat-label>
        <mat-select [(value)]="selectedContactor" [(ngModel)]="actionItemModel.ResponsibleContractorId" (selectionChange)="onContractorSelected(selectedContactor)" formControlName="ResponsibleContractorId">
          <mat-option *ngFor="let ig of rescontractors" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.actionitemForm.get('ResponsibleContractorId')?.hasError('required')">
          Responsible Contractor is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Responsible</mat-label>
        <input matInput maxlength="100" autocomplete="off" inputmode="tel" placeholder="Responsible" [(ngModel)]="actionItemModel.Responsible" formControlName="Responsible" #ResponsibleInput appTrimWhitespace>
        <mat-error *ngIf="this.actionitemForm.get('Responsible')?.hasError('required')">
          Responsible is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="mt-2 mb-2" align="right">
    <button mat-stroked-button (click)="add()" [disabled]="actionitemForm.invalid" class="WIRbutton WIRbutton-primary">
      <mat-icon class="WIRbutton-icons">add</mat-icon>Add
    </button>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatoractionitem [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller-child-Screen">
      <table mat-table [dataSource]="actionitemdataSource" class="mat-elevation-z8" matSort #sortactionitem="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size"> Action </th>
          <td mat-cell *matCellDef="let disc">
            <button mat-icon-button (click)="delete(disc.Title)">
              <mat-icon matTooltip="delete" class="mat-icon-small-size">
                delete
              </mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Title </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="ActionDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Action Date </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ActionDate | date: this._dateTimeFormat}} </td>
        </ng-container>
        <ng-container matColumnDef="InterfaceGroup">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Interface Group </th>
          <td mat-cell *matCellDef="let disc"> {{disc.InterfaceGroup}} </td>
        </ng-container>
        <ng-container matColumnDef="ResContractor">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Responsible Contractor </th>
          <td mat-cell *matCellDef="let disc"> {{disc.ResContractor}} </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let disc"> {{disc.Description}} </td>
        </ng-container>
        <ng-container matColumnDef="StatusName">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let disc"> {{disc.StatusName}} </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
