import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractorListComponent } from './contractor-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractorEditComponent } from './contractor-edit.component';
import { ContractorRoutingModule } from './contractor-routing.module';
import { ContractorCreateComponent } from './contractor-create.component';
import { MaterialModule } from '../../shared/material/material.module';
import { DisciplineModule } from '../discipline/discipline.module';
import { InterfacegroupModule } from '../interfacegroup/interfacegroup.module';
import { ContactModule } from '../contact/contact.module';
import { DialogModule } from '../../shared/components/dialog/dialog.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';
import { WorkflowTemplateMappingModule } from 'src/app/shared/components/workflowTemplateMapping/workflow-template-mapping.module';


@NgModule({
  declarations: [
    ContractorCreateComponent,
    ContractorListComponent,
    ContractorEditComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DisciplineModule,
    InterfacegroupModule,
    ContactModule,
    DialogModule,
    WorkflowTemplateMappingModule,
    AuthorisationModule,
    ContractorRoutingModule,ActiveRouteModule, CommonDirectivesModule
  ]
})
export class ContractorModule { }
