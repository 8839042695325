import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfGenerateComponent } from './pdf-generate.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [PdfGenerateComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [PdfGenerateComponent]
})
export class PdfGenerateModule { }
