import { InterfaceGroupTemplateMapping } from "./interfaceGroupTemplateMapping";
import { User } from "./user";
import { UserRoleProject } from "./userroleproject";

export class InterfaceGroup {
  Id?: number;
  Contractor1ID !: number;
  Contractor2ID !: number;
  Name !: string;
  IsDeleted !: boolean;
  Code !: string;
  ProjectID !: number;
  CreatedDate?: Date;
  CreatedBy?: number;
  ModifiedDate?: Date;
  ModifiedBy?: number;
  Contractor1?: any;
  Contractor2?: any;
  CreatedUserRoleMapping?: UserRoleProject;
  ModifiedUser?: User;
  InterfaceGroupTemplateMappings?: InterfaceGroupTemplateMapping[]=[];
}
