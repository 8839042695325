import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionItemCreateComponent } from './action-item-create.component';
import { MaterialModule } from '../../material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonDirectivesModule } from '../../directive/common/commondirective.module';
import { ActionItemEditComponent } from './action-item-edit.component';
import { ActionItemViewComponent } from './action-item-view.component';
import { ActionItemListViewComponent } from './action-item-list-view.component';



@NgModule({
  declarations: [ActionItemCreateComponent, ActionItemEditComponent, ActionItemViewComponent, ActionItemListViewComponent],
  imports: [
    CommonModule,
    MaterialModule, BrowserModule, ReactiveFormsModule, FormsModule, CommonDirectivesModule
  ],
  exports: [ActionItemCreateComponent, ActionItemEditComponent, ActionItemViewComponent, ActionItemListViewComponent]
})
export class ActionItemModule { }
