
<form [formGroup]="docForm">
  <div class="row pb-2">
    <div class="col-xl-2 col-l-2 col-md-8 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Document number</mat-label>
        <input matInput autocomplete="off" name="DocumentNumber" formControlName="docNumber" [(ngModel)]="document.Number"
               maxlength="20" placeholder="Document number" required>
        <button *ngIf="document.Number" matSuffix mat-icon-button aria-label="Clear"
                (click)="document.Number=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="this.docForm.get('docNumber')?.hasError('required')">
          Document number is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Title</mat-label>
        <input matInput autocomplete="off" name="DocumentTitle" formControlName="docTitle" [(ngModel)]="document.Title"
               maxlength="50" placeholder="Title" required>
        <button *ngIf="document.Title" matSuffix mat-icon-button aria-label="Clear"
                (click)="document.Title=''">
          <mat-icon class="smallIconSize">close</mat-icon>
        </button>
        <mat-error *ngIf="this.docForm.get('docTitle')?.hasError('required')">
          Title is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <app-upload [maxFileSize]="maxFileSize" [isFileUploadRequired]="true" [isControlDisable]="this.isControlDisable" [labelCaption]="labelCaption" [parentFormControlName]="parentControlName" [parentForm]="docForm" (fileSourceContent)="setfileSourceContent($event)" (fileNameAttr)="setfileName($event)"></app-upload>
    </div>

    <div class="col-xl-2 col-l-2 col-md-12 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button [disabled]="docForm.invalid || document.FileName == ''" (click)="addDocument()" type="button"
              class="WIRbutton WIRbutton-primary">
        <mat-icon class="WIRbutton-icons">add</mat-icon>Add
      </button>
    </div>
  </div>
</form>
<div class="row">
  <div class="col-lg-12">
    <mat-paginator #paginatorDocument [pageSizeOptions]="[10, 15, 20]" class="" showFirstLastButtons>
    </mat-paginator>
    <div class="table-responsive scroller">
      <table mat-table [dataSource]="documentdataSource" class="mat-elevation-z8" matSort #sortDocument="matSort">
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="table_header_size">
            Action
          </th>
          <td mat-cell *matCellDef="let doc">
            <div class="display-inline" *ngIf="doc.ContractorId == this._contractorID">
              <button mat-icon-button [hidden]="this.isControlDisable">
                <mat-icon class="mat-icon-small-size" (click)="doc.CreatedBy == this._userRoleMappingId && confirmCancelDialog(doc.Title,'document')"
                          matTooltip="delete">delete</mat-icon>
              </button>
            </div>
            <button mat-icon-button *ngIf="doc.Id != 0" (click)="downloadDocumentData(doc.FileNameSaved, doc.FileName,doc.FilePath)" [disabled]="doc.Status == 1" [matTooltip]='doc.Status == 1? "Upload inprogress" : "download"'>
              <mat-icon class="mat-icon-small-size">cloud_download</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="Number">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by number">
            No.
          </th>
          <td mat-cell *matCellDef="let doc"> {{doc.Number}}</td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
            Document Title
          </th>
          <td mat-cell *matCellDef="let doc"> {{doc.Title}} </td>
        </ng-container>
        <ng-container matColumnDef="DocumentType">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header sortActionDescription="Sort by title">
            Document Type
          </th>
          <td mat-cell *matCellDef="let doc"> {{this.showFileType(doc.FileName)}}  </td>
        </ng-container>
        <ng-container matColumnDef="SubmittedByUserID">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header
              sortActionDescription="Sort by SubmittedByUserID">
            Added by
          </th>
          <td mat-cell *matCellDef="let doc">
            {{doc.CreatedUserRoleMapping != null ? doc.CreatedUserRoleMapping?.User.FirstName +' '+ doc.CreatedUserRoleMapping?.User.LastName +' - ('+doc.Contractor.Name +')' : this._userName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="SubmittedDate">
          <th mat-header-cell *matHeaderCellDef class="table_header_size" mat-sort-header
              sortActionDescription="Sort by SubmittedDate">
            Submit Date
          </th>
          <td mat-cell *matCellDef="let doc">
            {{doc.CreatedDate | date : this._dateTimeFormat: utcoffsetvalue }} {{doc.CreatedDate | date : 'h:mm:ss a': utcoffsetvalue }}
          </td>
        </ng-container>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" [attr.colspan]="displayedDocumentColumns.length" align="center">
            <span><br />{{noRecordFoundMsg}}</span>
          </td>
        </tr>
        <tr mat-header-row *matHeaderRowDef="displayedDocumentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedDocumentColumns;"></tr>
      </table>
    </div>
  </div>
</div>


