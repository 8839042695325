<div class="row rowBottomPadding">
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Number</label><br />
    <label class="labelView">{{actionItemModel.Id}}</label>
  </div>
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Status</label><br />
    <span class="status status-{{statusText | lowercase}} mb-0"></span>{{statusText}}
  </div>
</div>
<div class="row rowBottomPadding">
  <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
    <label class="headerLabel">Title</label><br />
    <label class="labelView">{{actionItemModel.Title}}</label>
  </div>
</div>
<div class="row rowBottomPadding">
  <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
    <label class="headerLabel">Description</label><br />
    <label class="labelView enable-line-break">{{actionItemModel.Description}}</label>
  </div>
</div>
<div class="row rowBottomPadding">
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Action Date</label><br />
    <label class="labelView">{{actionItemModel.ActionDate |date:dateTimeFormat}}</label>
  </div>
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Responsible</label><br />
    <span class="labelView">{{actionItemModel.Responsible}}</span>
  </div>
</div>
<div class="row rowBottomPadding">
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Interface Group</label><br />
    <label class="labelView">{{actionItemModel.InterfaceGroup?.Name}}</label>
  </div>
  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
    <label class="headerLabel">Responsible Contractor</label><br />
    <label class="labelView">{{actionItemModel.ResContractor?.Name}}</label>
  </div>
</div>
<div class="mt-2 mb-2" align="right">
  <!--<button mat-stroked-button class="WIRbutton WIRbutton-primary" *ngIf="this.isEditVisible" (click)="redirectEditActionItem()">
    <mat-icon class="WIRbutton-icons">edit</mat-icon>Edit Interface
  </button>-->
  <button mat-stroked-button type="button" appBackButton class="WIRbutton WIRbutton-secondary">
    <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
  </button>
</div>
