import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefinationsettingsCreateComponent } from './definationsettings-create.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefinationsettingsEditComponent } from './definationsettings-edit.component';
import { MaterialModule } from '../../shared/material/material.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';


@NgModule({
  declarations: [DefinationsettingsCreateComponent, DefinationsettingsEditComponent],
  imports: [
    CommonModule, MaterialModule,
    FormsModule,
    ReactiveFormsModule,CommonDirectivesModule,
  ],
  exports: [DefinationsettingsCreateComponent, DefinationsettingsEditComponent]
})
export class DefinationsettingsModule { }
