import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { Project } from '../../app_data/project';
import { ProjectSetting } from '../../app_data/projectSetting';
import { Setting } from '../../app_data/setting';
import { DialogService } from '../../services/dialog.service';


@Component({
  selector: 'app-projectsettingstq',
  templateUrl: './projectsettingstq-create.component.html',
  styleUrls: ['./projectsettingstq-create.component.css']
})
export class ProjectSettingsTQCreateComponent implements OnInit {
  settingForm !: FormGroup;
  setting !: Setting;
  project!: Project;
  errorMessage!: string;
  _settings: ProjectSetting[] = [];
  show: boolean = true;
  showCustomiseTQNumberPattern: boolean = false;
  UniqueTQNumberbyProjecttext: string = myGlobals.UniqueTQNumberbyProjecttexttooltip;
  UniqueTQNumberbyGrouptext: string = myGlobals.UniqueTQNumberbyGrouptexttooltip;
  CustomiseTQNumberPatterntext: string = myGlobals.CustomiseTQNumberPatterntexttooltip;

  @Output() settingadded: EventEmitter<ProjectSetting[]> = new EventEmitter();

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private logger: NGXLogger, private dialog: DialogService) {
    this.setting = new Setting();
  }

  ngOnInit(): void {
    this.settingForm = new FormGroup({
      IsUniqueTQNumberByProject: new FormControl(true),
      IsUniqueTQNumberByInterfaceGroup: new FormControl(false),
      IsEnableCustomiseTQNumberPattern: new FormControl(false),
      CustomiseTQNumberPattern: new FormControl()
    });
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByProject", ConfigValue: String(true), CreatedBy: this._userRoleMappingId});
   
    this.settingadded.emit(this._settings);    
  }

  onTextChange(controlName: string, event: any) {
    this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: event.target.value, CreatedBy: this._userRoleMappingId});
    this.settingadded.emit(this._settings);
  }


  settingChecked(controlName: string, ev: any) {
    try {

      if (controlName == "IsUniqueTQNumberByProject") {
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseTQNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseTQNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseTQNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }


        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseTQNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }

      if (controlName == "IsUniqueTQNumberByInterfaceGroup") {
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsEnableCustomiseTQNumberPattern')?.setValue(!ev.target.checked);
          this.showCustomiseTQNumberPattern = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsEnableCustomiseTQNumberPattern = Boolean(!ev.target.checked);
        }


        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId});
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsEnableCustomiseTQNumberPattern");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsEnableCustomiseTQNumberPattern", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }
      
      if (controlName == "IsEnableCustomiseTQNumberPattern") {   
        this.showCustomiseTQNumberPattern = !this.showCustomiseTQNumberPattern;        
        if(!ev.target.checked){
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);  
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
        }else{
          this.settingForm.get('IsUniqueTQNumberByProject')?.setValue(!ev.target.checked);
          this.settingForm.get('IsUniqueTQNumberByInterfaceGroup')?.setValue(!ev.target.checked);
  
          this.setting.IsUniqueTQNumberByProject = Boolean(!ev.target.checked);
          this.setting.IsUniqueTQNumberByInterfaceGroup = Boolean(!ev.target.checked);
        }

        var index = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByProject");
        if (index > -1) {
          this._settings[index].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByProject", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }

        var index2 = this._settings.findIndex(x => x.ConfigKey == "IsUniqueTQNumberByInterfaceGroup");
        if (index2 > -1) {
          this._settings[index2].ConfigValue = String(!ev.target.checked);
        }
        else {
          this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: "IsUniqueTQNumberByInterfaceGroup", ConfigValue: String(!ev.target.checked), CreatedBy: this._userRoleMappingId });
        }
      }


      var index = this._settings.findIndex(x => x.ConfigKey == controlName);
      if (index > -1) {
        this._settings[index].ConfigValue = String(ev.target.checked);
      }
      else {
        this._settings.push({ Id: 0, ProjectId: this._projectID, ConfigKey: controlName, ConfigValue: String(ev.target.checked), CreatedBy: this._userRoleMappingId });
      }
      this.settingadded.emit(this._settings);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
