import { NGXLogger } from "ngx-logger";
import { NgxSpinnerService } from "ngx-spinner";
import { DialogService } from "../services/dialog.service";
import { NotificationService } from "../services/notification.service";
import { Interface } from "./interface";
import * as myGlobals from './globals';
import { Router } from "@angular/router";
import { Notification } from "./notification";
import { DatePipe } from "@angular/common";
import { EmailSubscription } from "./emailSubscription";
import { TemplateBuilderService } from "../services/template-builder.service";
import { DefinitionSetting } from "./definitionSetting";
import { environment } from "../../environments/environment";
import { EmailTemplateService } from "../services/email-template.service";
import { WorkflowmappingService } from "../services/workflowmapping.service";


export class CommonNotificationFunction {
  errorMessage: string = "";
  notificationModel !: Notification;
  interfaceModel !: Interface;
  definitionSetting!: DefinitionSetting;
  pipe = new DatePipe('en-US');
  _projectID = parseInt(sessionStorage.getItem("projectID")!);

  constructor(private notificationService: NotificationService, private SpinnerService: NgxSpinnerService, public datePipe: DatePipe,
    private dialog: DialogService, private logger: NGXLogger,
    private router: Router,   
    private emailTemplateService: EmailTemplateService,
    private templateBuilderService: TemplateBuilderService,
    private workflowMappingService: WorkflowmappingService) {
    this.interfaceModel = new Interface();
    this.notificationModel = new Notification();
  }

  addDays(date: Date, days: number): Date {
    date.setDate(date.getDate() + days);
    return date;
  }


  //Process Email Notification includes the get recepients and prepare email notifications.
  processEmailNotifications(moduleId: number, isReminder: boolean, isFunctionCalledFromCreate: boolean, reminderDays: number, interfaceModel: Interface,
    previousStatus: string, workflowTemplateId: number, currentStageId: number) {
    
    try {
      this.SpinnerService.show();

      //Get recepients who subscribed for current stage
      this.notificationService.getEmailSubscriptionsByFilter(moduleId, interfaceModel).subscribe({
        next: emailSubscriptions => {
          if (isReminder) {

            var objects: Array<Object> = [];
            objects.push(interfaceModel);

            //Planned Dates
            var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
            var approvedIssuePlannedDate = this.datePipe.transform(interfaceModel.ApprovedIssuePlanned, projectDateTimeFormat!);
            var firstIssuePlannedDate = this.datePipe.transform(interfaceModel.FirstIssuePlanned, projectDateTimeFormat!);
            var Dates: any = { ApprovedIssuePlannedDate: approvedIssuePlannedDate, FirstIssuePlannedDate: firstIssuePlannedDate }
            objects.push(Dates);

            //Reminder Days
            var ReminderDays: any = { ReminderDays: reminderDays }
            objects.push(ReminderDays);

            //Base link
            var Links: any = {
              BaseURL: environment.baseURL,
              SupportURL: myGlobals.supportEmail
            }
            objects.push(Links);

            //Logged in user
            var LoggedInUser: any = {
              LoggedInUser: sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName")
            }
            objects.push(LoggedInUser);

            var recipients: string[] = [];

            //Get recepients who subscribed
            emailSubscriptions.forEach(recepient => {
              var recipientEmail = recepient.Contact.Email!;
              if (recipientEmail != null && recipientEmail != "" && recipients.indexOf(recipientEmail) < 0)
                recipients.push(recipientEmail)
            });

            if (recipients.length > 0) {
              if (isFunctionCalledFromCreate)
                this.processOtherEmailNotifications(objects, this._projectID, interfaceModel.ModuleId!, "REMINDER", recipients, "Reminder added", interfaceModel.Id.toString(), interfaceModel.Module?.Name!, isReminder, reminderDays);
              else
                this.processOtherEmailNotificationUpdate(objects, this._projectID, interfaceModel.ModuleId!, "REMINDER", recipients, "Reminder updated", interfaceModel.Id.toString(), interfaceModel.Module?.Name!, reminderDays);
            }
          }

          this.saveNotification(emailSubscriptions, interfaceModel, previousStatus, workflowTemplateId, currentStageId);

          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  processOtherEmailNotificationUpdate(objects: Array<Object>, projectId: number, moduleId: number, emailTemplateCode: string, recipients: string[], activity: string, referenceId: string, referenceType: string, reminderDays: number) {

    try {
      this.SpinnerService.show();

      this.notificationService.getNotificationsByReferenceId(referenceId, referenceType).subscribe({
        next: notifications => {
          var reminderNotifications = notifications.filter(x => x.IsReminder);
          if (reminderNotifications.length > 0) {

            this.emailTemplateService.getEmailTemplateByProjectAndModule(projectId, moduleId, emailTemplateCode).subscribe({
              next: emailTemplates => {

                if (emailTemplates.length > 0) {
                  let emailTemplate = emailTemplates[0].Template;

                  this.updateOtherNotification(objects, reminderNotifications[0], emailTemplate, recipients, activity, reminderDays);
                }
                else {
                  this.emailTemplateService.getDefaultEmailTemplateByModule(moduleId, emailTemplateCode).subscribe({
                    next: defaultEmailTemplates => {

                      if (defaultEmailTemplates.length > 0) {
                        let emailTemplate = defaultEmailTemplates[0].Template;

                        this.updateOtherNotification(objects, reminderNotifications[0], emailTemplate, recipients, activity, reminderDays);
                      }
                    }
                  })
                }
                this.SpinnerService.hide();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            });
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  //Process Email Notification includes the get recepients, Template and prepare email notifications.
  processOtherEmailNotifications(objects: Array<Object>, projectId: number, moduleId: number, emailTemplateCode: string, recipients: string[], activity: string, referenceId: string, referenceType: string, isReminder: boolean, reminderDays: number) {

    try {
      this.SpinnerService.show();

      this.emailTemplateService.getEmailTemplateByProjectAndModule(projectId, moduleId, emailTemplateCode).subscribe({
        next: emailTemplates => {

          if (emailTemplates.length > 0) {
            let emailTemplate = emailTemplates[0].Template;
            this.saveOtherNotifications(objects, emailTemplate, recipients, activity, referenceId, referenceType, isReminder, reminderDays);
          }
          else {
            this.emailTemplateService.getDefaultEmailTemplateByModule(moduleId, emailTemplateCode).subscribe({
              next: defaultEmailTemplates => {

                if (defaultEmailTemplates.length > 0) {
                  let emailTemplate = defaultEmailTemplates[0].Template;
                  this.saveOtherNotifications(objects, emailTemplate, recipients, activity, referenceId, referenceType, isReminder, reminderDays);
                }
              }
            })
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  saveNotification(emailSubscriptions: EmailSubscription[], interfaceModel: Interface, previousStatus: string, workflowTemplateId:number, currentStageId:number) {
    try {
      
      this.SpinnerService.show();
      if (emailSubscriptions != null && emailSubscriptions.length > 0) {
        this.saveNotificationOnStage(interfaceModel, emailSubscriptions, previousStatus, workflowTemplateId, currentStageId);
      }
      else {
        this.SpinnerService.hide();
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  updateOtherNotification(objects: Array<Object>, notificationModel: Notification, emailTemplate: string, recipients: string[], activity: string, reminderDays?: number) {

    //Set the values and generate email body
    var emailBody = this.templateBuilderService.buildTemplate(emailTemplate, objects);

    //Generate email subject
    let emailSubject = this.extractValueFromTag(emailBody, 'title');

    recipients.forEach(recepient => {

      notificationModel = new Notification();
      notificationModel.Activity = activity;
      notificationModel.Message = emailBody;
      notificationModel.ToAddress = recepient;
      notificationModel.Subject = emailSubject;
      notificationModel.Type = "email";

      //this.notificationModel.IsReminder = isReminder;
      if (notificationModel.IsReminder)
        if (this.interfaceModel.ApprovedIssuePlanned != null) {
          var reminderDate = this.addDays(new Date(this.interfaceModel.ApprovedIssuePlanned!), -reminderDays!);
          if (notificationModel.ReminderDate != reminderDate)
            notificationModel.ReminderDate = reminderDate;
        }

      //Save notification in database
      this.notificationService.put(notificationModel).subscribe({
        next: data => {
          //Write a log
          this.SpinnerService.hide();
          //this.redirectToBackPage();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.SpinnerService.hide();
    });
  }


  saveOtherNotifications(objects: Array<Object>, emailTemplate: string, recipients: string[], activity: string, referenceId: string, referenceType: string, isReminder: boolean, reminderDays?: number) {

    //Set the values and generate email body
    var emailBody = this.templateBuilderService.buildTemplate(emailTemplate, objects);

    //Generate email subject
    let emailSubject = this.extractValueFromTag(emailBody, 'title');

    recipients.forEach(recepient => {

      this.notificationModel = new Notification();
      this.notificationModel.Activity = activity;
      this.notificationModel.Message = emailBody;
      this.notificationModel.ToAddress = recepient;
      this.notificationModel.Subject = emailSubject;
      this.notificationModel.Type = "email";
      
      this.notificationModel.IsReminder = isReminder;
      if (isReminder) {
        var interfaceModelData = objects[0] as Interface;
        if (interfaceModelData != null && interfaceModelData.ApprovedIssuePlanned != null) {
          var reminderDate = this.addDays(new Date(interfaceModelData.ApprovedIssuePlanned!), -reminderDays!);
          this.notificationModel.ReminderDate = reminderDate;
        }
      }

      this.notificationModel.ReferenceId = referenceId;
      this.notificationModel.ReferenceType = referenceType;

      //Save notification in database
      this.notificationService.post(this.notificationModel).subscribe({
        next: data => {
          //Write a log
          this.SpinnerService.hide();
          //this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Notification sent", "Notification sent to user(" + user.FirstName + " " + user.LastName + ")", this.interfaceModel.Id);
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.SpinnerService.hide();
    });
  }



  saveNotificationOnStage(interfaceModel: Interface, emailSubscriptions: EmailSubscription[], previousStatus: string, workflowTemplateId: number, currentStageId: number) {
    //if (workflowTemplateId != null && interfaceModel.ModuleId != null && interfaceModel.StageId != null) {
    if (workflowTemplateId != null && interfaceModel.ModuleId != null && currentStageId != null) {
      
      //Get workflow mapping based on workflow template, module and stage
      this.workflowMappingService.getCurrentStageWorkflowMapping(workflowTemplateId, interfaceModel.ModuleId, currentStageId).subscribe({
        next: workflowMappings => {

          if (workflowMappings.length > 0) {
            var workflowMapping = workflowMappings[0];

            var emailTemplateDetails = interfaceModel.IsStageRejected ? workflowMapping.RejectionEmailTemplate : workflowMapping.EmailTemplate;

            if (emailTemplateDetails != null && emailTemplateDetails.Template != null) {

              var emailTemplate = emailTemplateDetails.Template;

              if (emailTemplate != null && emailTemplate.length > 0) {

                //Read definition settings
                if (sessionStorage.getItem("definitionSettings")! != null) {

                  //Base link
                  var Links: any = {
                    BaseURL: environment.baseURL,
                    SupportURL : myGlobals.supportEmail
                  }

                  //Planned Dates
                  var projectDateTimeFormat = sessionStorage.getItem("projectDateTimeformat");
                  var approvedIssuePlannedDate = this.datePipe.transform(interfaceModel.ApprovedIssuePlanned, projectDateTimeFormat!);
                  var firstIssuePlannedDate = this.datePipe.transform(interfaceModel.FirstIssuePlanned, projectDateTimeFormat!);
                  var Dates: any = { ApprovedIssuePlannedDate: approvedIssuePlannedDate, FirstIssuePlannedDate: firstIssuePlannedDate }

                  //Logged in user
                  var LoggedInUserDetails: any = {
                    LoggedInUser: sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName"),
                    LoggedInUserRole: sessionStorage.getItem("roleName")
                  }

                  //Previous Status
                  var PreviousStatus: any = {
                    PreviousInterfaceStatus: previousStatus
                  }

                  if (interfaceModel.IsAcceptedAsPreliminary || interfaceModel.IsStageRejected)
                    interfaceModel.Response = (interfaceModel.Response == '') ? interfaceModel.InterfaceResponses[interfaceModel.InterfaceResponses.length - 1].Information : interfaceModel.Response;

                  var objects: Array<Object> = [];
                  objects.push(interfaceModel);
                  objects.push(JSON.parse(sessionStorage.getItem("definitionSettings")!));
                  objects.push(LoggedInUserDetails);
                  objects.push(Links);
                  objects.push(PreviousStatus);
                  objects.push(Dates);

                  //Accept Reject Comments
                  var acceptRejectComments = "";
                  if (interfaceModel.InterfaceResponses.length > 0) {
                    acceptRejectComments = (interfaceModel.InterfaceResponses[interfaceModel.InterfaceResponses.length - 1].Comments == '') ? interfaceModel.InterfaceResponses[interfaceModel.InterfaceResponses.length - 1].Information : interfaceModel.InterfaceResponses[interfaceModel.InterfaceResponses.length - 1].Comments;
                  }
                  var AcceptRejectComment: any = { AcceptRejectComment: acceptRejectComments }
                  objects.push(AcceptRejectComment);

                  //Set the values and generate email body
                  var emailBody = this.templateBuilderService.buildTemplate(emailTemplate, objects);
                  //Generate email subject
                  let emailSubject = this.extractValueFromTag(emailBody, 'title');

                  emailSubscriptions.forEach(emailSubscription => {

                    this.notificationModel = new Notification();
                    this.notificationModel.Activity = interfaceModel.Module?.Name! + (previousStatus == '' ? " added": " updated");
                    this.notificationModel.Message = emailBody;
                    this.notificationModel.ToAddress = emailSubscription.Contact.Email!;
                    this.notificationModel.Subject = emailSubject;
                    this.notificationModel.Status = "0";
                    this.notificationModel.Type = "email";
                    this.notificationModel.IsReminder = false;
                    this.notificationModel.ReferenceId = interfaceModel.Id.toString();
                    this.notificationModel.ReferenceType = interfaceModel.Module?.Name!;

                    //Save notification in database
                    this.notificationService.post(this.notificationModel).subscribe({
                      next: data => {
                        //Write a log
                        this.SpinnerService.hide();
                        //this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Notification sent", "Notification sent to user(" + user.FirstName + " " + user.LastName + ")", this.interfaceModel.Id);
                      },
                      error: err => {
                        this.SpinnerService.hide();
                        this.errorMessage = err
                        this.dialog.confirmDialog({
                          title: 'Error',
                          message: myGlobals.exceptionmessage,
                          cancelCaption: 'Close',
                        });
                        this.logger.error(err);
                      }
                    });

                    this.SpinnerService.hide();
                  });
                }
              }
            }
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });
    }
  }


  extractValueFromTag(template: string, tag: string): string {

    let tagValue: string = '';
    let segments = template.split('<' + tag.toLowerCase() + '>');

    segments.forEach(function (s) {
      let endIndex = s.indexOf('</' + tag.toLowerCase() + '>');

      tagValue = s.substring(0, endIndex);

    });

    return tagValue;
  }


  redirectToBackPage() {
    this.router.navigateByUrl('/Interfaces');
  }
}
