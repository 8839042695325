import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import * as myGlobals from '../../app_data/globals';
import { ReferenceLink } from '../../app_data/referenceLink';
import { Interface } from '../../app_data/interface';
import { Discipline } from '../../app_data/discipline';
import { Status } from '../../app_data/status';
import { Priority } from '../../app_data/priority';
import { Contractor } from '../../app_data/contractor';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { SystemLog } from '../../app_data/systemLog';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { User } from '../../app_data/user';
import { InterfaceService } from '../../services/interface.service';
import { DisciplineService } from '../../services/discipline.service';
import { ContractorService } from '../../services/contractor.service';
import { StatusService } from '../../services/status.service';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { PriorityService } from '../../services/priority.service';
import { CommunicationService } from '../../services/communication.service';
import { ProjectService } from '../../services/project.service';
import { SystemLogService } from '../../services/systemLog.service';
import { FilesService } from '../../services/files.service';
import { NotificationService } from '../../services/notification.service';
import { Notification } from '../../app_data/notification';
import { Document } from '../../app_data/document';
import { File } from '../../app_data/file';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from '../../app_data/contact';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { InterfaceProjectFieldsMapping } from 'src/app/app_data/InterfaceProjectFieldsMapping';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { ProjectFieldsConfigService } from '../../services/project-fields-config.service';
import { CommonNotificationFunction } from '../../app_data/commonNotificationFunction';
import { CommonFunction } from '../../app_data/commonFunction';
import { DocumentService } from 'src/app/services/document.service';
import { Guid } from 'guid-typescript';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { TemplateBuilderService } from '../../services/template-builder.service';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { StorageDetail } from '../../app_data/storageDetail';

let UploadDocuments: File[] = [];
let MY_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
  }
};

@Component({
  templateUrl: './interface-create.component.html',
  styleUrls: ['./interface-create.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    DatePipe,
    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class InterfaceCreateComponent implements OnInit {
  noRecordFoundMsg: string = myGlobals.NoRecordFound;
  pageTitle = 'Create Interface';
  permission = 'interface.create';
  datePickerConfig: Partial<BsDatepickerConfig> = {};
  selectedProviderDisciplines = '';
  selectedRequestingDisciplines = '';
  selectedStatus?: number = 0;
  selectedPriority?: number = 0;
  selectedProviderContractor: number = 0;
  selectedRequestingContractor: number = 0;
  selectedInterfaceGroup: number = 0;
  interfaceModel !: Interface;
  emptyInterfaceModel !: Interface;
  _isModelChanged: boolean = false;
  isSubmitted = false;
  errorMessage = '';
  disciplines: Discipline[] = [];
  requestingDisciplines: Discipline[] = [];
  status: Status[] = [];
  allstatus: Status[] = [];
  priority: Priority[] = [];
  providerContractors: Contractor[] = [];
  requestingContractors: Contractor[] = [];
  form !: FormGroup;
  interfaceGroups: InterfaceGroup[] = [];
  interfaceGroup2!: InterfaceGroup;
  systemLog !: SystemLog;
  dateTimeFormat: string = '';
  customiseInterfaceNumber: string = '';
  documentCount: string = "";
  referenceLinkCount: string = "";
  _interfaceID: number = 0;
  _isCopyDocLinks: boolean = false;
  _isRevision: boolean = false;
  _isCopy: boolean = false;
  today: Date = new Date();
  pipe = new DatePipe('en-US');
  dpColorTheme: string = "theme-dark-blue";
  contractorIds: number[] = [];
  filteredInterfaces: Interface[] = [];
  revisionNumber: number = 0;
  isEnableCloseoutReminder: boolean = false;
  closeOutReminderDays: number = 0;
  workflowMappings: WorkflowMapping[] = [];

  providerContractorLabel: string = myGlobals.ProviderContractorLabel;
  requestingContractorLabel: string = myGlobals.RequestingContractorLabel;
  providerDisciplineLabel: string = myGlobals.ProviderDisciplineLabel;
  requestingDisciplineLabel: string = myGlobals.RequestingDisciplineLabel;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  responseDateActualLabel: string = myGlobals.ResponseDateActualLabel;
  closeOutDateActualLabel: string = myGlobals.CloseOutDateActualLabel;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  interfaceType: string = "Interface";
  notificationModel !: Notification;
  receipentUsers: User[] = [];
  contractorReceipentUsers: Contact[] = [];
  _contractorListByIG: InterfaceGroup[] = [];
  contraArrs: Contractor[] = [];

  addDaystoExpResponseDate: number = 0;
  newCloseOutDate = new Date;
  newExpectedDate = new Date;

  maxFileSize: string = "";
  domainlist: SafeDomainList[] = [];
  safedomainval: boolean = false;
  showOtherTab: boolean = false;
  minDate: Date = CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false);

  _InterfaceByProject: boolean = false;
  isInterfaceNumberByGroup: boolean = false;
  isEnableCustomiseInterfaceNumberPattern: boolean = false;

  customiseInterfaceNumberPattern: string = "";
  isDualDisciplinebool: boolean = false; //default value is false
  commonNotificationFunction!: CommonNotificationFunction;
  commonFunction!: CommonFunction;

  uploadDocs: File[] = [];
  documentSrc!: any;
  sourceStorageDetail!: StorageDetail;

  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  constructor(private interfaceService: InterfaceService, private disciplineService: DisciplineService, private contractorService: ContractorService,
    private statusService: StatusService, private projectSettingService: ProjectSettingService, private priorityService: PriorityService,
    private interfaceGroupService: InterfaceGroupService, private router: Router, private communicationService: CommunicationService, private systemLogService: SystemLogService,
    private notificationService: NotificationService, private filesService: FilesService, private projectService: ProjectService,
    private logger: NGXLogger, private SpinnerService: NgxSpinnerService, private dialog: DialogService, private documentService: DocumentService,
    private workflowMappingService: WorkflowmappingService, private projectFieldsConfigService: ProjectFieldsConfigService,
    private snackBar: MatSnackBar, private emailTemplateService: EmailTemplateService, private datePipe: DatePipe,
    private rolePermissionService :RolePermissionService,
    private templateBuilderService: TemplateBuilderService) {
    this.interfaceModel = new Interface();
    this.interfaceGroup2 = new InterfaceGroup();
    this.emptyInterfaceModel = new Interface();
    this.systemLog = new SystemLog();
    this.notificationModel = new Notification();
    this.getProjectSettings();
    this.sourceStorageDetail = new StorageDetail();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, dialog, logger, router, emailTemplateService, templateBuilderService, workflowMappingService);
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
  }
  @ViewChild('fileInput') fileInput !: ElementRef;

  ngOnInit(): void {
    this.fillInterfaceGroupDDL(this._projectID, this._contractorID);
    this.fillPriorityDDL();
    this.fillStatusDDL();

    UploadDocuments = [];
    this.form = new FormGroup({
      titleFormControl: new FormControl(),
      ifiDateFormControl: new FormControl(),
      iaiDateFormControl: new FormControl(),
      interfaceGroupFormControl: new FormControl(),
      providerDisciplineFormControl: new FormControl(),
      requestingDisciplineFormControl: new FormControl(),
      statusFormControl: new FormControl(),
      priorityFormControl: new FormControl(),
      providerContractorFormControl: new FormControl(),
      requestingContractorFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      responseFormControl: new FormControl(),
      comments: new FormControl()
    });

    try {
      this._isRevision = this.communicationService._isRevision;
      this._interfaceID = this.communicationService._interfaceId;
      this._isCopyDocLinks = this.communicationService._isCopyDocLinks;
      this._isCopy = this.communicationService._isCopy;
      this.communicationService._interfaceId = 0;
      this.communicationService._isCopyDocLinks = false;
      this.communicationService._isRevision = false;

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

    if (this._interfaceID > 0) {
      this.fillRequestingContractorDDL();
      this.fillProviderContractorDDL();
      this.toCopyGetInterface(this._interfaceID, this._isCopyDocLinks, this._isRevision);
    }
    else {
      var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      this.interfaceModel.FirstIssuePlanned = tempDate;
      this.interfaceModel.ApprovedIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      this.interfaceModel.ApprovedIssuePlanned = tempDate
      try {
        this.SpinnerService.show();

        this.projectSettingService.getProjectSettings(this._projectID).subscribe({
          next: projectSettings => {
            if (projectSettings.length > 0) {
              this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;
              MY_FORMATS.parse.dateInput = this.dateTimeFormat.replace("dd", "DD");
              MY_FORMATS.display.dateInput = this.dateTimeFormat.replace("dd", "DD");
              MY_FORMATS.display.dateA11yLabel = this.dateTimeFormat.replace("dd", "DD");
              var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
              if (index > -1) {
                var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
                if (DefExpResponseDatebool == "true") {
                  var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                  if (index > -1) {
                    this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);

                    if (this.addDaystoExpResponseDate > 0) {
                      this.changeExpectedDate(formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US'));//Set Closeout Date on the basis of ProjectSetting's Response Days setting.
                    }
                  }
                }
              }
            }
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });

      } catch (e) {
        this.SpinnerService.hide();
        this.dialog.confirmDialog({
          title: 'Error',
          message: myGlobals.exceptionmessage,
          cancelCaption: 'Close',
        });
        this.logger.error(e);
      }
    }

  }

  fillDisciplineDDL(contractorId: any): void {
    try {
      this.SpinnerService.show();
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).subscribe({
        next: disciplines => {
          this.disciplines = disciplines;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {

          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderContractorName");
            if (index > -1) {
              this.providerContractorLabel = projectSettings.filter(x => x.ConfigKey == "ProviderContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingContractorName");
            if (index > -1) {
              this.requestingContractorLabel = projectSettings.filter(x => x.ConfigKey == "RequestingContractorName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ProviderDisciplineName");
            if (index > -1) {
              this.providerDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "ProviderDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "RequestingDisciplineName");
            if (index > -1) {
              this.requestingDisciplineLabel = projectSettings.filter(x => x.ConfigKey == "RequestingDisciplineName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "ResponseDateActualName");
            if (index > -1) {
              this.responseDateActualLabel = projectSettings.filter(x => x.ConfigKey == "ResponseDateActualName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateActualName");
            if (index > -1) {
              this.closeOutDateActualLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateActualName")[0].ConfigValue;
            }


            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
            if (index > -1) {
              var DefMaxFileSizebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
              if (DefMaxFileSizebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
                if (index > -1) {
                  this.maxFileSize = projectSettings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate");
            if (index > -1) {
              var DefExpResponseDatebool = projectSettings.filter(x => x.ConfigKey == "IsEnableDefaultExpectedResponseDate")[0].ConfigValue;
              if (DefExpResponseDatebool == "true") {
                var index = projectSettings.findIndex(x => x.ConfigKey == "DefaultExpectedResponseDateByDays");
                if (index > -1) {
                  this.addDaystoExpResponseDate = Number(projectSettings.filter(x => x.ConfigKey == "DefaultExpectedResponseDateByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateReminderByDays");
                if (index > -1) {
                  this.closeOutReminderDays = Number(projectSettings.filter(x => x.ConfigKey == "CloseOutDateReminderByDays")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsUniqueNumberByProject");
            if (index > -1) {
              var UniqueNumberByProjectbool = projectSettings.filter(x => x.ConfigKey == "IsUniqueNumberByProject")[0].ConfigValue;
              if (UniqueNumberByProjectbool == "true") {
                this._InterfaceByProject = true;
              }
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup");
            if (index > -1) {
              var UniqueNumberByInterfaceGroupbool = projectSettings.filter(x => x.ConfigKey == "IsUniqueNumberByInterfaceGroup")[0].ConfigValue;
              if (UniqueNumberByInterfaceGroupbool == "true") {
                this.isInterfaceNumberByGroup = true;
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern");
            if (index > -1) {
              var CustomiseInterfaceNumberPatternbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCustomiseInterfaceNumberPattern")[0].ConfigValue;
              if (CustomiseInterfaceNumberPatternbool == "true") {
                this.isEnableCustomiseInterfaceNumberPattern = true;
                var index = projectSettings.findIndex(x => x.ConfigKey == "CustomiseInterfaceNumberPattern");
                if (index > -1) {
                  this.customiseInterfaceNumberPattern = String(projectSettings.filter(x => x.ConfigKey == "CustomiseInterfaceNumberPattern")[0].ConfigValue);
                }
              }
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsDualDiscipline");
            if (index > -1) {
              var IsDualDisciplinebool = projectSettings.filter(x => x.ConfigKey == "IsDualDiscipline")[0].ConfigValue;
              if (IsDualDisciplinebool == "true") {
                this.isDualDisciplinebool = true;
              }
            }


            this.datePickerConfig = Object.assign({}, {
              containerClass: this.dpColorTheme,
              minDate: CommonFunction.ConvertUTCToTimeZoneTime(CommonFunction.ConvertLocalTimeToUTC(new Date()), this._projectTimeZone, false),
              showWeekNumbers: false,
              dateInputFormat: this.dateTimeFormat.toUpperCase(),
              rangeInputFormat: this.dateTimeFormat.toUpperCase()
            });

          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.projectService.getProject(this._projectID).subscribe({
        next: project => {
          if (project[0].SafeDomainLists.length > 0) {
            this.domainlist = project[0].SafeDomainLists.filter(a => !a.IsDeleted);
            this.communicationService.setDomainListdata(this.domainlist);
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

      this.projectFieldsConfigService.getProjectFieldsConfigurationByProjectId(this._projectID, this.interfaceType).subscribe({
        next: projectFields => {
          if (projectFields.length > 0) {
            this.showOtherTab = true;
          } else {
            this.showOtherTab = false;
          }
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  fillRequestingDisciplinesDDL(contractorId: any): void {
    this.SpinnerService.show();
    try {
      this.contractorIds.length = 0;
      this.contractorIds.push(contractorId);
      this.disciplineService.getDisciplinesByContractorIds(this.contractorIds).subscribe({
        next: disciplines => {
          this.requestingDisciplines = disciplines;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillProviderContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: providerContractor => {
          this.providerContractors = providerContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }

  }

  fillRequestingContractorDDL(): void {
    try {
      this.SpinnerService.show();
      this.contractorService.getContractorsByProjectId(this._projectID).subscribe({
        next: requestingContractor => {
          this.requestingContractors = requestingContractor;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.statusService.getStatusesByModule(myGlobals.InterfaceModule).subscribe({
        next: status => {

          this.allstatus = status;
          this.status = status.filter(x => x.Id == myGlobals.IdentifiedStatusId || x.Id == myGlobals.OpenStatusId);
          let statusResult = status.find(x => x.Name.toLowerCase().includes(myGlobals.IdentifiedStatusName.toLowerCase()))
          this.selectedStatus = statusResult?.Id;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillPriorityDDL(): void {
    try {
      this.SpinnerService.show();
      this.priorityService.getPriorities("Interface").subscribe({
        next: priority => {
          this.priority = priority;

          let priorityResult = priority.find(x => x.Name.toLowerCase().includes('normal'))
          this.interfaceModel.Priority = priorityResult?.Id!;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillInterfaceGroupDDL(mprojectId: number, contractorId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupsByProjectId(mprojectId, contractorId).subscribe({
        next: interfaceGroup => {
          this.interfaceGroups = interfaceGroup;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetInterface(forms: NgForm) {
    forms.resetForm();
  }

  updateDocumentUploadStatus(documentData: Document) {
    try {
      if (documentData != null) {
        documentData.Status = myGlobals.DocumentUploadStatusCompletedId; //Document status set to upload completed
        this.documentService.put(documentData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  uploadDocuments(uploadDataList: File[], _interface: Interface, documentData: Document[]) {
    try {
      this.SpinnerService.show();
      if (uploadDataList != null) {
        uploadDataList.forEach(uploadData => {
          var filepath = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/";
          uploadData.DestinationStorageDetail!.FilePath = filepath;
          uploadData.FileName = "Proj_" + _interface.ProjectId + "/InterfaceGroup_" + _interface.InterfaceGroupID + "/Interface_" + _interface.Number + "/" + uploadData.FileName;
          var docsdata = documentData.filter(x => filepath + x.FileNameSaved == uploadData.FileName && x.Status == Number(myGlobals.DocumentUploadStatusId));
          this.filesService.UploadFiles(uploadData).subscribe({
            next: data => {
              var docUploadData = docsdata[0]
              if (docUploadData != null) {
                docUploadData.InterfaceID = _interface.Id;
                this.updateDocumentUploadStatus(docUploadData);
              }
              this.SpinnerService.hide();
            },
            error: err => {
              this.SpinnerService.hide();
              this.errorMessage = err
              this.dialog.confirmDialog({
                title: 'Error',
                message: myGlobals.exceptionmessage,
                cancelCaption: 'Close',
              });
              this.logger.error(err);
            }
          })

        });

      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  generateCustomiseInterfaceNumber(interfacelist: Interface[]): string {
    var groupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
    var disciplineData = this.requestingDisciplines.filter(x => x.Id == Number(this.selectedRequestingDisciplines))
    var contractorData = this.requestingContractors.filter(x => x.Id == this.interfaceModel.ReceivingContractorID);
 
    this.customiseInterfaceNumberPattern = this.customiseInterfaceNumberPattern.replaceAll("<GroupCode>", groupData[0].Code)
    this.customiseInterfaceNumberPattern = this.customiseInterfaceNumberPattern.replaceAll("<DisciplineCode>", disciplineData[0].Code)
    this.customiseInterfaceNumberPattern = this.customiseInterfaceNumberPattern.replaceAll("<ContractorCode>", contractorData[0].Code);

    var recordlist = interfacelist.filter(x => x.Number?.includes(this.customiseInterfaceNumberPattern));
    let lastSequenceNumber = "";
    if (recordlist.length > 0) {
      lastSequenceNumber = recordlist[0].Number!;           
    }

    let num2 = 0;
    if (lastSequenceNumber != "") {
      let lastSequenceNumber4Digit = lastSequenceNumber;
      lastSequenceNumber4Digit = lastSequenceNumber4Digit?.slice(-4);
      let lastSequenceNumber3Digit = lastSequenceNumber;
      lastSequenceNumber3Digit = lastSequenceNumber3Digit?.slice(-3);

      if (!parseInt(lastSequenceNumber4Digit)) {
        if (!parseInt(lastSequenceNumber3Digit?.slice(-3))) {
          num2 = 0;
        } else {
          num2 = Number(lastSequenceNumber3Digit)
        }
      } else {
        num2 = Number(lastSequenceNumber4Digit)
      }

      num2++;
    }
    else {
      //Initial number strat with 1 sequence
      num2 = 1;
    }

    let num2Formatted = this.commonFunction.addLeadingZeros(String(num2), 4)
    this.customiseInterfaceNumber = this.customiseInterfaceNumberPattern + "-" + num2Formatted;

    return this.customiseInterfaceNumber;
  }

  save(): void {
    try {
      this.SpinnerService.show();
      if (this.form.valid) {
        let interfacenum: string;
        let interfacenum1: string;
        interfacenum1 = this.commonFunction.addLeadingZeros(String(this._projectID), 6)
        this.interfaceService.getInterfacesByProjectIdAndInterfaceType(this._projectID, "").subscribe({
          next: interfacelist => {
            if (this._InterfaceByProject) {

              interfacenum1 = this.commonFunction.addLeadingZeros(String(this._projectID), 6)

              if (interfacelist.length > 0) {
                let num1 = interfacelist[0].Number;
                num1 = num1?.slice(-4);
                let num2 = Number(num1);
                num2++;
                let num2Formatted = this.commonFunction.addLeadingZeros(String(num2), 4)
                interfacenum = interfacenum1 + num2Formatted;
              }
              else {
                interfacenum = interfacenum1 + "0001";
              }
            }
            else if (this.isInterfaceNumberByGroup) {
              var list = this.interfaceGroups.filter(x => x.Id == this.interfaceModel.InterfaceGroupID);
              var grpcode = list[0].Code;
              interfacenum1 = this.commonFunction.addLeadingZeros(grpcode!, 6)
              var recordlist = interfacelist.filter(x => x.InterfaceGroupID == this.interfaceModel.InterfaceGroupID);
              if (recordlist.length > 0) {
                let num1 = recordlist[0].Number;
                num1 = num1?.slice(-4);
                let num2 = Number(num1);
                num2++;
                let num2Formatted = this.commonFunction.addLeadingZeros(String(num2), 4)
                interfacenum = interfacenum1 + num2Formatted;
              } else {
                interfacenum = interfacenum1 + "0001";
              }


            }
            else if (this.isEnableCustomiseInterfaceNumberPattern) {
              interfacenum = this.generateCustomiseInterfaceNumber(interfacelist);
            }


            this.interfaceModel.Number = interfacenum;
            this.interfaceModel.NumberGenerationPattern = this._InterfaceByProject ? "ProjectLevel" : this.isInterfaceNumberByGroup ? "InterfaceGroupLevel" : "CustomisedPattern";
            this.interfaceModel.CreatedBy = this._userRoleMappingId;
            this.interfaceModel.CreatedByRoleId = this._userRoleId;
            this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlannedstr;
            this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlannedstr;
            this.interfaceModel.ProjectId = this._projectID;
            this.interfaceModel.ModuleId = myGlobals.InterfaceModule;
            this.interfaceModel.Documents = this.interfaceModel.Documents.filter(x => x.Id == 0 && !x.IsDeleted);
            this.interfaceModel.ReferenceLinks = this.interfaceModel.ReferenceLinks.filter(x => x.Id == 0 && !x.IsDeleted);

            //get Workflowtemplate from interfacegrouptemplate mapping                        
            var selectedInterfaceGroupData = this.interfaceGroups.filter(x => x.Id == this.selectedInterfaceGroup);
            this.interfaceGroupTemplateMappings = selectedInterfaceGroupData[0].InterfaceGroupTemplateMappings?.filter(x => x.ModuleId == myGlobals.InterfaceModule)!;
            if (this.interfaceGroupTemplateMappings.length > 0) {

              var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == myGlobals.InterfaceModule);

              //mark workflow stageid if workflow mapping is not avaiable for interfacegroup then bydefault stage will be save with 1 value
              this.workflowMappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, myGlobals.InterfaceModule)
                .toPromise()
                .then(workflowMappingData => {
                  this.workflowMappings = workflowMappingData!;

                  if (this.workflowMappings.length! > 0) {
                    var workflowStageData = this.workflowMappings.filter(x => x.Stage?.CurrentStatus == this.selectedStatus);

                    if (workflowStageData.length > 0) {
                      this.interfaceModel.StageId = workflowStageData[0].StageId;
                      this.interfaceModel.Status = workflowStageData[0].Stage?.ApprovedStatus!;

                      this.SpinnerService.hide();

                      if (!this.isDualDisciplinebool) {
                        this.interfaceModel.ResponsibleContractorDisciplineID = this.interfaceModel.ReceivingContractorDisciplineID;
                      }

                      if (this._isCopy || this._isRevision) {
                        this.interfaceModel.RecDiscipline = null;
                        this.interfaceModel.ResDiscipline = null;
                      }
                      
                      this.interfaceService.post(this.interfaceModel).subscribe({
                        next: data => {
                          this.SpinnerService.hide();
                          if (UploadDocuments != null && UploadDocuments.length > 0) {
                            this.uploadDocuments(UploadDocuments, data, this.interfaceModel.Documents);
                          }
                          //Write a log
                          this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Interface created", "Interface " + this.interfaceModel.Number + " created.", this._contractorID, this._userRoleId, data.Id);
                          this.interfaceModel.Documents.forEach((docRow) => {
                            //Write a log
                            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit documents", "Document added", "Document " + docRow.Title + " added.", this._contractorID, this._userRoleId, data.Id);
                          });
                          this.interfaceModel.ReferenceLinks.forEach((linkRow) => {
                            //Write a log
                            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Submit links", "Link added", "Link " + linkRow.Title + " added.", this._contractorID, this._userRoleId, data.Id);
                          });

                          this.interfaceModel = data;
                          //Update interface model data as expanded entity data is not available in the current interface model.
                          this.interfaceService.getInterfaceDataForEmailNotifcationById(this.interfaceModel.Id).subscribe({
                            next: interfaces => {
                              if (interfaces.length > 0) {
                                this.interfaceModel = interfaces[0];

                                //Save email notification on interface creation.
                                this.commonNotificationFunction.processEmailNotifications(myGlobals.InterfaceModule, this.isEnableCloseoutReminder, true, this.closeOutReminderDays, this.interfaceModel, "", workflowTemplate[0].WorkflowTemplateId, this.interfaceModel.StageId);
                              }
                            }
                          });
                          this.commonFunction.redirectToRegisterpage(myGlobals.InterfaceModule);
                          this.snackBar.openFromComponent(SnackbarComponent, {
                            data: "Interface created successfully!",
                            duration: myGlobals.snackBarDuration,
                            verticalPosition: myGlobals.snackBarVerticalPosition,
                            horizontalPosition: myGlobals.snackBarHorizontalPosition
                          });

                        },
                        error: err => {
                          this.SpinnerService.hide();
                          this.errorMessage = err
                          this.dialog.confirmDialog({
                            title: 'Error',
                            message: myGlobals.exceptionmessage,
                            cancelCaption: 'Close',
                          });
                          this.logger.error(err);
                        }
                      })
                    }
                    else {
                      this.SpinnerService.hide();
                      this.dialog.confirmDialog({
                        title: 'Warning!',
                        message: "Mapping not found for selected status. Please check with project admin for workflow mapping.",
                        cancelCaption: 'Close',
                      });
                    }
                  } else {
                    this.SpinnerService.hide();
                    this.dialog.confirmDialog({
                      title: 'Warning!',
                      message: "There is no Workflow Mapping. Please check with project admin for workflow mapping.",
                      cancelCaption: 'Close',
                    });
                  }
                });
            } else {
              this.SpinnerService.hide();
              this.dialog.confirmDialog({
                title: 'Warning!',
                message: "There is no workflow template mapped with selected interface group. Please check with project admin for workflow template mapping.",
                cancelCaption: 'Close',
              });
            }
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onCancel() {
    //reset Interface tab
    this.form.reset();
    this.disciplines = [];
    this.requestingDisciplines = [];
    this.commonFunction.redirectToRegisterpage(myGlobals.InterfaceModule);
  }

  onProviderContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArrs.filter(x => x.Id != contractorId);
        this.selectedRequestingContractor = result[0].Id;
        this.fillRequestingDisciplinesDDL(result[0].Id);
        this.fillDisciplineDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.disciplines = [];

      this.form.get('providerDisciplineFormControl')?.reset();
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onRequestingContractorSelected(contractorId: number) {
    try {
      if (contractorId > 0) {
        let result = this.contraArrs.filter(x => x.Id != contractorId);
        this.selectedProviderContractor = result[0].Id;
        this.fillDisciplineDDL(result[0].Id);
        this.fillRequestingDisciplinesDDL(contractorId);
        this.resetSelectedValue();
      }
      else
        this.requestingDisciplines = [];

      this.form.get('requestingDisciplineFormControl')?.reset();
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onInterfaceGroupSelected(interfaceGrpId: number): void {
    try {
      this.SpinnerService.show();
      this.interfaceGroupService.getInterfaceGroupById(interfaceGrpId).subscribe({
        next: igData => {
          this.interfaceGroup2 = igData[0];
          this.contraArrs = [];
          this.contraArrs.push(this.interfaceGroup2.Contractor1);
          this.contraArrs.push(this.interfaceGroup2.Contractor2);
          this.providerContractors = this.contraArrs;
          this.requestingContractors = this.contraArrs;

          this.selectedRequestingContractor = this._contractorID;        
          let result = this.contraArrs.filter(x => x.Id != this._contractorID);
          this.selectedProviderContractor = result[0].Id;     

          if (this.isDualDisciplinebool) {
            this.fillRequestingDisciplinesDDL(this._contractorID);                
            this.fillDisciplineDDL(result[0].Id);
          }
          else {
            this.fillRequestingDisciplinesDDL(this.interfaceGroup2.Contractor1ID);
            this.fillDisciplineDDL(this.interfaceGroup2.Contractor2ID);
          }         

          if (this._interfaceID == 0) {
            this.resetSelectedValue();
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  resetSelectedValue() {
    this.form.get('requestingDisciplineFormControl')?.reset();
    this.form.get('providerDisciplineFormControl')?.reset();
  }

  onApprovedResDateChange(type: string, event: any) {
    this.interfaceModel.ApprovedIssuePlanned = new Date(event.value!);
    this.interfaceModel.ApprovedIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
  }

  onFirstResDateChange(type: string, event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event.value!);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event.value, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event.value!);
        this.newCloseOutDate.setDate(this.newCloseOutDate.getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  changeExpectedDate(event: any) {
    try {
      this.interfaceModel.FirstIssuePlanned = new Date(event);
      this.interfaceModel.FirstIssuePlannedstr = formatDate(event, 'yyyy-MM-dd hh:mm:ss', 'en-US');
      if (this.addDaystoExpResponseDate > 0) {
        this.newCloseOutDate = new Date(event);
        this.newCloseOutDate.setDate(new Date(this.newCloseOutDate).getDate() + (this.addDaystoExpResponseDate));
        this.interfaceModel.ApprovedIssuePlanned = this.newCloseOutDate;
        this.interfaceModel.ApprovedIssuePlannedstr = formatDate(this.newCloseOutDate.toString(), 'yyyy-MM-dd hh:mm:ss', 'en-US');
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  toCopyGetInterface(interfaceId: number, isCopyDocLinks: boolean, isRevision: boolean): void {
    try {
      this.SpinnerService.show();
      this.interfaceService.getCopiedInterfaceById(interfaceId).subscribe({
        next: interfaceData => {
          this.interfaceModel = new Interface();
          this.interfaceModel.Title = interfaceData[0].Title;
          this.interfaceModel.InterfaceGroupID = interfaceData[0].InterfaceGroupID;
          this.onInterfaceGroupSelected(this.interfaceModel.InterfaceGroupID!);
          this.interfaceModel.Priority = interfaceData[0].Priority;
          this.interfaceModel.ReceivingContractorID = this.selectedRequestingContractor;
          this.interfaceModel.ResponsibleContractorID = this.selectedProviderContractor;

          this.interfaceModel.Description = interfaceData[0].Description;
          this.interfaceModel.Comments = interfaceData[0].Comments;

          this.interfaceModel.RecDiscipline = interfaceData[0].RecDiscipline;
          this.interfaceModel.ResDiscipline = interfaceData[0].ResDiscipline;

          this.selectedInterfaceGroup = interfaceData[0].InterfaceGroupID!;
          this.selectedProviderDisciplines = interfaceData[0].ResDiscipline.Id;

          //check if login contractor is provider contractor user
          if (this._contractorID == interfaceData[0].ResponsibleContractorID) {
            this.interfaceModel.ReceivingContractorDisciplineID = interfaceData[0].ResponsibleContractorDisciplineID;
            this.interfaceModel.ResponsibleContractorDisciplineID = interfaceData[0].ReceivingContractorDisciplineID;
          } else {
            this.interfaceModel.ReceivingContractorDisciplineID = interfaceData[0].ReceivingContractorDisciplineID;
            this.interfaceModel.ResponsibleContractorDisciplineID = interfaceData[0].ResponsibleContractorDisciplineID;
          }

          //Default date as per current time zone day.
          var tempDate = CommonFunction.getTimeZoneNowUTC(this._projectTimeZone);
          tempDate.setHours(0, 0, 0, 0);

          this.interfaceModel.FirstIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.interfaceModel.FirstIssuePlanned = tempDate;
          this.interfaceModel.ApprovedIssuePlannedstr = formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US');
          this.interfaceModel.ApprovedIssuePlanned = tempDate

          this.interfaceModel.Status = myGlobals.IdentifiedStatusId;
          this.interfaceModel.FilePathName = interfaceData[0].FilePathName;
          //Set Closeout Date on the basis of ProjectSetting's Response Days setting.
          this.changeExpectedDate(formatDate(tempDate, 'yyyy-MM-dd hh:mm:ss', 'en-US'));

          if (isRevision) {
            this.interfaceModel.RevisionFromId = interfaceData[0].Id;
            this.interfaceModel.Response = '';
            this.interfaceModel.Documents = [];
          }

          if (isCopyDocLinks) {
            interfaceData[0].Documents.forEach((docRow) => {
              docRow.Id = 0;
              docRow.CreatedBy = this._userRoleMappingId;
              docRow.CreatedDate = null!;
              docRow.InterfaceID = 0;
              docRow.CreatedUserRoleMapping = null!;
              docRow.Status = Number(myGlobals.DocumentUploadStatusId);
            });

            this.interfaceModel.Documents = interfaceData[0].Documents.filter(x => !x.IsDeleted);
            this.communicationService.setFilePathName(this.interfaceModel.FilePathName);
            this.communicationService.setDocumentsdata(this.interfaceModel.Documents.filter(x => !x.IsDeleted));
            this.documentCount = this.interfaceModel.Documents.filter(x => !x.IsDeleted).length.toString();
            interfaceData[0].ReferenceLinks.forEach((docRow) => {
              docRow.Id = 0;
              docRow.CreatedBy = this._userRoleMappingId;
              docRow.CreatedDate = null!;
              docRow.InterfaceID = 0;
              docRow.CreatedUserRoleMapping = null!;
            });
            this.interfaceModel.ReferenceLinks = interfaceData[0].ReferenceLinks.filter(x => !x.IsDeleted);
            this.communicationService.setReferenceLinksdata(this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted));
            this.referenceLinkCount = this.interfaceModel.ReferenceLinks.filter(x => !x.IsDeleted).length.toString();
          }


          if (interfaceData[0].InterfaceProjectFieldsMappings.length > 0) {
            interfaceData[0].InterfaceProjectFieldsMappings.forEach(fieldData => {
              this.interfaceModel.InterfaceProjectFieldsMappings.push({ Id: 0, InterfaceID: 0, ProjectFieldsConfigurationId: fieldData.ProjectFieldsConfigurationId, FieldValue: fieldData.FieldValue, IsDeleted: false, CreatedBy: this._userRoleMappingId })
            });
          }

          if (this.interfaceModel.Documents != null && this.interfaceModel.Documents.length > 0) {
            this.downloadCopyDocuments()
          }

          this.interfaceModel.Id = 0;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  downloadCopyDocuments() {
    try {
      if (this.interfaceModel.Documents.length > 0) {
        this.interfaceModel.Documents.forEach((copyDoc) => {
          var previousFileNameSaved = copyDoc.FileNameSaved;
          copyDoc.FileNameSaved = Guid.create() + "_" + copyDoc.FileName;
          this.getDocumentByName(this.interfaceModel.FilePathName, copyDoc.FileNameSaved, previousFileNameSaved!);
        })
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  async downloadDocument(blob: Blob, fileNameSaved: string): Promise<void> {
    const base64String = await CommonFunction.convertBlobToBase64(blob);
    this.documentSrc = base64String;

    var sourceBase64 = this.documentSrc.split(',');
    if (sourceBase64.length > 1) {
      this.documentSrc = sourceBase64[1];
    }

    UploadDocuments.push({ FileName: fileNameSaved, FileContent: this.documentSrc })

  }

  getDocumentByName(filePathName: string, fileNameSaved: string, previousFileName: string) {
    try {
      this.SpinnerService.show();
      this.uploadDocs = [];
      this.uploadDocs.push({ FileName: filePathName + previousFileName, SourceStorageDetail: this.sourceStorageDetail})
      this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
        next: data => {
          this.downloadDocument(data, fileNameSaved);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  documentsaddedHandler(documents: Document[] = []) {
    this.interfaceModel.Documents = documents;
  }

  refLinksaddedHandler(refLinks: ReferenceLink[] = []) {
    this.interfaceModel.ReferenceLinks = refLinks;
  }

  uploadDocsaddedHandler(files: File[] = []) {
    UploadDocuments.push(...files);
  }

  documentCountaddedHandler(docCount: string) {
    this.documentCount = docCount;
  }

  refLinkCountaddedHandler(reflinkCount: string) {
    this.referenceLinkCount = reflinkCount;
  }

  projectFieldsConfigaddedHandler(projectFieldConfig: InterfaceProjectFieldsMapping[] = []) {
    this.interfaceModel.InterfaceProjectFieldsMappings = projectFieldConfig;
  }

}
