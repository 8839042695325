import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssignmentComponent } from './assignment.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssignmentViewComponent } from './assignment-view.component';



@NgModule({
  declarations: [AssignmentComponent, AssignmentViewComponent],
  imports: [
    CommonModule, MaterialModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [AssignmentComponent, AssignmentViewComponent]
})
export class AssignmentModule { }
