import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { File } from '../../app_data/file';
import { MatSelectChange } from '@angular/material/select';
import { Project } from '../../app_data/project';
import { ProjectStatus } from '../../app_data/projectStatus';
import { TimeZones } from '../../app_data/timezones';
import { DateTimeFormat } from '../../app_data/datetimeformat';
import { Setting } from '../../app_data/setting';
import { ProjectStatusService } from '../../services/projectstatus.service';
import { ProjectService } from '../../services/project.service';
import { CommunicationService } from '../../services/communication.service';
import { FilesService } from '../../services/files.service';
import { ProjectSettingService } from '../../services/projectsetting.service';
import { ProjectSetting } from '../../app_data/projectSetting';
import { SafeDomainList } from '../../app_data/safedomainlist';
import { SystemLogService } from '../../services/systemLog.service';
import { NGXLogger } from 'ngx-logger';
import * as myGlobals from '../../app_data/globals';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';
import { ProjectFieldsConfiguration } from 'src/app/app_data/projectFieldsConfiguration';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { Subscription, map, share, timer } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';
import { StorageDetail } from '../../app_data/storageDetail';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectEditComponent implements OnInit, OnDestroy {
  Projectform !: FormGroup;
  project !: Project;
  pageTitle: string = "Edit Project";
  errorMessage: string = "";
  projectID!: number;
  isEdit = false;
  selectedProjStatus: number = 0;
  file !: File;
  fileAttr = '';
  imageSrc!: string;
  projectStatuslists: ProjectStatus[] = [];
  uploadimagedata!: File;
  uploadDocs: File[] = [];
  lastFileName: string = "";
  maxFileSize: string = "";
  selectedTimeZone?: string = "";
  selecteddateformat?: string = "";
  selectedTimeZoneName?: string = "";
  selecteddateformatName?: string = "";
  selectedvalue: any;
  timezones: TimeZones[] = [];
  dateformats: DateTimeFormat[] = [];
  todaydate !: Date;
  selectedValue: any;
  utcoffsetvalue: string = "";
  settingForm !: FormGroup;
  setting !: Setting;
  savedVal!: string;

  time = new Date();
  currentDayTime = new Date();
  intervalId: any;
  subscription!: Subscription;

  fileSourceContent: string = "";
  fileNameAttr: string = "";
  labelCaption: string = "Choose file";
  parentControlName: string = "LogoPath";
  isSelected: string = "";
  activeModules: number[] = [];
  hideTQTab: boolean = false;
  sourceStorageDetail!: StorageDetail;
  destinationStorageDetail!: StorageDetail;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userName = sessionStorage.getItem("userFName") + " " + sessionStorage.getItem("userLName");
  _projectTimeZone = sessionStorage.getItem("projectTimeZone")!;
  _dateTimeFormat = sessionStorage.getItem("projectDateTimeformat")!;
  imageURL!: SafeUrl;
  constructor(private projectstatusService: ProjectStatusService, private projectService: ProjectService,
    private router: Router, private communicationService: CommunicationService,
    private filesService: FilesService, private timezoneService: ProjectSettingService,
    private systemLogService: SystemLogService, private logger: NGXLogger,
    private SpinnerService: NgxSpinnerService, private sanitizer: DomSanitizer,
    private route: ActivatedRoute, private dialog: DialogService,
    private snackBar: MatSnackBar) {
    this.project = new Project(); this.uploadimagedata = new File(); this.setting = new Setting();
    this.sourceStorageDetail = new StorageDetail();
    this.destinationStorageDetail = new StorageDetail();
  }

  ngOnInit(): void {
    this.Projectform = new FormGroup({
      Name: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required]),
      Status: new FormControl(1, [Validators.required]),
      SupportMail: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      SupportPhone: new FormControl('', [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
      MailSenderAddress: new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")]),
      LogoPath: new FormControl(''),
    });

    this.settingForm = new FormGroup({
      TimeZoneName: new FormControl(''),
      DateTimeFormat: new FormControl(''),
      UTCOffset: new FormControl(''),
    });
    this.Projectform.controls["Code"].disable();
    this.fillTimeZones();
    this.fillDateTimeformat();
    this.projectID = this.route.snapshot.params['id']
    this.communicationService._interfaceId = 0;

    if (this.projectID > 0) {
      this.isEdit = true;
      this.getbyProjectId(this.projectID);
    }
    this.fillStatusDDL();

    this.subscription = timer(0, 1000)
      .pipe(
        map(() => CommonFunction.getTimeZoneDateUTC(this._projectTimeZone)),
        share()
      )
      .subscribe(time => {
        this.currentDayTime = time;
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.imageURL != null)
      URL.revokeObjectURL(this.imageURL.toString());
  }

  settingsaddedHandler(settings: ProjectSetting[]) {
    if (this.project.Settings == null) {
      this.project.Settings = settings;
    }
    else {
      if (settings != null) {
        settings.forEach(
          x => {
            var index = this.project.Settings.findIndex(a => a.ConfigKey == x.ConfigKey);
            if (index > -1) {
              this.project.Settings[index].ConfigValue = x.ConfigValue;
            }
            else {
              this.project.Settings.push(x)
            }
          }
        );
      }
    }
  }

  safedomainaddedHandler(safedomainlist: SafeDomainList[] = []) {
    this.project.SafeDomainLists = safedomainlist;
  }

  onFileChange(event: any) {
    try {
      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        this.fileAttr = file.name;
        this.Projectform.patchValue({
          LogoPath: file.name
        });
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.imageSrc = reader.result as string;
          this.Projectform.patchValue({
            fileSource: reader.result
          });
        };
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillStatusDDL(): void {
    try {
      this.SpinnerService.show();
      this.projectstatusService.getStatuses().subscribe({
        next: data => {
          this.projectStatuslists = data;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getbyProjectId(Id: number) {
    try {
      this.SpinnerService.show();
      
      this.projectService.getProject(Id).subscribe({
        next: Data => {
          this.project = Data[0];
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "UTCOffset");
          if (index > -1) {
            this.setting.UTCOffset = this.project.Settings.filter(x => x.ConfigKey == "UTCOffset")[0].ConfigValue;
            var offsetValue = this.setting.UTCOffset.split("|")[0]!;
            this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
            this.todaydate = CommonFunction.getTimeZoneDateUTC(this._projectTimeZone);
          }
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "CultureInfo");
          if (index > -1) {
            this.setting.CultureInfo = this.project.Settings.filter(x => x.ConfigKey == "CultureInfo")[0].ConfigValue;
          }
          var index = this.project.Settings.findIndex(x => x.ConfigKey == "Theme");
          if (index > -1) {
            this.isSelected = this.project.Settings.filter(x => x.ConfigKey == "Theme")[0].ConfigValue;
            this.setting.Theme = this.project.Settings.filter(x => x.ConfigKey == "Theme")[0].ConfigValue;
            this.savedVal = this.project.Settings.filter(x => x.ConfigKey == "Theme")[0].ConfigValue;
          }


          var index = this.project.Settings.findIndex(x => x.ConfigKey == "IsEnableDocumentUploadFileSize");
          if (index > -1) {
            var DefMaxFileSizebool = this.project.Settings.filter(x => x.ConfigKey == "IsEnableDocumentUploadFileSize")[0].ConfigValue;
            if (DefMaxFileSizebool == "true") {
              var index = this.project.Settings.findIndex(x => x.ConfigKey == "DocumentUploadFileSize");
              if (index > -1) {
                this.maxFileSize = this.project.Settings.filter(x => x.ConfigKey == "DocumentUploadFileSize")[0].ConfigValue;
              }
            }
          }
          
          var tqModulesIndex = this.project.ModuleProjectMappings.findIndex(a => !a.IsDeleted && a.ModuleId == Number(myGlobals.TQModule))
          if (tqModulesIndex > -1) {
            this.hideTQTab = false;
          } else {
            this.hideTQTab = true;
          }

          this.communicationService.setProjectSettingsdata(this.project);
          this.communicationService.setSafeDomainSettingsdata(this.project);
          if (Data[0].LogoPath != '') {
            this.getLogoByName("Proj_" + this.project.Id + "/files/Logo/" + Data[0].LogoPath);
            this.fileNameAttr = Data[0].LogoPath;
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  moduleProjectMappingFetch(moduleProjectMappings: ModuleProjectMapping[]) {
    this.project.ModuleProjectMappings = moduleProjectMappings;
    var tqModulesIndex = this.project.ModuleProjectMappings.findIndex(a => a.ModuleId == Number(myGlobals.TQModule) && !a.IsDeleted)
    if (tqModulesIndex > -1) {
      this.hideTQTab = false;
    } else {
      this.hideTQTab = true;
    }
  }


  save() {
    try {
      this.SpinnerService.show();
      if (this.Projectform.valid) {
        //update interface
        this.project.SafeDomainLists = this.project.SafeDomainLists.filter(x => (x.Id != 0 || !x.IsDeleted));
        this.projectService.put(this.project).subscribe({
          next: allprojects => {
            
            if (this.uploadimagedata.FileContent != '') {
              this.uploadimagedata.FileName = "Proj_" + this.project.Id + "/files/Logo/" + this.uploadimagedata.FileName;
              this.uploadimagedata.DestinationStorageDetail = this.destinationStorageDetail;
              this.uploadimagedata.DestinationStorageDetail!.FilePath = "Proj_" + this.project.Id + "/files/Logo/";
              this.uploadImage(this.uploadimagedata);
            }
            else {
              this.uploadimagedata.FileName = "Proj_" + this.project.Id + "/files/Logo/" + this.lastFileName;
              this.uploadimagedata.SourceStorageDetail = this.sourceStorageDetail;
              this.uploadimagedata.SourceStorageDetail!.FilePath = "Proj_" + this.project.Id + "/files/Logo/";
              this.deleteLogo(this.uploadimagedata);
              //Write a log
              this.systemLogService.writeLog(this.project.Id, this._userIdLogin, "Edit Project", "Project Setting", "Project logo filename '" + this.lastFileName + "' is removed.", this._contractorID, this._userRoleId);
            }

            this.systemLogService.writeLog(this.project.Id, this._userIdLogin, "Edit Project", "Project Detail", this.project.Name + "|" + this.project.SupportPhone + "|" + this.project.SupportMail
              + "|" + this.project.MailSenderAddress + "|" + this.project.Status + "|" + this.project.LogoPath, this._contractorID, this._userRoleId);
            this.project.Settings.forEach((docRow) => {
              //Write a log
              this.systemLogService.writeLog(this.project.Id, this._userIdLogin, "Edit Project", "Project Setting", docRow.ConfigKey + " - " + docRow.ConfigValue, this._contractorID, this._userRoleId);
            });
            this.project.SafeDomainLists.forEach((docRow) => {
              //Write a log
              this.systemLogService.writeLog(this.project.Id, this._userIdLogin, "Edit Project", "Project Safe Domain Lists", docRow.DomainName, this._contractorID, this._userRoleId);
            });

            this.SpinnerService.hide();

            this.snackBar.openFromComponent(SnackbarComponent, {
              data: "Project saved successfully!",
              duration: myGlobals.snackBarDuration,
              verticalPosition: myGlobals.snackBarVerticalPosition,
              horizontalPosition: myGlobals.snackBarHorizontalPosition
            });

            this.redirectToBackPage();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        });
      }
      this.SpinnerService.hide();
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToBackPage() {
    this.router.navigateByUrl('/ProjectList');
  }

  setfileSourceContentwithtype(eFileSourcewithtype: string) {
    this.imageSrc = eFileSourcewithtype;
  }

  setfileSourceContent(eFileSource: string) {
    this.fileSourceContent = eFileSource;
    this.uploadimagedata.FileContent = eFileSource;
  }

  setfileName(eFileName: string) {
    this.fileNameAttr = eFileName;
    this.project.LogoPath = eFileName;
    this.uploadimagedata.FileName = eFileName;
  }

  uploadImage(uploadData: File) {
    try {
      this.SpinnerService.show();
      if (uploadData != null) {
        this.filesService.UploadFiles(uploadData).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  deleteLogo(deleteFile: File) {
    try {
      this.SpinnerService.show();
      if (deleteFile != null) {
        deleteFile.SourceStorageDetail = this.sourceStorageDetail;
        this.filesService.DeleteFile(deleteFile).subscribe({
          next: data => {
            this.SpinnerService.hide();
          },
          error: err => {
            this.SpinnerService.hide();
            this.errorMessage = err
            this.dialog.confirmDialog({
              title: 'Error',
              message: myGlobals.exceptionmessage,
              cancelCaption: 'Close',
            });
            this.logger.error(err);
          }
        })
      }
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }


  getLogoByName(fileName: string) {
    try {
      this.SpinnerService.show();
      this.uploadDocs = [];
      this.uploadDocs.push({ FileName: fileName, SourceStorageDetail: this.sourceStorageDetail })
      this.filesService.DownloadFile(this.uploadDocs[0]).subscribe({
        next: data => {
          this.imageURL = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(data))
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillTimeZones(): void {
    try {
      this.SpinnerService.show();
      this.timezoneService.getTimezones().subscribe({
        next: timezones => {
          this.timezones = timezones;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  fillDateTimeformat(): void {
    try {
      this.SpinnerService.show();
      this.timezoneService.getDateformats().subscribe({
        next: datetimeformat => {
          this.dateformats = datetimeformat;
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ontimezoneChangevalue(e: MatSelectChange) {
    try {
      this.setting.TimeZoneName = e.source.triggerValue;
      var offsetValue = e.source.value.split("|")[0]!;
      this.utcoffsetvalue = offsetValue.substring(0, offsetValue.lastIndexOf(':'))!;
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "TimeZoneName");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = e.source.triggerValue;
        this.project.Settings[index].ModifiedBy = this._userIdLogin;
      }
      var index2 = this.project.Settings.findIndex(x => x.ConfigKey == "UTCOffset");
      if (index2 > -1) {
        this.project.Settings[index2].ConfigValue = e.source.value!;
        this.project.Settings[index].ModifiedBy = this._userIdLogin;
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  ontimeformatChangevalue(e: MatSelectChange) {
    try {
      this.setting.DateTimeFormat = e.source.triggerValue;
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "CultureInfo");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = e.source.value;
        this.project.Settings[index].ModifiedBy = this._userIdLogin;
      }
      var index2 = this.project.Settings.findIndex(x => x.ConfigKey == "DateTimeFormat");
      if (index2 > -1) {
        this.project.Settings[index2].ConfigValue = e.source.triggerValue;
        this.project.Settings[index].ModifiedBy = this._userIdLogin;
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  setTheme(theme: string) {
    try {
      this.isSelected = theme;
      this.setting.Theme = theme;
      var index = this.project.Settings.findIndex(x => x.ConfigKey == "Theme");
      if (index > -1) {
        this.project.Settings[index].ConfigValue = theme;
        this.project.Settings[index].ModifiedBy = this._userIdLogin;
      }
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  removeLogo() {
    this.lastFileName = this.project.LogoPath;
    this.fileNameAttr = '';
    this.project.LogoPath = '';
    this.uploadimagedata.FileName = '';
    this.uploadimagedata.FileContent = '';
    this.imageURL = '';
    this.imageSrc = '';
  }

  projectConfigaddedHandler(projectFieldConfig: ProjectFieldsConfiguration[] = []) {
    this.project.ProjectFieldsConfigurations = projectFieldConfig;

  }
}
