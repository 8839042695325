import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { EmailSubscription } from 'src/app/app_data/emailSubscription';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { SystemLogService } from 'src/app/services/systemLog.service';
import { UserService } from 'src/app/services/user.service';
import * as myGlobals from '../../app_data/globals';
import { DialogService } from '../../services/dialog.service';
import { SnackbarComponent } from '../../shared/components/snackbar/snackbar.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SubscriptionsComponent implements OnInit {
  subscriptions!: EmailSubscription[];
  errorMessage: string = "";
  saveEnable = false;
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  constructor(private userService: UserService,
    private logger: NGXLogger,
    private systemLogService: SystemLogService,
    private SpinnerService: NgxSpinnerService,
    private subscriptionService : SubscriptionService,
    private router: Router, private dialog: DialogService,
    private snackBar: MatSnackBar) {
   }

  ngOnInit(): void {
    this.saveEnable = false;
    
  }

  subscriptionaddedHandler(emailSubscription: EmailSubscription[] = []) {   
    this.subscriptions = emailSubscription;      
    if(this.subscriptions != null && this.subscriptions.length > 0){
      this.saveEnable = true;
    }else{
      this.saveEnable = false;
    }
  }

  save() {
    try {
      this.SpinnerService.show();
        this.subscriptions.forEach((subscriptionRow) => {             
          if (subscriptionRow.Id == 0) {
            subscriptionRow.Discipline = null;
            subscriptionRow.InterfaceGroup = null;
            subscriptionRow.Contact = null;
            subscriptionRow.Contractor = null;
            subscriptionRow.Module = null!;
            subscriptionRow.Stage = null!;
            subscriptionRow.CreatedBy = this._userRoleMappingId;
            
            this.subscriptionService.post(subscriptionRow).subscribe({
              next: users => {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Subscription", "Email Subscription", subscriptionRow.ContractorId + " - " + subscriptionRow.InterfaceGroupId + " - " + subscriptionRow.ModuleId + " - " + subscriptionRow.StageId+ " - " + subscriptionRow.DisciplineId, this._contractorID, this._userRoleId);
                this.SpinnerService.hide();
               
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "Subscription created successfully!",
                  duration: myGlobals.snackBarDuration,
                  verticalPosition: myGlobals.snackBarVerticalPosition,
                  horizontalPosition: myGlobals.snackBarHorizontalPosition
                });

                window.location.reload();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            });
          }else{
            this.subscriptionService.put(subscriptionRow).subscribe({
              next: users => {
                this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Subscription", "Email Subscription", subscriptionRow.InterfaceGroupId + " - " + subscriptionRow.DisciplineId, this._contractorID, this._userRoleId);
                this.SpinnerService.hide();

                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "Subscription saved successfully!",
                  duration: myGlobals.snackBarDuration,
                  verticalPosition: myGlobals.snackBarVerticalPosition,
                  horizontalPosition: myGlobals.snackBarHorizontalPosition
                });

                window.location.reload();
              },
              error: err => {
                this.SpinnerService.hide();
                this.errorMessage = err
                this.dialog.confirmDialog({
                  title: 'Error',
                  message: myGlobals.exceptionmessage,
                  cancelCaption: 'Close',
                });
                this.logger.error(err);
              }
            });
          }         
        });  
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToPage() {
    this.router.navigateByUrl('/Subscriptions');
  }

}
