import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProjectSetting } from '../app_data/projectSetting';
import { response } from '../app_data/OdataResponse';
import { TimeZones } from '../app_data/timezones';
import { DateTimeFormat } from '../app_data/datetimeformat';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}

@Injectable({
  providedIn: 'root'
})
export class ProjectSettingService {

  private apiUrl = environment.apiURl + 'projectsettings';
  private timezoneapiUrl = environment.apiURl + 'timezones';
  private datetimeapiUrl = environment.apiURl + 'DateTimeFormats';

  systemLog !: ProjectSetting;

  constructor(private http: HttpClient) {

  }

  getTimezones(): Observable<TimeZones[]> {
    return this.http.get<response>(this.timezoneapiUrl)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getDateformats(): Observable<DateTimeFormat[]> {
    return this.http.get<response>(this.datetimeapiUrl)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  post(model: ProjectSetting): Observable<ProjectSetting> {
    return this.http.post<ProjectSetting>(this.apiUrl, model, httpOptions);
  }


  getProjectSettings(projectId: number): Observable<ProjectSetting[]> {
    return this.http.get<response>(this.apiUrl + "?$filter=ProjectId eq " + projectId + "&$expand=project")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }


  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }

}
