<form [formGroup]="summaryForm" [appMatchDate]="['FromDate','ToDate']" >
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12" *ngIf="this.isContractorDropdownShow">
      <mat-form-field appearance="standard">
        <mat-label>Contractor</mat-label>
        <mat-select [(value)]="selectedContractor" formControlName="ContractorID" multiple (selectionChange)="onChangevalue($event)" [(ngModel)]="reportFilter.ContractorID" #ContractorIDInput>
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelectedContractor"
                          [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelectionContractor()">Select All</mat-checkbox>
          </div>
          <mat-option *ngFor="let ic of contractors" (click)="contractoroptionClick()" [value]="ic.Id">{{ ic.Name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Interface Group</mat-label>
        <mat-select [(value)]="selectedInterfaceGroup" formControlName="InterfaceGroupID" multiple [(ngModel)]="reportFilter.InterfaceGroupID" #InterfaceGroupIDInput>
          <div class="select-all">
            <mat-checkbox [(ngModel)]="allSelected"
                          [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection()">Select All</mat-checkbox>
          </div>
          <mat-option *ngFor="let ig of interfaceGroups" (click)="optionClick()" [value]="ig.Id">{{ ig.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.summaryForm.get('InterfaceGroupID')?.hasError('required')">
          InterfaceGroup is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="picker" [max]="maxDate" (dateChange)="onDateChange('change', $event)" formControlName="FromDate" [(ngModel)]="reportFilter.FromDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker disabled="false"></mat-datepicker>
        <mat-error *ngIf="this.summaryForm.get('FromDate')?.hasError('dateMismatch')">
          From Date should not be greater than To Date.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="topicker" [max]="maxDate" (dateChange)="onDateChange('change', $event)" formControlName="ToDate" [(ngModel)]="reportFilter.ToDate" disabled>
        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
        <mat-datepicker #topicker disabled="false"></mat-datepicker>
        <mat-error *ngIf="this.summaryForm.get('ToDate')?.hasError('dateMismatch')">
          From Date should not be greater than To Date.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12">
      <mat-form-field appearance="standard">
        <mat-label>Module Name</mat-label>
        <mat-select [(value)]="selectedModule" formControlName="ModuleName" (selectionChange)="onModuleChangeGeneratePDF(reportFilter)" [disabled]="true" [(ngModel)]="reportFilter.ModuleName" #ModuleNameInput>
          <mat-option *ngFor="let module of moduleProjectMappings" [value]="module.Module.Name">{{ module.Module.Name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="this.summaryForm.get('ModuleName')?.hasError('required')">
          Module Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-xl-2 col-l-2 col-md-6 col-sm-12 col-xs-12 mt-2">
      <button mat-stroked-button (click)="ShowPDf()" [disabled]="this.summaryForm.invalid" type="submit" class="WIRbutton WIRbutton-primary btn-sm">
        <mat-icon>summarize</mat-icon>Generate Report
      </button>
      <app-pdf-generate id="summaryReport" [reportName]="reportName" [projectName]="projectName" #PdfGenerateComponent></app-pdf-generate>
    </div>
  </div>
  <div class="row" style="visibility:hidden">
    <div id="sumrpt" class="stdrpt">
      <div class="svgimg">
        <apx-chart [series]="chartOptions.series"
                   [chart]="chartOptions.chart"
                   [colors]="chartOptions.colors"
                   [labels]="chartOptions.labels"
                   [xaxis]="chartOptions.xaxis"
                   [plotOptions]="chartOptions.plotOptions"
                   [dataLabels]="chartOptions.dataLabels"
                   [legend]="chartOptions.legend"
                   [responsive]="chartOptions.responsive"></apx-chart>
      </div>
    </div>
  </div>
</form>
