import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Menu } from '../app_data/menu';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, throwError } from 'rxjs';
import { response } from '../app_data/OdataResponse';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "*/*",
  }
  )
}


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private apiUrl = environment.apiURl + 'menus';
  errorMessage = '';
  Menu = [{ "Id": 1, "Text": "Dashboard", "router": "/dashboard", "Icon": "dashboard" }, { "Id": 2, "Text": "Interface", "router": "/Interfaces", "Icon": "anchor" }, { "Id": 4, "Text": "Project", "router": "/ProjectList", "Icon": "aspect_ratio" }, { "Id": 5, "Text": "Contractors", "router": "/Contractors", "Icon": "event" }];
  constructor(private http: HttpClient) { }

  getMenus(): Observable<Menu[]> {
    return this.http.get<response>(this.apiUrl)
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  getMenusByRoleId(roleId:number): Observable<Menu[]> {
    return this.http.get<response>(this.apiUrl + "?$expand=Permission,Module($expand=ModuleProjectMappings),MenuRoleMappings($filter=RoleId eq " + roleId +" and Isdeleted eq false)&$orderby=MenuOrder")
      .pipe(
        map((data: response) => data.value),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    return throwError(errorMessage);
  }
}
