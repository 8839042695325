import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdditionalInformationComponent } from './additional-information.component';
import { MaterialModule } from '../../material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [AdditionalInformationComponent],
  imports: [
    CommonModule, MaterialModule,
    FormsModule, ReactiveFormsModule
  ],
  exports: [AdditionalInformationComponent]
})
export class AdditionalInformationModule { }
