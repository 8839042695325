<div class="container-fluid">
  <div class="row ">
    <div class="d-flex mt-2 mb-2">
      <div class="col-md-4 align-self-center padding-0">
      </div>
      <div class="col-md-2 align-self-center text-align-end font-color-lightgrey">
      </div>
      <div class="col-md-3 align-self-center">
        <button mat-stroked-button class="WIRbutton WIRbutton-export float-end" (click)="exportExcel()">
          <mat-icon>download</mat-icon>
          Export to Excel
        </button>
      </div>
      <div class="col-md-3 padding-0">
        <div class="display-flex">
          <mat-form-field appearance="standard" class="width-100">
            <mat-select (selectionChange)='onSortChange($event.value)' [(value)]="sortColumn">
              <mat-option *ngFor="let sortColumn of sortColumnList" [value]="sortColumn.Value"><span class="{{sortColumn.DisplayName == '' ? 'spn_sort':'spn_sort_hide'}}">Sort by : </span>{{ sortColumn.DisplayName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div>
      <mat-paginator #paginatorTop
                     [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     showFirstLastButtons
                     (page)="pageEvent=loadActionListRegister($event)"
                     [pageIndex]="pageIndex"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
  <div class="row mb-1"></div>
  <div class="row">
    <div>
      <section class="grid-container border-radius-4 mat-elevation-z8" tabindex="0">
        <table mat-table [dataSource]="actionListDataSource" class="grid-width" matSort>
          <!-- Title Column -->
          <ng-container matColumnDef="MOM?.Title">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Minutes of Meeting Title </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.MOM?.Title}} </td>
          </ng-container>
          <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Action Title </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Title}} </td>
          </ng-container>
          <ng-container matColumnDef="Description">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Description </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Description}} </td>
          </ng-container>
          <ng-container matColumnDef="StatusName">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Status </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.StatusName}} </td>
          </ng-container>
          <ng-container matColumnDef="GroupName">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Group Name </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.InterfaceGroup?.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="ResContractor">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Responsible Contractor </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.ResContractor?.Name}} </td>
          </ng-container>
          <ng-container matColumnDef="ActionDate">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Action Date </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.ActionDate | date:dateTimeFormat : utcoffsetvalue}} </td>
          </ng-container>
          <ng-container matColumnDef="Responsible">
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border" mat-sort-header> Responsible </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border"> {{element.Responsible}} </td>
          </ng-container>

          <ng-container matColumnDef="Action" class="display-flex" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="table_header_size mat-header-cell-register column-border"> Action </th>
            <td mat-cell *matCellDef="let element" class="mat-cell-register column-border">
              <div class="display-flex">
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'MinutesofMeetingView'" (click)="viewRecord(element.MOM?.Id,element.Id)">
                    <mat-icon class="icon-size-20" matTooltip="View">visibility</mat-icon>
                  </button>
                </span>
                <span class="padding-left-right-2">
                  <button class="button-icon-circle align-items-center action-button" [appSetRouteActive]="'MinutesofMeetingEdit'" (click)="editRecord(element.MOM?.Id,element.Id)">
                    <mat-icon class="icon-size-20" matTooltip="Edit">edit</mat-icon>
                  </button>
                </span>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="mat-row-register"></tr>
        </table>
      </section>
    </div>
  </div>
  <div class="row">
    <div>
      <mat-paginator #paginatorBottom
                     [length]="length"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     showFirstLastButtons
                     (page)="pageEvent=loadActionListRegister($event)"
                     [pageIndex]="pageIndex"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
