<div class="container-fluid">
  <mat-card class="interfaceTab">
    <mat-card-content>
      <form [formGroup]="form" class="interfaceTab">
        <mat-tab-group animationDuration="0ms" id="tab-group">
          <mat-tab label="Project Registration">
            <div class="h500tabMin">
              <div class="row" *ngIf="this.isVisible">
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>First name</mat-label>
                    <input matInput inputmode="tel" placeholder="First name" maxlength="50" [(ngModel)]="user.FirstName" formControlName="FirstName" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('FirstName'))?.errors?.required">
                      First Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-xl-4 col-l-4 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Last name</mat-label>
                    <input matInput inputmode="tel" placeholder="Last name" maxlength="50" formControlName="LastName" [(ngModel)]="user.LastName" appTrimWhiteSpace>
                    <mat-error *ngIf="(form.get('LastName'))?.errors?.required">
                      Last Name is <strong>required</strong>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <app-user-invitation #invite (verificationCode)="verificationCodeHandler($event)"></app-user-invitation>
            </div>
          </mat-tab>
        </mat-tab-group>
      </form>
        <div class="row mt-2 mb-2">
          <div align="right">
            <button mat-stroked-button class="WIRbutton WIRbutton-primary" (click)="verifyInvitationCode()">
              <mat-icon class="WIRbutton-icons">save</mat-icon>Enroll
            </button>
          </div>
        </div>
    </mat-card-content>
  </mat-card>
</div>
