import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/services/dialog.service';
import * as myGlobals from '../../../app_data/globals';
import { NGXLogger } from 'ngx-logger';
import { Interface } from 'src/app/app_data/interface';
import { NgxSpinnerService } from 'ngx-spinner';
import { InterfaceService } from 'src/app/services/interface.service';
import { CommonFunction } from 'src/app/app_data/commonFunction';
import { SystemLogService } from 'src/app/services/systemLog.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { Router } from '@angular/router';
import { WorkflowmappingService } from 'src/app/services/workflowmapping.service';
import { WorkflowMapping } from 'src/app/app_data/workflowMappings';
import { CommonNotificationFunction } from '../../../app_data/commonNotificationFunction';
import { NotificationService } from '../../../services/notification.service';
import { ContractorService } from '../../../services/contractor.service';
import { StatusService } from '../../../services/status.service';
import { ProjectSettingService } from '../../../services/projectsetting.service';
import { TemplateBuilderService } from '../../../services/template-builder.service';
import { InterfaceGroupTemplateMapping } from 'src/app/app_data/interfaceGroupTemplateMapping';
import { EmailTemplateService } from 'src/app/services/email-template.service';
import { DatePipe } from '@angular/common';
import { RolePermissionService } from '../../../services/rolepermission.service';
import { ProjectService } from '../../../services/project.service';

@Component({
  selector: 'app-responseapprove-rejectdialog',
  templateUrl: './responseapprove-rejectdialog.component.html',
  styleUrls: ['./responseapprove-rejectdialog.component.css'],
  providers: [DatePipe]
})
export class ResponseApproveRejectdialogComponent implements OnInit {
  acceptRejectForm !: FormGroup;
  commonFunction !: CommonFunction;
  interfaceModel!: Interface;
  interfacePreviousStatus!: string;
  @Input() interfaceId!: number;
  approvalMessage: string = "";
  @Input() closeOutReminderDays!: number;
  nextStageFromWorkFlowMappings: WorkflowMapping[] = [];
  allStageFromWorkFlowMappings: WorkflowMapping[] = [];
  errorMessage = '';
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userRoleId = parseInt(sessionStorage.getItem("roleId")!);
  _userRoleMappingId = parseInt(sessionStorage.getItem("userRoleMappingId")!);

  acceptRejectComment: string = '';
  commonNotificationFunction!: CommonNotificationFunction;
  oldStatusValue = 0;
  expectedResponseDateLabel: string = myGlobals.ExpectedResponseDateLabel;
  closeOutDateLabel: string = myGlobals.CloseOutDateLabel;
  dateTimeFormat: string = "";
  isEnableCloseoutReminder: boolean = false;
  interfaceGroupTemplateMappings: InterfaceGroupTemplateMapping[] = [];
  workflowTemplateId: number = 0;

  constructor(private dialog: DialogService,
    private logger: NGXLogger, private notificationService: NotificationService, private datePipe: DatePipe,
    private SpinnerService: NgxSpinnerService,
    private interfaceService: InterfaceService,
    private contractorService: ContractorService,
    private rolePermissionService: RolePermissionService, private projectService: ProjectService,
    private systemLogService: SystemLogService, private statusService: StatusService,
    private workflowmappingService: WorkflowmappingService,
    private snackBar: MatSnackBar,
    private projectSettingService: ProjectSettingService,
    private templateBuilderService: TemplateBuilderService,
    private router: Router, private emailTemplateService: EmailTemplateService
  ) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
    this.getProjectSettings();
    this.commonNotificationFunction = new CommonNotificationFunction(notificationService, SpinnerService, datePipe, dialog, logger, router, emailTemplateService, templateBuilderService, workflowmappingService);
  }

  ngOnInit(): void {
    this.acceptRejectForm = new FormGroup({
      comments: new FormControl()
    });

    if (this.interfaceId > 0) {
      this.GetNextWorkflowStage();
    }
  }

  getProjectSettings() {
    try {
      this.SpinnerService.show();
      this.projectSettingService.getProjectSettings(this._projectID).subscribe({
        next: projectSettings => {
          if (projectSettings.length > 0) {
            this.dateTimeFormat = projectSettings.filter(x => x.ConfigKey == "DateTimeFormat")[0].ConfigValue;

            var index = projectSettings.findIndex(x => x.ConfigKey == "ExpectedResponseDateName");
            if (index > -1) {
              this.expectedResponseDateLabel = projectSettings.filter(x => x.ConfigKey == "ExpectedResponseDateName")[0].ConfigValue;
            }
            var index = projectSettings.findIndex(x => x.ConfigKey == "CloseOutDateName");
            if (index > -1) {
              this.closeOutDateLabel = projectSettings.filter(x => x.ConfigKey == "CloseOutDateName")[0].ConfigValue;
            }

            var index = projectSettings.findIndex(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays");
            if (index > -1) {
              var CloseoutReminderbool = projectSettings.filter(x => x.ConfigKey == "IsEnableCloseOutDateReminderByDays")[0].ConfigValue;
              if (CloseoutReminderbool == "true") {
                this.isEnableCloseoutReminder = true;
              }
            }
          }
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      });

    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  GetNextWorkflowStage() {
    try {
      this.interfaceService.getInterfaceById(this.interfaceId).subscribe({
        next: interfaceData => {
          //getNext stage and check if approval is required
          this.interfaceModel = interfaceData[0];
          var module = this.interfaceModel.InterfaceType.toLowerCase() == "tq" ? myGlobals.TQModule : myGlobals.InterfaceModule;
          this.interfaceModel.FirstIssuePlannedstr = this.interfaceModel.FirstIssuePlanned?.toString()!;
          this.interfaceModel.ApprovedIssuePlannedstr = this.interfaceModel.ApprovedIssuePlanned?.toString()!;
          this.oldStatusValue = this.interfaceModel.Status;

          this.interfaceGroupTemplateMappings = interfaceData[0].InterfaceGroup?.InterfaceGroupTemplateMappings!;
          var workflowTemplate = this.interfaceGroupTemplateMappings.filter(x => x.ModuleId == module);
          this.workflowTemplateId = workflowTemplate[0].WorkflowTemplateId;
          this.workflowmappingService.GetNextStageWorkFlowMapping(workflowTemplate[0].WorkflowTemplateId, module)
            .toPromise()
            .then(nextStageData => {
              if (nextStageData!.length > 0) {
                this.allStageFromWorkFlowMappings = nextStageData!;
                var currentStageData = this.allStageFromWorkFlowMappings.filter(x => x.StageId == interfaceData[0].StageId);
                let stagewithallroles: any[] = [];
                if (currentStageData.length > 1) {

                  var filterdata;
                  currentStageData.forEach(y => {
                    filterdata = this.allStageFromWorkFlowMappings.filter(x => x.StageId == y.NextStageId);
                    stagewithallroles.push(filterdata[0]);
                  });
                } else {
                  //increment workflow mapping sequence number to get next stage id
                  stagewithallroles = this.allStageFromWorkFlowMappings.filter(x => x.StageId == currentStageData[0].NextStageId);
                }
                this.nextStageFromWorkFlowMappings = stagewithallroles;
                this.approvalMessage = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovalMessage!;
              }
            });
          this.SpinnerService.hide();
        }
      })
    }
    catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  Accept() {
    try {

      this.SpinnerService.show();
      
      this.interfacePreviousStatus = this.interfaceModel.InterfaceStatus.Name;
      if (this.nextStageFromWorkFlowMappings.length > 0) {
        this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].StageId;
        this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.ApprovedStatus!;

        this.interfaceModel.ModifiedBy = this._userRoleMappingId;

        this.interfaceModel.IsStageRejected = false;
        this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
        this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();

      }
      var updatePreviousResponse = this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Response:")).sort().reverse();
      if (updatePreviousResponse.length > 0) {
        updatePreviousResponse[0].IsVisibleToRequester = true;
      }

      this.interfaceModel.InterfaceResponses?.push({ Information: "", Comments: "Response approved comment: " + this.acceptRejectComment, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: false, IsVisibleToProvider: true, ContractorId: this._contractorID });

      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {

          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
          //Save email notification.
          this.commonNotificationFunction.processEmailNotifications(this.interfaceModel.ModuleId!, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.interfacePreviousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);

          if (this.interfaceModel.InterfaceType.toLowerCase() == "tq") {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Response approved", "Response approved for Technical Query " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID,this._userRoleId ,this.interfaceModel.Id);
          } else {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Response approved", "Response approved for Interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          }

          this.commonFunction.redirectToRegisterpage(this.nextStageFromWorkFlowMappings[0].ModuleId!);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Response approved successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  Reject() {
    try {
      this.SpinnerService.show();
      
      this.interfacePreviousStatus = this.interfaceModel.InterfaceStatus.Name;
      this.interfaceModel.InterfaceResponses?.push({ Information: this.interfaceModel.Response, Comments: "Response rejected comment: " + this.acceptRejectComment, CreatedBy: this._userRoleMappingId, IsDeleted: false, IsVisibleToRequester: false, IsVisibleToProvider: true, ContractorId: this._contractorID });

      if (this.nextStageFromWorkFlowMappings.length > 0) {
        this.interfaceModel.StageId = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStage!;
        this.interfaceModel.Status = this.nextStageFromWorkFlowMappings[0].Stage?.RejectedStatus!;

        this.interfaceModel.Response = "";
        this.interfaceModel.ReferenceForResponse = "";
        this.interfaceModel.ModifiedBy = this._userRoleMappingId;

        this.interfaceModel.IsStageRejected = true;
        this.interfaceModel.StageApproveRejectBy = this._userIdLogin;
        this.interfaceModel.StageApproveRejectDate = CommonFunction.getNowUTC();
      }

      var updatePreviousResponse = this.interfaceModel.InterfaceResponses.filter(x => x.Information.startsWith("Response:")).sort().reverse();
      if (updatePreviousResponse.length > 0) {
        updatePreviousResponse[0].IsVisibleToRequester = false;
      }
      

      this.interfaceService.put(this.interfaceModel).subscribe({
        next: data => {

          //Update interface model data as expanded entity data is not available in the current interface model.
          this.interfaceModel.InterfaceStatusName = this.commonFunction.getStatusText(this.interfaceModel.Status);
          //Save email notification.
          this.commonNotificationFunction.processEmailNotifications(this.interfaceModel.ModuleId!, this.isEnableCloseoutReminder, false, this.closeOutReminderDays, this.interfaceModel, this.interfacePreviousStatus, this.workflowTemplateId, this.nextStageFromWorkFlowMappings[0].StageId);

          if (this.interfaceModel.InterfaceType.toLowerCase() == "tq") {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Technical Query register", "Response rejected", "Response rejected for Technical Query " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          } else {
            this.systemLogService.writeLog(this._projectID, this._userIdLogin, "Interface register", "Response rejected", "Response rejected for Interface " + this.interfaceModel.Number + ". Status changed from '" + this.commonFunction.getStatusText(this.oldStatusValue) + "' to '" + this.commonFunction.getStatusText(this.interfaceModel.Status) + "'.", this._contractorID, this._userRoleId, this.interfaceModel.Id);
          }
          this.commonFunction.redirectToRegisterpage(this.nextStageFromWorkFlowMappings[0].ModuleId!);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "Response rejected successfully!",
            duration: myGlobals.snackBarDuration,
            verticalPosition: myGlobals.snackBarVerticalPosition,
            horizontalPosition: myGlobals.snackBarHorizontalPosition
          });
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }
}
