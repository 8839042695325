import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetRouteActiveDirective } from './set-route-active.directive';



@NgModule({
  declarations: [SetRouteActiveDirective],
  imports: [
    CommonModule
  ],
  exports: [SetRouteActiveDirective]
})
export class ActiveRouteModule { }
