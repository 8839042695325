<form [formGroup]="acceptRejectForm">
  <div class="approvedialog">
    <div class="row">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
        <label class="approvalnote">{{this.approvalMessage}}</label><br />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
        <mat-form-field appearance="standard">
          <mat-label>Comments</mat-label>
          <textarea matInput class="heightTextArea" maxlength="1500" autocomplete="off" [(ngModel)]="this.acceptRejectComments" formControlName="comments" name="Comments"
            required></textarea>
          <mat-error *ngIf="this.acceptRejectForm.get('comments')?.hasError('required')">
            Comments is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12" align="right">
        <button mat-stroked-button [disabled]="acceptRejectForm.invalid" (click)="Accept()"
        type="button" class="WIRbutton WIRbutton-primary">
          <mat-icon class="WIRbutton-icons">check_circle</mat-icon>Accept
        </button>
        <button mat-stroked-button [disabled]="acceptRejectForm.invalid" (click)="Reject()"
        type="button" class="WIRbutton WIRbutton-secondary">
          <mat-icon class="WIRbutton-icons">highlight_off</mat-icon>Reject
        </button>
      </div>
    </div>
  </div>
</form>