import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactCreateComponent } from './contact-create.component';
import { ContactListComponent } from './contact-list.component';
import { ContactEditComponent } from './contact-edit.component';
import { ContactRoutingModule } from './contact-routing.module';
import { MaterialModule } from '../../shared/material/material.module';
import { ActiveRouteModule } from '../../shared/directive/active-route/active-route.module';
import { AuthorisationModule } from '../../shared/directive/authorisation/authorisation.module';
import { CommonDirectivesModule } from '../../shared/directive/common/commondirective.module';

@NgModule({
  declarations: [
    ContactCreateComponent,
    ContactListComponent,
    ContactEditComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AuthorisationModule,
    ContactRoutingModule,ActiveRouteModule,CommonDirectivesModule
  ],
  exports: [ContactCreateComponent,
    ContactListComponent,
    ContactEditComponent]
})
export class ContactModule { }
