<div class="container-fluid">
  <mat-card class="">
    <mat-card-content>
      <p class="pPageHeading">{{pageTitle}}</p>
      <form [formGroup]="form" [appMatchDate]="['ifiDateFormControl','iaiDateFormControl']" class="interfaceTab">
        <mat-tab-group animationDuration="0ms">

          <mat-tab label="Interface">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Number</mat-label>
                        <input matInput autocomplete="off" #input maxlength="256" name="Number" [(ngModel)]="interfaceModel.Number" readonly
                               formControlName="numberFormControl" appTrimWhitespace>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-8 col-l-8 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Title</mat-label>
                        <input matInput autocomplete="off" #input maxlength="256" placeholder="Title" name="Title"
                               [(ngModel)]="interfaceModel.Title" formControlName="titleFormControl" required appTrimWhitespace>
                        <mat-error *ngIf="this.form.get('titleFormControl')?.hasError('required')">
                          Title is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="end">{{interfaceModel.Title?.length || 0}}/256</mat-hint>
                      </mat-form-field>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Interface Group</mat-label>
                        <mat-select [(value)]="selectedInterfaceGroup" [(ngModel)]="interfaceModel.InterfaceGroupID"
                                    (selectionChange)="onInterfaceGroupSelected(selectedInterfaceGroup)"
                                    formControlName="interfaceGroupFormControl" [disabled]="isDisabledInterfaceGroup" required>
                          <mat-option *ngFor="let ig of interfaceGroups" [value]="ig.Id">{{ ig.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('interfaceGroupFormControl')?.hasError('required')">
                          Interface Group is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>Priority</mat-label>
                        <mat-select [(ngModel)]="interfaceModel.Priority" name="Priority" [(value)]="selectedPriority"
                                    formControlName="priorityFormControl" required>
                          <mat-option *ngFor="let pr of prioritys" [value]="pr.Id">{{ pr.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('priorityFormControl')?.hasError('required')">
                          Priority is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12" *ngIf="this._interfaceStatus == 'identified'">
                      <mat-form-field appearance="standard">
                        <mat-label>Status</mat-label>
                        <mat-select [(value)]="selectedStatus" name="Status" [(ngModel)]="interfaceModel.Status"
                                    formControlName="statusFormControl" required>
                          <mat-option *ngFor="let st of status" [value]="st.Id">{{ st.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('statusFormControl')?.hasError('required')">
                          Status is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12" *ngIf="this._interfaceStatus != 'identified'">
                      <label class="statusHeaderLabel">Status</label><br />
                      <span class="status status-{{statusshortname | lowercase}} mb-0"></span><label class="toolbarFontSize">{{statusText}}</label>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{requestingContractorLabel}}</mat-label>
                        <mat-select [(value)]="selectedRequestingContractor"
                                    (selectionChange)="onRequestingContractorSelected(selectedRequestingContractor)"
                                    name="ReceivingContractorID" [(ngModel)]="interfaceModel.ReceivingContractorID"
                                    formControlName="requestingContractorFormControl" [disabled]="true" required>
                          <mat-option *ngFor="let reqCon of requestingContractors" [value]="reqCon.Id">
                            {{ reqCon.Name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('requestingContractorFormControl')?.hasError('required')">
                          {{requestingContractorLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-l-8 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{providerContractorLabel}}</mat-label>
                        <mat-select [(value)]="selectedProviderContractor"
                                    (selectionChange)="onProviderContractorSelected(selectedProviderContractor)"
                                    name="ResponsibleContractorID" [(ngModel)]="interfaceModel.ResponsibleContractorID"
                                    formControlName="providerContractorFormControl" [disabled]="true" required>
                          <mat-option *ngFor="let prCon of providerContractors" [value]="prCon.Id">{{ prCon.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('providerContractorFormControl')?.hasError('required')">
                          {{providerContractorLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label>{{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}}</mat-label>
                        <mat-select [(value)]="selectedRequestingDisciplines" name="ReceivingContractorDisciplineID"
                                    [(ngModel)]="interfaceModel.ReceivingContractorDisciplineID"
                                    formControlName="requestingDisciplineFormControl" required>
                          <mat-option *ngFor="let disc of requestingDisciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('requestingDisciplineFormControl')?.hasError('required')">
                          {{this.isDualDisciplinebool ? requestingDisciplineLabel : "Discipline"}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-xl-4 col-l-4 col-md-8 col-sm-12 col-xs-12" *ngIf="this.isDualDisciplinebool">
                      <mat-form-field appearance="standard">
                        <mat-label>{{providerDisciplineLabel}}</mat-label>
                        <mat-select [(value)]="selectedProviderDisciplines" name="ResponsibleContractorDisciplineID"
                                    [(ngModel)]="interfaceModel.ResponsibleContractorDisciplineID"
                                    formControlName="providerDisciplineFormControl" required>
                          <mat-option *ngFor="let disc of disciplines" [value]="disc.Id">{{ disc.Name }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="this.form.get('providerDisciplineFormControl')?.hasError('required')">
                          {{providerDisciplineLabel}} is <strong>required</strong>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                      <mat-form-field appearance="standard" class="datePicker width-100">
                        <mat-label>{{expectedResponseDateLabel}}</mat-label>
                        <input matInput [matDatepicker]="picker" [min]="minDate" formControlName="ifiDateFormControl" [(ngModel)]="interfaceModel.FirstIssuePlanned"
                               (dateChange)="onFirstResDateChange('change', $event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker disabled="{{this._isProvider || this.isPlannedDateDisabled}}"></mat-datepicker>

                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('required')">
                          {{expectedResponseDateLabel}} is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('dateMismatch')">
                          {{expectedResponseDateLabel}} should not be greater than {{closeOutDateLabel}}.
                        </mat-error>
                        <mat-error *ngIf="this.form.get('ifiDateFormControl')?.hasError('minDateNotAllowed')">
                          Please select date greater than or equal to today
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                      <mat-form-field appearance="standard" class="datePicker width-100">
                        <mat-label>{{closeOutDateLabel}}</mat-label>
                        <input matInput [matDatepicker]="topicker" [min]="minDate" [(ngModel)]="interfaceModel.ApprovedIssuePlanned" formControlName="iaiDateFormControl"
                               (dateChange)="onApprovedResDateChange('change', $event)" readonly>
                        <mat-datepicker-toggle matSuffix [for]="topicker"></mat-datepicker-toggle>
                        <mat-datepicker #topicker disabled="{{this._isProvider || this.isPlannedDateDisabled}}"></mat-datepicker>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('required')">
                          {{closeOutDateLabel}} is <strong>required</strong>
                        </mat-error>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('dateMismatch')">
                          {{closeOutDateLabel}} should be greater than {{expectedResponseDateLabel}}.
                        </mat-error>
                        <mat-error *ngIf="this.form.get('iaiDateFormControl')?.hasError('minDateNotAllowed')">
                          Please select date greater than or equal to today
                        </mat-error>
                      </mat-form-field>

                    </div>
                  </div>

                </div>
                <div class="col-4">
                  <div [hidden]="this.isRequestApprovalDialogHidden" class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                    <app-approve-rejectdialog [data]="this.interfaceModel" [hasPermission]="'wf.awaitingforrequestapproval'" [interfaceId]="this.interfaceId" [closeOutReminderDays]="this.closeOutReminderDays"></app-approve-rejectdialog>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Request Description</mat-label>
                    <textarea matInput formControlName="descriptionFormControl" maxlength="3000" name="Description"
                              [(ngModel)]="interfaceModel.Description" class="heightTextArea" required appTrimWhitespace></textarea>
                    <mat-error *ngIf="this.form.get('descriptionFormControl')?.hasError('required')">
                      Description is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{interfaceModel.Description?.length || 0}}/3000</mat-hint>
                  </mat-form-field>
                </div>

                <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                  <mat-form-field appearance="standard">
                    <mat-label>Additional Comments</mat-label>
                    <textarea matInput class="heightTextArea" maxlength="1500" formControlName="comments" name="Comments"
                              [(ngModel)]="interfaceModel.Comments" appTrimWhitespace></textarea>
                    <mat-hint align="end">{{interfaceModel.Comments?.length || 0}}/1500</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Propose date" *ngIf="this.isProposeDateTabVisible || (isResponseGiven && !this._isProvider && this.isProposeDateTabVisible)">
            <div class="h500tabMin scroller">
              <div class="row">
                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                  <mat-form-field appearance="standard" class="datePicker width-100">
                    <mat-label>{{expectedResponseDateLabel}}</mat-label>
                    <div class="input-group hide-blue-border">
                      <input matInput autocomplete="off" autocomplete="off" name="ifiDate2" formControlName="ifiDate2FormControl" [(ngModel)]="interfaceModel.FirstIssuePlanned"
                             type="text" bsDatepicker [bsConfig]="datePickerConfig" class="form-control" />
                    </div>
                  </mat-form-field>
                </div>

                <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                  <mat-form-field appearance="standard" class="datePicker width-100">
                    <mat-label>{{closeOutDateLabel}}</mat-label>
                    <div class="input-group hide-blue-border">
                      <input matInput autocomplete="off" name="iaiDate2" formControlName="iaiDate2FormControl" [(ngModel)]="interfaceModel.ApprovedIssuePlanned"
                             type="text" bsDatepicker [bsConfig]="datePickerConfig" class="form-control" />
                    </div>
                  </mat-form-field>

                </div>
              </div>
              <form [formGroup]="proposeDateForm" [appMatchDate]="['ifiDateProposeFormControl','iaiDateProposeFormControl']">
                <div class="row">
                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                    <mat-form-field appearance="standard" class="width-100">
                      <mat-label>Proposed {{expectedResponseDateLabel}}</mat-label>
                      <input matInput [matDatepicker]="proposeResponsePicker" autocomplete="off" name="ifiDatePropose"
                             formControlName="ifiDateProposeFormControl" [(ngModel)]="interfaceModel.ProviderFirstIssuePlanned" readonly />
                      <mat-datepicker-toggle matSuffix [for]="proposeResponsePicker"></mat-datepicker-toggle>
                      <mat-datepicker #proposeResponsePicker disabled="false"></mat-datepicker>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('required')">
                        Proposed {{expectedResponseDateLabel}} is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('dateMismatch')">
                        Proposed {{expectedResponseDateLabel}} should not be greater than Proposed {{closeOutDateLabel}}.
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('ifiDateProposeFormControl')?.hasError('minDateNotAllowed')">
                        Please select date greater than or equal to today
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-xl-4 col-l-4 col-md-4 col-sm-12 col-xs-12 display-flex">
                    <mat-form-field appearance="standard" class="width-100">
                      <mat-label>Proposed {{closeOutDateLabel}}</mat-label>
                      <input matInput [matDatepicker]="proposeCloseoutPicker" autocomplete="off" name="iaiDatePropose" formControlName="iaiDateProposeFormControl"
                             [(ngModel)]="interfaceModel.ProviderApprovedIssuePlanned" readonly />
                      <mat-datepicker-toggle matSuffix [for]="proposeCloseoutPicker"></mat-datepicker-toggle>
                      <mat-datepicker #proposeCloseoutPicker disabled="false"></mat-datepicker>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('required')">
                        Proposed {{closeOutDateLabel}} is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('dateMismatch')">
                        Proposed {{closeOutDateLabel}} should be greater than Proposed {{expectedResponseDateLabel}}.
                      </mat-error>
                      <mat-error *ngIf="this.proposeDateForm.get('iaiDateProposeFormControl')?.hasError('minDateNotAllowed')">
                        Please select date greater than or equal to today
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
              </form>
            </div>
          </mat-tab>

          <mat-tab label="Documents ({{documentCount}})">
            <div class="h500tabMin">
              <app-document-edit [maxFileSize]="maxFileSize" [isControlDisable]="this.isControlDisable" (documentCountadded)="documentCountaddedHandler($event)" (documentsadded)="documentsaddedHandler($event)" (uploadDocsadded)="uploadDocsaddedHandler($event)"></app-document-edit>
            </div>
          </mat-tab>

          <mat-tab label="Links ({{referenceLinkCount}})">
            <div class="h500tabMin">
              <app-reference-link-edit [domainlist]="domainlist" [isControlDisable]="this.isControlDisable" (refLinksadded)="refLinksaddedHandler($event)" (refLinkCountadded)="refLinkCountaddedHandler($event)"></app-reference-link-edit>
            </div>
          </mat-tab>

          <mat-tab label="Tags" *ngIf="this.showOtherTab">
            <div class="h500tabMin scroller">
              <app-project-fields-configuration-update [isControlDisable]="this.isControlDisable" [isOtherTabControlDisable]="this.isOtherTabControlDisable" (projectFieldsConfigadded)="projectFieldsConfigaddedHandler($event)" [interfaceId]="this.interfaceId" [interfaceType]="this.interfaceType"></app-project-fields-configuration-update>
            </div>
          </mat-tab>

          <mat-tab label="Response" *ngIf="this.isResponseGiven || this._isProvider  || (this._isCompany && this.isResponseApprovalPending)">
            <div class="h500tabMin">
              <div class="row">
                <div class="col-8">
                  <div class="row">
                    <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 mt-2 pbZero txtMargingTop">
                      <mat-form-field appearance="standard">
                        <mat-label>Response</mat-label>
                        <textarea matInput formControlName="interfaceResponse" name="Response"
                                  [(ngModel)]="interfaceModel.Response" maxlength="3000" class="heightTextArea" required appTrimWhitespace></textarea>
                        <mat-error *ngIf="this.form.get('interfaceResponse')?.hasError('required')">
                          Response is <strong>required</strong>
                        </mat-error>
                        <mat-hint align="end">{{interfaceModel.Response?.length || 0}}/3000</mat-hint>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12 pbZero txtMargingTop">
                      <mat-form-field appearance="standard">
                        <mat-label>References</mat-label>
                        <textarea matInput formControlName="ReferenceForResponse" name="ReferenceForResponse"
                                  matTooltip="Max 1500 characters." [(ngModel)]="interfaceModel.ReferenceForResponse" maxlength="1500"
                                  class="heightTextArea" appTrimWhitespace></textarea>
                        <mat-hint align="end">{{interfaceModel.ReferenceForResponse?.length || 0}}/1500</mat-hint>
                      </mat-form-field>
                    </div>

                    <div class="col-xl-6 col-l-6 col-md-6 col-sm-12 col-xs-12">
                      <mat-form-field appearance="standard">
                        <mat-label> {{providerContractorLabel}} User</mat-label>
                        <input matInput #input value={{this.completedByUser}} readonly>
                      </mat-form-field>
                    </div>


                  </div>
                </div>
                <div class="col-4">
                  <div [hidden]="this.isResponseApprovalDialogHidden" class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                    <app-responseapprove-rejectdialog [hasPermission]="'wf.awaitingforresponseapproval'" [interfaceId]="this.interfaceId" [closeOutReminderDays]="this.closeOutReminderDays"></app-responseapprove-rejectdialog>
                  </div>
                </div>
              </div>

            </div>
          </mat-tab>

          <mat-tab label="Response History" *ngIf="this.isResponseGiven || this.isResponseHistoryVisible">
            <div class="h500tabMin scroller">
              <div class="container py-2 mt-4 mb-4">
                <!-- timeline item 1 -->
                <div class="row" *ngFor="let response of responseHistoryPaging let i = index;">
                  <!-- timeline item 1 left dot -->
                  <div class="col-auto text-center flex-column d-none d-sm-flex">
                    <div class="row h-40">
                      &nbsp;
                    </div>
                    <h5 class="m-2">
                      <div class="float-right text-muted fw-bolder">{{response.CreatedDate | date : dateTimeFormat: utcoffsetvalue }} {{response.CreatedDate | date :' hh:mm:ss' : utcoffsetvalue }}</div>
                    </h5>
                    <div class="row h-50">
                      &nbsp;
                    </div>
                  </div>

                  <div class="col-auto text-center flex-column d-none d-sm-flex">
                    <div class="row h-40">
                      <div class="col border-right">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                    <h5 class="m-2">
                      <span class="badge badge-pill bg-light border pillBorder">&nbsp;</span>
                    </h5>
                    <div class="row h-50">
                      <div class="col border-right">&nbsp;</div>
                      <div class="col">&nbsp;</div>
                    </div>
                  </div>
                  <!-- timeline item 1 event content -->
                  <div class="col py-2">
                    <div class="card right">
                      <div class="card-body">
                        <!-- <div class="float-right text-muted fw-bolder">{{response.CreatedDate | date:'dd/MM/yyyy hh:mm:ss'}}</div> -->
                        <h4 class="card-title">
                          <span *ngIf="response.Comments == ''">Response by :</span> <span *ngIf="response.Comments != ''">Comments by :</span>
                          {{response.CreatedUserRoleMapping != null ? response.CreatedUserRoleMapping?.User.FirstName +' '+ response.CreatedUserRoleMapping?.User.LastName +' - ('+ response.Contractor?.Name +')' : "" }}
                        </h4>
                        <div *ngIf="response.Comments == ''">
                          <p class="card-text card-text-content">{{response.Information}}</p>
                        </div>
                        <div *ngIf="response.Comments != ''">
                          <p class="card-text card-text-content">{{response.Comments}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--/row-->
              </div>
            </div>
          </mat-tab>


          <mat-tab label="Acceptance" *ngIf="this.isAcceptanceTabVisible">
            <div class="h500tabMin scroller">
              <form [formGroup]="acceptanceForm">
                <mat-accordion>
                  <mat-radio-group>
                    <div class="row">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelAccepted" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderAccepted(buttonR1)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonR1 class="acceptanceRadio" [value]="true"
                                                (change)="panelRadioButtonUnSelect(buttonR1)" [checked]="isAcceptedChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Received information accepted</div>
                                <div><span class="hintMessage">(When you select this option you accept received interface information. and On Accept, the interface item will be closed automatically)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>

                          <mat-form-field appearance="standard" *ngIf="isAcceptedChecked">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="acceptanceComments" [(ngModel)]="acceptanceComments" matInput
                                      name="acceptanceComments" maxlength="1500" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{acceptanceComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.acceptanceForm.get('acceptanceComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-accept mt-2"
                                  *ngIf="isAcceptedAndSignChecked || isAcceptedChecked" [disabled]="this.acceptanceForm.invalid"
                                  (click)='onAcceptAndSign()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Accept
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>

                    <div class="row" *ngIf="this.IsAcceptAsPreliminarybool">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelAcceptedAsPreliminary" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderAcceptedAsPreliminary(buttonR3)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonR3 class="acceptanceAsPreliminaryRadio" [value]="true"
                                                (change)="panelRadioButtonUnSelect(buttonR3)" [checked]="isAcceptedAsPreliminaryChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Received information accepted as preliminary</div>
                                <div><span class="hintMessage">(When you click on this button you are preliminary accepting received interface information. Action for providing final information is then transferred to Responsible contractor.)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>

                          <mat-form-field appearance="standard" *ngIf="isAcceptedAsPreliminaryChecked">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="acceptanceAsPreliminaryComments" [(ngModel)]="acceptanceAsPreliminaryComments" matInput
                                      name="acceptanceAsPreliminaryComments" maxlength="1500" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{acceptanceAsPreliminaryComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.acceptanceForm.get('acceptanceAsPreliminaryComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-acceptAsPreliminary mt-2"
                                  *ngIf="isAcceptedAsPreliminaryAndSignChecked || isAcceptedAsPreliminaryChecked" [disabled]="this.acceptanceForm.invalid"
                                  (click)='onAcceptAndSign()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Accept as Preliminary
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-xl-12 col-l-12 col-md-12 col-sm-12 col-xs-12">
                        <mat-expansion-panel [class]="matExpansionPanelRejected" hideToggle>
                          <mat-expansion-panel-header (click)="panelHeaderRejected(buttonR2)" class="matExpansionPanelHeader">
                            <mat-panel-title class="matPanelTitle">
                              <mat-radio-button #buttonR2 class="rejectedRadio" [value]="true" (change)="panelRadioButtonUnSelect(buttonR2)"
                                                [checked]="isRejectedChecked"></mat-radio-button>
                            </mat-panel-title>
                            <mat-panel-description class="mt-2 mb-2">
                              <div>
                                <div>Received information not accepted</div>
                                <div><span class="hintMessage">(When you select this option you are not accepting received interface information. and On Reject, Action for providing new information is then transferred to responsible contractor)</span> </div>
                              </div>
                            </mat-panel-description>
                          </mat-expansion-panel-header>
                          <mat-form-field appearance="standard" *ngIf="isRejectedChecked">
                            <mat-label>Comments</mat-label>
                            <textarea formControlName="rejectionComments" [(ngModel)]="rejectionComments" matInput
                                      name="rejectionComments" maxlength="3000" class="heightTextArea" required></textarea>
                            <mat-hint align="end">{{rejectionComments?.length || 0}}/1500</mat-hint>
                            <mat-error *ngIf="this.acceptanceForm.get('rejectionComments')?.hasError('required')">
                              Comments is <strong>required</strong>
                            </mat-error>
                          </mat-form-field>
                          <button mat-stroked-button class="WIRbutton WIRbutton-reject mt-2"
                                  *ngIf="isRejectedAndSignChecked || isRejectedChecked" [disabled]="this.acceptanceForm.invalid"
                                  (click)='onAcceptAndSign()'>
                            <mat-icon class="WIRbutton-icons">assignment_turned_in</mat-icon>Reject
                          </button>
                        </mat-expansion-panel>
                      </div>
                    </div>

                  </mat-radio-group>
                </mat-accordion>

              </form>
            </div>
          </mat-tab>

          <mat-tab label="Assignment">
            <div class="h500tabMin scroller">
              <app-assignment [interfaceId]="this.interfaceId"></app-assignment>
            </div>
          </mat-tab>

          <mat-tab label="Discussions">
            <div class="h500tabMin">
              <app-discussion (discussionadded)="discussionaddedHandler($event)" [interfaceModel]="this.interfaceModel" [interfacegroupId]="this.interfacegroupId" [moduleId]="this.moduleId" [interfaceId]="this.interfaceId"></app-discussion>
            </div>
          </mat-tab>

        </mat-tab-group>
       
        <div class="mt-2 mb-2" align="right">
          <button mat-stroked-button class="WIRbutton WIRbutton-primary"
                  [disabled]="(this.form.invalid || AcceptAndSignVisible || this.isControlDisable) && (this.form.invalid || this.isSaveDisabled)"
                  (click)='save()'>
            <mat-icon class="WIRbutton-icons">save</mat-icon>Save
          </button>
          <button mat-stroked-button type="button" class="WIRbutton WIRbutton-secondary" [appConfirmBackButton]="[this.oldInterfaceModel,this.interfaceModel]" >
            <mat-icon class="WIRbutton-icons">arrow_back</mat-icon>Back
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
