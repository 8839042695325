import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { INGXLoggerMetadata, NGXLoggerServerService } from 'ngx-logger';

const httpOptions = {
  headers: new HttpHeaders({
    //'X-PINGOTHER':  'pinpong',
    // 'Content-Type':  'application/json',
    // 'Access-Control-Allow-Origin':  '*',
    // 'Access-Control-Allow-Method':  'GET,POST,PUT'
  }
  )
}
@Injectable()
export class CustomizeErrorLogService extends NGXLoggerServerService {

  /**
   * Customse the data sent to the API
   * @param metadata the data provided by NGXLogger
   * @returns the data customised
   */
  public override customiseRequestBody(metadata: INGXLoggerMetadata): any {
    let body = { ...metadata };
    body.additional?.toString();
    // note, for the example we log the body but in a real case the log is useless
    //console.log('Customised body is', body);
    delete body.additional;
    return body;
  }
}
