import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Contractor } from '../../app_data/contractor';
import { ContractorService } from '../../services/contractor.service';
import { InterfaceService } from '../../services/interface.service';
import * as myGlobals from '../../app_data/globals';
import { InterfaceGroupService } from '../../services/interfaceGroup.service';
import { StatusChart } from '../../app_data/statusChart';
import { InterfaceGroup } from '../../app_data/interfaceGroup';
import { Interface } from '../../app_data/interface';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from '../../services/dialog.service';
import { NGXLogger } from 'ngx-logger';
import { MatSelectChange } from '@angular/material/select';
import { DatePipe, ViewportScroller } from '@angular/common';
import { CommunicationService } from '../../services/communication.service';
import { Router } from '@angular/router';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexLegend, ApexNonAxisChartSeries, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTitleSubtitle, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { EmailSubscriptionUserService } from '../../services/emailsubscriptionusers.service';
import { environment } from '../../../environments/environment';
import { ModuleProjectMappingsService } from '../../services/moduleprojectmappings.service';
import { ModuleProjectMapping } from '../../app_data/moduleProjectMapping';
import { CommonFunction } from '../../app_data/commonFunction';
import { ProjectService } from '../../services/project.service';
import { RolePermissionService } from '../../services/rolepermission.service';
import { SignalrService } from '../../services/signalr.service';

interface filtervalue {
  value: string;
  viewValue: string;
}
export type ChartOptions = {
  series: ApexNonAxisChartSeries | ApexAxisChartSeries;
  chart: ApexChart;
  plotOptions: ApexPlotOptions;
  labels: any;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  responsive: ApexResponsive;
  annotations: ApexAnnotations;
  colors: string[];
  xaxis: ApexXAxis;
  grid: ApexGrid;
  stroke: ApexStroke;
};

@Component({
  selector: 'app-dashboard-admin',
  templateUrl: './dashboard-admin.component.html',
  styleUrls: ['./dashboard-admin.component.css']
})

export class DashboardAdminComponent implements OnInit {
  contractors: Contractor[] = [];
  displayedColumns: string[] = [];
  contractorcnt: number = 0;
  interfacegrpcnt: number = 0;
  createdInterface: number = 0;
  closedInterface: number = 0;
  openInterface: number = 0;
  allOpenInterface: number = 0;
  completedInterface: number = 0;
  identifiedInterface: number = 0;
  overdueInterface: number = 0;
  responseOverdueInterface: number = 0;
  closeOverdueInterface: number = 0;
  subscribedUserscnt: number = 0;
  activeUserscnt: number = 0;
  closedStatusId : number = myGlobals.ClosedStatusId;
  chartData: StatusChart[] = [];
  interfacegrps: InterfaceGroup[] = [];
  interfaces: Interface[] = [];
  errorMessage!: string;
  todayDate = new Date();
  endDate = new Date();
  pipe = new DatePipe('en-US');
  daysfilters: filtervalue[] = [{ value: "1", viewValue: "Last 1 day" }, { value: "7", viewValue: "Last 7 days" }, { value: "30", viewValue: "Last 30 days" }, { value: "0", viewValue: "Till Date" }];
  selectedfilter = this.daysfilters[3].value;
  isBarShown: boolean = false;
  hideScrollToTop: boolean = false;
  hideResetScroll: boolean = false;
  piechartWidth: number = 380;
  isVisible: boolean = false;
  isChartVisible: boolean = false;
  iconUrl: string = environment.iconURl;
  commonFunction!: CommonFunction;

  moduleProjectMappings: ModuleProjectMapping[] = [];
  selectedModuleId: number = myGlobals.InterfaceModule;
  cardHeaderLabel: string = "Interface";
  isLiveNotify: boolean = false;

  _projectID = parseInt(sessionStorage.getItem("projectID")!);
  _contractorID = parseInt(sessionStorage.getItem("contractorId")!);
  _userIdLogin = parseInt(sessionStorage.getItem("userId")!);
  _rolePermissions = sessionStorage.getItem("rolePermissions")!;
  

  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions>;

  constructor(private contractorService: ContractorService, private interfaceService: InterfaceService,
    private interfaceGroupService: InterfaceGroupService, private elm: ElementRef, private signalrService: SignalrService,
    private userService: UserService, private SpinnerService: NgxSpinnerService, private projectService:ProjectService,
    private emailSubscriptionUserService: EmailSubscriptionUserService, private readonly viewport: ViewportScroller,
    private dialog: DialogService, private logger: NGXLogger, private moduleProjectMappingsService: ModuleProjectMappingsService,
    private communicationService: CommunicationService, private router: Router, private rolePermissionService:RolePermissionService) {
    this.commonFunction = new CommonFunction(router, projectService, rolePermissionService);
    this.chartOptions = {
      series: [1],
      colors: ['#f0f0f0'],
      chart: {
        width: this.piechartWidth,
        type: "pie"
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient"
      },
      labels: [],
      legend: {
        position: 'bottom'
      }

    };
  }

  ngOnInit(): void {
    this.signalrService.startConnection();
    this.signalrService.addNotificationListner();
    this.signalrService.getUpdatedNoification$.subscribe(notification => {
      if (notification.isAddedOrUpdated && notification.projectId == this._projectID) {
        this.isLiveNotify = true;
        this.refreshChart();
        this.refreshMatrix();
        this.refreshSummary();
        this.isLiveNotify = false;
      }
    });
    this.getModules(this._projectID);
    this.getContractorsByProjectId(this._projectID);
    this.getInterfaceGroupsByProjectId(this._projectID);
    this.getAllUsersByProjectId(this._projectID);
    this.getSubscribedUsers(this._projectID);
    const element = document.querySelector("#chart")!;
    if (element != null) {
      const value = window.getComputedStyle(element)
        .getPropertyValue('width');
      if (value != "auto")
        this.piechartWidth = Number(value.replace('px', ''));
    }

  }

  @HostListener('window:scroll', ['$event']) onScroll(event: any) {
    if (window.pageYOffset != 0) {
      this.hideScrollToTop = true;
    }
    else {
      this.hideScrollToTop = false;
    }
  }

  resetScroll(e1: HTMLElement) {
    e1.scrollTo(0, 0);
  }

  onScrollDiv(event: any): void {
    if (event.srcElement.scrollLeft != 0 || event.srcElement.scrollTop != 0) {
      this.hideResetScroll = true;
    } else {
      this.hideResetScroll = false;
    }
  }

  getModules(projectId: number) {
    try {
      this.SpinnerService.show();
      this.moduleProjectMappingsService.getModulesByProjectId(projectId).subscribe({
        next: moduleProjectMappings => {
          moduleProjectMappings.forEach(x => {
            var a = x.Module?.ModulePermissionMappings?.filter(v => v.IsProjectWise);
            var permissionindex = this._rolePermissions.indexOf(a![0].Permission?.Name!);
            if (permissionindex == -1) {
              x.IsDeleted = true;
            }
          });
          this.moduleProjectMappings = moduleProjectMappings.filter(x => !x.IsDeleted);
          let defaultModule = moduleProjectMappings.filter(x => !x.IsDeleted);
          this.selectedModuleId = defaultModule[0].ModuleId;
          this.cardHeaderLabel = defaultModule[0].Module?.Name!;
          this.getInterfacesByProjectIdAndModuleId(this._projectID, this.selectedModuleId);
          this.getOverallStatusSummary(this._projectID, this.selectedModuleId);
          this.SpinnerService.hide();
        },
        error: err => {
          this.SpinnerService.hide();
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  populateDashboard(moduleId: number, moduleName: string) {
    try {
      this.getInterfacesByProjectIdAndModuleId(this._projectID, moduleId);
      this.getOverallStatusSummary(this._projectID, moduleId);
      this.selectedModuleId = moduleId;
      this.cardHeaderLabel = moduleName;
    } catch (e) {
      this.SpinnerService.hide();
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getContractorsByProjectId(projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp9');
      }
      this.contractorService.getContractorsByProjectId(projectId).subscribe({
        next: data => {
          this.contractors = data;
          this.contractorcnt = data.length;
          this.SpinnerService.hide('sp9');
        },
        error: err => {
          this.SpinnerService.hide('sp9');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp9');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getInterfaceGroupsByProjectId(projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp9');
      }
      this.interfaceGroupService.getInterfaceGroupsforProjectAdmin(projectId).subscribe({
        next: data => {
          this.interfacegrpcnt = data.length;
          this.interfacegrps = data;
          this.SpinnerService.hide('sp9');
        },
        error: err => {
          this.SpinnerService.hide('sp9');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp9');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onChangefilter(e: MatSelectChange) {
    try {
      this.SpinnerService.show('sp11');
      this.selectedfilter = e.source.value;
      this.allOpenInterface = 0;
      this.completedInterface = 0;
      this.interfaceService.getInterfacesByProjectIdAndModuleId(this._projectID,this.selectedModuleId).subscribe({
        next: data => {
          this.SpinnerService.hide('sp11');
          this.todayDate = new Date();
          this.endDate = new Date();
          if (this.selectedfilter == "0") {
            this.allOpenInterface = data.length;
            this.completedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
          } else {
            this.endDate.setDate(this.todayDate.getDate() - Number(this.selectedfilter));
            this.allOpenInterface = data.filter(x => this.pipe.transform(x.CreatedDate, 'yyyy-MM-dd')! > this.pipe.transform(this.endDate, 'yyyy-MM-dd')!).length;
            this.completedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId && this.pipe.transform(x.CompletedDate, 'yyyy-MM-dd')! > this.pipe.transform(this.endDate, 'yyyy-MM-dd')!).length;
          }
        },
        error: err => {
          this.SpinnerService.hide('sp11');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp11');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getInterfacesByProjectIdAndModuleId(projectId: number, moduleId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp9');
      }
      this.interfaceService.getInterfacesByProjectIdAndModuleId(projectId, moduleId).subscribe({
        next: data => {
          this.interfaces = data;
          this.allOpenInterface = data.length;
          this.completedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
          this.createdInterface = data.filter(x => x.Status != myGlobals.ClosedStatusId).length;
          this.closedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
          
          this.openInterface = data.filter(x => (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId
            && x.Status != myGlobals.ClosedStatusId) && x.IsFirstIssuePlannedOverdue == false && x.IsApprovedIssuePlannedOverdue == false).length;

          this.responseOverdueInterface = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
            (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length

          this.closeOverdueInterface = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length

          this.identifiedInterface = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length;
          this.chartData = [{ value: this.identifiedInterface, name: myGlobals.IdentifiedStatusName }, { value: this.openInterface, name: myGlobals.OpenStatusName },
          { value: this.responseOverdueInterface, name: myGlobals.ResponseOverdueStatusName }, { value: this.closeOverdueInterface, name: myGlobals.CloseoutOverdueStatusName }, { value: this.closedInterface, name: myGlobals.ClosedStatusName }];
          this.isVisible = true;
          this.SpinnerService.hide('sp9');
        },
        error: err => {
          this.SpinnerService.hide('sp9');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp9');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getAllUsersByProjectId(projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp11');
      }
      this.userService.getUsersByProjectId(projectId).subscribe({
        next: users => {
          this.activeUserscnt = users.filter(x => x.UserName != 'Admin' && x.UserRoleProjects.length > 0).length;
          this.SpinnerService.hide('sp11');
        },
        error: err => {
          this.SpinnerService.hide('sp11');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp11');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  getSubscribedUsers(projectId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp11');
      }
      this.emailSubscriptionUserService.getEmailSubscribedContacts(projectId).subscribe({
        next: contacts => {
          var uniquecontacts = [];
          uniquecontacts = Array.from(new Set(contacts.map(x => x.ContactId).sort()));
          this.subscribedUserscnt = uniquecontacts.length;
          this.SpinnerService.hide('sp11');
        },
        error: err => {
          this.SpinnerService.hide('sp11');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp11');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  findInterfaceGroup(interfacegrps: InterfaceGroup[], rowContractor: Contractor, colContractor: Contractor): any[] {
    return interfacegrps.filter(x => x.Contractor2ID == rowContractor.Id && x.Contractor1ID == colContractor.Id);
  }

  findInterfaces(interfaces: Interface[], interfacegrp: InterfaceGroup): any[] {
    let filteredInterfaces = interfaces.filter(x => x.InterfaceGroupID == interfacegrp.Id);
    let result = [{ "Identified": filteredInterfaces.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length > 0 ? filteredInterfaces.filter(x => x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId  || x.Status == myGlobals.AwaitingForRequestApprovalStatusId).length : 0 },
    {
      "Open": filteredInterfaces.filter(x => (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId)
        && x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length > 0 ?
        filteredInterfaces.filter(x => (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.ClosedStatusId)
          && x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length : 0
    },
    {
      "ResponseOverdue": filteredInterfaces.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
        (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length > 0 ?
        filteredInterfaces.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
          (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length : 0
    },
    {
      "CloseOverdue": filteredInterfaces.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length > 0 ?
        filteredInterfaces.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length : 0
    },
    { "Closed": filteredInterfaces.filter(x => x.Status == myGlobals.ClosedStatusId).length > 0 ? filteredInterfaces.filter(x => x.Status == myGlobals.ClosedStatusId).length : 0 }
    ];
    return result;
  }

  getOverallStatusSummary(projectId: number, moduleId: number) {
    try {
      if (!this.isLiveNotify) {
        this.SpinnerService.show('sp10');
      }
      this.interfaceService.getInterfacesByProjectIdAndModuleId(projectId, moduleId).subscribe({
        next: data => {
          if (data.length > 0) {
            this.interfaces = data;
            this.closedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
            this.openInterface = data.filter(x => (x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId && x.Status != myGlobals.IdentifiedStatusId
              && x.Status != myGlobals.ClosedStatusId) && x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length;

            this.responseOverdueInterface = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
              (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length

            this.closeOverdueInterface = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length


            this.identifiedInterface = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length;

            this.chartOptions = {
              series: [this.identifiedInterface, this.openInterface, this.responseOverdueInterface, this.closeOverdueInterface, this.closedInterface],
              chart: {
                width: this.piechartWidth,
                type: "pie",
                events: {
                  dataPointSelection:(event, chartContext, opts) =>  {
                      this.statusSummaryClick(opts.w.config.labels[opts.dataPointIndex])
                  }
              }    
              },
              dataLabels: {
                enabled: true,
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                  return w.config.series[seriesIndex]
                },
                textAnchor: "middle",
                style: {
                  colors: ["#fff"]
                },
                offsetX: 0,
                dropShadow: {
                  enabled: true
                }
              },
              fill: {
                type: "gradient"
              },
              colors: [myGlobals.identifiedcolor, myGlobals.opencolor, myGlobals.responseoverduecolor, myGlobals.overduecolor, myGlobals.closedcolor],
              labels: [myGlobals.IdentifiedStatusName, myGlobals.OpenStatusName, myGlobals.ResponseOverdueStatusName, myGlobals.CloseoutOverdueStatusName, myGlobals.ClosedStatusName],
              legend: {
                position: 'bottom',
                onItemClick: {
                  toggleDataSeries: false
                }
              }
            };
          } else {
            this.chartOptions = {
              series: [1],
              colors: ['#f0f0f0'],
              chart: {
                width: this.piechartWidth,
                type: "pie"
              },
              dataLabels: {
                enabled: false
              },
              fill: {
                type: "gradient"
              },
              labels: [],
              legend: {
                position: 'bottom'
              }

            };
          }
          this.isChartVisible = true;
          this.SpinnerService.hide('sp10');
        },
        error: err => {
          this.SpinnerService.hide('sp10');
          this.errorMessage = err
          this.dialog.confirmDialog({
            title: 'Error',
            message: myGlobals.exceptionmessage,
            cancelCaption: 'Close',
          });
          this.logger.error(err);
        }
      })
    } catch (e) {
      this.SpinnerService.hide('sp10');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  statusSummaryClick(statusName: string){
    try {
      var statusId = 0;
      switch (statusName.toLowerCase()) {
        case "identified":
          statusId = myGlobals.IdentifiedStatusId;
          break;
        case "open":
          statusId = myGlobals.OpenStatusId;
          break;
        case "response overdue":
          statusId = myGlobals.ResponseOverdueStatusId;
          break;
        case "closeout overdue":
          statusId = myGlobals.CloseoutOverdueStatusId;
          break;
        case "closed":
          statusId = myGlobals.ClosedStatusId; 
          break;
      }
    
      this.redirectToInterface(0, statusId);

    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToInterface(Id: number, status: number) {
    try {
      this.communicationService._interfaceGroupId = Id;
      this.communicationService._statusId = [status];
      var endDateFilter = new Date();         
      if(this.selectedfilter == "0"){
        endDateFilter = null!;      
      }else{
        endDateFilter.setDate(new Date().getDate() - Number(this.selectedfilter));      
      }

      this.communicationService._filterDate = endDateFilter;
      this.commonFunction.redirectToRegisterpage(this.selectedModuleId);
    } catch (e) {
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  redirectToUsers() {
    this.router.navigateByUrl('/Users');
  }

  redirectToInterfaceGroups() {
    this.router.navigateByUrl('/InterfaceGroups');
  }

  redirectToNotificationSubscription() {
    this.router.navigateByUrl('/Subscriptions');
  }

  redirectToContractor() {
    this.router.navigateByUrl('/Contractors');
  }
  
  showBarSummary() {
    try {
      this.SpinnerService.show('sp10');
      this.isBarShown = true;
      var data = this.interfaces;
      this.closedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
      this.openInterface = data.filter(x => (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId
        && x.Status != myGlobals.ClosedStatusId) && x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length;

      this.responseOverdueInterface = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
        (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length

      this.closeOverdueInterface = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length

      this.identifiedInterface = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length;

      this.chartOptions = {
        series: [{
          data: [this.identifiedInterface, this.openInterface, this.responseOverdueInterface, this.closeOverdueInterface, this.closedInterface],
          name: this.cardHeaderLabel
        }],
        chart: {
          height: '300',
          width: '80%',
          type: "bar",
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          },
          toolbar: {
            export: {
              csv: {
                filename: this.cardHeaderLabel + "StatusSummary_Bar",
              },
              svg: {
                filename: this.cardHeaderLabel + "StatusSummary_Bar",
              },
              png: {
                filename: this.cardHeaderLabel + "StatusSummary_Bar",
              }
            },
          },
          events: {
            dataPointSelection: (event, chartContext, opts) => {
              this.statusSummaryClick(opts.w.config.xaxis.categories[opts.dataPointIndex])
            }
          }
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: "center"
            }
          }
        },
        colors: [myGlobals.identifiedcolor, myGlobals.opencolor, myGlobals.responseoverduecolor, myGlobals.overduecolor, myGlobals.closedcolor],
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          style: {
            colors: ["#fff"]
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        title: {
          text: ''
        },
        xaxis: {
          categories: [myGlobals.IdentifiedStatusName, myGlobals.OpenStatusName, myGlobals.ResponseOverdueStatusName, myGlobals.CloseoutOverdueStatusName, myGlobals.ClosedStatusName]
        },
        legend: {
          show: false,
          position: "bottom",
          horizontalAlign: "center",
          offsetY: 0
        }
      };
      this.isChartVisible = true;
      this.SpinnerService.hide('sp10');
    } catch (e) {
      this.SpinnerService.hide('sp10');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  showpieSummary() {
    try {
      this.SpinnerService.show('sp10');
      this.isBarShown = false;
      var data = this.interfaces;
      if (data.length > 0) {
        this.interfaces = data;
        this.closedInterface = data.filter(x => x.Status == myGlobals.ClosedStatusId).length;
        this.openInterface = data.filter(x => (x.Status != myGlobals.IdentifiedStatusId && x.Status != myGlobals.AdditionalInformationRequiredStatusId && x.Status != myGlobals.AwaitingForRequestApprovalStatusId
          && x.Status != myGlobals.ClosedStatusId) && x.IsApprovedIssuePlannedOverdue == false && x.IsFirstIssuePlannedOverdue == false).length;

        this.responseOverdueInterface = data.filter(x => ((x.IsFirstIssuePlannedOverdue == true && x.Response == "") ||
          (x.IsFirstIssuePlannedOverdue == true && x.Status == myGlobals.AwaitingForResponseApprovalStatusId && x.Response != ""))).length

        this.closeOverdueInterface = data.filter(x => (x.IsApprovedIssuePlannedOverdue == true && x.Response != "" && x.Status != myGlobals.AwaitingForResponseApprovalStatusId)).length

        this.identifiedInterface = data.filter(x => (x.Status == myGlobals.IdentifiedStatusId || x.Status == myGlobals.AdditionalInformationRequiredStatusId || x.Status == myGlobals.AwaitingForRequestApprovalStatusId)).length;

        this.chartData = [{ value: this.identifiedInterface, name: myGlobals.IdentifiedStatusName }, { value: this.openInterface, name: myGlobals.OpenStatusName },
        { value: this.responseOverdueInterface, name: myGlobals.ResponseOverdueStatusName }, { value: this.closeOverdueInterface, name: myGlobals.CloseoutOverdueStatusName }, { value: this.closedInterface, name: myGlobals.ClosedStatusName }];

        this.chartOptions = {
          series: [this.identifiedInterface, this.openInterface, this.responseOverdueInterface, this.closeOverdueInterface, this.closedInterface],
          chart: {
            width: this.piechartWidth,
            type: "pie",
            events: {
              dataPointSelection: (event, chartContext, opts) => {
                this.statusSummaryClick(opts.w.config.labels[opts.dataPointIndex])
              }
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function (value, { seriesIndex, dataPointIndex, w }) {
              return w.config.series[seriesIndex]
            },
            textAnchor: "middle",
            style: {
              colors: ["#fff"]
            },
            offsetX: 0,
            dropShadow: {
              enabled: true
            }
          },
          fill: {
            type: "gradient"
          },
          colors: [myGlobals.identifiedcolor, myGlobals.opencolor, myGlobals.responseoverduecolor, myGlobals.overduecolor, myGlobals.closedcolor],
          labels: [myGlobals.IdentifiedStatusName, myGlobals.OpenStatusName, myGlobals.ResponseOverdueStatusName, myGlobals.CloseoutOverdueStatusName, myGlobals.ClosedStatusName],
          legend: {
            position: 'bottom',
            onItemClick: {
              toggleDataSeries: false
            }
          }
        };

      } else {
        this.chartOptions = {
          series: [1],
          colors: ['#f0f0f0'],
          chart: {
            width: this.piechartWidth,
            type: "pie"
          },
          dataLabels: {
            enabled: false
          },
          fill: {
            type: "gradient"
          },
          labels: [],
          legend: {
            position: 'bottom'
          }

        };
      }
      this.isChartVisible = true;
      this.SpinnerService.hide('sp10');
    } catch (e) {
      this.SpinnerService.hide('sp10');
      this.dialog.confirmDialog({
        title: 'Error',
        message: myGlobals.exceptionmessage,
        cancelCaption: 'Close',
      });
      this.logger.error(e);
    }
  }

  onScrollToTop(): void {
    this.viewport.scrollToPosition([0, 0]);
  }

  refreshMatrix() {
    this.getContractorsByProjectId(this._projectID);
    this.getInterfaceGroupsByProjectId(this._projectID);
    this.getInterfacesByProjectIdAndModuleId(this._projectID, this.selectedModuleId);
  }

  refreshChart() {
    this.getOverallStatusSummary(this._projectID, this.selectedModuleId);
    const element = document.querySelector("#chart")!;
    const value = window.getComputedStyle(element)
      .getPropertyValue('width');
    if (value != "auto")
      this.piechartWidth = Number(value.replace('px', ''));
  }

  refreshSummary() {
    this.getContractorsByProjectId(this._projectID);
    this.getInterfaceGroupsByProjectId(this._projectID);
    this.getInterfacesByProjectIdAndModuleId(this._projectID, this.selectedModuleId);
    this.getAllUsersByProjectId(this._projectID);
    this.getSubscribedUsers(this._projectID);
    this.selectedfilter = this.daysfilters[3].value;
  }
}

