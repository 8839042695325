import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortenPipe } from './shorten.pipe';
import { UtcToLocalTime } from './utcToLocalTime.pipe';



@NgModule({
  declarations: [ShortenPipe, UtcToLocalTime],
  imports: [
    CommonModule
  ],
  exports: [ShortenPipe, UtcToLocalTime]
})
export class CommonpipesModule { }
