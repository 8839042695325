import { Interface } from "./interface";

export class StatusCard {
  Name: string = "";
  Requestordata: Interface[]=[];
  Providerdata: Interface[] = [];
  Icon: string = "";
  IconClass: string = "";
  IsDeleted!: boolean;
  IsRequestorOnly!: boolean;
  IsOverdue!: boolean;
  IsApprovedIssuePlannedOverdue!: boolean;
}
